import React, { AllHTMLAttributes, FC } from "react";
import styles from "./index.module.css";
import cn from "classnames";

type propsType = {
} & AllHTMLAttributes<HTMLDivElement>;

export const Subtitle: FC<propsType> = ({ children, ...props }) => {
  return <div className={cn(styles.container, props.className, {[styles.disabled]: props.disabled})}>{children}</div>;
};
