import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import styles from "./index.module.css";
import { SmartForm } from "../../../../hocs/SmartForm";
import { RangeWithTitle } from "../../../../ui/RangeWithTitle";
import { Button } from "../../../../ui/Button";
import { InputNumber } from "../../../../ui/Inputs/InputNumber";
import { Icons } from "../../../../../assets/icons";
import { InputText } from "../../../../ui/Inputs/InputText";
import {
  onPastePhoneNumberHandler,
  stringHelpers,
} from "../../../../../helpers/string";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { newLoanFormType } from "./types";
import { VinAndLicensePlate } from "../../../../ui/VinAndLicensePlate";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { yupResolver } from "@hookform/resolvers/yup";
import { newLoanSchema } from "./schema";

export const monthRangeData = ["24 месяца", "36 месяцев", "48 месяцев"];

export const NewLoan: FC = () => {
  //test
  const dispatch = useDispatch();
  const [disabledButton, setDisabledButton] = useState(false);
  const [disabledButtonLength, setDisabledButtonLength] = useState(false);
  const loading = useSelector(
    processLoading.get(applicationSagaActions.createApplication.type)
  );
  const amount = lsController.get("amount");
  const period_month = lsController.get("period_month");
  const form = useForm<newLoanFormType & helperFields>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(newLoanSchema),
    defaultValues: {
      loan_data: {
        amount: amount ? +amount : 80000,
        loan_term: period_month ? +period_month : 24,
      },
    },
  });
  // Типы-костыли, чтобы положить госномер или вин в одно поле для бека
  type helperFields = {
    vin_or_license_plate_selected: boolean;
    vin_number: string;
    reg_number: string;
  };

  const submit = (data: newLoanFormType & helperFields) => {
    const vin_or_reg_number = data.vin_or_license_plate_selected
      ? data.reg_number
      : data.vin_number;
    const comfortablePayment = +String(data.comfortable_payment)?.replaceAll(
      " ",
      ""
    );
    dispatch(
      applicationSagaActions.createApplication({
        email: data.email,
        trusted_person_phone: stringHelpers.clearString(
          data.trusted_person_phone
        ),
        comfortable_payment: comfortablePayment,
        monthly_income: +String(data.monthly_income)?.replaceAll(" ", ""),
        current_loans_payment: +String(data.current_loans_payment)?.replaceAll(
          " ",
          ""
        ),
        vin_or_reg_number,
        loan_data: {
          amount: data.loan_data.amount,
          loan_term: data.loan_data.loan_term.toString(),
        },
      })
    );
  };

  /*
    TODO убрать все useWatch, которые используются для валидации
    Переписать валидацию на yup
   */

  const trustedPersonPhone = useWatch({
    control: form.control,
    name: "trusted_person_phone",
  });

  const comfortablePayment = useWatch({
    control: form.control,
    name: "comfortable_payment",
  });

  const monthlyIncome = useWatch({
    control: form.control,
    name: "monthly_income",
  });
  const email = useWatch({
    control: form.control,
    name: "email",
  });
  const currentLoansPayment = useWatch({
    control: form.control,
    name: "current_loans_payment",
  });
  const isChecked = useWatch({
    control: form.control,
    name: "vin_or_license_plate_selected",
  });

  const vinNumber = useWatch({
    control: form.control,
    name: "vin_number",
  });

  const regNumber = useWatch({
    control: form.control,
    name: "reg_number",
  });
  //проверка на количество символов
  let validationСheck =
    trustedPersonPhone?.length > 15 &&
    comfortablePayment?.length > 0 &&
    monthlyIncome?.length > 0 &&
    currentLoansPayment?.length > 0 &&
    email?.length > 0;

  //проверка на количество символов в vin и reg полях
  useEffect(() => {
    if (isChecked) {
      if (
        typeof regNumber == "undefined" ||
        regNumber?.length == 0 ||
        regNumber?.length < 8
      ) {
        setDisabledButton(true);
      } else {
        setDisabledButton(false);
      }
    } else {
      if (
        typeof vinNumber == "undefined" ||
        vinNumber?.length == 0 ||
        vinNumber?.length < 17
      ) {
        setDisabledButton(true);
      } else {
        setDisabledButton(false);
      }
    }

    if (!validationСheck) {
      setDisabledButtonLength(true);
    } else {
      setDisabledButtonLength(false);
    }
  });

  useEffect(() => {
    if (+comfortablePayment?.replaceAll(" ", "") > 1000000) {
      form.setError("comfortable_payment", {
        type: "custom",
        message: "Сумма не должна превышать 1 000 000 р.",
      });
      setDisabledButton(true);
    } else if (+monthlyIncome?.replaceAll(" ", "") > 1000000) {
      form.setError("monthly_income", {
        type: "custom",
        message: "Сумма не должна превышать 1 000 000 р.",
      });
    } else if (+currentLoansPayment?.replaceAll(" ", "") > 1000000) {
      form.setError("current_loans_payment", {
        type: "custom",
        message: "Сумма не должна превышать 1 000 000 р.",
      });
    } else {
      setDisabledButton(false);
    }
  }, [comfortablePayment, monthlyIncome, currentLoansPayment]);

  if (trustedPersonPhone?.length === 0) {
    form.setValue("trusted_person_phone", "+7");
  }

  console.log();

  useEffect(() => {
    const grzFromLs = localStorage.getItem("grz");
    if (grzFromLs) {
      form.setValue("vin_or_license_plate_selected", true);
      form.setValue("reg_number", grzFromLs);
    }
  }, []);

  return (
    <div className={styles.container}>
      <SmartForm form={form} submit={submit} className={styles.form}>
        <RangeWithTitle
          step={10000}
          min={80000}
          max={500000}
          title={"Сумма займа, ₽"}
          name={"loan_data.amount"}
          containerClass={styles.loan_amount}
        />

        <RangeWithTitle
          marks
          min={24}
          max={48}
          step={12}
          hasInput={false}
          title={"Срок займа"}
          rangeData={monthRangeData}
          name={"loan_data.loan_term"}
          containerClass={styles.loan_amount}
        />

        <div className={styles.grid}>
          <InputNumber
            inputMode={"numeric"}
            // isAllowed={({ floatValue }) => {
            //   return Number(floatValue) >= 0 && Number(floatValue) <= 1000000;
            // }}
            support={<Icons.ui.Ruble data-class={"dim"} />}
            thousandsGroupStyle={"thousand"}
            name={"comfortable_payment"}
            label={"Комфортный платеж"}
            variant={"large"}
            min={0}
            max={1000000}
            maxLength={9}
          />

          <InputNumber
            inputMode={"numeric"}
            support={<Icons.ui.Ruble data-class={"dim"} />}
            thousandsGroupStyle={"thousand"}
            label={"Ежемесячный доход"}
            name={"monthly_income"}
            variant={"large"}
            min={0}
            max={1000000}
            maxLength={9}
          />

          <InputNumber
            // isAllowed={({ floatValue }) => {
            //   return Number(floatValue) >= 0 && Number(floatValue) <= 1000000;
            // }}
            inputMode={"numeric"}
            thousandsGroupStyle={"thousand"}
            support={<Icons.ui.Ruble data-class={"dim"} />}
            label={"Платежи по текущим кредитам"}
            name={"current_loans_payment"}
            variant={"large"}
            min={0}
            max={1000000}
            maxLength={9}
          />

          <InputText
            variant={"large"}
            label={"e-mail"}
            name={"email"}
            maxLength={255}
          />

          <InputNumber
            inputMode={"tel"}
            onPaste={onPastePhoneNumberHandler}
            onBlur={() => onPastePhoneNumberHandler}
            label="Телефон доверенного лица"
            name="trusted_person_phone"
            defaultValue={"+7"}
            format="+# ### ### ## ##"
            variant={"large"}
            noNumericString
            minLength={16}
            min={16}
            maxLength={16}
          />

          <VinAndLicensePlate
            setError={form.setError}
            className={styles.vin_and_license_plate}
          />
        </div>

        <Button
          disabled={disabledButton || disabledButtonLength}
          loading={loading}
          style={{ marginTop: "55px" }}
        >
          Продолжить
        </Button>
      </SmartForm>
    </div>
  );
};
