import React, { FC, useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { motion } from "framer-motion";
import { Icons } from "assets/icons";
import { RangeWithTitleVariant2 } from "../../../../../ui/RangeWithTitleVariant2";
import { SwitcherTriple } from "../../../../../ui/SwitcherTriple";
import { Loader } from "../../../../../ui/Loader";
import { Button } from "../../../../../ui/Button";
import { dateMethods } from "../../../../../../helpers/date";
import { stringHelpers } from "../../../../../../helpers/string";

type propsType = {
  animated?: boolean;
  scheduleLoading?: boolean;
  approve_button?: boolean;
  monthly_payment?: number;
  amount?: number;
  loan_term?: number;
  className?: string;
  loading: boolean;
  primaryColor?: "blue";
};

export const ConditionFioComponent: FC<propsType> = ({
  animated = false,
  scheduleLoading,
  approve_button = true,
  monthly_payment,
  loading,
  className,
  children,
  amount,
  loan_term,
  primaryColor,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      className={cn(styles.container, {
        [`${className}`]: className,
      })}
      initial={animated && { height: 138 }}
      animate={animated && { height: isOpen ? 426 : 138 }}
      transition={{ type: "spring", bounce: 0.25, duration: 0.3, }}
    >
      <div className={styles.chosen_conditions}>
        <div>
          Вы выбрали{" "}
          {amount
            ? stringHelpers.transformMoneyValue(amount) + " ₽"
            : "500000 ₽"}{" "}
          <br /> на{" "}
          {loan_term
            ? loan_term + " " + dateMethods.getMonthString(loan_term)
            : "48 месяцев"}
        </div>
        <div
          className={cn(styles.dropdown, {
            [styles.dropdown_open]: isOpen,
            [styles.dropdown_open_blue]: isOpen && primaryColor === "blue"
          })}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className={styles.dropdown_container}>
            <div className={cn(styles.dropdown_text, {
              [styles.dropdown_text_open]: isOpen,
              [styles.dropdown_text_open_blue]: isOpen && primaryColor === "blue"
            })}>
              {/*{isOpen ? "Отменить" : "Изменить"}*/}
            </div>
            <Icons.ui.DropDown />
          </div>
        </div>
      </div>
      {isOpen ? (
        <motion.div
        // variants={dropdownMenuAnimation}
        // initial={"close"}
        // animate={isOpen ? "open" : "close"}
        >
          {children ? (
            children
          ) : (
            <>
              <RangeWithTitleVariant2
                step={10000}
                min={80000}
                max={500000}
                title={"Сумма займа, ₽"}
                name={"loan_data.amount"}
                containerClass={styles.loan_amount}
                primaryColor={primaryColor}
              />
              <div className={styles.divider} />
              <SwitcherTriple
                name={"loan_data.loan_term"}
                outerLabel={"Срок займа"}
                values={[24, 36, 48]}
                innerLabels={["24 месяца", "36 месяцев", "48 месяцев"]}
                switchVariant={"huge_gender"}
                variant={"large"}
              />
            </>
          )}
        </motion.div>
      ) : (
        <div className={styles.divider_thin} />
      )}
      <div
        className={cn(styles.monthly_payment, {
          [styles.monthly_payment_open]: isOpen,
        })}
      >
        Ежемесячный платеж:{" "}
        <span>
          {monthly_payment
            ? stringHelpers.transformMoneyValue(monthly_payment.toFixed(2)) + " ₽"
            : <Loader color={"blue"} />
          }
        </span>
      </div>
      {isOpen && approve_button && (
        <Button
          className={cn(styles.button, {
            [styles.button_blue]: primaryColor === "blue"
          })}
          loading={loading}
          loadingText={"Формирование документов"}
        >
          Подтвердить
        </Button>
      )}
    </motion.div>
  );
};
