import { FC } from "react";
import styles from "./index.module.css";
import { Icons } from "../../../assets/icons";
import cn from "classnames";

export const Info: FC = () => {
  const nbsp = "\u00A0";

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.container}>
          <Icons.loader.LoaderName className={styles.header_name} />
          <div className={styles.header_icons}>
            <Icons.info.InfoIcon className={styles.header_icon} />
            <Icons.info.InfoIcon className={styles.header_icon_next} />
          </div>
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles.title}>Общая информация</div>
          <div className={styles.textUnderTitle}>
            Микрокредитная компания Кредди (ООО МКК "Кредди") специализируется
            на выдаче займов под залог транспортных средств в Российской
            Федерации. С более подробной информацией вы можете ознакомиться на
            страницах{" "}
            <a
              href={"https://creddy.ru/clients"}
              target={"_blank"}
              className={styles.link}
            >
              клиентам
            </a>
            ,{" "}
            <a
              href={"https://creddy.ru/partners"}
              target={"_blank"}
              className={styles.link}
            >
              партнерам
            </a>{" "}
            или
            <a href={"https://creddy.ru/faq_pts"} className={styles.link}>
              FAQ
            </a>
            .
          </div>

          <div className={styles.line} />
          <div className={styles.subTile}>Подробная информация</div>
          <div className={styles.textBlock}>
            <span className={styles.textBold}>
              Полное фирменное наименование:
            </span>
            <span className={styles.text}>
              Общество {nbsp} с ограниченной ответственностью «Микрокредитная
              компания Кредди»
            </span>
          </div>
          <div className={styles.textBlock}>
            <span className={styles.textBold}>
              Сокращенное фирменное наименование:
            </span>
            <span className={styles.text}>{""} ООО МКК «Кредди»</span>
          </div>
          <div className={styles.textBlock}>
            <span className={styles.textBold}>ИНН: </span>
            <span className={styles.text}>9710037450</span>
          </div>
          <div className={styles.textBlock}>
            <span className={styles.textBold}>ОГРН: </span>
            <span className={styles.text}>5177746022262</span>
          </div>
          <div className={styles.textBlock}>
            <span className={styles.textBold}>
              Ссылка на официальный сайт Банка России в
              информационно-телекоммуникационной сети «Интернет»:{" "}
            </span>
            <a
              href={"https://cbr.ru/"}
              target={"_blank"}
              className={styles.textLink}
            >
              https://cbr.ru/
            </a>
          </div>

          <div className={styles.textBlock}>
            <span className={styles.textBold}>
              Ссылка на страницу Банка России, содержащую государственный реестр
              микрофинансовых организаций:{" "}
            </span>
            <a
              href={"https://cbr.ru/microfnance/registrv/"}
              target={"_blank"}
              className={styles.textLink}
            >
              {" "}
              https://cbr.ru/microfnance/registrv/.
            </a>
          </div>

          <div className={styles.textBlock}>
            <span className={styles.textBold}>
              Регистрационный номер записи в государственном реестре
              микрофинансовых организаций:{" "}
            </span>
            <span className={styles.text}>1703045008644 от 01.12.2017.</span>
          </div>

          <div className={styles.textBlock}>
            <span className={styles.textBold}>
              Место непосредственного ведения деятельности:{" "}
            </span>
            <span className={styles.text}>
              109544, г. Москва, вн.тер.г. муниципальный округ Таганский, б-р
              Энтузиастов, д. 2, помещ. 7/7
            </span>
          </div>

          <div className={styles.textBlock}>
            <span className={styles.textBold}>Контактный телефон: </span>
            <a href={"tel:: 88007078665"} className={styles.textLink}>
              8 (800) 707-86-65
            </a>
          </div>

          <div className={styles.textBlock}>
            <span className={styles.textBold}>Контактный e-mail: </span>
            <a href={"mailto::support@creddy.ru"} className={styles.textLink}>
              support@creddy.ru
            </a>
          </div>

          <div className={styles.textBlock}>
            <span className={styles.textBold}>
              Адрес (местонахождение) СРО «МиР»:{" "}
            </span>
            <span className={styles.text}>
              107078, г. Москва Орликов переулок, д.5, стр. 1, этаж 2, пом.11
            </span>
          </div>

          <div className={styles.textBlock}>
            <span className={styles.textBold}>
              Официальный сайт СРО «МиР» в информационно-телекоммуникационной
              сети «Интернет»:{" "}
            </span>
            <a
              href={"https://npmir.ru/"}
              target={"_blank"}
              className={styles.textLink}
            >
              https://npmir.ru/
            </a>
          </div>
          <div className={styles.textBlock}>
            <span className={styles.textBold}>
              Ссылка на страницу интернет-приемной Банка России на сайте Банка
              России:{""}
            </span>
            <a
              href={"https://cbr.ru/Reception/"}
              target={"_blank"}
              className={styles.textLink}
            >
              https://cbr.ru/Reception/
            </a>
          </div>

          <div className={styles.textBlock}>
            <span className={styles.textBold}>
              Правила предоставления микрозаймов ООО MKK "Кредди":{" "}
            </span>
            <a
              href="https://static.creddy.ru/Правила_предоставления_микрозаймов_ООО_MKK_Кредди.pdf"
              target={"_blank"}
              className={styles.textLink}
            >
              https://static.creddy.ru/
            </a>
          </div>

          <div className={styles.textBlock}>
            <span className={styles.textBold}>
              Личный кабинет получателя финансовой услуги:{" "}
            </span>
            <a
              href="https://client.creddy.ru/user/personal_cabinet"
              target={"_blank"}
              className={styles.textLink}
            >
              https://client.creddy.ru/
            </a>
          </div>

          <div className={styles.textBlock}>
            <span className={styles.textBold}>Интернет-приёмная ФССП: </span>
            <a
              href="https://fssp.gov.ru/welcome/form"
              target={"_blank"}
              className={styles.textLink}
            >
              https://fssp.gov.ru/welcome/form
            </a>
          </div>

          <div className={styles.textBlock}>
            <span className={styles.textBold}>
              Официальный сайт финансового уполномоченного в сети «Интернет»:{" "}
            </span>
            <a
              href="https://finombudsman.ru/"
              target={"_blank"}
              className={styles.textLink}
            >
              https://finombudsman.ru/
            </a>
          </div>

          <div className={styles.textBlock}>
            <span className={styles.textBold}>
              Персональный состав органов управления ООО МКК «Кредди»:{" "}
            </span>
            <span className={styles.text}>
              Генеральный директор Марьенко Денис Вадимович, с 24.08.2023 года.
            </span>
          </div>

          <div className={cn(styles.text, styles.textUnderBlock)}>
            Общество с ограниченной ответственностью «Микрокредитная компания
            Кредди» является членом Саморегулируемой организации Союз
            микрофинансовых организаций «Микрофинансирование и развитие»,
            регистрационный номер в реестре членов СРО «МиР» 77000960 от
            23.05.2018
          </div>

          <div className={cn(styles.text, styles.textUnderBlock)}>
            ООО МКК «Кредди» использует файлы «cookie» с целью персонализации
            сервисов и повышения удобства пользования веб-сайтом. Если вы не
            хотите использовать файлы «cookie», пожалуйста, измените настройки
            вашего браузера.
          </div>

          <div className={cn(styles.text, styles.textUnderBlock)}>
            Информация об условиях предоставления, использования и возврата
            микрозайма по продукту{" "}
            <a
              href={"https://creddy.ru/faq_pts"}
              target={"_blank"}
              className={styles.textLink}
            >
              «Займ под залог авто»
            </a>
            . © 2022 Creddy. Все права соблюдены.
          </div>
        </div>
      </div>
    </div>
  );
};
