import * as yup from "yup";
import { validationsMessages } from "../../../../../helpers/validation/validationsMessages";

export const loanProcessingSchema = yup.object().shape({
  loan_data: yup.object().shape({
    card_number: yup.string().required(validationsMessages.required),
    date: yup.string().required(validationsMessages.required),
    card_cvv: yup.string().required(validationsMessages.required),
  }),
});
