import React, { FC, ReactFragment } from "react";
import styles from "./index.module.css";

type propsType = {
  title: ReactFragment;
  date?: string;
  amount?: string;
  style?: any;
};

export const Divider = (props: any) => {
  return <div className={styles.divider} {...props} />;
};

export const DocumentsBlockHeader: FC<propsType> = ({
  title,
  date,
  amount,
  ...jsxAttr
}) => {
  return (
    <div className={styles.container} {...jsxAttr}>
      <div className={styles.flex}>
        <div>
          {title}
          {date && ` | ${date}`}
        </div>
        {amount && <div className={styles.amount}>{amount} &#8381;</div>}
      </div>
      <Divider />
    </div>
  );
};
