import React, { FC } from "react";
import { LoadingPage } from "../../LoadingPage";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../../../../store/reducers/application";
import { useQuery } from "react-query";
import { API } from "../../../../../../api";
import { routes } from "../../../../../../routes";
import { applicationType } from "../../../../../../types/application/types";
import { Icons } from "assets/icons";
import styles from "./index.module.css";
import { lsController } from "../../../../../../store/auxiliary/localStorage";
import { FillingAssistance } from "../../../../../ui/FillingAssistance";

type propsType = {};
export const SignedIssuingProcess: FC<propsType> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const application_id = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const query = useQuery(
    "SignedIssuingProcess",
    () =>
      API.main.application.getApplication({ applicationId: application_id }),
    {
      enabled: application_id !== null,
      refetchInterval: 10000,
      onSuccess: (data: any) => {
        const application: applicationType = data?.data?.data;
        const error_type: applicationType = data?.data?.data?.error_type;

        dispatch(
          applicationReducer.actions.setApplicationType({
            application_type: application?.application_type,
          })
        );

        if (application?.application_type === "issued_final") {
          history.push(routes.application.statuses.issued_final);
        } else if (application?.application_type === "error_final") {
          dispatch(
            applicationReducer.actions.setError({
              error_message: application?.error_message,
            })
          );
          history.push(routes.application.statuses.error_final);
        } else if (
          application?.application_type === "signed_issuing_sbp_error"
        ) {
          lsController.set("bank_name", application?.bank_card?.bank);
          history.push(
            `/application/sbp_check_error/${
              error_type ? error_type : "sbp_error"
            }`
          );
        }
      },
    }
  );

  return (
    <div className={styles.container}>
      <LoadingPage
        title={"Договор подписан"}
        subtitle={"Ожидайте зачисления денег"}
      />
      <FillingAssistance className={styles.footer} />
    </div>
  );
};
