import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../../../../store/reducers/application";
import { useQuery } from "react-query";
import { API } from "../../../../../../api";
import { routes } from "../../../../../../routes";
import { LoadingPage } from "../../LoadingPage";
import { applicationType } from "../../../../../../types/application/types";
import { useGetInfoFromApplication } from "../../../../../../hooks/api/useGetInfoFromApplication";

type propsType = {};

/*
  Статус проверки СБП
 */
export const AccountCheck: FC<propsType> = (props) => {
  const history = useHistory();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { application_type, error_type } = useGetInfoFromApplication(3000);

  useEffect(() => {
    if (application_type === "signing_applying_agreement_filling") {
      history.push(routes.application.account_confirmation);
    }
    if (application_type === "survey_filling") {
      history.push(routes.application.sbp_bank_filling);
    }
    if (application_type === "sbp_check_error") {
      history.push(`/application/sbp_check_error/${error_type}`);
    }
  }, [application_type]);

  return (
    <LoadingPage
      banner
      title={"Подождите, пожалуйста"}
      subtitle={"Проверяем ваш счет в СБП"}
      countdownTime={300}
    />
  );
};
