import { PayloadAction } from "@reduxjs/toolkit";
import { createPasswordType } from "../../../api/auth/types";
import { processStateController } from "../../util/helpers/processStateController";
import { sagaApiType } from "../../util/types";
import { call, put, select } from "redux-saga/effects";
import { API } from "../../../api";
import { authSagaActions } from "../actions";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { userReducer } from "../../../store/reducers/user";
import { userReducerSelectors } from "../../../store/reducers/user/selectors";
import { sagaActions } from "../../util/sagaActions";
import { errorsReducer } from "../../../store/auxiliary/errorsReducer";

export function* createPasswordSaga({
  payload,
  type: actionType,
}: PayloadAction<createPasswordType>) {
  const process = processStateController(actionType);

  try {
    const code: string = yield select(userReducerSelectors.getCode);

    yield process.start();
    const response: sagaApiType = yield call(API.auth.createPassword, {
      // ...payload,
      code,
    });

    const { token } = response.data.data;
    yield put(userReducer.actions.setToken({ token }));

    yield put(sagaActions.auth.getToken({}));

    // yield redirect(routes.auth.phone);
  } catch (e) {
    // yield call(errorHandlerSaga, {
    //   response: e,
    //   handledStatuses: [400],
    //   processType: authSagaActions.createPassword.type,
    // });
  } finally {
    yield process.stop();
  }
}
