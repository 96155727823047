import React, { useState } from "react";
import { createFC } from "../../../../../helpers/createFC";
import { PhoneEditable } from "../../components/PhoneEditable";
import { routes } from "../../../../../routes";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { StateType } from "../../../../../store";
import { Button } from "../../../../ui/Button";
import styles from "./index.module.css";
import { applicationApi } from "../../../../../api/main/application";
import { userReducerSelectors } from "../../../../../store/reducers/user/selectors";
import { authApi } from "../../../../../api/auth";
import { useCountdown } from "../../../../../helpers/date/useCountdown";
import { isMobile } from "react-device-detect";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { RoundContainer } from "../../../../ui/Containers/RoundContainer";
import { Icons } from "assets/icons";
import cn from "classnames";
import { stringHelpers } from "../../../../../helpers/string";
import { CodeTimer } from "../../../Application/components/CodeTimer";
import { API } from "../../../../../api";
import { sendMetrics } from "../../../../../metrics";
import { useCodeFieldLogic } from "../../../../ui/CodeField/useCodeFieldLogic";
import { errorsReducer } from "../../../../../store/auxiliary/errorsReducer";

type propsType = {};

export const ToMobileDevice = createFC((props: propsType) => {
  const history = useHistory();
  const phoneNumber = useSelector(
    (state: StateType) => state.userReducer.phone
  );

  const [retryCount, setRetryCount] = useState(0);

  const { timeLeft, setTimeLeft } = useCountdown(0);

  const disableButton = retryCount !== 0 && Boolean(timeLeft);

  const [codeRequested, setCodeRequested] = useState(true);

  const resend = () => {
    setCodeRequested(true);
    API.auth
      .code({ smsTemplate: "approve_application_logon_mobile" })
      .then((res) => {
        if (res.status === 201) {
          sendMetrics(
            "common-client_otpravlen-nomer-dlya-registracii_common-app",
            "otpravlen-nomer-dlya-registracii"
          );
        }
      });
    // dispatch(authSagaActions.getCode({ smsTemplate: codeMode }));
  };

  // Регистрация нового пользователя (нет в БД) - надо отправлять -POST/user/{user_id}/code в АУС
  // Пользователь есть в  бд, есть заявка - надо отправлять -PUT/user/{user_id}/continue-application-sms МЕЙН
  const smsRequestResolver = () => {
    return authApi.code({
      smsTemplate: "approve_application_logon_mobile",
    });
  };

  console.log(retryCount, "retryCount");

  return (
    <MobilePageContainer className={styles.form}>
      <RoundContainer className={styles.round_container}>
        <Icons.ui.ToMobileIcon className={styles.icon} />
        <div className={styles.title}>Перейдите на телефон</div>
        <div className={styles.additional_title}>
          Сейчас нам нужно получить от вас фотографии документов. Так мы
          проверим, что заявку подаете действительно Вы. Нажмите на кнопку
          «Отправить СМС», мы отправим вам СМС со ссылкой для входа. Откройте
          эту ссылку на телефоне и сделайте нужные фотографии
        </div>
        <div className={styles.auth_block_code}>
          <div>{stringHelpers.phoneNormalizeSecondVariant(phoneNumber)}</div>
          <div>
            <Icons.ui.PencilPurple
              onClick={() => history.push(routes.auth.phone)}
            />
          </div>
        </div>

        <Button
          onClick={() => {
            smsRequestResolver();
            setTimeLeft(60);
            setRetryCount((prevState) => prevState + 1);
          }}
          className={styles.smsButton}
          disabled={Boolean(retryCount === 5) || disableButton}
          loading={Boolean(timeLeft)}
        >
          Отправить СМС
        </Button>
        {retryCount > 0 && (
          <div className={styles.code_timer}>
            <CodeTimer
              // disabled={disabled}
              sendCodeHandler={resend}
              retryCount={retryCount}
              setRetryCount={setRetryCount}
            />
          </div>
        )}
        {typeof timeLeft !== "undefined" && retryCount > 0 && (
          <div className={styles.counter}>
            <div className={styles.retryCount}>
              {/*{retryCount > 0 && <>Попытка {retryCount} из 5</>}*/}
              Попытка {retryCount} из 5
            </div>
          </div>
        )}

        {/*<Button*/}
        {/*  variant={"underline"}*/}
        {/*  className={styles.toMainPage}*/}
        {/*  onClick={() => (window.location.href = "https://creddy.ru/")}*/}
        {/*>*/}
        {/*  На главную страницу*/}
        {/*</Button>*/}
      </RoundContainer>
      <div className={styles.bottom_subTitle}>
        Никому не пересылайте СМС, ссылка содержит данные для входа в личный
        кабинет заёмщика. Просить вас переслать сообщение могут только
        мошенники!
      </div>
    </MobilePageContainer>
  );
});
