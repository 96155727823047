import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { rootSaga } from "../sagas";
import { loadingReducer } from "./auxiliary/loadingReducer";
import { errorsReducer } from "./auxiliary/errorsReducer";
import { userReducer } from "./reducers/user";
import { applicationReducer } from "./reducers/application";
import { uiReducer } from "./reducers/ui";
import { loanReducer } from "./reducers/loan";

export type AppDispatch = typeof store.dispatch;

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

export const stateActions = {
  uiActions: uiReducer.actions,
  userActions: userReducer.actions,
  loadingReducerActions: loadingReducer.actions,
  applicationActions: applicationReducer.actions,
  loanReducerActions: loanReducer.actions
};

const reducer = combineReducers({
  router: connectRouter(history),
  uiReducer: uiReducer.reducer,
  userReducer: userReducer.reducer,
  applicationReducer: applicationReducer.reducer,
  loadingReducer: loadingReducer.reducer,
  errorsReducer: errorsReducer.reducer,
  loanReducer: loanReducer.reducer
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(sagaMiddleware)
      .concat(routerMiddleware(history)),
});
sagaMiddleware.run(rootSaga);

export type StateType = ReturnType<typeof reducer>;
