import React, { FC } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../../../../api";
import {
  applicationReducer,
  applicationReducerSelectors
} from "../../../../../../store/reducers/application";
import { routes } from "../../../../../../routes";
import { LoadingPage } from "../../LoadingPage";
import { applicationType } from "../../../../../../types/application/types";

type propsType = {};

export const CheckingCar: FC<propsType> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const application_id = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const query = useQuery(
    "CheckingCar",
    () =>
      API.main.application.getApplication({ applicationId: application_id }),
    {
      refetchInterval: 10000,
      onSuccess: (data: any) => {
        const application: applicationType = data?.data?.data;
        dispatch(applicationReducer.actions.setApplicationType({ application_type: application.application_type }));

        if (application?.application_type === "document_filling") {
          history.push(routes.application.documents);
        }

        if (application?.application_type === "refusal_final") {
          if (application?.refusal_cause === "car") {
            dispatch(
              applicationReducer.actions.setCar({
                car: {
                  ...application?.car,
                  refusal_cause_comment: application?.refusal_cause_comment
                }
              })
            );
            history.push(routes.application.statuses.refusal_final_bad_car);
          } else if (application?.refusal_cause === "scoring") {
            dispatch(
                applicationReducer.actions.setError({
                  error_message: application?.refusal_cause_comment,
                  error_type: "scoring",
                })
            );
            history.push(routes.application.statuses.error_final);
          } else if (application?.refusal_cause === "car_documents") {
            dispatch(
                applicationReducer.actions.setError({
                  error_message: application?.refusal_cause_comment,
                  error_type: "car_documents",
                })
            );
            history.push(routes.application.statuses.error_final);
          } else if (application?.refusal_cause === "pts") {
            dispatch(
                applicationReducer.actions.setError({
                  error_message: application?.refusal_cause_comment,
                  error_type: "pts",
                })
            );
            history.push(routes.application.statuses.error_final);
          } else if (application?.refusal_cause === "bki") {
              dispatch(
                  applicationReducer.actions.setError({
                      error_message: application?.refusal_cause_comment,
                      error_type: "bki",
                  })
              );
              history.push(routes.application.statuses.error_final);
          }
          else if (application?.refusal_cause === "car_appraisal") {
            dispatch(
                applicationReducer.actions.setError({
                  error_message: application?.refusal_cause_comment,
                  error_type: "car_appraisal",
                })
            );
            history.push(routes.application.statuses.error_final);
          } else {
            dispatch(
              applicationReducer.actions.setError({
                error_message: application?.error_message
              })
            );
            history.push(routes.application.statuses.error_final);
          }
        } else if (application?.application_type === "error_final") {
          dispatch(
            applicationReducer.actions.setError({
              error_message: application?.error_message
            })
          );
          history.push(routes.application.statuses.error_final);
        }
      }
    }
  );

  return (
    <LoadingPage
      title={"Подождите, пожалуйста"}
      subtitle={"Определяем информацию по машине"}
    />
  );
};
