import { PayloadAction } from "@reduxjs/toolkit";
import { verifyApplicationType } from "../../../api/main/application/types";
import { processStateController } from "../../util/helpers/processStateController";
import { call, put } from "redux-saga/effects";
import { errorsReducer } from "../../../store/auxiliary/errorsReducer";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { useParams } from "react-router-dom";
import { userSaleApi } from "../../../api/main/user_sale";
import { sendMetrics } from "../../../metrics";

export function* signLoanContractSaga({
  payload,
  type: actionType,
}: PayloadAction<verifyApplicationType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    // POST который отправляет код челу, лежит в sendingLoanTermsSaga временно

    // PUT /loan-contract/{code}
    const response: sagaApiType = yield call(userSaleApi.sendCode, payload);

    if (response.status === 204) {
      sendMetrics("signing process");
      sendMetrics("common-client_vydan-zajm_common-app", "vydan-zajm");
    }

    yield redirect(routes.application.statuses.signing_process);
  } catch (e: any) {
    if (e?.response?.status == 404) {
      yield put(
        errorsReducer.actions.setFieldsErrors({
          code: "Неверный код!",
        })
      );
    } else {
      yield call(errorHandlerSaga, {
        response: e,
      });
    }
  } finally {
    yield process.stop();
  }
}
