import styles from "./index.module.css";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import moment from "moment/moment";
import { loanType } from "../../../../../../types/cabinet/types";
import { Icons } from "../../../../../../assets/icons";
import { stringHelpers } from "../../../../../../helpers/string";
import { API } from "../../../../../../api";
import { downloadFile } from "../../../../../../helpers/file";

export const PaidLoanItemCard = ({ loan }: { loan: loanType }) => {
  const [certificateId, setCertificateId] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [successDownload, setSuccessDownload] = useState(false);

  const query = useQuery(
    "GenerateCertificate",
    () => API.main.application.downloadCertificate({
      loan_id: loan.id,
      certificate_id: certificateId,
    }),
    {
      retry: 15,
      refetchInterval: 10000,
      enabled: Boolean(loan.id && certificateId) && !successDownload,
      onSuccess: (data: { data: { data: { content: string, file_name: string } } }) => {
        downloadFile(data.data.data.content);
        setSuccessDownload(true);
        setDownloading(false);
      },
    }
  );

  const downloadCertificate = () => {
    if (downloading) return;

    setDownloading(true);
    setSuccessDownload(false);

    if (loan.id) {
      API.main.application.getCertificate({
        loan_id: loan.id
      }).then((res: AxiosResponse<{
        data: { items: Array<{ id: string, certificate_type: string, created_at: Date }>, items_count: number }
      }>) => {
        const response = res.data.data;

        if (response.items_count === 0 || response.items[0].certificate_type !== "debt_absence_certificate") {
          API.main.application.generateCertificate({
            loan_id: loan.id,
            certificate_type: "debt_absence_certificate",
          }).then((res: AxiosResponse<{ data: { id: string, certificate_type: string, created_at: Date } }>) => {
            const response = res.data.data;

            if (res.status === 201) {
              setCertificateId(response.id);
            }

            API.main.application.downloadCertificate({
              loan_id: loan.id,
              certificate_id: response.id,
            }).then(res => console.log(res))
          })
        } else {
          setCertificateId(response.items[0].id);
        }
      })
    }
  }

  return (
    <div className={styles.card_gray}>
      <div className={styles.title}>
        <div style={{ color: "#7D858B", lineHeight: "normal"}}>
          <span>
            {`Займ ${moment(loan.issued_date).format("DD.MM.YYYY")}`}{" "}
          </span>
          <span style={{ whiteSpace: "nowrap" }}>
            {`N ${loan.loan_number}`}
          </span>
        </div>
        <span>Статус: погашен</span>
      </div>
      <div className={styles.sum}>
        {stringHelpers.transformMoneyValue(loan.loan_data.amount)} ₽
      </div>
      <div className={styles.divider} />
      <div>
        <a
          style={{ display: "flex", alignItems: "center", gap: "14px" }}
          onClick={downloadCertificate}
        >
          <div style={{ width: "25px"}}><Icons.ui.PdfLogoPurple /></div>
          <div className={styles.link}>Справка об отсутствии задолженности</div>
          {downloading && <Icons.ui.Loader className={styles.loaderIcon} />}
        </a>
      </div>
    </div>
  );
};
