/* Documents instructions */
import passportFirstPage from "./documentsInstructions/passportFirstPage.svg";
import passportRegistration from "./documentsInstructions/passportRegistration.svg";
import driverLicenseFront from "./documentsInstructions/driverLicenseFront.svg";
import driverLicenseBack from "./documentsInstructions/driverLicenseBack.svg";
import carRegistrationCertificateFront from "./documentsInstructions/back.svg";
import carRegistrationCertificateBack from "./documentsInstructions/front.svg";
import passportSelfie from "./documentsInstructions/passportSelfie.svg";
import ptsFront from "./documentsInstructions/ptsFront.svg";
import ptsBack from "./documentsInstructions/ptsBack.svg";

/* Car photo instructions */
import carBackLeft from "./carInstructions/back_left_manual.svg";
import carBackRight from "./carInstructions/back_right_manual.svg";
import carFrontLeftManual from "./carInstructions/front_left_manual.svg";
import carFrontRightManual from "./carInstructions/front_right_manual.svg";
import vinManual from "./carInstructions/vin_manual.svg";
import selfieWithCarManual from "./carInstructions/selfie_with_car_manual.svg";
import dashboardManual from "./carInstructions/dashboard_manual.svg";
import carSalonManual from "./carInstructions/car_salon_manual.svg";

/* Repledge instructions */
import debtCertificateInstruction from "./documentsInstructions/debtCertificate.jpg";
import loanContract from "./documentsInstructions/loanContract.jpg";

/* Car photo placeholders */
import carFrontLeft from "./carPhotoPlaceholders/front_left.svg";
import carBackRightPlaceholder from "./carPhotoPlaceholders/back_right.svg";
import carBackLeftPlaceholder from "./carPhotoPlaceholders/back_left.svg";
import carFrontLeftPlaceholder from "./carPhotoPlaceholders/front_left.svg";
import carFrontLeftNoCameraPlaceholder from "./carPhotoPlaceholders/front_left_no_camera.svg";
import carFrontRight from "./carPhotoPlaceholders/front_right.svg";
import carFrontRightPlaceholder from "./carPhotoPlaceholders/front_right.svg";
import dashboard from "./carPhotoPlaceholders/dashboard.svg";
import carDashboardPlaceholder from "./carPhotoPlaceholders/dashboard.svg";
import vin from "./carPhotoPlaceholders/vin.svg";
import carVinPlaceholder from "./carPhotoPlaceholders/vin.svg";
import selfieWithCar from "./carPhotoPlaceholders/selfie_with_car.svg";
import carSelfiePlaceholder from "./carPhotoPlaceholders/selfie_with_car.svg";
import carEmptyPlaceholder from "./carPhotoPlaceholders/empty.png";
import carSalonPlaceholder from "./carPhotoPlaceholders/car_salon.svg";
/* Document photo placeholders */
import passportMain from "./documentPhotoPlaceholders/passport_main.jpg";
import passportDocumentRegistration from "./documentPhotoPlaceholders/passport_registration.jpg";
import selfieWithPassport from "./documentPhotoPlaceholders/selfie_with_passport.jpg";
import ptsBackDocument from "./documentPhotoPlaceholders/pts_back.svg";
import ptsFrontDocument from "./documentPhotoPlaceholders/pts_front.svg";
import stsVehicleData from "./documentPhotoPlaceholders/sts_vehicle_data.svg";
import stsOwnerInformation from "./documentPhotoPlaceholders/sts_owner_information.svg";
import licenseFront from "./documentPhotoPlaceholders/license_front.svg";
import licenseBack from "./documentPhotoPlaceholders/license_back.svg";
import backGroundGray from "./documentPhotoPlaceholders/gray_back_ground.svg";
/* Repledge documents photo placeholders */
import debtCertificate from "./documentPhotoPlaceholders/debt_certificate.svg";
import replegeDocument from "./documentPhotoPlaceholders/replege_document.svg";
import repledgeLoanContractAdd from "./documentPhotoPlaceholders/repledge_document_add.svg";

export const Images = {
  documentsInstructions: {
    passport: {
      firstPage: passportFirstPage,
      registration: passportRegistration,
      passportSelfie,
    },
    driverLicense: {
      front: driverLicenseFront,
      back: driverLicenseBack,
    },
    registration: {
      front: carRegistrationCertificateBack,
      back: carRegistrationCertificateFront,
    },
    pts: {
      front: ptsFront,
      back: ptsBack,
    },
  },

  carPhotoInstructions: {
    car: {
      backLeft: carBackLeft,
      backRight: carBackRight,
      frontLeft: carFrontLeftManual,
      frontRight: carFrontRightManual,
    },
    vinManual,
    selfieWithCarManual,
    dashboardManual,
    carSalonManual,
  },
  repledgeInstructions: {
    debtCertificate: debtCertificateInstruction,
    loanContract: loanContract
  },
  carPhotoPlaceholders: {
    frontLeft: carFrontLeftPlaceholder,
    backLeft: carBackLeftPlaceholder,
    backRight: carBackRightPlaceholder,
    frontRight: carFrontRightPlaceholder,
    dashboard: carDashboardPlaceholder,
    vin: carVinPlaceholder,
    empty: carEmptyPlaceholder,
    selfie: carSelfiePlaceholder,
    frontLeftNoCamera: carFrontLeftNoCameraPlaceholder,
    carSalon: carSalonPlaceholder,
  },
  documentPhotoPlaceholders: {
    replegeDocument: replegeDocument,
    passportMain: passportMain,
    passportRegistration: passportDocumentRegistration,
    selfieWithPassport: selfieWithPassport,
    ptsBackDocument: ptsBackDocument,
    ptsFrontDocument: ptsFrontDocument,
    stsVehicleData: stsVehicleData,
    stsOwnerInformation: stsOwnerInformation,
    licenseFront: licenseFront,
    licenseBack: licenseBack,
    backGroundGray: backGroundGray,
    debtCertificate: debtCertificate,
    repledgeLoanContractAdd: repledgeLoanContractAdd,
  },
};
