import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../../util/helpers/processStateController";
import { call, put, select } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { pass1 } from "../../../components/pages/Application/pages/Documents/docsData/pass1";
import { applicationReducerSelectors } from "../../../store/reducers/application";
import { pass2 } from "../../../components/pages/Application/pages/Documents/docsData/pass2";
import { selfie } from "../../../components/pages/Application/pages/Documents/docsData/selfie";
import { pts1 } from "../../../components/pages/Application/pages/Documents/docsData/pts1";
import { pts2 } from "../../../components/pages/Application/pages/Documents/docsData/pts2";
import { sts1 } from "../../../components/pages/Application/pages/Documents/docsData/sts1";
import { sts2 } from "../../../components/pages/Application/pages/Documents/docsData/sts2";
import { applicationSagaActions } from "../actions";
import { prava1 } from "../../../components/pages/Application/pages/Documents/docsData/prava1";
import { prava2 } from "../../../components/pages/Application/pages/Documents/docsData/prava2";
import { testPhone } from "../../../components/pages/Application/pages/Documents/docsData/testPhone";

export function* sendAllDocsSaga({ payload, type: actionType }: PayloadAction) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const applicationId: number = yield select(
      applicationReducerSelectors.getApplicationId
    );

    // * pass1 start
    const passport1: sagaApiType = yield call(
      API.main.documents.uploadDocument,
      {
        // content: testPhone,
        content: pass1,
        file_name: "passport1.jpg",
      }
    );

    yield call(API.main.documents.attachingDocumentToTheApplication, {
      photo_type: "passport_main",
      applicationId,
      document_id: passport1.data.data.id,
    });
    // * end

    // * pass2 start
    const passport2: sagaApiType = yield call(
      API.main.documents.uploadDocument,
      {
        content: pass2,
        file_name: "passport2.jpg",
      }
    );

    yield call(API.main.documents.attachingDocumentToTheApplication, {
      photo_type: "passport_registration",
      applicationId,
      document_id: passport2.data.data.id,
    });
    // * end

    // * selfie start
    const selfieData: sagaApiType = yield call(
      API.main.documents.uploadDocument,
      {
        content: selfie,
        file_name: "selfie.jpg",
      }
    );

    yield call(API.main.documents.attachingDocumentToTheApplication, {
      photo_type: "selfie_with_passport",
      applicationId,
      document_id: selfieData.data.data.id,
    });
    // * end

    // * pts1 start
    // const ptsFront: sagaApiType = yield call(
    //   API.main.documents.uploadDocument,
    //   {
    //     content: pts2,
    //     file_name: "pts1.jpg",
    //   }
    // );
    //
    // yield call(API.main.documents.attachingDocumentToTheApplication, {
    //   photo_type: "pts_front",
    //   applicationId,
    //   document_id: ptsFront.data.data.id,
    // });
    // // * end
    //
    // // * pts2 start
    // const ptsBack: sagaApiType = yield call(API.main.documents.uploadDocument, {
    //   content: pts1,
    //   file_name: "pts2.jpg",
    // });
    //
    // yield call(API.main.documents.attachingDocumentToTheApplication, {
    //   photo_type: "pts_back",
    //   applicationId,
    //   document_id: ptsBack.data.data.id,
    // });
    // // * end
    //
    // // * sts1 start
    // const stsFront: sagaApiType = yield call(
    //   API.main.documents.uploadDocument,
    //   {
    //     content: sts1,
    //     file_name: "sts1.jpg",
    //   }
    // );
    //
    // yield call(API.main.documents.attachingDocumentToTheApplication, {
    //   photo_type: "vehicle_registration_certificate_front",
    //   applicationId,
    //   document_id: stsFront.data.data.id,
    // });
    // // * end
    //
    // // * sts2 start
    // const stsBack: sagaApiType = yield call(API.main.documents.uploadDocument, {
    //   content: sts2,
    //   file_name: "sts2.jpg",
    // });
    //
    // yield call(API.main.documents.attachingDocumentToTheApplication, {
    //   photo_type: "vehicle_registration_certificate_back",
    //   applicationId,
    //   document_id: stsBack.data.data.id,
    // });
    // // * end
    //
    // // * prava1 start
    // const pravaFront: sagaApiType = yield call(
    //   API.main.documents.uploadDocument,
    //   {
    //     content: prava1,
    //     file_name: "prava1.jpg",
    //   }
    // );
    //
    // yield call(API.main.documents.attachingDocumentToTheApplication, {
    //   photo_type: "driver_license_front",
    //   applicationId,
    //   document_id: pravaFront.data.data.id,
    // });
    // // * end
    //
    // // * prava2 start
    // const pravaBack: sagaApiType = yield call(
    //   API.main.documents.uploadDocument,
    //   {
    //     content: prava2,
    //     file_name: "prava2.jpg",
    //   }
    // );
    //
    // yield call(API.main.documents.attachingDocumentToTheApplication, {
    //   photo_type: "driver_license_back",
    //   applicationId,
    //   document_id: pravaBack.data.data.id,
    // });
    // * end

    yield put(
      applicationSagaActions.sendingDocumentsForRecognize({
        applicationId,
      })
    );
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
    });
  } finally {
    yield process.stop();
  }
}
