import { Icons } from "assets/icons";
import React from "react";
import styles from "./index.module.css";

type PropsType = {
  text?: string | null;
  bankName?: string | null;
};

export const ErrorBanner = (props: PropsType) => {
  const { bankName, text } = props;

  return (
    <>
      <div className={styles.sbp_error_block}>
        <div className={styles.img_container}>
          <Icons.ui.Exclamation className={styles.img} />
        </div>
        <div className={styles.error_text}>
          {text ? (
            text
          ) : (
            <>
              Счет в{" "}
              <span className={styles.bold}>
                {bankName
                  ? `${bankName} ${" "}`
                  : "Петербургский социальный коммерческий банк "}
              </span>
              недоступен для выдачи по СБП
            </>
          )}
        </div>
      </div>
    </>
  );
};
