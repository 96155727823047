import yup from "helpers/validation/yupExtended";
import { validationsMessages } from "../../../../../helpers/validation/validationsMessages";

export const documentsSchema = yup.object().shape({
  files: yup.object().shape(
    {
      passport_main: yup
        .array()
        .validatationDocument(validationsMessages.required),
      passport_registration: yup
        .array()
        .validatationDocument(validationsMessages.required),

      // driver_license_front: yup
      //   .array()
      //   .test(
      //     "driver-license-front",
      //     "Фото обязательно к загрузке",
      //     function (value: any) {
      //       const driverLicenseBack: any = this.resolve(
      //         yup.ref("driver_license_back")
      //       );
      //       return (
      //         (!driverLicenseBack[0].value && !value[0]?.value) ||
      //         Boolean(value[0]?.value)
      //       );
      //     }
      //   ),
      //
      // driver_license_back: yup
      //   .array()
      //   .test(
      //     "driver-license-back",
      //     "Фото обязательно к загрузке",
      //     function (value: any) {
      //       const driverLicenseFront: any = this.resolve(
      //         yup.ref("driver_license_front")
      //       );
      //       return (
      //         (!driverLicenseFront[0]?.value && !value[0]?.value) ||
      //         Boolean(value[0]?.value)
      //       );
      //     }
      //   ),

      selfie_with_passport: yup
        .array()
        .validatationDocument(validationsMessages.required),

      vehicle_registration_certificate_front: yup
        .array()
        .validatationDocument(validationsMessages.required),
      vehicle_registration_certificate_back: yup
        .array()
        .validatationDocument(validationsMessages.required),

      pts_match: yup.boolean(),

      // pts_front: yup.array().when("pts_match", {
      //   is: false,
      //   then: yup.array().test("_", "ASDASD", function () {
      //     return false;
      //   }),
      //   // otherwise: yup.mixed().nullable(),
      // }),
      // pts_back: yup.array().when("pts_match", {
      //   is: true,
      //   then: yup.array().validatationDocument(validationsMessages.required),
      // }),

      pts_front: yup.array().validatationDocument(validationsMessages.required),
      pts_back: yup.array().validatationDocument(validationsMessages.required),
    },
    [
      ["description", "name"],
      ["name", "description"],
    ]
  ),
});
