import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import styles from "./index.module.css";
import cn from "classnames";
import { TextInputPropsType } from "../Inputs/types";
import { loanDataType } from "../../../types/application/types";
import { useLocation } from "react-router-dom";

type propsType<T> = {
  huge?: boolean;
  falseStatus?: string;
  trueStatus?: string;
  primaryColor?: "blue";
  switchVariant?: "default" | "middle" | "large" | "huge" | "huge_gender";
  values: [string | number, string | number, string | number];
  innerLabels: [string, string, string];
  outerLabel?: string;
  offers?: Array<Omit<loanDataType, "daily_percent_rate">>;
} & Omit<TextInputPropsType, "control">;

export function SwitcherTriple<T>({
  huge,
  switchVariant,
  innerLabels,
  outerLabel,
  ...props
}: propsType<T>) {
  const { control, setValue } = useFormContext();

  const value = useWatch({
    control,
    name: props.name,
  });

  const inputProps = control.register(props.name);

  const { pathname } = useLocation();

  return (
    <div>
      {outerLabel && <div className={styles.outer_label}>{outerLabel}</div>}
      <div
        className={cn(props.className, {
          [styles.container]: true,
          [styles.huge]: huge,
          [styles[`${switchVariant}`]]: switchVariant !== "default",
          [styles[`${props.variant}`]]: props.variant !== "default",
          [styles.checked]: value,
          // [styles.disabled]: props.disabled,
        })}
      >
        <input
          className={styles.switch_input}
          type="radio"
          id={String(props.values[0])}
          value={props.values[0]}
          defaultChecked={props.defaultChecked}
          {...inputProps}
          onChange={(e) => {
            inputProps.onChange(e);
            setValue(inputProps.name, props.values[0]);
            props.onChange && props.onChange(e);
          }}
          disabled={props.disabled}
          // disabled={props.disabled || (props.name === "loan_data.loan_term" && pathname.includes("code/contract") && !props.offers?.find(item => item.loan_term === "24"))}
        />
        <input
          className={styles.switch_input}
          type="radio"
          id={String(props.values[1])}
          value={props.values[1]}
          defaultChecked={props.defaultChecked}
          {...inputProps}
          onChange={(e) => {
            inputProps.onChange(e);
            setValue(inputProps.name, props.values[1]);
            props.onChange && props.onChange(e);
          }}
          // disabled={props.disabled || (props.name === "loan_data.loan_term" && pathname.includes("code/contract") && !props.offers?.find(item => item.loan_term === "36"))}
        />
        <input
          className={styles.switch_input}
          type="radio"
          id={String(props.values[2])}
          value={props.values[2]}
          defaultChecked={props.defaultChecked}
          {...inputProps}
          onChange={(e) => {
            inputProps.onChange(e);
            setValue(inputProps.name, props.values[2]);
            props.onChange && props.onChange(e);
          }}
          // disabled={props.disabled || (props.name === "loan_data.loan_term" && pathname.includes("code/contract") && !props.offers?.find(item => item.loan_term === "48"))}
        />
        <div className={styles.circle_container}>
          {innerLabels && (
            <>
              <div
                className={cn(styles.innerLabel, {
                  [styles.innerLabel_active]: value === props.values[0],
                })}
              >
                <label htmlFor={String(props.values[0])}>{innerLabels?.[0]}</label>
              </div>
              <div
                className={cn(styles.innerLabel, {
                  [styles.innerLabel_active]: value === props.values[1],
                })}
              >
                <label htmlFor={String(props.values[1])}>{innerLabels?.[1]}</label>
              </div>
              <div
                className={cn(styles.innerLabel, {
                  [styles.innerLabel_active]: value === props.values[2],
                })}
              >
                <label htmlFor={String(props.values[2])}>{innerLabels?.[2]}</label>
              </div>
            </>
          )}
          <div
            className={cn(styles.circle, {
              [styles.circle_blue]: props.primaryColor === "blue"
            })}
            style={{
              left: value === props.values[1] ? "33%" : value === props.values[2] ? "67%" : "1px"
            }}
          />
        </div>
      </div>
    </div>
  );
}
