import React, { FC } from "react";
import styles from "./index.module.css";
import { Images } from "../../../../../assets/img";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { Button } from "../../../../ui/Button";
import { HelpFooter } from "../../../../ui/HelpFooter";

export const VideoCallPlanned: FC = () => {
  return (
    <>
      {/*<MobilePageContainer className={styles.container}>*/}
      {/*  <div className={styles.title}>Запланирован видеозвонок</div>*/}
      {/*  <div className={styles.subtitle}>*/}
      {/*    Дата и время: <span className={styles.bold}>20.02.2024 в 15:00</span> по <span className={styles.bold}>*/}
      {/*  Екатеринбургскому времени.</span> Проверьте, что у Вас установлен{" "}*/}
      {/*    <span className={styles.underline}>WhatsApp</span> на номере{" "}*/}
      {/*    <span className={styles.bold}>+7 900 000 00 00</span>*/}
      {/*  </div>*/}
      {/*  <Button>Изменить</Button>*/}
      {/*  <div className={styles.images_block}>*/}
      {/*    <div className={styles.title}>Вам будут нужны:</div>*/}
      {/*    <div className={styles.images}>*/}
      {/*      <div>*/}
      {/*        <div className={styles.img_title}>ПТС</div>*/}
      {/*        <div className={styles.image}>*/}
      {/*          <img src={Images.documentPhotoPlaceholders.ptsFrontDocument} alt="pts_front" />*/}
      {/*        </div>*/}
      {/*        <div className={styles.img_title}>СТС</div>*/}
      {/*        <div className={styles.image}>*/}
      {/*          <img src={Images.documentPhotoPlaceholders.stsOwnerInformation} alt="sts_front" />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div>*/}
      {/*        <div className={styles.img_title}>Паспорт</div>*/}
      {/*        <div className={styles.image}>*/}
      {/*          <img src={Images.documentPhotoPlaceholders.passportMain} alt="passport_main" />*/}
      {/*        </div>*/}
      {/*        <div className={styles.img_title}>Автомобиль</div>*/}
      {/*        <div className={styles.image}>*/}
      {/*          <img src={Images.carPhotoPlaceholders.frontLeftNoCamera} alt="car" />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</MobilePageContainer>*/}
      {/*<HelpFooter className={styles.footer} variant={"videoCall"} />*/}
    </>
  );
};
