import yup from "helpers/validation/yupExtended";
import { validationsMessages } from "../../../../../helpers/validation/validationsMessages";

export const carPhotoSchema = yup.object().shape({
  files: yup.object().shape({
    front_left: yup.array().validatationDocument(validationsMessages.required),
    front_right: yup.array().validatationDocument(validationsMessages.required),
    back_left: yup.array().validatationDocument(validationsMessages.required),
    back_right: yup.array().validatationDocument(validationsMessages.required),
    dashboard: yup.array().validatationDocument(validationsMessages.required),
    vin: yup.array().validatationDocument(validationsMessages.required),
    selfie_with_car: yup
      .array()
      .validatationDocument(validationsMessages.required),
  }),
});
