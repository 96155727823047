export type ErrorType = {
  [key: string]: string;
};
export const errorMapper = (response: any) => {
  const errorsFromServer = response?.data?.data?.errors;
  if (!Array.isArray(errorsFromServer)) return {};

  let errorsObject: ErrorType = {};
  errorsFromServer.forEach((item: ErrorType) => {
    if (!item?.field) {
      return;
    }
    const indexOfDots = item.message.indexOf(":");
    // тут было
    // const fieldName = item.field.substr(item.field.lastIndexOf(".") + 1);
    //это ломало мапинг ошибок для name={passport.issue_date}  те имен филдов в форме с точкой. Не понял, зачем так было написано. Если что-то сломается - курить дальше
    const fieldName = item.field;
    errorsObject[fieldName] = indexOfDots
      ? item.message.substr(indexOfDots + 2)
      : item.message;
  });
  return errorsObject;
};
