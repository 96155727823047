import React, { FC, useState } from "react";
import styles from "./index.module.css";
import { Icons } from "../../../../../assets/icons";
import { Button } from "../../../../ui/Button";
import { useSelector } from "react-redux";
import { StateType } from "../../../../../store";
import { API } from "../../../../../api";
import { useQuery } from "react-query";
import { showDMYDate } from "../../../../../helpers/date";
import { Loader } from "../../../../ui/Loader";
import { SmartForm } from "../../../../hocs/SmartForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { carPhotoSchema } from "../../../Application/pages/CarPhoto/schema";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../routes";
import { stringHelpers } from "../../../../../helpers/string";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { authSagaActions } from "../../../../../sagas/auth/actions";

export const EarlyRepaymentAccept: FC = () => {
  const form = useForm({
    reValidateMode: "onSubmit",
    resolver: yupResolver(carPhotoSchema),
  });

  const history = useHistory();

  const loan_id: string | null = useSelector(
    (state: StateType) => state.loanReducer.loan_id
  );

  const loadingAutoAuth = useSelector(
    processLoading.get(authSagaActions.autoAuth.type)
  );

  const { data, isLoading } = useQuery(
    "code",
    () => API.main.application.getAllEarlyRepayments({ loan_id }),
    {
      enabled: loan_id !== null,
      onSuccess: (data: any) => {},
    }
  );

  const lastRepayment = data?.data?.data?.items?.[0];

  const submit = () => {
    history.push(routes.cabinet.my_loan);
  };

  return (
    <SmartForm submit={submit} form={form}>
      <div className={styles.container}>
        {isLoading || loadingAutoAuth ? (
          <Loader variant={"large"} className={styles.loader} />
        ) : (
          <>
            <div className={styles.title}>Платеж принят</div>
            <div className={styles.subtitle}>
              Досрочное погашение на сумму <br />
              <strong>
                {stringHelpers.transformMoneyValue(lastRepayment?.amount.toFixed(2))} ₽
              </strong>{" "}
              пройдёт{" "}
              <strong>{showDMYDate(lastRepayment?.payment_date)}</strong>
            </div>
            <div className={styles.icon}>
              <Icons.ui.CheckmarkNoPadding />
            </div>
            <Button className={styles.button}>На главную страницу</Button>
          </>
        )}
      </div>
    </SmartForm>
  );
};
