import styles from "./index.module.css";
import { createFC } from "../../../helpers/createFC";
import {
  scheduleCreditType,
  scheduleType,
} from "../../../types/application/types";
import { AllHTMLAttributes, FC, useState } from "react";
import { dateMethods } from "../../../helpers/date";
import cn from "classnames";
import { stringHelpers } from "../../../helpers/string";
import { Icons } from "../../../assets/icons";

type propsType = {
  schedule: scheduleType;
  variant?: "application" | "cabinet";
} & AllHTMLAttributes<HTMLDivElement>;

export const PaymentSchedule = createFC(({ schedule, variant }: propsType) => {
  const [dropDownCredit, setDropDownCredit] = useState(true);

  return (
    <div
      className={cn(styles.container, {
        [styles[`${variant}`]]: variant,
      })}
    >
      <p className={styles.header_title}>График платежей</p>
      <div className={styles.header}>
        <p>
          {schedule?.loan_term} мес.&nbsp;&nbsp;{schedule?.percent_rate} %
        </p>
        <p className={styles.total_loan_amount}>
          {stringHelpers.transformMoneyValue(schedule?.amount)} ₽
        </p>
      </div>
      {/*  тут был schedule?.credit вместо schedule?.schedule
       Поменяли бек чтобы компонента щедуля натягивалась и тут и в заявке на данные из ответа */}
      <div
        className={cn(styles.wrapper, {
          [styles.wrapper_revert]: dropDownCredit,
        })}
      >
        <div
          className={cn(styles.dropDown_wrapper, {
            [styles.dropDown_wrapper_revert]: dropDownCredit,
          })}
        >
          {/*<div className={styles.dropDown_title}>*/}
          {/*  {dropDownCredit*/}
          {/*    ? "Свернуть"*/}
          {/*    : `Еще ${schedule?.schedule?.length - 4} ${*/}
          {/*        schedule?.schedule?.length - 4 === 20 ? "платежей" : "платежa"*/}
          {/*      }`}*/}
          {/*</div>*/}
          {/*<Icons.cabinet.dropDownArrow*/}
          {/*  className={cn(styles.icon, {*/}
          {/*    [styles.icon_revert]: dropDownCredit,*/}
          {/*  })}*/}
          {/*  onClick={() => setDropDownCredit(!dropDownCredit)}*/}
          {/*/>*/}
        </div>

        {schedule?.schedule.map((credit_item) => (
          <CreditLine key={credit_item?.date} credit_item={credit_item} />
        ))}
      </div>
    </div>
  );
});

const CreditLine: FC<{ credit_item: scheduleCreditType }> = ({
  credit_item,
}) => {
  // * добавить сюда линию, будет сверху только
  return (
    <div className={styles.credit_line}>
      <p>{dateMethods.format2(credit_item?.date, true)}</p>
      {/*<p>{credit_item?.amount} ₽</p>*/}
      <p className={styles.credit_line_amount}>
        {stringHelpers.transformMoneyValue(credit_item?.amount.toFixed(2))} ₽
      </p>
    </div>
  );
};
