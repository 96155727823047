import { Variants } from "framer-motion";

export const manualAnimation: Variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: {
        duration: 0.3,
      },
      opacity: {
        duration: 0,
      },
    },
    display: "block",
  },
  close: {
    y: "100vh",
    opacity: 0,
    transition: {
      duration: 0.2,
    },
    transitionEnd: {
      display: "block",
    },
  },
};
