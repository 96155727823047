import styles from "./index.module.css";
import { createFC } from "../../../../../helpers/createFC";
import { Icons } from "../../../../../assets/icons";

export const BankiHeader = createFC(() => {
  return (
    <div className={styles.container}>
      <div className={styles.logos}>
        <Icons.logos.CreddyLogo className={styles.creddy_logo} />
        <Icons.logos.BankiRu className={styles.banki_logo} />
      </div>
      {/*<div className={styles.scale}>*/}
      {/*  <div className={styles.scale_progress}>*/}
      {/*    <div className={styles.progress_text}>Заполнено 90%</div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className={styles.text}>Сохраните свои данные в creddy, подтвердив смс кодом</div>*/}
    </div>
  );
});
