import { Variants } from "framer-motion";

export const dotsDropdownMenuAnimation: Variants = {
  open: {
    opacity: 1,
    transition: {
      opacity: {
        duration: 0.1,
      },
    },
    display: "block",
    zIndex: 1,
  },
  close: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
    transitionEnd: {
      display: "none",
    },
  },
};
