import React, { FC } from "react";
import cn from "classnames";
import styles from "./index.module.css";
import { Loader } from "../Loader";
import { dateMethods, showDMYDate, showYMDDate } from "../../../helpers/date";

export function formatLicensePlate(licensePlate?: string | null) {
  const firstPart = licensePlate?.substring(0, 1).toUpperCase();
  const secondPart = licensePlate?.substring(1, 4);
  const thirdPart = licensePlate?.substring(4, 6).toUpperCase();
  const fourthPart = licensePlate?.substring(6);

  return `${firstPart} ${secondPart} ${thirdPart} ${fourthPart}`;
}

export type propsType = {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  birthDay?: string;
  isLoading: boolean;
  className?: string;
  passportIssueDate: string | null;
  passportSeries: string | null;
  passportNumber: string | null;
};

interface TitleAndValue {
  title: string;
  value?: string | number | null;
}

export const NoAutoInn: FC<propsType> = (props) => {
  const {
    firstName,
    middleName,
    lastName,
    birthDay,
    isLoading,
    passportIssueDate,
    passportSeries,
    passportNumber,
  } = props;

  const birthDayRender = showDMYDate(birthDay);
  const dateOfIssuePassport = showDMYDate(passportIssueDate as string);
  const passportNumberAndSeriesRender =
    passportSeries && passportNumber && passportSeries + " " + passportNumber;
  const fioRender =
    (lastName || "-") + " " + (firstName || "-") + " " + (middleName || "-");

  const titleAndValuePersonalData: TitleAndValue[] = [
    { title: "ФИО:", value: fioRender ? fioRender : " - " },
    { title: "Дата рождения:", value: birthDayRender ? birthDayRender : " - " },
    {
      title: "Серия и номер паспорта:",
      value: passportNumberAndSeriesRender
        ? passportNumberAndSeriesRender
        : " - ",
    },
    {
      title: "Дата выдачи паспорта:",
      value: dateOfIssuePassport ? dateOfIssuePassport : " - ",
    },
  ];

  return (
    <div className={cn(styles.main_container, props.className)}>
      <>
        {titleAndValuePersonalData.map((item: TitleAndValue, index: number) => (
          <div>
            <div key={index} className={styles.flex_container}>
              <span className={styles.title}>{item.title}</span>
              {isLoading ? (
                <Loader />
              ) : (
                <span className={styles.value}>
                  {item.value ? item.value : "-"}
                </span>
              )}
            </div>
            {index !== titleAndValuePersonalData.length - 1 && (
              <div className={styles.lane}></div>
            )}
          </div>
        ))}
      </>
    </div>
  );
};
