import * as axios from "axios";
import {
  createPasswordType,
  createUserType,
  getCodeType,
  getTokenType,
  verifyCodeType,
} from "./types";
import { stringHelpers } from "../../helpers/string";

export const authAxios = axios.default.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  timeout: 300000,
});

export const authAxiosWithoutToken = axios.default.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  timeout: 300000,
});

class AuthApiClass {
  user_id: number | null = null;

  constructor() {}

  get = ({ phone }: { phone: string }) =>
    authAxiosWithoutToken.get(
      `/user?phone_number=${stringHelpers.clearString(phone)}`
    );

  create = ({ phone, ...body }: createUserType) =>
    authAxios.post("/user", {
      phone_number: stringHelpers.clearString(phone),
      ...body,
    });

  code = (props: getCodeType) =>
    authAxios.post(`/user/${this.user_id}/code`, {
      template: props.smsTemplate,
      target: "phone",
    });

  verifyCode = (props: verifyCodeType) =>
    authAxios.head(`/user/${this.user_id}/code/${props.code}`);

  createPassword = ({ code, ...body }: createPasswordType) =>
    authAxios.put(`/user/${this.user_id}/code/${code}`, body);

  token = (body: getTokenType) =>
    authAxios.post(`/user/${this.user_id}/token`, body);

  getUserTokenized = () => authAxios.get(`/user/${this.user_id}`);

  getPdDocument = ({ hash }: { hash: string }) => authAxios.get(`/pd/${hash}`);
}

export const authApi = new AuthApiClass();
