import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Icons } from "../../../assets/icons";
import { useGetInfoFromApplication } from "../../../hooks/api/useGetInfoFromApplication";
import { Button } from "../Button";
import styles from "./index.module.css";

export const MotivationalPopup: FC = (props) => {
  const [showModal, setShowModal] = useState(false);

  const { pathname } = useLocation();
  const { application_type, documents } = useGetInfoFromApplication();

  useEffect(() => {
    if (
      pathname.includes("application/documents") &&
      application_type === "document_filling" &&
      (!documents || documents.length === 0)
    ) {
      setShowModal(true);
    }
  }, [application_type, pathname]);

  if (!showModal) return null;

  return (
    <div className={styles.container}>
      <div className={styles.container__inner}>
        <div className={styles.icon_cross} onClick={() => setShowModal(false)}>
          {/*TODO svg*/}
          {/*<Icons.ui.CrossBlack />*/}
        </div>
        <div className={styles.body}>
          <div className={styles.icon_money}>
            <Icons.ui.Money />
          </div>
          <p className={styles.message}>
            Оформите займ в течение
            <br />
            24 часов и получите
            <span className={styles.bold}> + 30 000 &#8381;</span>
            <br />к сумме одобрения
          </p>
        </div>
        <Button onClick={() => setShowModal(false)}>Супер!</Button>
      </div>
    </div>
  );
};
