import React, { FC } from "react";
import { Control } from "react-hook-form";
import styles from "./index.module.css";

type propsType = {
  control: Control<any>;
  name: string;
};
export const InputForScroll: FC<propsType> = ({ control, name }) => {
  return (
    <input ref={control.register(name).ref} className={styles.container} />
  );
};
