import React, { AllHTMLAttributes, FC } from "react";
import styles from "./index.module.css";
import ReactPaginate from "react-paginate";
import { usePaginatorType } from "../../../hooks/logical/usePaginator";
import { Icons } from "../../../assets/icons";
import cn from "classnames";

type propsType = { showArrow?: boolean; center?: boolean } & usePaginatorType &
  AllHTMLAttributes<HTMLDivElement>;

export const Paginator: FC<propsType> = ({
  showArrow = false,
  className,
  ...props
}) => {
  const { ...other } = props;

  return (
    <div className={cn(styles.container, className)}>
      <ReactPaginate
        containerClassName={styles.Paginator}
        activeClassName={styles.Paginator__active}
        marginPagesDisplayed={1}
        breakClassName={styles.break}
        nextClassName={styles.Paginator__next}
        previousClassName={styles.Paginator__prev}
        nextLabel={false}
        previousLabel={
          showArrow && (
            <Icons.ui.arrowAngleIcon style={{ transform: "rotate(180deg)" }} />
          )
        }
        initialPage={other.current}
        {...other}
        pageRangeDisplayed={3}
      />
    </div>
  );
};
