import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.css";
import { Title } from "../../../../../../ui/Title";
import { Icons } from "../../../../../../../assets/icons";
import { Button } from "../../../../../../ui/Button";
import { amountSelectionAnimation } from "../AmountSelectionPopup/animate";
import { cabinetSagaActions } from "../../../../../../../sagas/cabinet/actions";
import { processLoading } from "../../../../../../../store/auxiliary/loadingReducer";
import moment from "moment";
import { paymentType } from "../../../../../../../api/main/application/types";
import { StateType } from "../../../../../../../store";
import { API } from "../../../../../../../api";
import { AxiosResponse } from "axios";
import { Loader } from "../../../../../../ui/Loader";
import { stringHelpers } from "../../../../../../../helpers/string";
import { Subtitle } from "../../../../../../ui/Subtitle";
import { backgroundAnimation } from "../../../../../../ui/BurgerMenu/animate";
import { useGetLoan } from "../../../../../../../hooks/api/useGetLoan";

type propsType = {
  isOpenPopup: boolean;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAmountSelectionPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setToPay: React.Dispatch<React.SetStateAction<number>>;
};

export const FullRepaymentPopup: FC<propsType> = ({
  isOpenPopup,
  setIsOpenPopup,
  setIsAmountSelectionPopupOpen,
  setToPay,
}) => {
  const dispatch = useDispatch();

  const loan_id = useSelector((state: StateType) => state.loanReducer.loan_id);
  const isPostLoading = useSelector(
    processLoading.get(cabinetSagaActions.createPartialEarlyRepayment.type)
  );

  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [fullRepaymentError, setFullRepaymentError] = useState(false);

  const { currentSituation } = useGetLoan();

  useEffect(() => {
    if (loan_id && isOpenPopup && !paymentAmount) {
      API.main.application
        .getFullRepaymentAmount({
          loan_id,
        })
        .then((res: AxiosResponse<any>) => {
          if (res.status === 200) {
            setPaymentAmount(res.data.data?.amount);
            setIsLoading(false);
          }
        })
        .catch((e) => setFullRepaymentError(true));
    }
  }, [isOpenPopup]);

  const createEarlyRepayment = () => {
    console.log(paymentAmount);
    if (loan_id && paymentAmount) {
      dispatch(
        cabinetSagaActions.createPartialEarlyRepayment({
          loan_id,
          amount: paymentAmount,
          type: "full_repayment",
        })
      );
    }
  };

  return (
    <>
      <motion.div
        variants={backgroundAnimation}
        initial={"close"}
        animate={isOpenPopup ? "open" : "close"}
        className={styles.tinted_background}
        onClick={() => {
          setIsOpenPopup(false);
        }}
      ></motion.div>
      <motion.div
        variants={amountSelectionAnimation}
        initial={"close"}
        animate={isOpenPopup ? "open" : "close"}
        className={styles.popup}
      >
        <div className={styles.background} />

        <div className={styles.content}>
          <div className={styles.header}>
            {!fullRepaymentError && (
              <Title className={styles.title}>Сумма к оплате</Title>
            )}
            <div className={styles.cross}>
              <Icons.ui.CrossSmallBlack
                onClick={() => {
                  setIsOpenPopup(false);
                }}
              />
            </div>
          </div>

          {
            // isLoading ? <Loader variant={"large"} className={styles.loader} /> :
            !fullRepaymentError && (
              <>
                <div className={styles.formedOn}>
                  Сформировано на {moment().format("DD.MM.YYYY")}
                </div>

                <div className={styles.paymentAmount}>
                  {stringHelpers.transformMoneyValue(paymentAmount)} &#8381;
                </div>

                <div className={styles.hint}>
                  Убедитесь в наличии денежных средств <br /> для списания
                  указанной суммы
                </div>
              </>
            )
          }

          {fullRepaymentError && (
            <div className={styles.errorContainer}>
              <Title style={{ marginTop: "36px" }}>Оплата невозможна</Title>
              <Subtitle className={styles.errorSubtitle}>
                Есть просроченная задолженность
              </Subtitle>
              <div className={styles.errorCircle}>
                <Icons.ui.CrossWarning className={styles.iconCross} />
              </div>
            </div>
          )}

          {!fullRepaymentError && (
            <Button
              style={{ marginTop: "52px" }}
              loading={isLoading || isPostLoading}
              onClick={createEarlyRepayment}
              disabled={!paymentAmount}
            >
              Оплатить
            </Button>
          )}

          {/* Сделать: кнопка открывает оплату поставляя в инпут сумму просрочки + штрафов*/}
          {fullRepaymentError && (
            <Button
              style={{ marginTop: "27px" }}
              onClick={() => {
                setIsOpenPopup(false);
                setIsAmountSelectionPopupOpen(true);
                setToPay(
                  currentSituation.overdue_loan_body +
                    currentSituation.overdue_percents +
                    currentSituation?.percent_penalties +
                    currentSituation?.loan_body_penalties
                );
              }}
              // disabled={!paymentAmount}
            >
              Оплатить задолженность
            </Button>
          )}

          <Button
            style={{ marginTop: "22px" }}
            variant={"underline"}
            className={styles.cancel}
            onClick={() => setIsOpenPopup(false)}
          >
            Отмена
          </Button>
        </div>
      </motion.div>
    </>
  );
};
