import { takeEvery } from "redux-saga/effects";
import { cabinetSagaActions } from "./actions";
import { createLoanPaymentSaga } from "./methods/createLoanPaymentSaga";
import { createPartialEarlyRepaymentSaga } from "./methods/createPartialEarlyRepaymentSaga";
import { deletePartialEarlyRepaymentSaga } from "./methods/DeletePartialEarlyRepaymentSaga";

export function* cabinetWatcher() {
  yield takeEvery(cabinetSagaActions.createLoanPayment, createLoanPaymentSaga);
  yield takeEvery(
    cabinetSagaActions.createPartialEarlyRepayment,
    createPartialEarlyRepaymentSaga
  );
  yield takeEvery(
    cabinetSagaActions.deletePartialEarlyRepayment,
    deletePartialEarlyRepaymentSaga
  );
}
