import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { authSagaActions } from "../actions";
import { processStateController } from "../../util/helpers/processStateController";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { UserType } from "../../../types/user/types";
import { userReducer } from "../../../store/reducers/user";
import { stringHelpers } from "../../../helpers/string";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { isMobile } from "react-device-detect";
import { lsController } from "../../../store/auxiliary/localStorage";
import { carType } from "../../../store/reducers/application/types";
import { StateType } from "../../../store";
import { sendMetrics } from "../../../metrics";

export function* getUserSaga({
  payload,
  type: actionType,
}: PayloadAction<{ phone: string; car?: carType }>) {
  const process = processStateController(actionType);
  try {
    yield process.start();
    const response: sagaApiType = yield call(API.auth.get, payload);

    const location: string = yield select(
      (state: StateType) => state.router.location.pathname
    );
    const search: string = yield select(
      (state: StateType) => state.router.location.search
    );
    const query: {
      [key: string]: string;
    } = yield select((state: StateType) => state.router.location.query);

    const users = response.data.data;

    const grz = lsController.get("grz");
    const vin = lsController.get("vin");

    const transaction_id = lsController.get("transaction_id");
    const webmaster_id = lsController.get("webmaster_id");

    if (transaction_id) {
      yield call(
        API.main.services.postAdvertisementTracking,
        webmaster_id
          ? {
              phone: stringHelpers.clearString(payload.phone),
              transaction_id: String(lsController.get("transaction_id")),
              advertisement_type: String(
                lsController.get("transaction_provider")
              ),
              webmaster_id,
            }
          : {
              phone: stringHelpers.clearString(payload.phone),
              transaction_id: String(lsController.get("transaction_id")),
              advertisement_type: String(
                lsController.get("transaction_provider")
              ),
            }
      );
    }

    if (!users.length) {
      if (!query.grz && location === "/user/personal_cabinet") {
        lsController.clearGRZ();
      }
      // yield put(userReducer.actions.setIsNewUser({ isNewUser: true }));
      /**
       * Если пришел пустой массив,
       * то пользователя нет.
       * Eго нужно создать
       * */
      yield put(
        authSagaActions.createUser({
          phone: payload.phone,
          car: payload.car,
        })
      );
      return;
    }

    const { id, is_has_password, signed_pdn }: UserType = users[0];

    yield put(
      userReducer.actions.setPhone({
        phone: payload.phone,
      })
    );

    yield put(
      userReducer.actions.setId({
        user_id: id,
      })
    );

    yield put(
      userReducer.actions.setIsHasPassword({
        is_has_password,
      })
    );

    yield put(
      userReducer.actions.setIsHasSigned_pdn({
        signed_pdn,
      })
    );

    lsController.set("is_has_password", is_has_password);

    if (location === "/user/personal_cabinet" && signed_pdn) {
      yield put(
        authSagaActions.getCode({
          smsTemplate: isMobile ? "logon_mobile" : "logon_desktop",
        })
      );
      yield process.stop();
      return;
    } else if (location === "/user/personal_cabinet" && !signed_pdn) {
      yield put(
        authSagaActions.getCode({
          smsTemplate: isMobile ? "register_mobile" : "register_desktop",
        })
      );
      yield process.stop();
      return;
    }

    if (!query?.pin) {
      if (signed_pdn && payload.phone) {
        yield put(
          authSagaActions.getCode({
            smsTemplate: isMobile ? "logon_mobile" : "logon_desktop",
          })
        );
      } else if (!signed_pdn) {
        sendMetrics("common-client_registraciya_new-app", "registraciya");
        yield put(
          authSagaActions.getCode({
            smsTemplate: isMobile ? "register_mobile" : "register_desktop",
          })
        );
      }
    }

    yield process.stop();
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: authSagaActions.getUser.type,
    });
  } finally {
    // yield process.stop();
  }
}
