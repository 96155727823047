import React, { AllHTMLAttributes, FC } from "react";
import styles from "./index.module.css";
import { Icons } from "../../../../../../assets/icons";
import cn from "classnames";

type propsType = {
  password: string;
  password_confirm: string;
};

const length = (password: string) => {
  return password?.length >= 8 && password?.length < 33;
};

const length0 = (password: string) => {
  return password?.length === 0 || typeof password === "undefined";
};

const containsNumbers = (password: string) => {
  return /\d/.test(password);
};

const containsUpperCase = (password: string) => {
  return /[A-Z]/.test(password);
};

const containsOnlyAllowedCharacters = (password: string) => {
  return /^[a-zA-Z0-9 !"#\$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~ ]*$/.test(password);
};

export const PasswordRequirement: FC<propsType> = (props) => {
  const passwordLengthValidate = length(props.password);
  const passwordNumberValidate = containsNumbers(props.password);
  const passwordUpperCaseValidate = containsUpperCase(props.password);
  const passwordLengthValidateZero = length0(props.password);
  const passwordsConfirm =
    props.password === props.password_confirm &&
    typeof props.password !== "undefined" &&
    props.password.length !== 0;
  const containsOnlyAllowedCharactersValidate =
    containsOnlyAllowedCharacters(props.password) &&
    !passwordLengthValidateZero;

  const passwordConditionsTitle = [
    "Не менее 8 символов",
    "Минимум 1 заглавная буква",
    "Минимум 1 цифра",
    !containsOnlyAllowedCharactersValidate && !passwordLengthValidateZero
      ? "Допустима латиница, цифры и спецсимволы: пробел и ! \" # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _` { | } ~"
      : "Допустима латиница, цифры и спецсимволы",
    "Пароли совпадают",
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {passwordConditionsTitle.map((title, index) => {
          const isValid =
            (passwordLengthValidate && index === 0) ||
            (passwordUpperCaseValidate && index === 1) ||
            (passwordNumberValidate && index === 2) ||
            (containsOnlyAllowedCharactersValidate && index === 3) ||
            (passwordsConfirm && index === 4);

          const showCheckmark = isValid && !passwordLengthValidateZero;
          const showErrorIcon = !isValid && !passwordLengthValidateZero;
          const showCheckmarkZero = !isValid && passwordLengthValidateZero;

          return (
            <div className={styles.flex_container}>
              {showCheckmark && (
                <div>
                  <Icons.ui.Checkmark_password
                    className={styles.icon_default}
                  />
                </div>
              )}
              {showErrorIcon && (
                <div>
                  {/*TODO svg*/}
                  {/*<Icons.ui.Cross_warning_password className={styles.icon} />*/}
                </div>
              )}
              {showCheckmarkZero && (
                <div>
                  <Icons.ui.Checkmark_password className={styles.icon} />
                </div>
              )}
              <div
                className={cn(styles.title, {
                  [styles.title_error]: showErrorIcon,
                })}
              >
                {title}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
