import { createAction } from "@reduxjs/toolkit";
import {
  createLoanPaymentType,
  createPartialEarlyRepaymentType,
  deletePartialEarlyRepaymentType,
} from "../../api/main/application/types";

export const cabinetSagaActions = {
  createLoanPayment: createAction<createLoanPaymentType>(
    "cabinet/createLoanPayment"
  ),
  createPartialEarlyRepayment: createAction<createPartialEarlyRepaymentType>(
    "cabinet/createPartialEarlyRepayment"
  ),

  deletePartialEarlyRepayment: createAction<deletePartialEarlyRepaymentType>(
    "cabinet/deletePartialEarlyRepayment"
  ),
};
