import { useQuery } from "react-query";
import { API } from "../../api";
import { getCarDataType, getCarType } from "../../api/main/services/types";

/*
    а873во136 - найдет, подойдет
    т492ху60 - будет ошибка spectrum_data_error
    JA4MW51R51J010121 - будет отказ car
    р936ак136 - будет отказ car
 */

export const useGetCar = (props: getCarType) => {
  const data: any = useQuery(["getCar"], () => API.main.services.getCar(props));

  return {
    isLoading: data?.isLoading,
    carData: data?.data?.data?.data as getCarDataType,
  };
};
