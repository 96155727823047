import { applicationBasisType } from "../../../api/main/application/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../../util/helpers/processStateController";
import { call, select } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { useSelector } from "react-redux";
import { applicationReducerSelectors } from "../../../store/reducers/application";
import { sagaApiType } from "../../util/types";
import { sendMetrics } from "../../../metrics";

export function* sendingDocumentsForRecognizeSaga({
  payload: { applicationId },
  type: actionType,
}: PayloadAction<applicationBasisType>) {
  const process = processStateController(actionType);

  const applicationType: string = yield select(
    applicationReducerSelectors.getApplicationType
  );

  try {
    yield process.start();

    const response: sagaApiType = yield call(
      API.main.application.sendingDocumentsForRecognize,
      {
        applicationId,
      }
    );

    if (response.status === 204) {
      if (applicationType === "passport_photo_filling") {
        sendMetrics(
          "common-client_otpravleny-foto-pasporta-ptv_common-app",
          "otpravleny-foto-pasporta-ptv"
        );
      } else if (applicationType === "car_document_filling") {
        sendMetrics(
          "common-client_otpravleny-foto-pts-sts_common-app",
          "otpravleny-foto-pts-sts"
        );
      }
    }

    // if (applicationType === "car_document_filling") {
    //   yield redirect(routes.application.documents);
    // } else if (applicationType === "car_photo_filling") {
    //   yield redirect(routes.application.car_photo);
    // } else {
    //   yield redirect(routes.application.statuses.recognize_documents);
    // }
    yield redirect(routes.application.statuses.recognize_documents);
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
    });
  } finally {
    yield process.stop();
  }
}
