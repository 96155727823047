import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../../util/helpers/processStateController";
import { call, put, select } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../store/reducers/application";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { pass1 } from "../../../components/pages/Application/pages/Documents/docsData/pass1";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { applicationSagaActions } from "../actions";

export function* sendAllCarPhotosSaga({
  payload,
  type: actionType,
}: PayloadAction) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const applicationId: number = yield select(
      applicationReducerSelectors.getApplicationId
    );

    // * car1 start
    const car1: sagaApiType = yield call(API.main.documents.uploadDocument, {
      content: pass1,
      file_name: "car1.jpg",
    });

    yield call(API.main.documents.attachingCarPhotoToTheApplication, {
      photo_type: "front_left",
      applicationId,
      document_id: car1.data.data.id,
    });
    // * end

    // * car2 start
    const car2: sagaApiType = yield call(API.main.documents.uploadDocument, {
      content: pass1,
      file_name: "car2.jpg",
    });

    yield call(API.main.documents.attachingCarPhotoToTheApplication, {
      photo_type: "front_right",
      applicationId,
      document_id: car2.data.data.id,
    });
    // * end

    // * car3 start
    const car3: sagaApiType = yield call(API.main.documents.uploadDocument, {
      content: pass1,
      file_name: "car3.jpg",
    });

    yield call(API.main.documents.attachingCarPhotoToTheApplication, {
      photo_type: "back_left",
      applicationId,
      document_id: car3.data.data.id,
    });
    // * end

    // * car4 start
    const car4: sagaApiType = yield call(API.main.documents.uploadDocument, {
      content: pass1,
      file_name: "car4.jpg",
    });

    yield call(API.main.documents.attachingCarPhotoToTheApplication, {
      photo_type: "back_right",
      applicationId,
      document_id: car4.data.data.id,
    });
    // * end

    // * car5 start
    const car5: sagaApiType = yield call(API.main.documents.uploadDocument, {
      content: pass1,
      file_name: "car5.jpg",
    });

    yield call(API.main.documents.attachingCarPhotoToTheApplication, {
      photo_type: "vin",
      applicationId,
      document_id: car5.data.data.id,
    });
    // * end

    // * car6 start
    const car6: sagaApiType = yield call(API.main.documents.uploadDocument, {
      content: pass1,
      file_name: "car6.jpg",
    });

    yield call(API.main.documents.attachingCarPhotoToTheApplication, {
      photo_type: "dashboard",
      applicationId,
      document_id: car6.data.data.id,
    });
    // * end

    // * car7 start
    const car7: sagaApiType = yield call(API.main.documents.uploadDocument, {
      content: pass1,
      file_name: "car7.jpg",
    });

    yield call(API.main.documents.attachingCarPhotoToTheApplication, {
      photo_type: "selfie_with_car",
      applicationId,
      document_id: car7.data.data.id,
    });
    // * end

    // * car8 start
    const car8: sagaApiType = yield call(API.main.documents.uploadDocument, {
      content: pass1,
      file_name: "car8.jpg",
    });

    yield call(API.main.documents.attachingCarPhotoToTheApplication, {
      photo_type: "interior",
      applicationId,
      document_id: car8.data.data.id,
    });
    // * end

    yield put(
      applicationSagaActions.sendingCarPhotoForRecognize({
        applicationId,
      })
    );

    // yield redirect(routes.application.statuses.issue_method_filling);

    // yield call(API.main.application.code, {
    //   applicationId,
    // });

    // yield put(
    //   applicationReducer.actions.setMakeRepeatResend({
    //     value: false,
    //   })
    // );

    // yield redirect(routes.application.code.loan);
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
    });
  } finally {
    yield process.stop();
  }
}
