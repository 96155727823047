import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../../../../store/reducers/application";
import { useQuery } from "react-query";
import { API } from "../../../../../../api";
import { routes } from "../../../../../../routes";
import { LoadingPage } from "../../LoadingPage";
import { applicationType } from "../../../../../../types/application/types";
import { sendMetrics } from "../../../../../../metrics";
import { MobilePageContainer } from "../../../../../ui/Containers/MobilePageContainer";
import { BannerWhite } from "../../../../../ui/BannerWhite";
import { Icons } from "../../../../../../assets/icons";
import { FillingAssistance } from "../../../../../ui/FillingAssistance";

type propsType = {};
export const RecognizeDocuments: FC<propsType> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const query = useQuery(
    "RecognizeDocuments",
    () => API.main.application.getApplication({ applicationId }),
    {
      refetchInterval: 10000,
      onSuccess: (data: any) => {
        const application: applicationType = data?.data?.data;
        dispatch(
          applicationReducer.actions.setApplicationType({
            application_type: application?.application_type,
          })
        );
        if (
          application?.application_type === "need_document_correction_filling"
        ) {
          history.push(routes.application.documents);
        } else if (application?.application_type === "passport_filling") {
          history.push(routes.application.passport_and_inn);
        } else if (application?.application_type === "error_final") {
          dispatch(
            applicationReducer.actions.setError({
              error_message: application?.error_message,
            })
          );
          history.push(routes.application.statuses.error_final);
        } else if (application?.application_type === "refusal_final") {
          sendMetrics("common-client_avtootkaz-po-grz_common-app");
          history.push(routes.application.statuses.error_final);
        } else if (application?.application_type === "car_document_filling") {
          history.push(routes.application.documents);
        } else if (
          application?.application_type === "repledge_document_filling"
        ) {
          history.push(routes.application.replege_photo);
        } else if (application?.application_type === "car_photo_filling") {
          history.push(routes.application.car_photo);
          // history.push(routes.application.replege_photo);
        }
      },
    }
  );

  useEffect(() => {
    sendMetrics("recognize documents");
  }, []);

  return (
    // <LoadingPage
    //   withoutIcon
    //   title={"Подождите, пожалуйста"}
    //   subtitle={
    //     <>
    //       <div>
    //         Ваши документы загружаются <br /> и распознаются. Между тем, <br />{" "}
    //         отличное время переместиться <br />к вашему автомобилю, чтобы <br />
    //         сфотографировать его для нас.{" "}
    //       </div>
    //       <div className={styles.bold}>Спасибо за терпение!</div>
    //     </>
    //   }
    //   additionalSubtitle={
    //     <>
    //       Мы пришлем СМС для <br />
    //       перехода к фото автомобиля
    //     </>
    //   }
    // />
    <>
      <MobilePageContainer className={styles.form}>
        <div className={styles.container}>
          <div className={styles.title}>Идёт распознание</div>
          {/*<div className={styles.text}>*/}
          {/*  Мы автоматически распознаём паспорт <br /> и заполняем анкету 1*/}
          {/*  минуту*/}
          {/*</div>*/}
          <div className={styles.loader}>
            <Icons.ui.BigFingerUp />
          </div>
          {/*<div>Пришлём СМС для продолжения заполнения</div>*/}
        </div>

        {/*<FillingAssistance className={styles.container_filling} />*/}
      </MobilePageContainer>
      <FillingAssistance className={styles.footer} />
    </>
  );
};
