import { spawn } from "redux-saga/effects";
import { clearErrorsSaga } from "./util/helpers/clearErrorsSaga";
import { errorWatcherSaga } from "./util/helpers/errorHandlerSaga";
import { authWatcher } from "./auth";
import { routingWatcherSaga } from "./util/helpers/routingSaga";
import { autoAuth } from "./auth/methods/autoAuth";
import { applicationWatcher } from "./application";
import { cabinetWatcher } from "./cabinet";

export function* rootSaga() {
  //init sagas
  yield spawn(autoAuth);
  yield spawn(routingWatcherSaga);
  // main sagas
  yield spawn(authWatcher);
  yield spawn(applicationWatcher);
  yield spawn(cabinetWatcher);

  //Errors sagas
  yield spawn(clearErrorsSaga);
  yield spawn(errorWatcherSaga);
}
