import React, { FC, SetStateAction, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import moment from "moment";
import { motion } from "framer-motion";
import { API } from "../../../../../../api";
import { notificationDataType } from "../../../../../../types/cabinet/types";
import { Icons } from "../../../../../../assets/icons";
import { Checkbox } from "../../../../../ui/Checkbox";
import { useDispatch } from "react-redux";
import { loanReducer } from "../../../../../../store/reducers/loan";
import { downloadFile } from "../../../../../../helpers/file";

type propsType = {
  data: notificationDataType;
  isCheckedAllNew: boolean;
  setIsCheckedAllNew: React.Dispatch<SetStateAction<boolean>>;
  className?: string;
};

export const NotificationItem: FC<propsType> = ({
  data,
  isCheckedAllNew,
  setIsCheckedAllNew,
  className
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [messageHeight, setMessageHeight] = useState(53);

  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement | null>(null);

  const handleOnChange = () => {
    if (isCheckedAllNew && isChecked) setIsCheckedAllNew(false);
    setIsChecked((prev) => !prev);
  };

  const downloadNotification = () => {
    if (downloading) return;
    setDownloading(true);
    API.main.application.downloadNotificationFile({ notification_id: data.id })
      .then(res => {
        if (res.status === 200) {
          downloadFile(res.data.data.content);
          setDownloading(false);
        }
      });
  };

  useEffect(() => {
    dispatch(loanReducer.actions.updateNotification({
      id: data.id,
      checked: isChecked,
    }));
  }, [isChecked]);

  useEffect(() => {
    isCheckedAllNew && setIsChecked(true);
  }, [isCheckedAllNew]);

  useEffect(() => {
    ref.current?.scrollHeight && setMessageHeight(ref.current.scrollHeight);
  }, []);

  return (
    <div className={cn(styles.container, {
        [`${className}`]: className,
      })}
    >
      <div className={styles.header}>
        <div className={styles.date}>{moment(data.created_at).format("DD.MM.YYYY")}</div>
        <div className={styles.status}>
          <div className={styles.status_title}>
            {data.type === "new_notification" ? "Новое" : "Прочитано"}
          </div>
          <div className={cn(styles.status_circle, {
            [styles.status_circle_new]: data.type === "new_notification",
            [styles.status_circle_read]: data.type === "accepted_notification",
          })} />
        </div>
      </div>
      <div className={styles.title}>{data.header}</div>
      <div className={styles.divider} />
      <motion.div ref={ref}
        initial={{ height: 53 }}
        animate={{ height: isOpen ? "auto" : 53 }}
        className={cn(styles.content, {
          [styles.content_open]: isOpen,
        })}
      >
        {data.content}
      </motion.div>
      {data.file_name && (
        <div className={styles.link} onClick={downloadNotification}>
          <Icons.ui.PdfLogoPurple width={15} height={18} />
          <span>{downloading ? <Icons.ui.Loader className={styles.loaderIcon} /> : data.file_name}</span>
        </div>
      )}
      <div className={styles.divider} />
      <div className={cn(styles.footer, {
        [styles.footer_read]: data.type === "accepted_notification",
      })}>
        {data.type === "new_notification" && (
          <div className={styles.checkbox}>
            <Checkbox
              name={`notif-${data.id}`}
              className={styles.mr_check_box}
              checked={isChecked || isCheckedAllNew}
              onChange={handleOnChange}
              blockValue={true}
            />
            Выбрать
          </div>
        )}
        <div
          className={cn(styles.dropdown, {
            [styles.dropdown_open]: isOpen,
          })}
          onClick={() => setIsOpen(!isOpen)}
        >
          {messageHeight > 53 && (
            <>
              <div className={styles.dropdown}>{isOpen ? "Свернуть" : "Развернуть"}</div>
              <Icons.ui.DropDown />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
