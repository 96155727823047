import { useMutation, useQueryClient } from "react-query";
import { API } from "../../api";
import React from "react";

export const useDeletePayment = (
  loan_id: string,
  early_repayment_id: string | null,
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    () =>
      API.main.application.deletePartialEarlyRepayment({
        loan_id,
        early_repayment_id,
        setIsOpenPopup,
      }),
    {
      onSettled: () => {
        setIsOpenPopup(false);
        queryClient.invalidateQueries("getLoanById");
      },
    }
  );

  const deletePayment = () => {
    mutation.mutate();
  };

  return {
    deletePayment,
    isLoading: mutation.isLoading,
    error: mutation.isError ? mutation.error : null,
  };
};
