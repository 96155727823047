import * as axios from "axios";
import { getDocumentType, underwriterBasisType } from "./types";

export const underwriterAxios = axios.default.create({
  baseURL: process.env.REACT_APP_UNDERWRITER_URL,
  timeout: 300000,
});

class UnderwriterApiClass {
  constructor() {}

  getApplications = () => underwriterAxios.get("/application");

  getApplication = ({ applicationId }: underwriterBasisType) =>
    underwriterAxios.get(`/application/${applicationId}`);

  approveApplication = ({ applicationId, ...body }: underwriterBasisType) =>
    underwriterAxios.put(`/application/${applicationId}/approve`, body);

  declineApplication = ({ applicationId }: underwriterBasisType) =>
    underwriterAxios.put(`/application/${applicationId}/decline`);

  getDocument = ({ applicationId, document_type }: getDocumentType) =>
    underwriterAxios.get(
      `/application/${applicationId}/document/${document_type}`
    );
}

export const underwriterApi = new UnderwriterApiClass();
