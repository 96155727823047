import { useEffect, useState } from "react";

export const useMedia = () => {
   const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

   const setWidth = function () {
      unsubscribeToResize();
      window.setTimeout(() => {
         setCurrentWidth(window.innerWidth);
         subscribeToResize();
      }, 300);
   };

   const subscribeToResize = () => {
      window.addEventListener("resize", setWidth);
   };
   const unsubscribeToResize = () => {
      window.removeEventListener("resize", setWidth);
   };

   useEffect(() => {
      subscribeToResize();
   }, []);

   return {
      width: currentWidth,
   };
};
