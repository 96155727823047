import cn from "classnames";
import styles from "./index.module.css";
import React, {
  AllHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from "react";

type propsType = {
  hugeInputWrapper?: boolean;
  length?: number | string;
  className?: string;
  placeholder?: string;
  error?: string;
  timeout?: number;
  onChange(value: string): void;
  timerCallback?(): void;
  sendCodeHandler?(): void;

  tempInput: any;
  fieldsArray: Array<string>;
  arrayOfInputs: any;
  setFieldsArray: (val: any) => void;
  onClickHandler: any;
  pasteHandler: any;
  keyPressHandler: any;
  onChangeHandler: any;
} & AllHTMLAttributes<HTMLDivElement>;

export const RegionPartInput = (props: propsType) => {
  const {
    error,
    className,
    placeholder,
    fieldsArray,
    tempInput,
    arrayOfInputs,
    keyPressHandler,
    onClickHandler,
    pasteHandler,
    onChangeHandler,
    onFocus,
  } = props;

  // Вся эта логика нужна, чтобы прыгать автоматически между инпутом региона и номера туда-обратно
  const lastRegionInput = document.querySelector(
    "#mainPartInput div div:last-child input"
  ) as HTMLElement | null;

  const [isBackspacePressed, setIsBackspacePressed] = useState<boolean>();

  const handleUserKeyPress = useCallback((event) => {
    setIsBackspacePressed(event.key === "Backspace");
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    if (fieldsArray[0] === "" && isBackspacePressed) {
      lastRegionInput?.focus();
      //  TODO доделать удаление региона при клике на мейн парт и переходе на регион
      //   setFieldsArray(
      //     fieldsArray.map((item, index) => {
      //       return index >= value ? "" : item;
      //     })
      //   );
    }
  }, [fieldsArray]);

  // аж до сюда

  return (
    <div
      className={cn({
        [`${className}`]: className,
        [styles.wrapper]: true,
        [styles.wrapper_error]: error,
      })}
      id={props?.id}
    >
      <div className={styles.CodeField}>
        {fieldsArray.map((field: any, index: any) => {
          const disabled = tempInput != index && !fieldsArray[index];

          const isFilled = fieldsArray[index];

          function handlerRef(el: any) {
            arrayOfInputs.current[index] = el;
          }

          function handlerClick(event: React.MouseEvent<HTMLInputElement>) {
            onClickHandler(event, index);
          }

          return (
            <div
              key={index}
              className={cn(styles.input_wrapper, {
                [styles.input_wrapper_huge]: props.hugeInputWrapper,
                [styles.input_wrapper__error]: error,
                [styles.input_wrapper__filled]: isFilled,
              })}
            >
              <input
                className={cn({
                  [styles.wrapper_error_number]: error,
                })}
                type="tel"
                key={index}
                maxLength={1}
                max={1}
                id={`${index}-id`}
                data-cy={`field-${index}`}
                placeholder={placeholder}
                value={fieldsArray[index]}
                // disabled={disabled}
                ref={handlerRef}
                onChange={onChangeHandler}
                onKeyDown={keyPressHandler}
                onClick={handlerClick}
                onPaste={pasteHandler}
                onFocus={onFocus}
              />
            </div>
          );
        })}
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </div>
  );
};
