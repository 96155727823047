import React, { FC, useEffect } from "react";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { authSagaActions } from "../../../../../sagas/auth/actions";
import { InputPassword } from "../../../../ui/Inputs/InputPassword";
import { Button } from "../../../../ui/Button";
import { SmartForm } from "../../../../hocs/SmartForm";
import { Icons } from "assets/icons";
import { useHistory } from "react-router-dom";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { onPastePhoneNumberHandler } from "../../../../../helpers/string";
import { InputNumber } from "../../../../ui/Inputs/InputNumber";
import { sendMetrics } from "../../../../../metrics";

type formType = {
  password: string;
  phone_number: string;
};

export const PersonalCabinet: FC = () => {
  const history = useHistory();

  const loading = useSelector(processLoading.get(authSagaActions.getUser.type));

  const dispatch = useDispatch();

  const form = useForm<formType>({
    reValidateMode: "onSubmit",
    // resolver: yupResolver(scheme),
  });

  useEffect(() => {
    sendMetrics("common-client_stranitca-logina_common-app", "stranitca-logina");
  }, []);

  // useEffect(() => {
  //   dispatch(userReducer.actions.setToken({ token: null }));
  // }, []);

  const phone = useWatch({
    control: form.control,
    name: "phone_number",
  });

  const submit = (data: formType) => {
    dispatch(authSagaActions.getUser({ phone: data.phone_number }));
  };

  if (phone?.length === 0) {
    form.setValue("phone_number", "+7");
  }

  const loadingCode = useSelector(
    processLoading.get(authSagaActions.getCode.type)
  );

  return (
    <div className={styles.container}>
      <SmartForm form={form} submit={submit}>
        <div className={styles.subtitle_container}>
          <div className={styles.title_vehicle}>
            Онлайн-займ <br /> под залог авто
          </div>
          <div className={styles.subtitle_text}>Вход в личный кабинет</div>
          <div className={styles.comments}>Введите свой номер телефона</div>
        </div>
        <InputNumber
          support={<Icons.ui.PencilBlack />}
          inputMode={"tel"}
          defaultValue={"+7"}
          onPaste={onPastePhoneNumberHandler}
          format="+# ### ### ## ##"
          name="phone_number"
          noNumericString
          label="Телефон"
          maxLength={16}
          // variant={"large"}
          autoFocus
          className={styles.phone_input}
        />
        <Button
          loading={loading || loadingCode}
          disabled={phone?.length !== 16}
          className={styles.button}
        >
          Продолжить
        </Button>
      </SmartForm>
    </div>
  );
};
