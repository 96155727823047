import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { useHistory, useParams } from "react-router-dom";
import { Loader } from "../../../../ui/Loader";
import { useGetPaymentInfo } from "../../../../../hooks/api/useGetPaymentInfo";
import { Button } from "../../../../ui/Button";
import { routes } from "../../../../../routes";
import { LoadingPage } from "../../../Application/pages/LoadingPage";
import { CabinetErrorPage } from "../Statuses/CabinetErrorPage";
import { Icons } from "../../../../../assets/icons";
import { ReactComponent as ExclamationMark } from "../../../../../assets/icons/cabinet/exclamation.svg";

type propsType = {};

export const FullEarlyRepaymentIframe: FC<propsType> = (props) => {
  const { loan_id, payment_id, amount } = useParams<{
    loan_id: string;
    payment_id: string;
    amount: string;
  }>();

  const history = useHistory();
  // const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);

  // const { paymentInfo, isLoading, showFullRepaymentLoader, paymentError } =
  //   useGetPaymentInfo({
  //     loan_id,
  //     payment_id,
  //   });
  // secure тестануть

  useEffect(() => {
    console.log(iframeRef?.contentWindow);
    console.log(iframeRef?.contentWindow?.location?.pathname);
  }, [iframeRef]);

  /*
     единственное место в проекте, где нужно сделать падинг 0 на контейнере.
      не стал ради этого переписывать mobilePageContainer, который юзается везде - оставил падинг внизу
     */

  // if (paymentError) {
  //   return <CabinetErrorPage />;
  // }

  return (
    <div className={styles.container}>
      <>
        <div className={styles.icon_cross}>
          {/*TODO svg*/}
          {/*<Icons.ui.CrossBlack />*/}
        </div>
        <div className={styles.title}>Будьте внимательны</div>
        <div className={styles.subtitle}>
          При полном досрочном погашении все <br /> предыдущие заявления о
          досрочном <br /> погашении будут аннулированы
        </div>
        <div className={styles.icon}>
          <Icons.cabinet.ExclamationMark />
        </div>
        <Button
          className={styles.button}
          onClick={() =>
            history.push(
              routes.cabinet.early_repayment_code.root({
                loan_id,
                early_repayment_id: payment_id,
                amount,
              })
            )
          }
        >
          Продолжить
        </Button>
      </>
    </div>
  );
};
