import { authApi } from "../auth";
import { applicationApi } from "../main/application";
import { userSaleApi } from "../main/user_sale";
import { lsController } from "../../store/auxiliary/localStorage";
import { documentsApi } from "../main/documents";

export const setUserIdToApiClass = (user_id: number | null) => {
  authApi.user_id = user_id;
  applicationApi.user_id = user_id;
  userSaleApi.user_id = user_id;
  documentsApi.user_id = user_id;

  lsController.set("user_id", user_id);
};
