import { useDispatch, useSelector } from "react-redux";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../store/reducers/application";
import { useQuery } from "react-query";
import { API } from "../../api";
import {
  applicationStatusType,
  applicationType,
  bankCardType,
  passportType,
  SBPCheckErrorType,
} from "../../types/application/types";
import { lsController } from "../../store/auxiliary/localStorage";
import { useState } from "react";
import { queryClient } from "../../services/react_query";
import { DocumentTypes } from "../../types/documents/types";

export type offerType = {
  loan_term: "24" | "36" | "48";
  amount: number;
  percent_rate: number;
};

export const useGetInfoFromApplication = (refetchInterval?: number | false) => {
  const dispatch = useDispatch();
  const [documentPhotos, setDocumentPhotos] = useState<DocumentTypes.entity[]>(
    []
  );

  const [isDocumentsPhotoLoader, setIsDocumentsPhotoLoader] = useState(false);

  const [isPingEnabled, setIsPingEnabled] = useState(true);

  const applicationIdLocal = lsController.get("application_id");

  const applicationId =
    useSelector(applicationReducerSelectors.getApplicationId) ||
    applicationIdLocal;

  const delayPing = (photoType: string) => {
    setDocumentPhotos((prev) =>
      prev.filter((photo: any) => photo.photo_type != photoType)
    );

    setIsPingEnabled(false);
    queryClient.cancelQueries("Application");
    setTimeout(() => {
      setIsPingEnabled(true);
    }, 4000);
  };

  const delayPingForRepeat = (photoType: string) => {
    setIsDocumentsPhotoLoader(true);
    setIsPingEnabled(false);
    queryClient.cancelQueries("Application");
    setTimeout(() => {
      setIsPingEnabled(true);
    }, 4000);
  };

  const continuePing = () => {
    setIsPingEnabled(true);
  };

  // const applicationId = lsController.get("application_id");

  const { data, isLoading } = useQuery(
    "Application",
    ({ signal }) => {
      if (applicationId) {
        return API.main.application.getApplication({ applicationId }, signal);
      } else {
        return Promise.resolve(null);
      }
    },

    {
      onSuccess: (data: any) => {
        setIsDocumentsPhotoLoader(false);
        dispatch(
          applicationReducer.actions.setApplicationType({
            application_type: data?.data?.data?.application_type,
          })
        );
        setDocumentPhotos(data?.data?.data?.document_photos);
        return data?.data?.data;
      },
      refetchInterval: refetchInterval ?? false,
      enabled: isPingEnabled && Boolean(applicationId),
    }
  );

  return {
    data: data?.data?.data,
    applicationId: data?.data?.data?.id,
    userId: data?.data?.data?.user_id,
    isLoading: isLoading,
    documents: documentPhotos,
    docs: data?.data?.data?.documents,
    car_photos: data?.data?.data?.car_photos,
    car: data?.data?.data?.car,
    loan_data: data?.data?.data?.loan_data,
    payment_url: data?.data?.data?.payment_url,
    application_type: data?.data?.data
      ?.application_type as applicationStatusType,
    offers: data?.data?.data?.offers as Array<offerType>,
    bank_card: data?.data?.data?.bank_card as bankCardType,
    passport: data?.data?.data?.passport as passportType,
    error_type: data?.data?.data?.error_type as applicationType["error_type"],
    delayPing,
    isPingEnabled,
    delayPingForRepeat,
    isDocumentsPhotoLoader,
  };
};
