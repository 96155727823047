import { Variants } from "framer-motion";

export const sidebarAnimation: Variants = {
  open: {
    x: 0,
    transition: {
      duration: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.075,
    },
    display: "block",
  },
  close: {
    x: "-100%",
    transition: {
      duration: 0.15,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

export const sidebarUpperPartAnimation: Variants = {
  open: {
    opacity: 1,
  },
  close: {
    opacity: 0,
  },
};

export const sidebarLinkAnimation: Variants = {
  open: {
    x: 0,
    opacity: 1,
  },
  close: {
    x: -30,
    opacity: 0,
  },
};
