import {
  applicationStatusType,
  applicationType,
  SBPCheckErrorType,
} from "../../types/application/types";
import { routes } from "../../routes";
import { errorApplicationType } from "../../store/reducers/application/types";

/*
  Тут по статусу заявки с бека решается, на какую страницу пойдет юзер.
  Для каждого тайпа - своя страница
 */
export const userRedirect = (
  status: applicationStatusType | undefined,
  error_type?: applicationType["error_type"]
) => {
  switch (status) {
    case "inn_check_process":
    case "new":
      return routes.application.statuses.data_verification;

    case "refusal_final":
      return routes.application.loan_rejection;

    case "document_filling":
    case "car_document_filling":
    case "need_document_correction_filling":
    case "passport_photo_filling":
      return routes.application.documents;

    case "recognize_documents_process":
      return routes.application.statuses.checking_car;

    case "need_replace_photo_filling":
      return routes.application.repeated_photos;

    case "passport_filling":
      return routes.application.passport_and_inn;

    case "car_photo_filling":
      return routes.application.car_photo;

    // новый флоу, раньше был код
    case "signing_applying_agreement_filling":
      return routes.application.code.loan;

    case "sent_process":
    case "scoring_process":
    case "video_call":
    case "underwriter_scoring_process":
      return routes.application.statuses.sent_and_scoring_process;

    case "super_underwriter_scoring_process":
      return routes.application.statuses.scoring_and_loan_disbursement;

    case "order_filling":
      return routes.application.loan_decision;

    // case "generate_loan_contract_process":
    //   return routes.application.statuses.payment_system_response;

    case "issue_method_filling":
      return routes.application.statuses.issue_method_filling;

    /*
        iframe с сбп будет тут, на application.loan_processing и дальше
       */
    case "issue_method_payment_process":
      return routes.application.loan_processing;

    case "sbp_check_process":
      return routes.application.statuses.account_check;

    case "sbp_check_error":
    case "signed_issuing_sbp_error":
      return `/application/sbp_check_error/${error_type}`;

    case "repledge_filling":
      return routes.application.refinancing;

    case "repledge_document_filling":
      return routes.application.replege_photo;

    case "survey_filling":
      // return routes.application.refinancing;
      return routes.application.sbp_bank_filling;

    case "signing_loan_contract_filling":
    case "generate_loan_contract_process":
      return routes.application.code.contract;

    case "signing_process":
    case "signed_check_documents_process":
      return routes.application.statuses.signing_process;

    case "signed_issuing_process":
      return routes.application.statuses.signed_issuing_process;

    case "document_scoring_process":
    case "document_underwriter_scoring_process":
    case "document_quality_underwriter_scoring_process":
      return routes.application.statuses.scoring_and_loan_disbursement;

    default:
      return routes.sandbox;

    //  TODO обрабатывать ли остальные 4 статуса, а именно final тут?
  }
};
