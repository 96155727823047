import React from "react";
import styles from "./index.module.css";
import { Icons } from "../../../../../assets/icons";
import { createFC } from "../../../../../helpers/createFC";

export const OneStepFlowHeader = createFC(() => {
  return (
    <div className={styles.container}>
      <Icons.logos.CreddyLarge className={styles.creddy_logo} />
      <Icons.logos.BankiRu className={styles.banki_logo} />
    </div>
  )
});
