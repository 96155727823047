/**
 * @payload
 * -- password
 * -- password_confirm
 * -- code
 */
import { PayloadAction } from "@reduxjs/toolkit";
import { call } from "redux-saga/effects";
import { downloadFileDocument } from "../../../api/main/documents/types";
import { processStateController } from "../../util/helpers/processStateController";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { downloadFile } from "../../../helpers/file";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaActions } from "../../util/sagaActions";
import { logout } from "../../../store/reducers/user";

export function* newLoanSaga({
  payload,
  type: actionType,
}: PayloadAction<any>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    yield call(logout.saga);
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.application.downloadFile.type,
    });
  } finally {
    yield process.stop();
  }
}
