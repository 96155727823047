import React, {
  FC,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import get from "lodash/get";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../../../store/reducers/application";
import { useForm, useWatch } from "react-hook-form";
import { SmartForm } from "../../../../hocs/SmartForm";
import { InputEditable } from "../../../../ui/Inputs/InputEditable";
import { SmartCalendar } from "../../../../ui/Calendar";
import { Switcher } from "../../../../ui/Switcher";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { Button } from "../../../../ui/Button";
import { RoundContainer } from "../../../../ui/Containers/RoundContainer";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetIssuedBy } from "../../../../../hooks/api/useGetIssuedBy";
import cn from "classnames";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { capitalize } from "../../../../../helpers/string";
import { showYMDDate } from "../../../../../helpers/date";
import { sendMetrics } from "../../../../../metrics";
import {
  Conditions,
  roundDownToNearestThousand,
} from "../../../../ui/Conditions";
import { RangeWithTitleVariant2 } from "../../../../ui/RangeWithTitleVariant2";
import { SwitcherTriple } from "../../../../ui/SwitcherTriple";
import { RangeWithTitle } from "../../../../ui/RangeWithTitle";
import { FillingAssistance } from "../../../../ui/FillingAssistance";
import { VinAndLicensePlate } from "../../../../ui/VinAndLicensePlate";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { fioAndGrzSchema } from "./shema";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import { userReducerSelectors } from "../../../../../store/reducers/user/selectors";
import { useFetchCar } from "../../../../../hooks/logical/useFetchCar";
import { useChangeFocus } from "../../../../../hooks/logical/useChangeFocus";
import { useGetSchedule } from "../../../../../hooks/api/useGetSchedule";
import { useDebounce } from "../../../../../hooks/logical/useDebounce";
import { ConditionFioComponent } from "./ConditionFioComponent";
import { useGetInfoFromDocuments } from "../../../../../hooks/api/useGetInfoFromDocuments";
import { useGetApplicationAndSetValue } from "../../../../../hooks/api/useGetApplicationAndSetValue";
import { InputText } from "../../../../ui/Inputs/InputText";
import { Icons } from "../../../../../assets/icons";
import { userReducer } from "../../../../../store/reducers/user";
import { routingAction } from "../../../../../sagas/util/helpers/routingSaga";
import { ErrorBanner } from "../Statuses/SBPCheckError/errorBannerSBP";
import useTrackTabClose from "../../../../../metrics/customHooks/useTrackTabClose";
import { Popup } from "../../../../ui/Popup";

export const FioAndGrz: FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  // const [currentField, setCurrentField] = useState("");
  const [isLoadingFetchCar, setIsLoadingFetchCar] = useState(false);

  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const [onChangeDisable, setOnChangeDisable] = useState(false);

  const [testLoading, setTestLoading] = useState(false);

  const lastNameRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const middleNameRef = useRef<HTMLInputElement>(null);
  const birthDateRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const history = useHistory();

  // const calendarRef = useRef<HTMLInputElement>(null);

  const phoneNumber = useSelector(userReducerSelectors.getPhone);

  // const fetchCar = useFetchCar(phoneNumber, history, setIsLoadingFetchCar);

  const postApplicationLoading = useSelector(
    processLoading.get(applicationSagaActions.postApplicationSaga.type)
  );

  const routingLoading = useSelector(processLoading.get("routing"));

  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const applicationType = useSelector(
    applicationReducerSelectors.getApplicationType
  );

  const form = useForm<any>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(fioAndGrzSchema),
    /** при вводе в инпут с датой всякой дичи типа 55.68.999, datepicker сразу при вводе преобразовывает это в какую-то
     валидную, но неочевидную дату, и соответственно, дальнейшая валидация через yup проходит, из hook-form достать
     можем только уже преобразованную дату, поэтому отсекаем такие штуки сразу через реф (так же для даты выдачи
     паспорта на шаге 2) */
    context: { dateInputValue: birthDateRef?.current?.value },
  });

  const [
    amount,
    loan_term,
    schedule_amount,
    schedule_loan_term,
    last_name,
    first_name,
    middle_name,
    birth_day,
    gender,
    email,
    reg_number,
    vin,
    isChecked,
  ] = useWatch({
    control: form.control,
    name: [
      "loan_data.amount",
      "loan_data.loan_term",
      "schedule_amount",
      "schedule_loan_term",
      "passport.last_name",
      "passport.first_name",
      "passport.middle_name",
      "passport.birth_day",
      "passport.gender",
      "email",
      "reg_number",
      "vin_number",
      "vin_or_license_plate_selected",
    ],
  });

  const isAllFieldsFilled = !!(
    last_name &&
    first_name &&
    middle_name &&
    birth_day &&
    email &&
    reg_number
  );

  const firstMainInput = document.querySelector(
    "#mainPartInput div div input"
  ) as HTMLInputElement | null;

  const refuseReason: number | string | null = useSelector(
    applicationReducerSelectors.getRefuseReason
  );

  const { isLoading } = useGetApplicationAndSetValue(
    form.setValue,
    form.setError
  );

  const debouncedValue = useDebounce(
    { schedule_amount, schedule_loan_term, percent_rate: 60 },
    1000
  );

  const { keyPressHandler, setCurrentField } = useChangeFocus(
    [
      lastNameRef,
      firstNameRef,
      middleNameRef,
      birthDateRef,
      emailRef,
      { current: firstMainInput },
    ],
    isAllFieldsFilled
  );

  const { schedule, isLoading: scheduleLoading } =
    useGetSchedule(debouncedValue);

  useEffect(() => {
    form.setValue("schedule_amount", amount);
    form.setValue("schedule_loan_term", loan_term);
  }, [amount, loan_term, schedule_amount, schedule_loan_term]);

  // window.onbeforeunload = function () {
  //   return "Данные не сохранены. Точно перейти?";
  // };

  // для стейджа, чтобы скипать заполнение формы
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      form.setValue("passport.first_name", "Андрей");
      form.setValue("passport.last_name", "Щербатых");
      form.setValue("passport.middle_name", "Сергеевич");
      form.setValue("passport.birth_day", "1986-12-20");
      form.setValue("email", "flash.good99@gmail.com");
      form.setValue("reg_number", "Е151ВО197");
    }
  }, []);

  const submit = (data: any) => {
    let firstName = String(data?.passport?.first_name).toLowerCase().trim();
    let last_name = String(data?.passport?.last_name).toLowerCase().trim();
    let middle_name = data?.passport?.middle_name
      ? String(data?.passport?.middle_name).toLowerCase().trim()
      : null;
    const birthDayData = showYMDDate(data?.passport?.birth_day);
    const email = data.email.trim();

    if (reg_number?.length >= 8) {
      lsController.set("grz", reg_number);
      dispatch(
        userReducer.actions.setFullName({
          full_name:
            (data?.passport?.last_name ? data.passport.last_name + " " : "") +
            (data?.passport?.first_name ? data.passport.first_name + " " : "") +
            (data?.passport?.middle_name ? data.passport.middle_name : ""),
        })
      );

      if (!applicationId || applicationType === "refusal_final") {
        dispatch(
          applicationSagaActions.postApplicationSaga({
            vin_or_reg_number: reg_number,
            loan_data: {
              amount,
              loan_term: String(loan_term),
            },
            user_info: {
              first_name: capitalize(firstName),
              middle_name: middle_name ? capitalize(middle_name) : null,
              last_name: capitalize(last_name),
              birth_day: birthDayData,
              gender: data.passport.gender ? "female" : "male",
            },
            email,
          })
        );
      } else {
        dispatch(routingAction());
      }

      setIsLoadingFetchCar(false);
      TagManager.dataLayer({
        dataLayer: {
          event: "buttonClick",
          buttonLabel: "Example Button",
        },
      });
    } else {
      form.setError("reg_number", {
        type: "custom",
        message: "Заполните поле",
      });
    }

    if (vin?.length === 17) {
      setIsLoadingFetchCar(true);
      lsController.set("vin", vin);
      dispatch(
        userReducer.actions.setFullName({
          full_name:
            (data?.passport?.last_name ? data.passport.last_name + " " : "") +
            (data?.passport?.first_name ? data.passport.first_name + " " : "") +
            (data?.passport?.middle_name ? data.passport.middle_name : ""),
        })
      );
      dispatch(
        applicationSagaActions.postApplicationSaga({
          vin_or_reg_number: vin,
          loan_data: {
            amount,
            loan_term: String(loan_term),
          },
          user_info: {
            first_name: capitalize(firstName),
            middle_name: middle_name ? capitalize(middle_name) : null,
            last_name: capitalize(last_name),
            birth_day: birthDayData,
            gender: data.passport.gender ? "female" : "male",
          },
          email,
          monthly_income: schedule?.monthly_payment,
        })
      );
      setIsLoadingFetchCar(false);
    } else {
      form.setError("vin_number", {
        type: "custom",
        message: "Заполните поле",
      });
    }
  };

  const localGrz = lsController.get("grz");

  useEffect(() => {
    lsController.clearWasOptionalPhotoRecognized();
    lsController.clearFullName();
  }, []);

  useMemo(() => {
    if (refuseReason) {
      return sendMetrics(
        "common-client_avtootkaz-po-grz_common-app",
        "avtootkaz-po-grz"
      );
    }
  }, [refuseReason]);

  useTrackTabClose("common-client_leave-LK_common-app", "leave-LK");

  const loanData = {
    amount,
    loan_term,
  };

  useEffect(() => {
    form.setValue(
      "loan_data.amount",
      Number(lsController.get("amount")) || 500000
    );
    form.setValue(
      "loan_data.loan_term",
      Number(lsController.get("period_month")) || 48
    );
  }, []);

  const lowPeriod = amount >= 50000 && amount < 80000;

  useEffect(() => {
    if (lowPeriod && loan_term > 12) {
      form.setValue("loan_data.loan_term", 12);
    }
  }, [amount, loan_term]);

  const monthRangeDataHigh = [3, 48];

  const monthRangeDataLow = [3, 12];

  const amountError = amount < 50000 || amount > 500000;

  useEffect(() => {
    if (form.formState.errors.vin_or_reg_number?.message && vin.length >= 17) {
      form.setError("vin_number", {
        type: "custom",
        message: "Введите корректный VIN",
      });
    } else if (
      form.formState.errors.vin_or_reg_number?.message &&
      reg_number.length >= 8
    ) {
      form.setError("reg_number", {
        type: "custom",
        message: "Введите корректный номер автомобиля",
      });
    }
  }, [form.formState.errors.vin_or_reg_number?.message]);

  let a = undefined;

  const memoizedConditions = React.useMemo(
    () => (
      <ConditionFioComponent
        scheduleLoading={scheduleLoading}
        approve_button={false}
        monthly_payment={schedule?.monthly_payment}
        amount={amount}
        loan_term={loan_term}
        loading={testLoading}
        children={
          <>
            <RangeWithTitleVariant2
              amount={amount}
              amountError={amountError}
              maxLength={7}
              period={false}
              step={10000}
              min={50000}
              max={500000}
              title={""}
              name={"loan_data.amount"}
              containerClass={styles.loan_amount}
            />
            <div className={styles.divider} />
            <RangeWithTitleVariant2
              amountForTextInformation={amount}
              textInformation
              maxLength={2}
              period
              marks
              rangeData={lowPeriod ? monthRangeDataLow : monthRangeDataHigh}
              max={lowPeriod ? 12 : 48}
              min={3}
              title={""}
              name={"loan_data.loan_term"}
              containerClass={styles.loan_amount}
            />
            {/*<SwitcherTriple*/}
            {/*  values={[24, 36, 48]}*/}
            {/*  name={"loan_data.loan_term"}*/}
            {/*  outerLabel={"Срок займа"}*/}
            {/*  innerLabels={["24 месяца", "36 месяцев", "48 месяцев"]}*/}
            {/*  switchVariant={"huge_gender"}*/}
            {/*  variant={"large"}*/}
            {/*/>*/}
          </>
        }
        className={styles.conditions_container}
      />
    ),
    [schedule?.monthly_payment, amount, loan_term, testLoading]
  );

  return (
    <MobilePageContainer
      className={cn(styles.form, {
        [styles.form_no_padding]: false,
      })}
    >
      {/*{isLoading ? (*/}
      {/*  <Loader color={"purple"} variant={"large"} className={styles.loader} />*/}
      {/*) : (*/}
      <>
        <SmartForm form={form} submit={submit}>
          {/*<Conditions*/}
          {/*  className={styles.conditions_container}*/}
          {/*  loanData={loanData}*/}
          {/*  loading={testLoading}*/}
          {/*  isOpen={isDropdownOpen}*/}
          {/*  setIsOpen={setIsDropdownOpen}*/}
          {/*  acceptButton={false}*/}
          {/*/>*/}
          {memoizedConditions}
          <RoundContainer className={styles.fio_grid}>
            <InputText
              name={"passport.last_name"}
              label={"Фамилия"}
              className={styles.fullName}
              inputElementRef={lastNameRef}
              onKeyDown={keyPressHandler}
              onFocus={() => setCurrentField("passport.last_name")}
            />
            <InputText
              name={"passport.first_name"}
              label={"Имя"}
              className={styles.fullName}
              inputElementRef={firstNameRef}
              onKeyDown={keyPressHandler}
              onFocus={() => setCurrentField("passport.first_name")}
            />
            <InputText
              name={"passport.middle_name"}
              label={"Отчество"}
              className={styles.fullName}
              inputElementRef={middleNameRef}
              onKeyDown={keyPressHandler}
              onFocus={() => setCurrentField("passport.middle_name")}
            />

            <SmartCalendar
              name={"passport.birth_day"}
              placeholder={"Дата рождения"}
              inputRef={birthDateRef as MutableRefObject<HTMLInputElement>}
              onKeyDown={keyPressHandler}
              onFocus={() => setCurrentField("passport.birth_day")}
            />

            <Switcher
              name={"passport.gender"}
              className={styles.gender}
              outerLabel={"Пол"}
              innerLabels={["Мужской", "Женский"]}
              switchVariant={"huge_gender"}
              variant={"large"}
            />
          </RoundContainer>
          <InputText
            className={styles.mt_input}
            variant={"large"}
            label={"e-mail"}
            name={"email"}
            maxLength={255}
            autoCapitalize={"off"}
            autoComplete={"email"}
            inputMode={"email"}
            inputElementRef={emailRef}
            onKeyDown={keyPressHandler}
            onFocus={() => setCurrentField("email")}
          />
          {refuseReason === "car_check_no_car" ? (
            <>
              <ErrorBanner
                text={`По номеру ${
                  localGrz ? localGrz : " - "
                } не удалось определить автомобиль, введите VIN`}
              />
              <VinAndLicensePlate
                variant={"onlyVin"}
                setError={form.setError}
                className={styles.vin_and_license_plate}
              />
            </>
          ) : (
            <>
              <VinAndLicensePlate
                variant={"onlyLicensePlate"}
                setError={form.setError}
                className={styles.vin_and_license_plate}
              />
            </>
          )}
          <div className={styles.note}>Автомобиль и ПТС остаются у вас</div>
          <Button
            className={styles.button}
            loading={postApplicationLoading || routingLoading}
            disabled={refuseReason === "car_check_no_car" && vin?.length < 17}
          >
            Продолжить
          </Button>
          {/*<FillingAssistance />*/}
        </SmartForm>
        <Button
          variant={"outline"}
          className={styles.button_outline}
          onClick={() => {
            setShowPopup(true);
            sendMetrics(
              "common-client_click-net-avto-step1anketa_common-app",
              "click-net-avto-step1anketa"
            );
          }}
          disabled={refuseReason === "car_check_no_car" && vin?.length < 17}
        >
          У меня нет автомобиля
        </Button>
      </>
      <Popup
        isOpen={showPopup}
        setIsOpen={setShowPopup}
        children={(
          <div className={styles.popup}>
            <div className={styles.popup_title}>Мы выдаем займы только <br /> под залог авто</div>
            <div className={styles.popup_content}>
              <div className={styles.popup_text}>
                Для получения беззалогового займа Вы можете обратиться к нашим партнерам
              </div>
              <Button onClick={() => {
                sendMetrics(
                  "common-client_click-popup-netavto-partner-app_common-app",
                  "click-popup-netavto-partner-app"
                );
              }}>
                <a href={"https://bankipartners.ru/s/rlK15FDhLL?statid=1282_&erid=3"} target={"_blank"}>
                  Перейти к предложениям
                </a>
              </Button>
              <Button
                className={styles.button_underline}
                variant={"underline"}
                onClick={() => setShowPopup(false)}
              >
                Вернуться к анкете
              </Button>
            </div>
          </div>
        )}
      />
    </MobilePageContainer>
  );
};
