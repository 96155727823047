import React, { useEffect, useState } from "react";
import moment from "moment";
import styles from "./index.module.css";
import { createFC } from "../../../../../helpers/createFC";
import { RoundContainer } from "../../../../ui/Containers/RoundContainer";
import { PaymentSchedule } from "../../../../ui/PaymentSchedule";
import { Button } from "../../../../ui/Button";
import { ResizablePanel } from "../../../../ui/ResizablePanel";
import { InfoBlock } from "../../../../ui/InfoBlock";
import { Icons } from "../../../../../assets/icons";
import cn from "classnames";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { Loader } from "../../../../ui/Loader";
import { useGetLoan } from "../../../../../hooks/api/useGetLoan";
import { dateMethods } from "../../../../../helpers/date";
import { useDispatch, useSelector } from "react-redux";
import { AmountSelectionPopup } from "./components/AmountSelectionPopup";
import {
  firstLetterUpperCase,
  stringHelpers,
} from "../../../../../helpers/string";
import { DotsDropdown } from "../../../../ui/DotsDropdown";
import { paymentType } from "../../../../../api/main/application/types";
import { FullRepaymentPopup } from "./components/FullRepaymentPopup";
import { DesktopSupportLinks } from "../../components/DesktopSupportLinks";
import { LoanInfoDesktop } from "./components/LoanInfoDesktop";
import { StateType } from "../../../../../store";
import { useGetInfoFromApplication } from "../../../../../hooks/api/useGetInfoFromApplication";
import { linksToDocumentsOnStatic } from "../../../../../helpers/init/constantStrings";
import { InfoDropdown } from "./components/Dropdown";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { months } from "../../../../ui/Calendar/helpers";
import { FillingAssistance } from "../../../../ui/FillingAssistance";
import { HelpFooter } from "../../../../ui/HelpFooter";
import { API } from "../../../../../api";
import { AxiosResponse } from "axios";
import {
  earlyRepaymentInfoType,
  paymentInfoType,
} from "../../../../../types/cabinet/types";
import { CancelRepaymentPopup } from "./components/CancelRepaymentPopup";
import { routes } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import { getMonth } from "date-fns";
import { PaidLoanItemCard } from "../../../Application/pages/PaidLoan/PaidLoanItemCard";
import { BannerGreen } from "../../../../ui/BannerGreen";
import { BannerWhite } from "../../../../ui/BannerWhite";
import { Banner, onAddFileType } from "../../../../ui/Banner";
import { useForm } from "react-hook-form";
import { SmartForm } from "../../../../hocs/SmartForm";

type propsType = {};

export const MyLoan = createFC((props: propsType) => {
  const form = useForm<any>();

  const dispatch = useDispatch();

  const history = useHistory();

  const [loanType, setLoanType] = useState<"user" | "taxi">("user");

  // Попап на обычную оплату и чдп
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [isCancelRepaymentPopupOpen, setIsCancelRepaymentPopupOpen] =
    useState(false);
  const [isCancelRepaymentId, setIsCancelRepaymentId] = useState(null);
  // const [earlyReapymentInfo, setEarlyRepaymentInfo] = useState<{ amount: number, date: Date }>();
  const [amount, setAmount] = useState(0);
  const [partialRepaymentError, setPartialRepaymentError] = useState(false);

  const loan_id = useSelector((state: StateType) => state.loanReducer.loan_id);

  const applicationId: any = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  // попап на пдп
  const [isFullRepaymentPopupOpen, setIsFullRepaymentPopupOpen] =
    useState<boolean>(false);

  // const [isOverdue, setIsOverdue] = useState<boolean>(false);

  const [paymentType, setPaymentType] = useState<paymentType>("due_repayment");

  const {
    data,
    nextPayment,
    paymentHasBeenMade,
    currentSituation,
    currentSchedule,
    isPaymentSoon,
    currentAmount,
    nextSchedule,
    allSchedule,
    currentLoan,
    isOverdue,
    isLoading,
    fullDaysToPenalty,
    overdueFullDays,
  } = useGetLoan();

  //TODO payment_process_repayment статус для рендера плашки, проверить с беком

  const signedRepayment = data?.data?.data?.early_repayments.filter(
    (item: any) => {
      return item.status === "signed_repayment";
    }
  );

  const {
    bank_card,
    car,
    data: applicationData,
    isLoading: carDataLoading,
  } = useGetInfoFromApplication();

  // * test Убрать потом везде эти значения потом
  const [isPaymentSoon2, setIsPaymentSoon] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [isLoanPaid, setIsLoanPaid] = useState(false);
  // const [toPay, setToPay] = useState<number>(currentAmount || 0);
  const [toPay, setToPay] = useState<number>(
    isOverdue
      ? currentSituation?.overdue_loan_body +
          currentSituation?.overdue_percents +
          currentSituation?.loan_body_penalties +
          currentSituation?.percent_penalties
      : 0
  );
  // * test end

  const notMoneyEnough =
    currentSchedule?.amount &&
    currentSchedule?.amount - currentSituation?.balance;

  const dropdownItemsOverdue = {
    title: `К погашению: ${stringHelpers.transformMoneyValue(
      (
        currentSituation?.overdue_loan_body +
        currentSituation?.overdue_percents +
        currentSituation?.loan_body_penalties +
        currentSituation?.percent_penalties
      ).toFixed(2)
    )} ₽`,
    variant: "white",
    // className: styles.inf_drop_container_first,
    // shiftDownStyles: styles.shiftDown_first,
    items: (
      <ul className={cn(styles.dropdown_items, styles.dropdown_items_white)}>
        <li>
          Просрочено:{" "}
          <span className={styles.bold_text}>
            {stringHelpers.transformMoneyValue(
              (
                currentSituation?.overdue_loan_body +
                currentSituation?.overdue_percents
              ).toFixed(2)
            )}{" "}
            ₽
          </span>
        </li>
        {/*<li>Проценты на задолженность: <span className={styles.bold_text}>???</span></li>*/}
        <li>
          Пени:{" "}
          <span className={styles.bold_text}>
            {stringHelpers.transformMoneyValue(
              (
                currentSituation?.loan_body_penalties +
                currentSituation?.percent_penalties
              ).toFixed(2)
            )}{" "}
            ₽
          </span>
        </li>
      </ul>
    ),
  };

  const dropdownItemsNotMoneyEnough = {
    title: (
      <div>
        Не хватает:{" "}
        <span className={styles.bold_text}>
          {currentSchedule?.amount &&
            stringHelpers.transformMoneyValue(
              (currentSchedule?.amount - currentSituation?.balance).toFixed(2)
            )}
        </span>{" "}
        ₽
      </div>
    ),
    variant: "white",
    // className: styles.inf_drop_container_first,
    // shiftDownStyles: styles.shiftDown_first,
    items: (
      <ul className={cn(styles.dropdown_items)}>
        <li>
          Основной долг:{" "}
          <span className={styles.bold_text}>
            {stringHelpers.transformMoneyValue(
              currentSchedule?.loan_body.toFixed(2)
            )}{" "}
            ₽
          </span>
        </li>
        <li>
          Проценты:{" "}
          <span className={styles.bold_text}>
            {stringHelpers.transformMoneyValue(
              currentSchedule?.percents.toFixed(2)
            )}{" "}
            ₽
          </span>
        </li>
      </ul>
    ),
  };

  const dropdownItemsCabbyOverduePayment = {
    title: `К погашению: 200 000,22 ₽`,
    variant: "white",
    // className: styles.inf_drop_container_first,
    // shiftDownStyles: styles.shiftDown_first,
    items: (
      <ul
        className={cn(styles.dropdown_items_cabby, styles.dropdown_items_black)}
      >
        <li>
          Просрочено:{""}
          <span className={styles.bold_text}> 200 000,22 ₽</span>
        </li>
        <li>
          Проценты на задолженность:{""}
          <span className={styles.bold_text}> 80 000,01 ₽</span>
        </li>
        <li>
          Пени:{""}
          <span className={styles.bold_text}> 20 000,10 ₽</span>
        </li>
      </ul>
    ),
  };

  const createLoanPayment = () => {
    setToPay(
      isOverdue
        ? currentSituation?.overdue_loan_body +
            currentSituation?.overdue_percents +
            currentSituation?.loan_body_penalties +
            currentSituation?.percent_penalties
        : currentAmount
    );
    setIsOpenPopup(true);
  };

  useEffect(() => {
    if (loan_id) {
      API.main.application
        .getAllPayments({ loan_id })
        .then(
          (
            response: AxiosResponse<{
              data: { items_count: number; items: Array<paymentInfoType> };
            }>
          ) => {
            const amountFromAllPayments = response.data.data.items
              .filter((item) => item.status === "success_payment")
              .reduce((acc, item) => acc + item.amount, 0);
            setAmount(amountFromAllPayments);
          }
        )
        // .then(() => {
        //   API.main.application.getAllEarlyRepayments({loan_id})
        //     .then((response: AxiosResponse<{ data: { items_count: number, items: Array<earlyRepaymentInfoType> } }>) => {
        //       const amountFromEarlyPayments = response.data.data.items
        //         .filter((item) => item.status === "success_repayment")
        //         .reduce((acc, item) => acc + item.amount, 0);
        //       setAmount((prev) => prev + amountFromEarlyPayments);
        //     })
        // })
        .catch((e) => console.log(e));
    }
  }, [loan_id]);

  const uploadDocument = (data: onAddFileType) => {
    return API.main.documents.uploadDocumentMyLoan({
      document_type: "repledge_loan_closure_certificate",
      loan_id: loan_id as string,
      content: data.content,
      file_name: data.file_name,
    });
  };

  const textForBanner = () => {
    if (applicationData?.repledge) {
      return `Приложите справку об отсутствии задолженности в ${applicationData?.repledge_external_loan_data?.bank_name}`;
    } else {
      return "";
    }
  };

  if (isLoading || !loan_id || !applicationId || carDataLoading) {
    return (
      <MobilePageContainer className={styles.bg_gray}>
        <Loader variant={"large"} color={"purple"} className={styles.loader} />
      </MobilePageContainer>
    );
  }

  if (loanType === "taxi") {
    return (
      <>
        <div className={styles.container_cabby}>
          <DesktopSupportLinks />

          <div className={styles.mb_top_cabby}></div>

          <div className={styles.desktop_grid}>
            <div>
              <RoundContainer variant={"cabby"}>
                <div className={styles.payment_info}>
                  {currentLoan?.status !== "paid_loan" ? (
                    <div className={styles.balance_container_cabby}>
                      <div>
                        <div className={styles.balance_title_cabby}>
                          Ваш баланс:
                        </div>
                        <div
                          className={cn(styles.amount_cabby, {
                            [styles.amount_overdue]: isOverdue,
                          })}
                        >
                          {stringHelpers.transformMoneyValue(
                            currentSituation?.balance
                          )}{" "}
                          ₽
                        </div>
                      </div>

                      <div className={styles.dividing_line_cabby}></div>

                      {isOverdue ? (
                        <>
                          <div className={styles.overdue_cabby_amount}>
                            Платеж просрочен:{" "}
                            <span className={styles.bold_text}>
                              {" "}
                              123 дня, 200 000,22₽
                            </span>
                          </div>
                          <InfoDropdown
                            className={styles.drop_down_cabby}
                            variant={"white"}
                            title={dropdownItemsCabbyOverduePayment.title}
                            titleFooter={dropdownItemsCabbyOverduePayment.items}
                            isOverdue={isOverdue}
                          />
                        </>
                      ) : (
                        <div className={styles.board_cabby}>
                          Осталось внести:
                          <span className={styles.bold_text}> 19 000,00 ₽</span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={styles.payment_done}>
                      <div className={styles.icon_wrapper}>
                        <Icons.ui.CheckmarkNoPadding
                          className={styles.icon_checkmark}
                        />
                      </div>
                      <h4 className={styles.payment_done_title}>
                        Займ погашен
                      </h4>
                    </div>
                  )}
                </div>

                <div
                  className={styles.container_payment_notification_cabby}
                  // onClick={() => {
                  //   setIsOverdue((prevState) => !prevState);
                  // }}
                >
                  <div
                    className={cn(styles.payment_notification_cabby, {
                      [styles.payment_notification_cabby_warning]: isOverdue,
                    })}
                  >
                    {" "}
                    <span className={styles.bold_text}>
                      {" "}
                      18 ноября 19 000,00 ₽
                    </span>{" "}
                    <br />
                    будет списано из вашей зарплаты <br />
                    от таксопарка автоматически
                  </div>
                  <div className={styles.issued_information}>
                    <Icons.cabinet.Dollars />
                    <div>Выдано: 20 000,00 ₽</div>
                  </div>
                  <div className={styles.dividing_line_cabby_issued}></div>
                  <div className={styles.issued_information}>
                    <Icons.cabinet.CalendarCabinet />
                    <div>Срок займа: 4 недели</div>
                  </div>
                </div>

                {/* * также пляшем от того, есть ли задолженность или нет */}
                {/* isLoanPaid тут для теста, потом выпилить */}
                {currentLoan?.status !== "paid_loan" && (
                  <Button
                    onClick={createLoanPayment}
                    variant={isError || isOverdue ? "warning" : "default"}
                    className={styles.button}
                  >
                    {isError || isOverdue
                      ? "Погасить задолженность"
                      : "Пополнить"}
                  </Button>
                )}

                <ResizablePanel
                  header={
                    <div className={styles.panel_header}>
                      Общая информация о займе{" "}
                      <Icons.ui.arrowAngleIcon className={styles.icon_arrow} />
                    </div>
                  }
                  className={styles.resizable_panel}
                >
                  <div className={styles.line} />
                  <div className={styles.grid}>
                    {/*{process.env.REACT_APP_ENVIRONMENT == "stage" && (*/}
                    {/*  <>*/}
                    {/*    <Button*/}
                    {/*      onClick={() => {*/}
                    {/*        setIsError(false);*/}
                    {/*        setIsPaymentDone(false);*/}
                    {/*        setIsPaymentSoon(false);*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      Сделать, когда всё гуд*/}
                    {/*    </Button>*/}
                    {/*    <Button*/}
                    {/*      onClick={() => {*/}
                    {/*        setIsError(false);*/}
                    {/*        setIsPaymentDone(false);*/}
                    {/*        setIsPaymentSoon(true);*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      Сделать, что скоро оплата*/}
                    {/*    </Button>*/}
                    {/*    <Button*/}
                    {/*      onClick={() => {*/}
                    {/*        setIsError(true);*/}
                    {/*        setIsPaymentDone(false);*/}
                    {/*        setIsPaymentSoon(false);*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      Сделать просроченным*/}
                    {/*    </Button>*/}
                    {/*    <Button*/}
                    {/*      onClick={() => {*/}
                    {/*        setIsError(false);*/}
                    {/*        setIsPaymentDone(true);*/}
                    {/*        setIsPaymentSoon(false);*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      Сделать, когда заранее оплатил*/}
                    {/*    </Button>*/}

                    {/*    /!*не прячется кнопка "Оплатить". забил.*!/*/}
                    {/*    <Button*/}
                    {/*      onClick={() => {*/}
                    {/*        setIsError(false);*/}
                    {/*        setIsLoanPaid(true);*/}
                    {/*        setIsPaymentSoon(false);*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      Сделать, погасил займ*/}
                    {/*    </Button>*/}
                    {/*  </>*/}
                    {/*)}*/}
                    <InfoBlock
                      infoKey={"Название услуги"}
                      infoValue={"Онлайн-займ под ПТС авто"}
                    />
                    <InfoBlock
                      infoKey={"Номер договора"}
                      infoValue={currentLoan?.id}
                    />
                    <InfoBlock
                      infoKey={"Сумма кредита"}
                      infoValue={`${currentLoan?.loan_data?.amount} ₽`}
                    />
                    <InfoBlock infoKey={"Валюта"} infoValue={"Рубль России"} />
                    <InfoBlock
                      infoKey={"Процентная ставка по кредиту"}
                      infoValue={`${(
                        currentLoan?.loan_data?.percent_rate / 12
                      ).toFixed(2)} % (в месяц)`}
                    />
                    <InfoBlock
                      infoKey={"Дата выдачи кредита"}
                      infoValue={moment(currentLoan?.created_at).format(
                        "DD.MM.YYYY"
                      )}
                    />
                    <InfoBlock
                      infoKey={"Дата окончания действия кредита"}
                      infoValue={dateMethods.format2(
                        allSchedule?.schedule?.[
                          allSchedule?.schedule.length - 1
                        ]?.date,
                        true
                      )}
                    />
                    <InfoBlock
                      infoKey={"Срок договора"}
                      infoValue={`${currentLoan?.loan_data?.loan_term} ${
                        currentLoan?.loan_data?.loan_term === "24"
                          ? "месяца"
                          : "месяцев"
                      }`}
                    />
                    <InfoBlock
                      infoKey={"Текущий остаток"}
                      infoValue={`${currentSituation?.current_loan_body} ₽`}
                    />
                    <InfoBlock
                      infoKey={"Дата очередного платежа"}
                      infoValue={dateMethods.format2(
                        (paymentHasBeenMade
                          ? nextSchedule?.date
                          : currentSchedule?.date) ?? null,
                        true
                      )}
                    />
                    <InfoBlock
                      infoKey={"Сумма очередного платежа:"}
                      infoValue={`${currentSchedule?.amount} ₽`}
                    />
                    <InfoBlock
                      infoKey={"Просрочено"}
                      infoValue={`${(
                        currentSituation?.overdue_loan_body +
                        currentSituation?.overdue_percents
                      ).toFixed(2)} ₽`}
                      warning={isOverdue}
                    />

                    <InfoBlock
                      infoKey={"Начислено пени"}
                      infoValue={`${(
                        currentSituation?.percent_penalties +
                        currentSituation?.loan_body_penalties
                      ).toFixed(2)} ₽`}
                      warning={isOverdue}
                    />
                  </div>
                </ResizablePanel>
              </RoundContainer>
            </div>
            <LoanInfoDesktop
              allSchedule={allSchedule}
              currentLoan={currentLoan}
              car={car}
              className={styles.LoanInfoDesktop}
            />
          </div>
        </div>
        {/*<HelpFooter variant="minimalism" className={styles.footer} />*/}
      </>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <DesktopSupportLinks />

        <div className={styles.desktop_page_title}>Займ под залог авто</div>
        <div className={styles.desktop_page_subtitle}>
          {car?.name_original}, {car?.year} г.,{" "}
          {stringHelpers.transformRegNumber(car?.reg_number)}
        </div>

        <div className={styles.desktop_grid}>
          <div>
            <RoundContainer variant={"cabinet"}>
              {applicationId === "1ee0ff03-7b58-6796-ac07-c30c5b9ef860" && (
                <div className={styles.notes_block}>
                  <div>
                    <a target={"_blank"} href={linksToDocumentsOnStatic.mb}>
                      <Icons.ui.PdfLogo className={styles.img} />
                    </a>
                  </div>
                  <div className={styles.notes_block_text}>
                    <a target={"_blank"} href={linksToDocumentsOnStatic.mb}>
                      {" "}
                      Уведомление о предоставлении льготного периода и изменении
                      условий Договора потребительского микрозайма № 369818977
                      от 21.06.2023г.
                    </a>
                  </div>
                </div>
              )}
              <div className={styles.header}>
                <h2 className={styles.title}>Онлайн-займ под авто</h2>
                {currentLoan?.status !== "paid_loan" && (
                  <DotsDropdown
                    className={styles.dotsDropdown}
                    handlePartialEarlyRepayment={() => {
                      setPaymentType((prev) => "partly_repayment");
                      // history.push(routes.cabinet.early_repayment_amount);
                      setIsOpenPopup(true);
                      setIsFullRepaymentPopupOpen(false);
                      setPartialRepaymentError(isOverdue);
                    }}
                    handleFullRepayment={() => {
                      // history.push(routes.cabinet.early_repayment_amount);
                      setIsFullRepaymentPopupOpen(true);
                      setIsOpenPopup(false);
                    }}
                  />
                )}
                <div className={styles.info_car}>
                  {car &&
                    `${car?.name_original}, ${car?.year} г., ${car?.reg_number}`}
                </div>
                {/*{(isPaymentSoon2 || isPaymentSoon) && (*/}
                {/*  <div className={styles.header_right_part}>*/}
                {/*    Скоро платеж*/}
                {/*    <div*/}
                {/*      className={cn(styles.circle_status, {*/}
                {/*        // * это делать true, когда платёж просрочен.*/}
                {/*        [styles.circle_status_warning]: isError || isOverdue,*/}
                {/*      })}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*)}*/}

                {currentLoan?.status === "paid_loan" && (
                  <div className={styles.header_right_part}>
                    Погашен
                    <div
                      className={cn(
                        styles.circle_status,
                        styles.circle_status_green
                      )}
                    />
                  </div>
                )}
              </div>

              <SmartForm
                form={form}
                submit={() => console.log()}
                className={styles.banner}
              >
                {applicationData.repledge && (
                  <Banner
                    name={"file"}
                    setValue={form.setValue}
                    control={form.control}
                    variant={"blue"}
                    text={textForBanner()}
                    iconLeft={<Icons.ui.Information />}
                    // iconRight={<Icons.ui.CheckmarkWhiteCircle />}
                    uploadDocument={uploadDocument}
                  />
                )}
              </SmartForm>

              <div className={styles.info}>
                <p>
                  {currentLoan?.car?.name_original}, {currentLoan?.car?.year}{" "}
                  г.,
                </p>
                <p>
                  {stringHelpers.transformRegNumber(
                    currentLoan?.car?.reg_number
                  )}
                </p>
              </div>

              <div className={styles.payment_info}>
                {/* * если платёж не внесён, то true, если уже внесён, то false */}
                {currentLoan?.status !== "paid_loan" ? (
                  // isPaymentDone || paymentHasBeenMade ? (
                  //   <div className={styles.payment_done}>
                  //     <div className={styles.icon_wrapper}>
                  //       <Icons.ui.Checkmark_no_padding
                  //         className={styles.icon_checkmark}
                  //       />
                  //     </div>
                  //     <h4 className={styles.payment_done_title}>
                  //       Платеж внесен
                  //     </h4>
                  //     {nextSchedule && (
                  //       <p className={styles.next_payment}>
                  //         Следующий платеж:{" "}
                  //         {dateMethods.format2(
                  //           nextSchedule?.date || null,
                  //           true
                  //         )}
                  //       </p>
                  //     )}
                  //   </div>
                  // ) : (
                  <div>
                    {/*<div className={styles.nearest_payments}>*/}
                    {/*  {isOverdue ? (*/}
                    {/*    <h2 className={styles.nearest_payment}>*/}
                    {/*      Платеж просрочен:{" "}*/}
                    {/*      <span className={styles.nearest_payment_content}>*/}
                    {/*      {stringHelpers.getDaysWithNoun(overdueFullDays)}{", "}*/}
                    {/*        <span className={styles.amount_overdue}>*/}
                    {/*          {stringHelpers.transformMoneyValue(*/}
                    {/*            (*/}
                    {/*              currentSituation?.overdue_loan_body +*/}
                    {/*              currentSituation?.overdue_percents).toFixed(2*/}
                    {/*            )*/}
                    {/*          )}{" "}*/}
                    {/*        </span>₽*/}
                    {/*      </span>*/}
                    {/*    </h2>*/}
                    {/*  ) : (*/}
                    {/*    <h2 className={styles.nearest_payment}>*/}
                    {/*      Платеж:{" "}*/}
                    {/*      <span className={styles.nearest_payment_content}>*/}
                    {/*      до {moment(nextPayment).format("DD")}{" "}*/}
                    {/*        {nextPayment && dateMethods.getMonthName(*/}
                    {/*          new Date(nextPayment)*/}
                    {/*        )}{", "}*/}
                    {/*        <span className={styles.sum}>*/}
                    {/*          {stringHelpers.transformMoneyValue(*/}
                    {/*            Number(currentSchedule?.amount).toFixed(2)*/}
                    {/*          )}{" "}₽*/}
                    {/*        </span>*/}
                    {/*  </span>*/}
                    {/*    </h2>*/}
                    {/*  )}*/}
                    {/*  <h2 className={styles.nearest_payment}>*/}
                    {/*    Следующий:{" "}*/}
                    {/*    <span className={styles.nearest_payment_content}>*/}
                    {/*      до {moment(nextSchedule?.date).format("DD")}{" "}*/}
                    {/*      {nextSchedule?.date && dateMethods.getMonthName(*/}
                    {/*        new Date(nextSchedule.date)*/}
                    {/*      )}{", "}*/}
                    {/*      <span className={styles.sum}>*/}
                    {/*        {stringHelpers.transformMoneyValue(*/}
                    {/*          Number(nextSchedule?.amount).toFixed(2)*/}
                    {/*        )}{" "}₽*/}
                    {/*      </span>*/}
                    {/*  </span>*/}
                    {/*  </h2>*/}
                    {/*</div>*/}

                    {/*<p*/}
                    {/*  className={cn(styles.amount, {*/}
                    {/*    [styles.amount_warning]:*/}
                    {/*      isPaymentSoon2 || isPaymentSoon,*/}
                    {/*    [styles.amount_danger]: isError || isOverdue,*/}
                    {/*  })}*/}
                    {/*>*/}
                    {/*  {stringHelpers.transformMoneyValue(*/}
                    {/*    Number(currentAmount).toFixed(2)*/}
                    {/*  )}{" "}*/}
                    {/*  ₽*/}
                    {/*</p>*/}
                    {isOverdue && (
                      <div className={styles.overdue_banner_container}>
                        <div className={styles.overdue_banner_main_title}>
                          Имеется задолженность
                        </div>
                        <div className={styles.overdue_banner_title}>
                          {`По договору потребительского микрозайма  №${
                            currentLoan?.loan_number
                              ? currentLoan?.loan_number
                              : "-"
                          }
                            от ${moment(currentLoan?.issued_date).format(
                              "DD.MM.YYYY"
                            )} г. `}
                        </div>
                      </div>
                    )}
                    <div className={styles.balance_container}>
                      <div>Ваш баланс:</div>
                      <div
                        className={cn(styles.amount, {
                          [styles.amount_overdue]:
                            isOverdue ||
                            (fullDaysToPenalty !== undefined &&
                              fullDaysToPenalty <= 3 &&
                              fullDaysToPenalty >= 0 &&
                              notMoneyEnough &&
                              notMoneyEnough > 0),
                        })}
                      >
                        {isOverdue
                          ? stringHelpers.transformMoneyValue(
                              (
                                currentSituation?.balance -
                                (currentSituation?.overdue_loan_body +
                                  currentSituation?.overdue_percents +
                                  currentSituation?.loan_body_penalties +
                                  currentSituation?.percent_penalties)
                              ).toFixed(2)
                            )
                          : stringHelpers.transformMoneyValue(
                              (
                                currentSituation?.balance +
                                (currentSituation?.partial_repayment_balance ||
                                  0)
                              ).toFixed(2)
                            )}{" "}
                        ₽
                      </div>
                      <div className={styles.divider} />
                      {!isOverdue &&
                        !!(
                          fullDaysToPenalty !== undefined &&
                          fullDaysToPenalty >= 0 &&
                          fullDaysToPenalty <= 3 &&
                          notMoneyEnough &&
                          notMoneyEnough > 0
                        ) && (
                          <div className={styles.penalty_days}>
                            {stringHelpers.getDaysWithNoun(fullDaysToPenalty)}{" "}
                            до начисления штрафов
                          </div>
                        )}
                      {isOverdue ? (
                        <>
                          <h2 className={styles.nearest_payment}>
                            Платеж просрочен:{" "}
                            <span className={styles.nearest_payment_content}>
                              {stringHelpers.getDaysWithNoun(overdueFullDays)}
                              {", "}
                              <span className={styles.sum_bold}>
                                {stringHelpers.transformMoneyValue(
                                  (
                                    currentSituation?.overdue_loan_body +
                                    currentSituation?.overdue_percents
                                  ).toFixed(2)
                                )}{" "}
                                ₽
                              </span>
                            </span>
                          </h2>
                          <h2 className={styles.nearest_payment}>
                            Следующий:{" "}
                            <span className={styles.nearest_payment_content}>
                              до {moment(nextPayment?.date).format("DD")}{" "}
                              {nextPayment?.date &&
                                dateMethods.getMonthName(
                                  new Date(nextPayment?.date)
                                )}
                              {", "}
                              <span className={styles.sum_bold}>
                                {stringHelpers.transformMoneyValue(
                                  Number(nextPayment?.amount).toFixed(2)
                                )}{" "}
                                ₽
                              </span>
                            </span>
                          </h2>
                        </>
                      ) : (
                        <h2 className={styles.nearest_payment}>
                          {notMoneyEnough !== undefined &&
                          notMoneyEnough <= 0 ? (
                            <>
                              Следующий:{" "}
                              <span className={styles.nearest_payment_content}>
                                до {moment(nextSchedule?.date).format("DD")}{" "}
                                {nextSchedule?.date &&
                                  dateMethods.getMonthName(
                                    new Date(nextSchedule?.date)
                                  )}
                                {", "}
                                <span className={styles.sum}>
                                  {stringHelpers.transformMoneyValue(
                                    Number(nextSchedule?.amount).toFixed(2)
                                  )}{" "}
                                  ₽
                                </span>
                              </span>
                            </>
                          ) : (
                            <>
                              Платеж:{" "}
                              <span className={styles.nearest_payment_content}>
                                до {moment(nextPayment?.date).format("DD")}{" "}
                                {nextPayment?.date &&
                                  dateMethods.getMonthName(
                                    new Date(nextPayment?.date)
                                  )}
                                {", "}
                                <span className={styles.sum}>
                                  {stringHelpers.transformMoneyValue(
                                    Number(nextPayment?.amount).toFixed(2)
                                  )}{" "}
                                  ₽
                                </span>
                              </span>
                            </>
                          )}
                        </h2>
                      )}
                    </div>

                    {Boolean(amount) && (
                      <div style={{ marginBottom: "11px" }}>
                        <div className={styles.board}>
                          Ожидают зачисления:{" "}
                          <span className={styles.sum_bold}>
                            {stringHelpers.transformMoneyValue(
                              amount.toFixed(2)
                            ) || ""}
                          </span>{" "}
                          ₽
                        </div>
                      </div>
                    )}

                    {/*<div style={{ marginBottom: "24px" }}>*/}
                    {/*  <div className={styles.board_white}>*/}
                    {/*    Не хватает:{" "}*/}
                    {/*    <span className={styles.sum_bold}>*/}
                    {/*    {currentSchedule?.amount && stringHelpers.transformMoneyValue(*/}
                    {/*      (currentSchedule?.amount - currentSituation?.balance).toFixed(2))}*/}
                    {/*  </span>*/}
                    {/*    {" "}₽*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    {/* * когда нету просрочек true, когда есть false */}
                    {isError || isOverdue ? (
                      <div className={styles.expired_payment}>
                        {/* * тут потом тоже смотреть, если ли пеня или нет, если есть то показывать */}
                        {/*<p>*/}
                        {/*  Просрочено:{" "}*/}
                        {/*  <span className={styles.expired_amount}>*/}
                        {/*    {(*/}
                        {/*      currentSituation?.overdue_loan_body +*/}
                        {/*      currentSituation?.overdue_percents*/}
                        {/*    ).toFixed(2)}{" "}*/}
                        {/*    ₽*/}
                        {/*  </span>*/}
                        {/*</p>*/}
                        {/*<p>*/}
                        {/*  Пени:{" "}*/}
                        {/*  <span className={styles.expired_amount}>*/}
                        {/*    {(*/}
                        {/*      currentSituation?.percent_penalties +*/}
                        {/*      currentSituation?.loan_body_penalties*/}
                        {/*    ).toFixed(2)}{" "}*/}
                        {/*    ₽*/}
                        {/*  </span>*/}
                        {/*</p>*/}
                      </div>
                    ) : (
                      <p className={styles.total_sum}>
                        {/*Сумма займа:{" "}*/}
                        {/*{stringHelpers.transformMoneyValue(*/}
                        {/*  Number(currentLoan?.loan_data?.amount)*/}
                        {/*)}{" "}*/}
                        {/*₽*/}
                      </p>
                    )}
                  </div>
                ) : (
                  // )
                  <div className={styles.payment_done}>
                    <div className={styles.icon_wrapper}>
                      <Icons.ui.CheckmarkNoPadding
                        className={styles.icon_checkmark}
                      />
                    </div>
                    <h4 className={styles.payment_done_title}>Займ погашен</h4>
                  </div>
                )}
              </div>

              {/*{amount && (*/}
              {/*  <div className={styles.board}>*/}
              {/*    Ожидают зачисления:{" "}*/}
              {/*    <span className={styles.sum_bold}>{amount}</span>*/}
              {/*    {" "}₽*/}
              {/*  </div>*/}
              {/*)}*/}

              {isOverdue ? (
                <InfoDropdown
                  variant={"white"}
                  title={dropdownItemsOverdue.title}
                  titleFooter={dropdownItemsOverdue.items}
                  isOverdue={isOverdue}
                />
              ) : notMoneyEnough && notMoneyEnough > 0 ? (
                <InfoDropdown
                  variant={"white"}
                  title={dropdownItemsNotMoneyEnough.title}
                  titleFooter={dropdownItemsNotMoneyEnough.items}
                  notMoneyEnough={!!notMoneyEnough}
                />
              ) : (
                <div className={styles.board}>Денег достаточно</div>
              )}

              {Boolean(signedRepayment.length) && (
                <>
                  {signedRepayment.map((item: any) => (
                    <div className={styles.early_repayment_block}>
                      <div className={styles.board}>
                        <div className={styles.early_repayment_container}>
                          <div className={styles.early_repayment_info}>
                            <div className={styles.early_repayment_title}>
                              Досрочное погашение
                            </div>
                            <div>
                              {moment(item?.created_at).format("DD MMMM")}{" "}
                              <strong>
                                {stringHelpers.transformMoneyValue(
                                  item?.amount
                                )}{" "}
                                ₽
                              </strong>
                            </div>
                          </div>
                          <div
                            className={styles.early_repayment_cancel}
                            onClick={() => {
                              setIsCancelRepaymentPopupOpen(true);
                              setIsCancelRepaymentId(item?.id);
                            }}
                          >
                            <div>Отменить</div>
                            {/*TODO svg*/}
                            {/*<Icons.ui.CrossBlack className={styles.cancel_icon} />*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}

              {/* * также пляшем от того, есть ли задолженность или нет */}
              {/* isLoanPaid тут для теста, потом выпилить */}
              {currentLoan?.status !== "paid_loan" && (
                <Button
                  onClick={createLoanPayment}
                  variant={isError || isOverdue ? "warning" : "default"}
                  className={styles.button}
                >
                  {isError || isOverdue
                    ? "Погасить задолженность"
                    : "Пополнить"}
                </Button>
              )}

              <ResizablePanel
                header={
                  <div className={styles.panel_header}>
                    Общая информация о займе{" "}
                    <Icons.ui.arrowAngleIcon className={styles.icon_arrow} />
                  </div>
                }
                className={styles.resizable_panel}
              >
                {/* * как тестовые батоны будут не нужны вынести ниже всё в отдельную комп */}
                <div className={styles.line} />
                <div className={styles.grid}>
                  {process.env.REACT_APP_ENVIRONMENT == "stage" && (
                    <>
                      <Button
                        onClick={() => {
                          setIsError(false);
                          setIsPaymentDone(false);
                          setIsPaymentSoon(false);
                        }}
                      >
                        Сделать, когда всё гуд
                      </Button>
                      <Button
                        onClick={() => {
                          setIsError(false);
                          setIsPaymentDone(false);
                          setIsPaymentSoon(true);
                        }}
                      >
                        Сделать, что скоро оплата
                      </Button>
                      <Button
                        onClick={() => {
                          setIsError(true);
                          setIsPaymentDone(false);
                          setIsPaymentSoon(false);
                        }}
                      >
                        Сделать просроченным
                      </Button>
                      <Button
                        onClick={() => {
                          setIsError(false);
                          setIsPaymentDone(true);
                          setIsPaymentSoon(false);
                        }}
                      >
                        Сделать, когда заранее оплатил
                      </Button>

                      {/*не прячется кнопка "Оплатить". забил.*/}
                      <Button
                        onClick={() => {
                          setIsError(false);
                          setIsLoanPaid(true);
                          setIsPaymentSoon(false);
                        }}
                      >
                        Сделать, погасил займ
                      </Button>
                    </>
                  )}
                  <InfoBlock
                    infoKey={"Название услуги"}
                    infoValue={"Займ под залог авто"}
                  />
                  <InfoBlock
                    infoKey={"Номер договора"}
                    infoValue={currentLoan?.loan_number}
                  />
                  <InfoBlock
                    infoKey={"Сумма кредита"}
                    infoValue={`${stringHelpers.transformMoneyValue(
                      currentLoan?.loan_data?.amount.toFixed(2)
                    )} ₽`}
                  />
                  <InfoBlock infoKey={"Валюта"} infoValue={"Рубль России"} />
                  <InfoBlock
                    infoKey={"Процентная ставка по кредиту"}
                    infoValue={`${(
                      currentLoan?.loan_data?.percent_rate / 12
                    ).toFixed(2)} % (в месяц)`}
                  />
                  <InfoBlock
                    infoKey={"Дата выдачи кредита"}
                    infoValue={moment(currentLoan?.created_at).format(
                      "DD.MM.YYYY"
                    )}
                  />
                  <InfoBlock
                    infoKey={"Дата окончания действия кредита"}
                    infoValue={dateMethods.format2(
                      allSchedule?.schedule?.[allSchedule?.schedule.length - 1]
                        ?.date,
                      true
                    )}
                  />
                  <InfoBlock
                    infoKey={"Срок договора"}
                    infoValue={`${currentLoan?.loan_data?.loan_term} ${
                      currentLoan?.loan_data?.loan_term === "24"
                        ? "месяца"
                        : "месяцев"
                    }`}
                  />
                  <InfoBlock
                    infoKey={"Текущий остаток"}
                    infoValue={`${stringHelpers.transformMoneyValue(
                      currentSituation?.current_loan_body.toFixed(2)
                    )} ₽`}
                  />
                  <InfoBlock
                    infoKey={"Дата очередного платежа"}
                    infoValue={dateMethods.format2(
                      (paymentHasBeenMade
                        ? nextSchedule?.date
                        : currentSchedule?.date) ?? null,
                      true
                    )}
                  />
                  <InfoBlock
                    infoKey={"Сумма очередного платежа:"}
                    infoValue={`${stringHelpers.transformMoneyValue(
                      currentSchedule?.amount.toFixed(2)
                    )} ₽`}
                  />
                  <InfoBlock
                    infoKey={"Просрочено"}
                    infoValue={`${stringHelpers.transformMoneyValue(
                      (
                        currentSituation?.overdue_loan_body +
                        currentSituation?.overdue_percents
                      ).toFixed(2)
                    )} ₽`}
                    warning={isOverdue}
                  />

                  <InfoBlock
                    infoKey={"Начислено пени"}
                    infoValue={`${stringHelpers.transformMoneyValue(
                      (
                        currentSituation?.percent_penalties +
                        currentSituation?.loan_body_penalties
                      ).toFixed(2)
                    )} ₽`}
                    warning={isOverdue}
                  />
                  <InfoBlock
                    infoKey={"Способ перечисления"}
                    infoValue={"СБП"}
                  />
                  <InfoBlock
                    infoKey={"Банк получатель"}
                    infoValue={bank_card.bank}
                  />
                  <InfoBlock
                    infoKey={"Получатель"}
                    infoValue={`+${applicationData.phone} ${applicationData.user_info.last_name} ${applicationData.user_info.first_name} ${applicationData.user_info.middle_name}`}
                  />
                </div>
              </ResizablePanel>
            </RoundContainer>

            <PaymentSchedule schedule={allSchedule} variant={"cabinet"} />
          </div>
          <LoanInfoDesktop
            allSchedule={allSchedule}
            currentLoan={currentLoan}
            car={car}
            className={styles.LoanInfoDesktop}
          />
        </div>
      </div>
      <AmountSelectionPopup
        id={currentLoan?.id}
        amount={toPay}
        isOpenPopup={isOpenPopup}
        setIsOpenPopup={setIsOpenPopup}
        paymentType={paymentType}
        setPaymentType={setPaymentType}
        isOverdue={isOverdue}
        setToPay={setToPay}
        setIsAmountSelectionPopupOpen={setIsOpenPopup}
        partialRepaymentError={partialRepaymentError}
        setPartialRepaymentError={setPartialRepaymentError}
        currentSituation={currentSituation}
      />
      <FullRepaymentPopup
        isOpenPopup={isFullRepaymentPopupOpen}
        setIsOpenPopup={setIsFullRepaymentPopupOpen}
        setIsAmountSelectionPopupOpen={setIsOpenPopup}
        setToPay={setToPay}
      />
      <CancelRepaymentPopup
        earlyRepaymentId={isCancelRepaymentId}
        id={currentLoan?.id}
        // amount={toPay}
        isOpenPopup={isCancelRepaymentPopupOpen}
        setIsOpenPopup={setIsCancelRepaymentPopupOpen}
        // paymentType={paymentType}
        // setPaymentType={setPaymentType}
        // isOverdue={isOverdue}
        // setToPay={setToPay}
        // setIsAmountSelectionPopupOpen={setIsOpenPopup}
        // partialRepaymentError={partialRepaymentError}
        // setPartialRepaymentError={setPartialRepaymentError}
        // currentSituation={currentSituation}
      />
    </>
  );
});
