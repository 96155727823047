import React, { FC } from "react";
import styles from "./index.module.css";
import { Button } from "../../../../../../ui/Button";
import { useDispatch } from "react-redux";
import { downloadFileSaga } from "../../../../../../../sagas/application/methods/downoloadFileSaga";
import { applicationSagaActions } from "../../../../../../../sagas/application/actions";

type propsType = {
  userId: string | null | undefined;
  applicationId: number | undefined;
  documentType?: string | undefined;
  title: string;
  documentNumber: string;
  date: string;
};

export const SingleDocument: FC<propsType> = ({
  userId,
  applicationId,
  documentType,
  title,
  documentNumber,
  date,
}) => {
  const dispatch = useDispatch();

  const downloadFile = (documentType: string | undefined) => {
    dispatch(
      applicationSagaActions.downloadFile({
        applicationId: applicationId,
        documentType: documentType,
        userId: userId,
      })
    );
  };
  return (
    <div className={styles.container}>
      {documentType && (
        <Button
          variant={"underline"}
          onClick={() => downloadFile(documentType)}
          className={styles.button}
        >
          {title}
        </Button>
      )}
      {!documentType && (
        <Button variant={"underline"} className={styles.button}>
          <a
            href="https://static.creddy.ru/Политика_в_отношении_обработки_персональных_данных.pdf"
            target={"_blank"}
          >
            Политика обработки и хранения ПД
          </a>
        </Button>
      )}
      <div className={styles.details}>
        {date}
      </div>
    </div>
  );
};
