import React, { FC } from "react";
import { routes } from "../../../../../routes";
import { Route } from "react-router-dom";
import { CheckingCar } from "./CheckingCar";
import { RecognizeDocuments } from "./RecognizeDocuments";
import { SentAndScoringProcess } from "./SentAndScoringProcess";
import { SigningProcess } from "./SigningProcess";
import { SignedIssuingProcess } from "./SignedIssuingProcess";
import { IssuedFinal } from "./IssuedFinal";
import { ErrorFinal } from "./ErrorFinal";
import { IssueMethodFilling } from "./IssueMethodFilling";
import { RefusalFinalBadCar } from "./RefusalFinalBadCar";
import { AccountCheck } from "./AccountCheck";
import { SBPCheckError } from "./SBPCheckError";
import { PaymentSystemResponse } from "./PaymentSystemResponse";
import { UnderwriterRefusal } from "./UnderwriterRefusal";
import { CheckingDocuments } from "../CheckingDocuments";
import { ScoringAndLoanDisbursement } from "./ScoringAndLoanDisbursement";
import { DataVerification } from "./DataVerification";
import { VideoCallPlanned } from "../VideoCallPlanned";
import { PartnerApplication } from "../PartnerApplication";

const {
  application: { statuses },
} = routes;

export const ApplicationStatusRoutes: FC = () => {
  return (
    <>
      <Route path={statuses.checking_car}>
        <CheckingCar />
      </Route>

      <Route path={statuses.refusal_final_bad_car}>
        <RefusalFinalBadCar />
      </Route>

      <Route path={statuses.recognize_documents}>
        <RecognizeDocuments />
        {/*<CheckingDocuments />*/}
      </Route>

      <Route path={statuses.data_verification}>
        <DataVerification countdownTime={30} />
      </Route>

      <Route path={statuses.issue_method_filling}>
        <IssueMethodFilling />
      </Route>

      <Route path={statuses.payment_system_response}>
        <PaymentSystemResponse />
      </Route>

      <Route path={statuses.sent_and_scoring_process}>
        <SentAndScoringProcess />
      </Route>

      <Route path={statuses.scoring_and_loan_disbursement}>
        <ScoringAndLoanDisbursement />
      </Route>

      <Route path={statuses.signing_process}>
        <SigningProcess />
      </Route>

      <Route path={statuses.signed_issuing_process}>
        <SignedIssuingProcess />
      </Route>

      <Route path={statuses.issued_final}>
        <IssuedFinal />
      </Route>

      <Route path={statuses.account_check}>
        <AccountCheck />
      </Route>

      <Route path={statuses.sbp_check_error}>
        <SBPCheckError />
      </Route>

      <Route path={statuses.error_final}>
        <ErrorFinal />
      </Route>

      <Route path={statuses.underwriter_refusal}>
        <PartnerApplication />
      </Route>
    </>
  );
};
