import React from "react";
import styles from "./index.module.css";
import { Icons } from "../../../assets/icons";
import { stringHelpers } from "../../../helpers/string";
import { lsController } from "../../../store/auxiliary/localStorage";

type propsType = {
  secondBanner?: boolean;
};

export const BannerWhite = (props: propsType) => {
  const carCost = Number(lsController.get("cost"));

  return (
    <>
      <div className={styles.header}>
        <div className={styles.header_icon}>
          <Icons.ui.CheckmarkTransparentGreenBg />
        </div>
        {carCost ? (
          <>
            <div className={styles.header_text}>
              Залоговая стоимость авто
              <span className={styles.header_value}>
                {" "}
                {stringHelpers.transformMoneyValue(carCost)} ₽
              </span>
            </div>
          </>
        ) : (
          <>
            <div className={styles.header_text}>
              Максимальная сумма займа до{" "}
              <span className={styles.header_value}>500 000 ₽</span>
            </div>
          </>
        )}
      </div>
      {props.secondBanner && (
        <div className={styles.header2}>
          <div className={styles.header_icon}>
            <Icons.ui.CheckmarkTransparentGreenBg />
          </div>
          <div className={styles.header_text}>Документы в порядке</div>
        </div>
      )}
    </>
  );
};
