import { createAction } from "@reduxjs/toolkit";
import {
  addingPassportAndInnToApplicationType,
  applicationBasisType,
  createApplicationType,
  postApplicationType,
  sendingLoanTermsType,
  verifyApplicationType,
} from "../../api/main/application/types";
import { downloadFileDocument } from "../../api/main/documents/types";
import { postApplicationSaga } from "./methods/postApplicationSaga";
import { newLoanSaga } from "./methods/newLoanSaga";
import { sendingReplegePhotoForRecognizeSaga } from "./methods/sendingReplegePhotoForRecognizeSaga";

export const applicationSagaActions = {
  createApplication: createAction<createApplicationType>(
    "application/createApplication"
  ),

  createApplicationShort: createAction("application/createApplicationShort"),

  newLoanSaga: createAction("application/newLoanSaga"),

  postApplicationSaga: createAction<postApplicationType>(
    "application/postApplicationSaga"
  ),

  sendingDocumentsForRecognize: createAction<applicationBasisType>(
    "application/sendingDocumentsForRecognize"
  ),

  sendingCarPhotoForRecognize: createAction<applicationBasisType>(
    "application/sendingCarPhotoForRecognize"
  ),
  sendingReplegePhotoForRecognize: createAction<applicationBasisType>(
    "application/sendingReplegePhotoForRecognize"
  ),

  sendingReplacedPhotoForRecognize: createAction<applicationBasisType>(
    "application/sendingReplacedPhotoForRecognize"
  ),

  addingPassportAndInnToApplication:
    createAction<addingPassportAndInnToApplicationType>(
      "application/addingPassportAndInnToApplicationSaga"
    ),

  sendApplication: createAction("application/sendApplication"),

  verifyApplication: createAction<verifyApplicationType>(
    "application/verifyApplication"
  ),

  sendingLoanTerms: createAction<Omit<sendingLoanTermsType, "applicationId">>(
    "application/sendingLoanTerms"
  ),

  signLoanContract: createAction<verifyApplicationType>(
    "application/signLoanContract"
  ),

  // * новое поведение заявки
  createCard: createAction<{
    repeat?: boolean;
    redirectTo: string;
  }>("application/createCard"),

  //  * временно
  sendAllDocs: createAction("application/sendAllDocs"),

  sendPtsAndStsDocumentsSaga: createAction(
    "application/sendPtsAndStsDocumentsSaga"
  ),

  sendAllCarPhotos: createAction("application/sendAllCarPhotos"),

  downloadFile: createAction<downloadFileDocument>("application/downloadFile"),
};
