import React, { FC } from "react";
import { Button } from "../../ui/Button";
import { API } from "../../../api";

type propsType = {};
export const Underwriter: FC<propsType> = (props) => {
  const approve = () => {
    API.underwriter.approveApplication({
      applicationId: 2223,
      amount: 80000,
      loan_term: "24",
      percent_rate: 6,
    });
  };

  return (
    <div>
      <Button onClick={approve}>Принять заявку</Button>
    </div>
  );
};
