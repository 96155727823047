import React, { FC } from "react";
import styles from "./index.module.css";
import { Icons } from "../../../../../assets/icons";
import { SmartForm } from "../../../../hocs/SmartForm";
import { useForm } from "react-hook-form";
import { Button } from "../../../../ui/Button";
import { InputNumber } from "../../../../ui/Inputs/InputNumber";

export const EarlyRepaymentAmount: FC = () => {
  const form = useForm();

  const submit = () => {};

  return (
    <SmartForm className={styles.container} submit={submit} form={form}>
      <InputNumber
        className={styles.input}
        support={<Icons.ui.Ruble data-class={"dim"} />}
        thousandsGroupStyle={"thousand"}
        label={"Сумма досрочного погашения"}
        inputMode={"numeric"}
        variant={"large"}
        name={"amount"}
      />
      <Button className={styles.button}>
        Далее
      </Button>
      <Button
        className={styles.cancel}
        variant={"underline"}
      >
        Отмена
      </Button>
    </SmartForm>
  );
};
