import { PayloadAction } from "@reduxjs/toolkit";
import { call } from "redux-saga/effects";
import { downloadFileDocument } from "../../../api/main/documents/types";
import { processStateController } from "../../util/helpers/processStateController";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { downloadFile, setDownloadedFileName } from "../../../helpers/file";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaActions } from "../../util/sagaActions";

export function* downloadFileSaga({
  payload,
  type: actionType,
}: PayloadAction<downloadFileDocument>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const response: sagaApiType = yield call(
      API.main.documents.downloadFileDocument,
      payload
    );

    // let nameFileDownload = payload.documentType;

    // if (nameFileDownload === "loan_contract") {
    //   nameFileDownload = "Индивидуальные условия";
    // } else if (nameFileDownload === "deposit_contract") {
    //   nameFileDownload = "Договора залога";
    // } else if (nameFileDownload === "personal_data_agreement") {
    //   nameFileDownload = "Согласие с обработкой ПД и использованием АСП";
    // } else if (nameFileDownload === "applying_agreement") {
    //   nameFileDownload =
    //     "Анкета-заявление на выдачу потребительского микрозайма физическому лицу";
    // } else if (nameFileDownload === "pre_acceptance") {
    //   nameFileDownload = "Согласие с безакцептным списанием";
    // } else if (nameFileDownload === "risk_notification") {
    //   nameFileDownload = "Уведомление о существующих рисках";
    // } else if (nameFileDownload === "applying_agreement_second") {
    //   nameFileDownload =
    //     "Анкета-заявление на выдачу потребительского микрозайма физическому лицу";
    // } else if (nameFileDownload === "order") {
    //   nameFileDownload =
    //     "Распоряжение о перечислении в счет погашения задолженности";
    // }

    const url = response?.data?.data?.content;
    downloadFile(url, `${setDownloadedFileName(payload.documentType)}`, "_blank");
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.application.downloadFile.type,
    });
  } finally {
    yield process.stop();
  }
}
