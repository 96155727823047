import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../../../../store/reducers/application";
import { useQuery } from "react-query";
import { API } from "../../../../../../api";
import { routes } from "../../../../../../routes";
import { LoadingPage } from "../../LoadingPage";
import { applicationType } from "../../../../../../types/application/types";
import { sendMetrics } from "../../../../../../metrics";
import { MobilePageContainer } from "../../../../../ui/Containers/MobilePageContainer";
import { BannerWhite } from "../../../../../ui/BannerWhite";
import { Icons } from "../../../../../../assets/icons";
import { FillingAssistance } from "../../../../../ui/FillingAssistance";
import { useCountdown } from "../../../../../../helpers/date/useCountdown";
import { applicationSagaActions } from "../../../../../../sagas/application/actions";
import useTrackTabClose from "../../../../../../metrics/customHooks/useTrackTabClose";

type propsType = {
  countdownTime?: number;
};
export const DataVerification: FC<propsType> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { timeLeft } = useCountdown(props.countdownTime);

  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  useTrackTabClose("common-client_leave-LK_common-app", "leave-LK");

  const query = useQuery(
    "DataVerification",
    () => API.main.application.getApplication({ applicationId }),
    {
      enabled: applicationId !== null,
      refetchInterval: 10000,
      onSuccess: (data: any) => {
        const application: applicationType = data?.data?.data;
        dispatch(
          applicationReducer.actions.setApplicationType({
            application_type: application?.application_type,
          })
        );
        if (application?.application_type === "passport_filling") {
          history.push(routes.application.passport_and_inn);
        } else if (
          application?.application_type === "refusal_final" &&
          application?.refuse_reason === "car_check_no_car"
        ) {
          dispatch(
            applicationReducer.actions.setRefuseReason({
              refuse_reason: application?.refuse_reason,
            })
          );
          history.push(routes.application.fio_and_grz);
        } else if (
          application?.application_type === "refusal_final" &&
          application?.refuse_reason !== "application_check_other"
        ) {
          dispatch(
            applicationReducer.actions.setRefuseReason({
              refuse_reason: application?.refuse_reason,
            })
          );
          sendMetrics("common-client_avtootkaz-anketa_common-app", "avtootkaz-anketa");
          history.push(routes.application.loan_rejection);
        } else if (
          application?.application_type === "refusal_final" &&
          application?.refuse_reason !== "application_check_no_auto_inn"
        ) {
          dispatch(
            applicationReducer.actions.setRefuseReason({
              refuse_reason: application?.refuse_reason,
            })
          );
          sendMetrics("common-client_avtootkaz-anketa_common-app");
          history.push(routes.application.loan_rejection);
        } else if (
          application?.application_type === "refusal_final" &&
          application?.refuse_reason === "application_check_other"
        ) {
          history.push(routes.application.partner_application);
        } else if (application?.application_type === "survey_filling") {
          history.push(routes.application.sbp_bank_filling);
        } else if (application?.application_type === "repledge_filling") {
          history.push(routes.application.refinancing);
        } else if (
          application.application_type === "signing_applying_agreement_filling"
        ) {
          dispatch(applicationSagaActions.sendApplication());
        }
      },
    }
  );

  return (
    // <LoadingPage
    //   withoutIcon
    //   title={"Подождите, пожалуйста"}
    //   subtitle={
    //     <>
    //       <div>
    //         Ваши документы загружаются <br /> и распознаются. Между тем, <br />{" "}
    //         отличное время переместиться <br />к вашему автомобилю, чтобы <br />
    //         сфотографировать его для нас.{" "}
    //       </div>
    //       <div className={styles.bold}>Спасибо за терпение!</div>
    //     </>
    //   }
    //   additionalSubtitle={
    //     <>
    //       Мы пришлем СМС для <br />
    //       перехода к фото автомобиля
    //     </>
    //   }
    // />
    <>
      <MobilePageContainer className={styles.form}>
        {/*<BannerWhite />*/}
        <div className={styles.container}>
          <div className={styles.title}>Проверяем Ваши данные</div>
          <div className={styles.text}>Подождите несколько секунд</div>
          <div className={styles.loader}>
            <Icons.ui.Hourglass />
          </div>
          {props.countdownTime && typeof timeLeft !== "undefined" && (
            <div className={styles.timer}>
              {timeLeft === 0 && "Подождите еще..."}
              {timeLeft > 0 && timeLeft < 60
                ? `${new Date(timeLeft * 1000)
                    .toISOString()
                    .substring(17, 19)} сек.`
                : timeLeft > 0 &&
                  new Date(timeLeft * 1000).toISOString().substring(14, 19)}
            </div>
          )}
          {/*<div>Пришлём СМС для продолжения заполнения</div>*/}
        </div>
      </MobilePageContainer>
    </>
  );
};
