import { createFC } from "helpers/createFC";
import styles from "./index.module.css";
import { MobilePageContainer } from "../../../../../ui/Containers/MobilePageContainer";
import { Icons } from "assets/icons";
import { useGetCar } from "../../../../../../hooks/api/useGetCar";
import { Loader } from "../../../../../ui/Loader";
import { Button } from "../../../../../ui/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../../../../store/reducers/application";
import { removeNullishFieldsFromObject } from "../../../../../../helpers/object";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../../routes";
import { carType } from "../../../../../../store/reducers/application/types";
import React, { useEffect } from "react";

type propsType = {};

export const RefusalFinalBadCar = createFC((props: propsType) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const car: carType = useSelector(applicationReducerSelectors.getCar);

  const applicationType = useSelector(
    applicationReducerSelectors.getApplicationType
  );

  let { carData }: any = {};
  applicationType !== "refusal_final" &&
    useEffect(() => {
      carData = useGetCar(
        // @ts-ignore
        removeNullishFieldsFromObject(car)
      );
    });

  const redirectToNewLoan = () => {
    dispatch(applicationReducer.actions.clear);
    history.push(routes.auth.vehicle_valuation);
  };

  const routeChange = () => {
    let path = routes.auth.vehicle_valuation;
    history.push(path);
  };

  return (
    <MobilePageContainer>
      {applicationType === "refusal_final" ? (
        <div className={styles.block_top}>
          <div className={styles.title}>Отказ по заявке</div>
          <div className={styles.subtitle}>Спасибо за обращение</div>
          <div className={styles.decline}>
            <Icons.ui.CrossWarning />
          </div>

          <Button className={styles.button_car} onClick={() => routeChange()}>
            К оформлению займа
          </Button>
        </div>
      ) : (
        <>
          {carData.isLoading ? (
            <Loader
              color={"purple"}
              variant={"large"}
              className={styles.loader}
            />
          ) : (
            <>
              <Icons.ui.CautionTriangle className={styles.triangle_icon} />
              <div className={styles.title}>Внимание</div>
              <div className={styles.subtitle}>Извините, вам отказ</div>

              {carData?.photo && (
                <img
                  alt={"Фото автомобиля"}
                  className={styles.car_image}
                  src={carData?.photo}
                />
              )}

              <div className={styles.car_name}>{carData.name}</div>

              <div className={styles.car_stats}>
                <div className={styles.car_stat}>
                  <div className={styles.stat_title}>VIN:</div>
                  <div className={styles.stat_value}>{carData.vin}</div>
                </div>
                <div className={styles.car_stat}>
                  <div className={styles.stat_title}>Год производства:</div>
                  <div className={styles.stat_value}>{carData.year}</div>
                </div>
                <div className={styles.car_stat}>
                  <div className={styles.stat_title}>Категория ТС:</div>
                  <div className={styles.stat_value}>{carData.category}</div>
                </div>
                <div className={styles.car_stat}>
                  <div className={styles.stat_title}>Расположение руля:</div>
                  <div className={styles.stat_value}>{carData.wheel}</div>
                </div>
                <div className={styles.car_stat}>
                  <div className={styles.stat_title}>Тип двигателя:</div>
                  <div className={styles.stat_value}>{carData.engine_type}</div>
                </div>
                <div className={styles.car_stat}>
                  <div className={styles.stat_title}>Мощность двигателя:</div>
                  <div className={styles.stat_value}>
                    {carData.engine_hp} л.с.
                  </div>
                </div>
                <div className={styles.car_stat}>
                  <div className={styles.stat_title}>Объем двигателя:</div>
                  <div className={styles.stat_value}>
                    {carData.engine_volume} куб.см
                  </div>
                </div>
              </div>

              <Button onClick={redirectToNewLoan} className={styles.button}>
                Другое авто
              </Button>
            </>
          )}
        </>
      )}
    </MobilePageContainer>
  );
});
