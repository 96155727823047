import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import styles from "./index.module.css";
import { SmartForm } from "../../../../../../hocs/SmartForm";
import { Title } from "../../../../../../ui/Title";
import { Icons } from "../../../../../../../assets/icons";
import { InputNumber } from "../../../../../../ui/Inputs/InputNumber";
import { Button } from "../../../../../../ui/Button";
import { amountSelectionAnimation } from "./animate";
import { cabinetSagaActions } from "../../../../../../../sagas/cabinet/actions";
import { paymentType } from "../../../../../../../api/main/application/types";
import { processLoading } from "../../../../../../../store/auxiliary/loadingReducer";
import { backgroundAnimation } from "../../../../../../ui/BurgerMenu/animate";
import { Subtitle } from "../../../../../../ui/Subtitle";
import { currentSituationLoanType } from "../../../../../../../types/cabinet/types";
import { useDeletePayment } from "../../../../../../../hooks/api/useDeletePayment";
import { Loader } from "../../../../../../ui/Loader";

type propsType = {
  // amount?: number | null;
  earlyRepaymentId: string | null;
  id: string;
  isOpenPopup: boolean;
  // isOverdue: boolean;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;

  // paymentType пропс решает, какой вариант оплаты будет производиться с этого попапа в сабмите
  // paymentType: paymentType;
  // currentSituation: currentSituationLoanType;
  // setPaymentType: React.Dispatch<React.SetStateAction<paymentType>>;
  // setToPay: React.Dispatch<React.SetStateAction<number>>;
  // setIsAmountSelectionPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // partialRepaymentError: boolean;
  // setPartialRepaymentError: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CancelRepaymentPopup: FC<propsType> = ({
  // amount,
  earlyRepaymentId,
  id,
  // isOverdue,
  isOpenPopup,
  setIsOpenPopup,
  // paymentType,
  // setPaymentType,
  // setToPay,
  // setIsAmountSelectionPopupOpen,
  // partialRepaymentError,
  // setPartialRepaymentError,
  // currentSituation,
}) => {
  const dispatch = useDispatch();
  const form = useForm();

  const { deletePayment, isLoading, error } = useDeletePayment(
    id,
    earlyRepaymentId,
    setIsOpenPopup
  );
  //
  // const isLoadingPartialEarlyRepayment = useSelector(
  //   processLoading.get(cabinetSagaActions.createPartialEarlyRepayment.type)
  // );
  //
  // const isLoadingLoanPayment = useSelector(
  //   processLoading.get(cabinetSagaActions.createLoanPayment.type)
  // );
  //
  // useEffect(() => {
  //   if (paymentType === "partly_repayment" && !isOverdue) {
  //     form.setValue("amount", "");
  //   } else {
  //     form.setValue("amount", Boolean(amount) ? String(amount) : "");
  //   }
  // }, [paymentType, isOpenPopup]);
  //
  // useEffect(() => {
  //   if (isOverdue) {
  //     form.setValue("amount", String(amount));
  //   }
  // }, [amount]);
  //
  // useEffect(() => {
  //   if (isOpenPopup) {
  //     setTimeout(() => {
  //       form.setFocus("amount", {shouldSelect: true});
  //     }, 50);
  //   }
  // }, [form.setFocus, isOpenPopup]);
  //
  // useEffect(() => {
  //   return () => {
  //     // setPaymentType("due_repayment")
  //   };
  // });

  const submit = (data: any) => {
    deletePayment();
    // if (paymentType === "partly_repayment") {
    // dispatch(
    //   cabinetSagaActions.deletePartialEarlyRepayment({
    //     loan_id: id,
    //     early_repayment_id: earlyRepaymentId,
    //     setIsOpenPopup,
    //   })
    // );

    //   );
    // } else {
    //   dispatch(
    //     cabinetSagaActions.createLoanPayment({
    //       loan_id: id,
    //       amount: +data.amount?.replaceAll(" ", ""),
    //     })
    //   );
    // }
    // setPaymentType("due_repayment");
  };

  return (
    <>
      <motion.div
        variants={backgroundAnimation}
        initial={"close"}
        animate={isOpenPopup ? "open" : "close"}
        className={styles.tinted_background}
        onClick={() => {
          setIsOpenPopup(false);
          // setPaymentType("due_repayment");
        }}
      ></motion.div>

      <motion.div
        variants={amountSelectionAnimation}
        initial={"close"}
        animate={isOpenPopup ? "open" : "close"}
        className={styles.popup}
      >
        <div className={styles.background} />
        <div className={styles.content}>
          <SmartForm form={form} submit={submit} className={styles.form}>
            <Title className={styles.title}>
              Аннулировать заявление <br /> о досрочном погашении?
            </Title>

            <div className={styles.buttons}>
              {isLoading ? (
                <Loader className={styles.loader} />
              ) : (
                <>
                  <div onClick={() => setIsOpenPopup(false)}>Отмена</div>
                  <button className={styles.button_yes}>Да</button>
                </>
              )}
            </div>
          </SmartForm>
        </div>
      </motion.div>
    </>
  );
};
