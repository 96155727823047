import { useQuery } from "react-query";
import { API } from "../../../../../api";
import { bankType } from "../../../../../api/main/application/types";
import { useState } from "react";
import { selectItemType } from "../../../../ui/Selects/Select";

export const useGetBanks = () => {
  const [transformedDataForSelect, setTransformedDataForSelect] = useState<
    Array<selectItemType<any>>
  >([]);

  const data: any = useQuery(
    ["useGetBanks"],
    () => API.main.application.getBanks(),
    {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          setTransformedDataForSelect(
            res?.data?.data?.items.map((item: bankType) => {
              return {
                id: item.id,
                text: item.name,
                value: item,
              };
            })
          );
        }
      },
    }
  );

  return {
    isLoading: data?.isLoading,
    transformedDataForSelect,
  };
};
