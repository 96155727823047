import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { Icons } from "../../../../../assets/icons";
import { SidebarCabinet } from "../Sidebar";
import { Link, useHistory, useLocation } from "react-router-dom";
import { routes } from "../../../../../routes";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../../../store";
import { stringHelpers } from "../../../../../helpers/string";
import { sidebarData } from "../Sidebar/data";
import { clearState } from "../../../../../store/auxiliary/clearState";
import { setTokenToAxios } from "../../../../../api/methods/setTokenToAxios";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { constantStrings } from "../../../../../helpers/init/constantStrings";
import { API } from "../../../../../api";
import { loanReducer } from "../../../../../store/reducers/loan";
import { setProcessLoading } from "../../../../../store/auxiliary/loadingReducer";

type propsType = {};

export const HeaderCabinet: FC<propsType> = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const first_name = useSelector(
    (state: StateType) => state.userReducer.first_name
  );

  const last_name = useSelector(
    (state: StateType) => state.userReducer.last_name
  );

  const phone = useSelector((state: StateType) => state.userReducer.phone);

  const notifications = useSelector(
    (state: StateType) => state.loanReducer.notifications
  );

  // TODO не забыть кинуть в онклик
  const handleLogout = () => {
    clearState();
    setTokenToAxios(null);
    lsController.clearAll();
    history.push(routes.auth.phone);
  };

  const openSidebar = () => {
    setIsOpenSidebar(true);
  };

  const routeToApplicationInfo = () => {
    history.push(routes.cabinet.application_info);
  };

  const headerToRender = () => {
    if (pathname.includes("early_repayment_to_pay")) {
      return <div className={styles.title}>Сумма к оплате</div>;
    } else if (pathname.includes("full_early_repayment")) {
      return <div className={styles.title}>Отмена предыдущих заявлений</div>;
    } else if (pathname.includes("early_repayment_accept")) {
      return <div className={styles.title}>Платеж принят</div>;
    } else if (
      pathname.includes("early_repayment") ||
      pathname.includes("repayment_signing")
    ) {
      return <div className={styles.title}>Подписание заявления</div>;
    } else if (pathname.includes("loan_payment")) {
      return <div className={styles.title}>Переход к оплате</div>;
    } else {
      return (
        <div className={styles.lower_part}>
          <div className={styles.link} onClick={handleLogout}>
            <Icons.cabinet.Exit className={styles.exit_icon} />
            <p className={styles.link_text}>Выход</p>
          </div>
        </div>
      );
    }
  };

  const getNotifications = () => {
    dispatch(setProcessLoading("notifications", true));
    API.main.application.getNotifications().then((res) => {
      if (res.status === 200) {
        dispatch(
          loanReducer.actions.setNotifications({
            notifications: {
              items_count: res.data.data.items_count,
              items: res.data.data.items
                .sort(
                  (a, b) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                )
                .map((item) => ({
                  ...item,
                  checked: false,
                })),
            },
          })
        );
      }
      dispatch(setProcessLoading("notifications", false));
    });
  };

  useEffect(() => {
    notifications &&
      setNewNotificationsCount(
        notifications.items.filter((item) => item.type === "new_notification")
          .length
      );
  }, [notifications]);

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.upper_part}>
          <div className={styles.creddy_logo}>
            <div onClick={() => history.push(routes.cabinet.my_loan)}>
              <Icons.logos.Creddy />
            </div>
          </div>
          <div className={styles.right_part}>
            <div
              className={styles.bell}
              onClick={() => {
                getNotifications();
                history.push(routes.cabinet.notifications);
              }}
            >
              <Icons.ui.Bluebell />
              {newNotificationsCount > 0 && (
                <div className={styles.notifications}>
                  {newNotificationsCount > 9 ? 9 : newNotificationsCount}
                  {newNotificationsCount > 9 && (
                    <div className={styles.plus}>+</div>
                  )}
                </div>
              )}
            </div>
            <div className={styles.burger}>
              <Icons.ui.Burger onClick={openSidebar} />
            </div>
          </div>
        </div>
        {headerToRender()}
        {/*{pathname.includes("early_repayment_iframe") ? (*/}
        {/*  <div className={styles.title}>Переход к оплате</div>*/}
        {/*) : pathname.includes("loan_payment") ||*/}
        {/*  pathname.includes("early_repayment") ||*/}
        {/*  pathname.includes("repayment_signing") ? (*/}
        {/*  <div className={styles.title}>Подписание заявления</div>*/}
        {/*) : (*/}
        {/*  <div className={styles.lower_part}>*/}
        {/*    /!*<div className={styles.info}>*!/*/}
        {/*    /!*  <p className={styles.fio}>*!/*/}
        {/*    /!*    {last_name} {first_name}*!/*/}
        {/*    /!*  </p>*!/*/}
        {/*    /!*  <p className={styles.phone}>*!/*/}
        {/*    /!*    {stringHelpers.phoneNormalize(String(phone))}*!/*/}
        {/*    /!*  </p>*!/*/}
        {/*    /!*  <div className={styles.links}>*!/*/}
        {/*    /!*    {sidebarData.map(({ name, route, icon }) => (*!/*/}
        {/*    /!*      <div key={route}>*!/*/}
        {/*    /!*        <Link to={route} className={styles.link}>*!/*/}
        {/*    /!*          {React.createElement(icon, {})}*!/*/}
        {/*    /!*          <p className={styles.link_text}>{name}</p>*!/*/}
        {/*    /!*        </Link>*!/*/}
        {/*    /!*      </div>*!/*/}
        {/*    /!*    ))}*!/*/}
        {/*    /!*  </div>*!/*/}
        {/*    /!*</div>*!/*/}
        {/*    <div className={styles.link} onClick={handleLogout}>*/}
        {/*      <Icons.cabinet.Exit className={styles.exit_icon} />*/}
        {/*      <p className={styles.link_text}>Выход</p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
      <div className={styles.support_links}>
        <div className={styles.row}>
          <div className={styles.support_title}>Техподдержка:</div>
          <a
            href={constantStrings.telegramLinkForSupport}
            target={"_blank"}
            className={styles.telegram_icon}
          >
            <Icons.logos.Telegram />
          </a>
          <a
            href={`https://wa.me/${constantStrings.whatsappNumberForSupport}`}
            target={"_blank"}
            className={styles.whatsapp_icon}
          >
            <Icons.logos.Whatsapp_white />
          </a>
        </div>

        <a href={`tel:${constantStrings.phoneNumberForSupport}`}>
          {constantStrings.phoneNumberForSupportMask}
        </a>
      </div>

      <SidebarCabinet
        isOpenSidebar={isOpenSidebar}
        setIsOpenSidebar={setIsOpenSidebar}
      />
    </>
  );
};
