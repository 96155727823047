import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../../util/helpers/processStateController";
import { call, put, select } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { applicationReducerSelectors } from "../../../store/reducers/application";
import { pts1 } from "../../../components/pages/Application/pages/Documents/docsData/pts1";
import { pts2 } from "../../../components/pages/Application/pages/Documents/docsData/pts2";
import { sts1 } from "../../../components/pages/Application/pages/Documents/docsData/sts1";
import { sts2 } from "../../../components/pages/Application/pages/Documents/docsData/sts2";
import { applicationSagaActions } from "../actions";

export function* sendPtsAndStsDocumentsSaga({
  payload,
  type: actionType,
}: PayloadAction) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const applicationId: number = yield select(
      applicationReducerSelectors.getApplicationId
    );

    // * pts1 start
    const ptsFront: sagaApiType = yield call(
      API.main.documents.uploadDocument,
      {
        content: pts2,
        file_name: "pts1.jpg",
      }
    );

    yield call(API.main.documents.attachingDocumentToTheApplication, {
      photo_type: "pts_front",
      applicationId,
      document_id: ptsFront.data.data.id,
    });
    // * end

    // * pts2 start
    const ptsBack: sagaApiType = yield call(API.main.documents.uploadDocument, {
      content: pts1,
      file_name: "pts2.jpg",
    });

    yield call(API.main.documents.attachingDocumentToTheApplication, {
      photo_type: "pts_back",
      applicationId,
      document_id: ptsBack.data.data.id,
    });
    // * end

    // * sts1 start
    const stsFront: sagaApiType = yield call(
      API.main.documents.uploadDocument,
      {
        content: sts1,
        file_name: "sts1.jpg",
      }
    );

    yield call(API.main.documents.attachingDocumentToTheApplication, {
      photo_type: "vehicle_registration_certificate_front",
      applicationId,
      document_id: stsFront.data.data.id,
    });
    // * end

    // * sts2 start
    const stsBack: sagaApiType = yield call(API.main.documents.uploadDocument, {
      content: sts2,
      file_name: "sts2.jpg",
    });

    yield call(API.main.documents.attachingDocumentToTheApplication, {
      photo_type: "vehicle_registration_certificate_back",
      applicationId,
      document_id: stsBack.data.data.id,
    });
    // * end

    yield put(
      applicationSagaActions.sendingDocumentsForRecognize({
        applicationId,
      })
    );
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
    });
  } finally {
    yield process.stop();
  }
}
