import { put, select, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import { StateType } from "../../../store";
import { errorsReducer } from "store/auxiliary/errorsReducer";
import { lsController } from "../../../store/auxiliary/localStorage";

export function* clearErrorsSaga() {
  const location: string = yield select(
    (state: StateType) => state.router.location.pathname
  );

  const isBankiApiFlow = lsController.get("lead") === "banki-api";

  /** В одношаговом флоу с banki-api мы при получении 400 ошибки в POST/passport или PUT/survey редиректим чела на
   эти экраны, и нужно подсветить поле с ошибкой, поэтому чистить их при смене location нельзя, для этого добавил иф */
  yield takeEvery(LOCATION_CHANGE, function* () {
    if (isBankiApiFlow && (
      !location.includes("banki-api") &&
      !location.includes("passport_and_inn") &&
      !location.includes("sbp_bank_filling")
    )) {
      yield put(errorsReducer.actions.setFieldsErrors({}));
    }
    yield put(errorsReducer.actions.setGlobalError(""));
  });
}
