import { useRef } from "react";
import mergeRefs from "react-merge-refs";
import get from "lodash/get";
import cn from "classnames";
import styles from "../index.module.css";
import { TextInputPropsType } from "../types";
import { Icons } from "../../../../assets/icons";
import { ConnectedForm } from "../../ConnectedForm";
import { InputBase } from "../InputBase";
import { useToggle } from "../../../../hooks/logical/useToggle";

export const InputPassword = (props: Omit<TextInputPropsType, "control">) => {
  const { className, ...other } = props;
  const [isVisible, toggleVisible] = useToggle(false);
  const ref = useRef<any>();

  return (
    <ConnectedForm>
      {({ control, formState: { errors } }) => (
        <InputBase
          {...other}
          type={
            (props.isVisible ? props.isVisible : isVisible)
              ? "text"
              : "password"
          }
          inputElementRef={mergeRefs([ref, props.inputElementRef])}
          support={
            <div
              onClick={
                props.toggleVisible ? props.toggleVisible : toggleVisible
              }
            >
              {props.isVisible ? (
                props.isVisible ? (
                  <Icons.ui.Eye />
                ) : (
                  <Icons.ui.ClosedEye />
                )
              ) : isVisible ? (
                <Icons.ui.Eye />
              ) : (
                <Icons.ui.ClosedEye />
              )}
            </div>
          }
          className={cn({
            [`${props.className}`]: true,
            [styles.password]: props.isVisible ? !props.isVisible : !isVisible,
          })}
          error={props.error || get(errors as any, `${props.name}.message`)}
          control={control}
        />
      )}
    </ConnectedForm>
  );
};
