import { PayloadAction } from "@reduxjs/toolkit";
import {
  createLoanPaymentType,
  createPartialEarlyRepaymentType,
  deletePartialEarlyRepaymentType,
} from "../../../api/main/application/types";
import { processStateController } from "../../util/helpers/processStateController";
import { call, delay, put } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { loanReducer } from "../../../store/reducers/loan";

export function* deletePartialEarlyRepaymentSaga({
  payload,
  type: actionType,
}: PayloadAction<deletePartialEarlyRepaymentType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    payload.setIsOpenPopup(false);

    const response: sagaApiType = yield call(
      API.main.application.deletePartialEarlyRepayment,
      payload
    );
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
    });
  } finally {
    yield process.stop();
  }
}
