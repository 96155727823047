import * as yup from "yup";

export const PasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Введите подходящий пароль")
    .matches(/\d/, "Введите подходящий пароль")
    .matches(/[A-Z]/, "Введите подходящий пароль")
    .matches(
      /^[a-zA-Z0-9 !"#\$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~ ]*$/,
      "Введите подходящий пароль"
    ),
  password_confirm: yup
    .string()
    .required("Введите подходящий пароль")
    .oneOf([yup.ref("password"), null], "Пароли не совпадают"),
});
