import React, { FC } from "react";
import styles from "./index.module.css"
import cn from "classnames";

type propsType = {
  isCommonDocuments?: boolean;
}

export const DocumentsBlock: FC<propsType> = ({children, isCommonDocuments}) => {
  return (
      <div className={cn(styles.container, {
        [styles.isCommonDocuments]: isCommonDocuments
      })}>
        {children}
      </div>
  );
};