import React, { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Range } from "react-range";
import cn from "classnames";
import styles from "./index.module.css";
import { stringHelpers } from "../../../helpers/string";
import { RangeType } from "./types";

// * PROPS
// * marks - показывать или нет метки между step
// * step - сколько будет занимать шаг ползунка
// * min - минимальное значение
// * max - максимальное значение
// * rangeData - данные, которые будут отображаться снизу под range элементом
// * className - будет улетать в обёртку (сontainer крч)
// * name - нужен нам тут для сета значения из Range в форму + чтобы отслеживать value формы и реагировать правильно
// * setValues - это функция для сета значений самого Range компонента
// * values - значения самого Range компонента

export const RangeSquare: FC<RangeType> = ({
  marks,
  step = 1,
  min,
  max,
  rangeData,
  className,
  name,
  setValues,
  values,
  ...jsxAttrs
}) => {
  const { setValue, control } = useFormContext();

  // * сама линия у react-range не подходит, поэтому я её скрыл и сделал кастомные, тут высчитывается проценты width каждой линии
  const filledLine = ((values[0] - min) * 100) / (max - min);
  const unfilledLine = 100 - ((values[0] - min) * 100) / (max - min);

  const formValue = useWatch({
    control,
    name: name,
  });

  // * юзер в инпут может ввести значение любое, но Range компонента у нас рассчитана только на диапазон min-max
  //  * поэтому я проверяю значение в инпуте и если оно неподходящее, то не сечу в Range
  useEffect(() => {
    if (formValue && formValue !== values[0]) {
      formValue >= min && formValue <= max && setValues([formValue]);
    }
  }, [formValue]);

  return (
    <div
      className={cn(styles.container, {
        [`${className}`]: className,
      })}
      {...jsxAttrs}
    >
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        onChange={(values) => {
          setValues(values);
          setValue(name, values[0]);
        }}
        // * это метки
        renderMark={
          marks
            ? ({ props, index }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                  }}
                  className={cn(styles.mark, {
                    [styles.mark_filled]: min + index * step < values[0],
                  })}
                >
                  {max / step !== index && index !== 0 && rangeData && (
                    <div className={cn(styles.mark_text, styles.text)}>
                      {rangeData[index]}
                    </div>
                  )}
                </div>
              )
            : undefined
        }
        // * это линии по которым бегаем
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
            }}
            className={styles.track_container}
          >
            <div ref={props.ref} className={styles.track_line_invisible}>
              {children}
              <div
                style={{
                  width: `${filledLine}%`,
                }}
                className={styles.track_line_filled}
              />
              <div
                style={{
                  width: `${unfilledLine}%`,
                }}
                className={styles.track_line_unfilled}
              />
            </div>
          </div>
        )}
        // * Это кружочек, на который жмякаем
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
            }}
            className={styles.thumb}
          >
            <div
              className={cn(styles.circle, {
                [styles.circle_Dragged]: isDragged,
              })}
            />
          </div>
        )}
      />
      {/*{!marks && <div className={styles.circle_end} />}*/}
      {/*/!*  * Это всё, что ниже range *!/*/}
      {/*<div className={cn(styles.text_left, styles.text)}>*/}
      {/*  {rangeData*/}
      {/*    ? rangeData[0]*/}
      {/*    : `${stringHelpers.transformMoneyValue(min)} ₽`}*/}
      {/*</div>*/}
      {/*<div className={cn(styles.text_right, styles.text)}>*/}
      {/*  {rangeData*/}
      {/*    ? rangeData[max / step]*/}
      {/*    : `${stringHelpers.transformMoneyValue(*/}
      {/*        max === 80001 ? 80000 : max*/}
      {/*      )} ₽`}*/}
      {/*</div>*/}
    </div>
  );
};
