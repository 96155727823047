import * as yup from "yup";

// yup.addMethod(yup.array, "unique", function (message, mapper = (a: any) => a) {
//   return this.test("test-unique", message, function (list: any) {
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });

yup.addMethod<yup.ArraySchema<any>>(
  yup.array,
  "validatationDocument",
  function (errorMessage) {
    return this.test(`validatation-document`, errorMessage, function (value) {
      let isValid = true;

      value?.forEach((item) => {
        if (!item.file_id) {
          isValid = false;
        }
      });

      return isValid;
    });
  }
);

export default yup;
