import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { createFC } from "../../../../../helpers/createFC";
import { Title } from "../../components/Title";
import { DocumentsBlock } from "./components/DocumentsBlock";
import { SingleDocument } from "./components/SingleDocument";
import { DocumentsBlockHeader } from "./components/DocumentsBlockHeader";
import { DesktopSupportLinks } from "../../components/DesktopSupportLinks";
import { useGetLoan } from "../../../../../hooks/api/useGetLoan";
import { useSelector } from "react-redux";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { useGetInfoFromApplication } from "../../../../../hooks/api/useGetInfoFromApplication";
import { dateMethods, showDMYDate } from "../../../../../helpers/date";
import { Loader } from "../../../../ui/Loader";
import { stringHelpers } from "../../../../../helpers/string";
import moment from "moment";
import { API } from "../../../../../api";
import { DocumentsBlockItem } from "./components/DocumentsBlockItem";
import { loanType } from "../../../../../types/cabinet/types";
import { applicationType } from "../../../../../types/application/types";
import {
  loanReducer,
  loanReducerSelectors,
} from "../../../../../store/reducers/loan";
import { loanDocumentsType } from "../../../../../types/documents/types";

const mockData1 = [
  {
    title: "Заявление на предоставление займа",
    downloadLink: "downloadLink",
    documentNumber: "№ 39929300",
    date: "20.09.2022",
  },
  {
    title: "Заявление на предоставление займа",
    downloadLink: "downloadLink",
    documentNumber: "№ 39929300",
    date: "20.09.2022",
  },
  {
    title: "Заявление на предоставление займа",
    downloadLink: "downloadLink",
    documentNumber: "№ 39929300",
    date: "20.09.2022",
  },
  {
    title: "Заявление на предоставление займа",
    downloadLink: "downloadLink",
    documentNumber: "№ 39929300",
    date: "20.09.2022",
  },
];

export const getDocuments = (photos: any[], name: string) => {
  return photos?.find((photo) => photo.document_type === name);
};

// type propsType = {};

type dataType = Array<{
  id: number;
  amount: number;
  end_date: Date;
  loan_number: number;
  documents: Array<loanDocumentsType>;
}>;

export const DocumentsCabinet = createFC(() => {
  const [data, setData] = useState<dataType>();

  const { docs, isLoading, applicationId, userId } =
    useGetInfoFromApplication();

  const currentLoan = useGetLoan();

  const paidLoans = useSelector(loanReducerSelectors.getPaidLoans);
  const personalDataAgreement = getDocuments(docs, "personal_data_agreement");
  const preAcceptance = getDocuments(docs, "pre_acceptance");

  const applyingAgreement = getDocuments(docs, "applying_agreement");
  const loanContract = getDocuments(docs, "loan_contract");
  const depositContract = getDocuments(docs, "deposit_contract");

  const generalDocuments = [
    // {
    //   title: "Согласие с обработкой ПД и использованием АСП",
    //   documentType: "personal_data_agreement",
    //   documentNumber: personalDataAgreement?.id,
    //   date: showDMYDate(personalDataAgreement?.created_at),
    // },
    // {
    //   title: "Согласие с безакцептным списанием",
    //   documentType: "pre_acceptance",
    //   documentNumber: preAcceptance?.id,
    //   date: showDMYDate(preAcceptance?.created_at),
    // },
    {
      title: "Политика обработки и хранение ПД",
      downloadLink:
        "https://static.creddy.ru/Политика_в_отношении_обработки_персональных_данных.pdf",
      documentNumber: "без номера",
      date: "01.12.2023",
    },
  ];

  // const loanDocuments = [
  //   {
  //     title: "Заявление на предоставление займа",
  //     documentType: "applying_agreement",
  //     documentNumber: applyingAgreement?.id,
  //     date: showDMYDate(applyingAgreement?.created_at),
  //   },
  //   {
  //     title: "Индивидуальные условия",
  //     documentType: "loan_contract",
  //     documentNumber: loanContract?.id,
  //     date: showDMYDate(loanContract?.created_at),
  //   },
  //   {
  //     title: "Договор залога",
  //     documentType: "deposit_contract",
  //     documentNumber: depositContract?.id,
  //     date: showDMYDate(depositContract?.created_at),
  //   },
  // ];

  useEffect(() => {
    if (paidLoans?.length) {
      API.main.application.getApplications().then((res) => {
        const apps = res.data.data.items.filter((item) => {
          for (let i = 0; i < paidLoans?.length; i++) {
            if (paidLoans[i].applicationId === item.id) {
              return true;
            }
          }
        });

        setData(
          apps.map((item) => {
            const loan = paidLoans.find(
              (loan) => loan.applicationId === item.id
            );

            return {
              id: item.id,
              documents: item.documents,
              amount: loan!.amount,
              end_date: loan!.end_date,
              loan_number: loan!.loan_number,
            };
          })
        );
      });
    }
  }, []);

  console.log(docs, "docs");

  return (
    <div className={styles.container}>
      <DesktopSupportLinks />

      <div className={styles.page_title}>Документы</div>
      <Title style={{ marginBottom: "15px" }}>Общие документы</Title>
      {!isLoading ? (
        <DocumentsBlock isCommonDocuments>
          {generalDocuments.map((item) => {
            return (
              <SingleDocument
                key={item.title}
                applicationId={applicationId}
                userId={userId}
                // documentType={item?.documentType}
                title={item.title}
                documentNumber={item.documentNumber}
                date={item.date}
              />
            );
          })}
        </DocumentsBlock>
      ) : (
        <Loader />
      )}

      <Title style={{ marginBottom: "15px" }}>Документы по займам</Title>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        // <div className={styles.loan_documents}>
        //   <DocumentsBlock>
        //     <DocumentsBlockHeader
        //       title={<>Договор ID: {loanContract?.id}</>}
        //       amount={stringHelpers.transformMoneyValue(
        //         data?.loan_data?.amount
        //       )}
        //       date={showDMYDate(loanContract?.created_at)}
        //     />
        //     {loanDocuments.map((item) => {
        //       return (
        //         <SingleDocument
        //           applicationId={applicationId}
        //           documentType={item?.documentType}
        //           userId={userId}
        //           title={item.title}
        //           documentNumber={item.documentNumber}
        //           date={item.date}
        //         />
        //       );
        //     })}
        //   </DocumentsBlock>
        // </div>
        <>
          {/*<div className={styles.loan_documents}>*/}
          {/*  <DocumentsBlock>*/}
          {/*     <DocumentsBlockHeader*/}
          {/*      title={<>Договор № {loanContract?.id}</>}*/}
          {/*      amount={stringHelpers.transformMoneyValue(*/}
          {/*        data?.loan_data?.amount*/}
          {/*      )}*/}
          {/*      date={showDMYDate(loanContract?.created_at)}*/}
          {/*    />*/}
          {/*    {loanDocuments.map((item) => {*/}
          {/*      return (*/}
          {/*        <SingleDocument*/}
          {/*          applicationId={applicationId}*/}
          {/*          documentType={item?.documentType}*/}
          {/*          userId={userId}*/}
          {/*          title={item.title}*/}
          {/*          documentNumber={item.documentNumber}*/}
          {/*          date={item.date}*/}
          {/*        />*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </DocumentsBlock>*/}
          {/*</div>*/}
          {(currentLoan?.data?.data.data.status === "issued_loan" || currentLoan?.data?.data.data.status === "overdue_loan") && (
            <DocumentsBlockItem
              data={{
                id: applicationId,
                documents: docs,
                amount: currentLoan?.data?.data.data.loan_data.amount,
                loan_number: currentLoan?.data?.data.data.loan_number,
              }}
            />
          )}
          {data
            ?.sort(
              (a, b) =>
                new Date(b.end_date).getTime() - new Date(a.end_date).getTime()
            )
            .map((item) => (
              <DocumentsBlockItem key={item.id} data={item} />
            ))}
        </>
      )}
    </div>
  );
});
