import { useQuery } from "react-query";
import { API } from "../../api";
import { paymentInfoType } from "../../types/cabinet/types";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../routes";
import { useSelector } from "react-redux";
import { StateType } from "../../store";

export const useGetPaymentInfo = ({
  amount_for_payment,
  loan_id,
  payment_id,
}: {
  amount_for_payment?: number | null;
  loan_id: string;
  payment_id: string;
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  /*
   Лоадер для пдп, который пришлось вкостылить из-за того, что бек сначала отдает статус
   оплаты success_payment, а займ падает в paid_loan только через полторы минуты
   */
  const [showFullRepaymentLoader, setShowFullRepaymentLoader] = useState(false);
  const [paymentError, setIsPaymentError] = useState(false);
  const paymentType = useSelector(
    (state: StateType) => state.loanReducer.paymentType
  );

  console.log(paymentType, "paymentType");

  const { data, error } = useQuery(
    ["LoanPayment", payment_id],
    () => {
      if (loan_id && payment_id && amount_for_payment !== 0) {
        return API.main.application.getPayment({
          loan_id,
          payment_id,
        });
      }
    },
    {
      refetchInterval: 5000,
      onSuccess: (data: any) => {
        const paymentInfo: paymentInfoType = data?.data?.data;

        if (isLoading && paymentInfo?.status === "process_payment") {
          setIsLoading(false);
        } else if (
          paymentInfo?.status === "success_payment" ||
          paymentInfo?.status === "transfered_payment"
        ) {
          if (paymentType === "due_repayment") {
            history.push(routes.cabinet.my_loan);
          } else if (paymentType === "partly_repayment") {
            history.push(routes.cabinet.early_repayment_accept);
          }
          if (paymentType === "full_repayment") {
            setShowFullRepaymentLoader(true);
            API.main.application.getLoanById(loan_id).then((res) => {
              if (res.status === 200) {
                if (res.data.data.status === "paid_loan") {
                  history.push(routes.auth.phone);
                } else {
                  history.push(routes.cabinet.early_repayment_accept);
                }
              }
            });
          }
        } else if (paymentInfo?.status === "error_payment") {
          setIsPaymentError(true);
        }
      },
    }
  );

  return {
    isLoading,
    paymentInfo: data?.data?.data as paymentInfoType,
    error,
    paymentError,
    showFullRepaymentLoader,
  };
};
