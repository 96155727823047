import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { API } from "../../api";
import { scheduleType } from "../../types/application/types";
import { lsController } from "../../store/auxiliary/localStorage";
import { applicationReducerSelectors } from "../../store/reducers/application";

export const useGetSchedule = ({
  schedule_amount,
  schedule_loan_term,
  percent_rate,
  enabled,
}: {
  schedule_amount: number;
  schedule_loan_term: number;
  enabled?: { value: boolean };
  percent_rate: number;
}) => {
  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const { data, isLoading } = useQuery(
    ["Schedule", { schedule_amount, schedule_loan_term, percent_rate }],
    () => {
      if (schedule_amount && schedule_loan_term) {
        return API.main.application.getSchedule({
          applicationId: applicationId || lsController.get("application_id"),
          amount: schedule_amount,
          loan_term: schedule_loan_term,
          percent_rate: percent_rate,
        });
      }
    },
    {
      enabled: enabled ? enabled.value : true,
    }
  );

  return {
    schedule: data?.data?.data as scheduleType,
    isLoading: isLoading,
  };
};
