import { createFC } from "../../../helpers/createFC";
import styles from "./index.module.css";
import React, { HTMLAttributes, useRef, useState } from "react";
import { useBlurred } from "../../../hooks/logical/useBlurred";
import cn from "classnames";
import { motion } from "framer-motion";
import { dotsDropdownMenuAnimation } from "./animate";
import { Icons } from "../../../assets/icons";
import { downloadFile } from "../../../helpers/file";
import { applicationSagaActions } from "../../../sagas/application/actions";
import { useDispatch, useSelector } from "react-redux";
import { applicationReducerSelectors } from "../../../store/reducers/application";
import { StateType } from "../../../store";
import { API } from "../../../api";
import { AxiosResponse } from "axios";
import { Loader } from "../Loader";

type propsType = {
  handlePartialEarlyRepayment: () => void;
  handleFullRepayment: () => void;
} & HTMLAttributes<HTMLDivElement>;

/*
  Вкорячил логику прям сюда пока, тк компонент нигде не переиспользуется
  Если где-то еще будет она же, но с другой логикой - вынести ее отсюда и оставить только верстку
 */
export const DotsDropdown = createFC(
  ({
    className,
    handlePartialEarlyRepayment,
    handleFullRepayment,
  }: propsType) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);
    useBlurred(menuRef, setIsMenuOpen, isMenuOpen);

    const [certificateId, setCertificateId] = useState("");
    const [downloading, setDownloading] = useState(false);
    const [successDownload, setSuccessDownload] = useState(false);

    const dispatch = useDispatch();

    const applicationId = useSelector(
      applicationReducerSelectors.getApplicationId
    );

    const loan_id = useSelector(
      (state: StateType) => state.loanReducer.loan_id
    );

    const downloadCertificate = () => {
      // if (downloading) return;
      //
      setDownloading(true);
      setSuccessDownload(false);

      if (loan_id) {
        API.main.application
          .getCertificate({
            loan_id: loan_id,
          })
          .then(
            (
              res: AxiosResponse<{
                data: {
                  items: Array<{
                    id: string;
                    certificate_type: string;
                    created_at: Date;
                  }>;
                  items_count: number;
                };
              }>
            ) => {
              const response = res.data.data;

              // if (response.items_count !== 0) {
                API.main.application
                  .generateCertificate({
                    loan_id: loan_id,
                    certificate_type: "debt_availability_certificate",
                  })
                  .then(
                    (
                      res: AxiosResponse<{
                        data: {
                          id: string;
                          certificate_type: string;
                          created_at: Date;
                        };
                      }>
                    ) => {
                      const response = res.data.data;

                      if (res.status === 201) {
                        setCertificateId(response.id);
                      }

                      let interval = setInterval(() => {
                        API.main.application
                          .downloadCertificate({
                            loan_id: loan_id,
                            certificate_id: response.id,
                          })
                          .then((res) => {
                            if (res.status === 200) {
                              // @ts-ignore
                              const url = res?.data?.data?.content;
                              downloadFile(
                                url,
                                "Справка о наличии задолженности",
                                "_blank"
                              );
                              clearInterval(interval);
                              setDownloading(false);
                            }
                          });
                      }, 3000);
                    }
                  );
              // } else {
              //   let interval = setInterval(() => {
              //     API.main.application
              //       .downloadCertificate({
              //         loan_id: loan_id,
              //         certificate_id: response.items[0].id,
              //       })
              //       .then((res) => {
              //         if (res.status === 200) {
              //           // @ts-ignore
              //           const url = res?.data?.data?.content;
              //           downloadFile(
              //             url,
              //             "Справка о наличии задолженности",
              //             "_blank"
              //           );
              //           clearInterval(interval);
              //           setDownloading(false);
              //         }
              //       });
              //   }, 3000);
              //   setCertificateId(response.items[0].id);
              // }
            }
          );
      }
    };

    return (
      <div
        className={cn(styles.container, {
          [`${className}`]: className,
        })}
      >
        <div
          className={styles.dots}
          onClick={() => setIsMenuOpen(true)}
          ref={menuRef}
        >
          <div></div>
        </div>
        <motion.div
          variants={dotsDropdownMenuAnimation}
          initial={"close"}
          animate={isMenuOpen ? "open" : "close"}
          className={styles.menu}
          ref={menuRef}
        >
          <button
            onClick={() => {
              setIsMenuOpen(false);
              handlePartialEarlyRepayment();
            }}
            className={styles.button}
          >
            Частичное досрочное погашение
          </button>
          <button
            onClick={() => {
              setIsMenuOpen(false);
              handleFullRepayment();
            }}
            className={styles.button}
          >
            Полное досрочное погашение
          </button>
          <button className={styles.button}>
            {downloading ? (
              <Loader />
            ) : (
              <a target={"_blank"} onClick={downloadCertificate}>
                Справка о наличии задолженности
              </a>
            )}
          </button>
        </motion.div>
      </div>
    );
  }
);
