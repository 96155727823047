import React, { SetStateAction } from "react";
import styles from "./index.module.css";
import { AnimatePresence, motion } from "framer-motion";
import { Icons } from "../../../assets/icons";
import { createFC } from "../../../helpers/createFC";

type propsType = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
};

export const Popup = createFC(({
  isOpen,
  setIsOpen,
  children
}: propsType) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <div className={styles.wrapper}>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className={styles.content}
          >
            <div
              className={styles.close_cross}
              onClick={() => setIsOpen(false)}
            >
              <Icons.ui.CrossBlack />
            </div>
            <div>{children}</div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
});
