import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { useHistory, useParams } from "react-router-dom";
import { Loader } from "../../../../ui/Loader";
import { useGetPaymentInfo } from "../../../../../hooks/api/useGetPaymentInfo";
import { Button } from "../../../../ui/Button";
import { routes } from "../../../../../routes";
import { Icons } from "../../../../../assets/icons";
import { LoadingPage } from "../../../Application/pages/LoadingPage";

type propsType = {};

/*
  Iframe для простой оплаты займа из лк (не чдп пдп)
 */
export const LoanPayment: FC<propsType> = (props) => {
  const { loan_id, payment_id } = useParams<{
    loan_id: string;
    payment_id: string;
  }>();
  const history = useHistory();
  // const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);

  const { paymentInfo, isLoading } = useGetPaymentInfo({
    loan_id,
    payment_id
  });

  // secure тестануть

  useEffect(() => {
    console.log(iframeRef?.contentWindow);
    console.log(iframeRef?.contentWindow?.location?.pathname);
  }, [iframeRef]);

  const redirectToMyLoan = () => {
    history.push(routes.cabinet.my_loan);
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <LoadingPage
          title={""}
          subtitle={<>Мы загружаем экран оплаты.<br /> Пожалуйста, подождите!</>}
          additionalSubtitle={<>Оплата будет доступна<br /> через несколько секунд</>}
          countdownTime={30}
        />
      ) : (
        /*

        <>
          <div>
            <div className={styles.h_iframe}>
              <iframe
                ref={setIframeRef}
                key={paymentInfo?.payment_url}
                src={paymentInfo?.payment_url}
                className={styles.responsive_iframe}
              ></iframe>
            </div>
          </div>

          <Button onClick={redirectToMyLoan} className={styles.button}>
            Вернуться в Мой займ
          </Button>
        </>
         */

        <>


          <div className={styles.title}>
            Перейдите к оплате
          </div>

          <div className={styles.icon}>
            <Icons.ui.Stacks />
          </div>

          <div className={styles.subtitle}>
            Страница оплаты откроется в новой вкладке, <br/>
            не закрывайте эту страницу до завершения <br/>
            платежа
          </div>

          <a href={paymentInfo?.payment_url} target={"_blank"}>
            <Button className={styles.button}>
              Перейти к оплате
            </Button>
          </a>
        </>

      )}
    </div>
  );
};
