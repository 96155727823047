import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationInitStateType, carBodyType, carType } from "./types";
import { StateType } from "../../index";
import {
  applicationStatusType, leadType,
  refuseReasonType,
  videoCallDateType,
} from "../../../types/application/types";
import { lsController } from "../../auxiliary/localStorage";

const initialState: ApplicationInitStateType = {
  leadData: null,
  refuse_reason: null,
  document_photos: null,
  car_body: null,
  application_id: null,
  car: null,
  makeRepeatResend: true,
  error: null,
  application_type: null,
  items_count: null,
  lastStatus: null,
  selected_loan_data: {
    amount: null,
  },
  videoCallStartDate: null,
};

export const applicationReducer = createSlice({
  name: "@applicationReducer",
  initialState,
  reducers: {
    setApplicationId: (
      state,
      { payload }: PayloadAction<{ application_id: number | null }>
    ) => {
      state.application_id =
        payload.application_id || lsController.get("application_id");
    },
    setApplicationDocuments: (
      state,
      { payload }: PayloadAction<{ document_photos: any }>
    ) => {
      state.document_photos = payload.document_photos;
    },
    // Экспериментальное поле в сторе, требует отладки, скорее всего будет показывать неправильное значение!
    setApplicationType: (
      state,
      { payload }: PayloadAction<{ application_type: applicationStatusType }>
    ) => {
      state.application_type = payload.application_type;
    },
    setCar: (state, { payload }: PayloadAction<{ car: carType }>) => {
      state.car = payload.car;
    },
    setRefuseReason: (
      state,
      { payload }: PayloadAction<{ refuse_reason: refuseReasonType }>
    ) => {
      state.refuse_reason = payload.refuse_reason;
    },
    setCarBody: (
      state,
      { payload }: PayloadAction<{ car_body: carBodyType }>
    ) => {
      state.car_body = payload.car_body;
    },
    setMakeRepeatResend: (
      state,
      { payload }: PayloadAction<{ value: boolean }>
    ) => {
      state.makeRepeatResend = payload.value;
    },

    setError: (
      state,
      { payload }: PayloadAction<ApplicationInitStateType["error"]>
    ) => {
      state.error = payload;
    },

    setItemsCount: (state, { payload }: PayloadAction<number>) => {
      state.items_count = payload;
    },

    setLastStatus: (state, { payload }: PayloadAction<string | null>) => {
      state.lastStatus = payload;
    },

    setSelectedLoanData: (state, { payload }: PayloadAction<{ amount: number }>) => {
      state.selected_loan_data.amount = payload.amount;
    },

    setVideoCallStartDate: (
      state,
      { payload }: PayloadAction<videoCallDateType | null>
    ) => {
      state.videoCallStartDate = payload;
    },

    setLeadData: (
      state,
      { payload }: PayloadAction<leadType | null>
    ) => {
      state.leadData = payload;
    },

    clear: () => initialState,
  },
});

export const applicationReducerSelectors = {
  getApplicationId: (state: StateType) =>
    state.applicationReducer.application_id,
  getRefuseReason: (state: StateType) => state.applicationReducer.refuse_reason,
  getApplicationDocuments: (state: StateType) =>
    state.applicationReducer.document_photos,
  getApplicationType: (state: StateType) =>
    state.applicationReducer.application_type,
  getCar: (state: StateType) => state.applicationReducer.car,
  getCarBody: (state: StateType) => state.applicationReducer.car_body,
  getMakeRepeatResend: (state: StateType) =>
    state.applicationReducer.makeRepeatResend,
  getError: (state: StateType) => state.applicationReducer.error,
  getItemsCount: (state: StateType) => state.applicationReducer.items_count,
  getLastStatus: (state: StateType) => state.applicationReducer.lastStatus,
  getSelectedLoanData: (state: StateType) => state.applicationReducer.selected_loan_data,
  // getVideoCallStartDate: (state: StateType) => state.applicationReducer.videoCallStartDate,
};
