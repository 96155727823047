import React, { AllHTMLAttributes, FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { Icons } from "../../../../../../../assets/icons";
import { routes } from "../../../../../../../routes";
import { useHistory } from "react-router-dom";
import cn from "classnames";

type propsType = {
  isActive?: boolean;
} & AllHTMLAttributes<HTMLDivElement>;

export const ApplicationCard: FC<propsType> = ({
  isActive = false,
  className,
  ...jsxAttr
}) => {
  const history = useHistory();

  const redirectToDocuments = (e: any) => {
    history.push(routes.cabinet.documents);

    e.stopPropagation();
  };

  const redirectToMyLoan = (e: any) => {
    isActive && history.push(routes.cabinet.my_loan);
  };

  return (
    <>
      <div
        onClick={redirectToMyLoan}
        className={cn(styles.container, {
          [styles.active]: isActive,
        })}
      >
        <div className={styles.header}>
          <p className={styles.header_text}>Займ под залог авто 26.02.2021</p>
          <Icons.cabinet.DocumentWithCircle
            className={styles.icon_document}
            onClick={redirectToDocuments}
          />
        </div>

        <div className={styles.amount}>850 000,00i</div>
        {isActive && (
          <>
            <div className={styles.sum}>Сумма к возврату 19 303 i</div>
            <div className={styles.date}>Оплатить до 20.03.2023</div>
          </>
        )}
        <div className={styles.card_number}>1234 56ХХ ХХХХ 1234</div>
      </div>

      {!isActive && <div className={styles.line} />}
    </>
  );
};
