import React, { FC, useState } from "react";
import styles from "./index.module.css";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import cn from "classnames";
import { Icons } from "../../../../../assets/icons/";
import { BurgerMenu } from "../../../../ui/BurgerMenu";
import { routes } from "../../../../../routes";
import { errorApplicationType } from "../../../../../store/reducers/application/types";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { useGetInfoFromApplication } from "../../../../../hooks/api/useGetInfoFromApplication";
import { createFC } from "../../../../../helpers/createFC";
import { stringHelpers } from "../../../../../helpers/string";

const titleResolver: { [key: string]: string } = {
  // Дописать еще статусы по мере поступления дезигна
  ["/application/new_loan"]: "Создание заявки",
  ["/application/loan_rejection"]: "Проверка автомобиля",
  ["/application/checking_car"]: "Создание заявки",
  ["/application/refusal_final_bad_car"]: "Решение по займу",
  ["/application/partner_application"]: "Фото документов",
  ["/application/documents"]: "Фото документов",
  ["/application/recognize_documents"]: "Распознание ПТС/СТС",
  ["/application/data_verification"]: "Проверка данных",
  ["/application/passport_and_inn"]: "Заполнение анкеты, шаг 2 из 3",
  ["/application/fio_and_grz"]: "Заполнение анкеты, шаг 1 из 3",
  ["/application/car_photo"]: "Фото автомобиля",
  ["/application/replege_photo"]: "Фото документов шаг 3 из 3",
  ["/application/scoring_and_loan_disbursement"]: "Документы приняты",
  ["/application/issue_method_filling"]: "Обрабатываем фотографии",
  ["/application/repeated_photos"]: "Повторные фото",
  ["/application/code/loan"]: "Подписание заявления",
  ["/application/sent_and_scoring_process"]: "Статус заявки",
  ["/application/video_call_planned"]: "Видеозвонок",
  ["/application/loan_decision"]: "Решение по займу",
  // новая логика с СБП
  ["/application/loan_processing"]: "Прикрепите свою карту",
  ["/application/account_check"]: "Проверка счета",
  ["/application/account_confirmation"]: "Проверка счета",
  ["/application/sbp_check_error/sbp_error"]: "Подтверждение счета",
  ["/application/sbp_check_error/not_connected"]: "Подтверждение счета",
  ["/application/sbp_check_error/not_sbp_bank"]: "Подтверждение счета",
  ["/application/sbp_check_error/name_differs"]: "Подтверждение счета",
  ["/application/sbp_bank_filling"]: "Заполнение анкеты, шаг 3 из 3",
  ["/application/refinancing"]: "Рефинансирование",
  // ["/application/payment_system_response"]: "Прикрепите свою карту",

  ["/application/code/contract"]: "Подписание договора",
  ["/application/issued_final"]: "Получение денег",
  ["/application/signing_process"]: "Подписание договора",
  ["/application/signed_issuing_process"]: "Ожидание выдачи",
  ["/application/error_final"]: "Решение по займу",
  ["/application/underwriter_refusal"]: "Решение по займу",
};

/*
    На тайтл и на прогрессбар 2 резолвера, потому что разное поведение на разных этапах заявки
 */

const progressBarWidthResolver = (pathname: string) => {
  /*
            всего ширина 100% / 16 = 16
         */

  // создание  заявки
  if (
    [
      routes.application.new_loan,
      routes.application.statuses.checking_car,
    ].includes(pathname)
  )
    return "0%";

  // Фото документов
  if ([routes.application.documents].includes(pathname)) return "10%";

  // Отправка заявки
  if ([routes.application.statuses.recognize_documents].includes(pathname))
    return "20%";

  // Проверка данных
  if ([routes.application.passport_and_inn].includes(pathname)) return "30%";

  // Фото автомобиля
  if ([routes.application.car_photo].includes(pathname)) return "40%";
  if ([routes.application.statuses.issue_method_filling].includes(pathname))
    return "50%";

  // Привязка карты
  if (
    [
      routes.application.statuses.sbp_bank_filling,
      routes.application.statuses.account_confirmation,
      routes.application.statuses.account_check,
    ].includes(pathname)
  )
    return "60%";

  //Отправка заявки
  if ([routes.application.code.loan].includes(pathname)) return "70%";

  // Решение по займу
  if (
    [
      routes.application.statuses.sent_and_scoring_process,
      routes.application.loan_decision,
      routes.application.loan_processing,
      routes.application.code.contract,
      routes.application.statuses.refusal_final_bad_car,
    ].includes(pathname)
  )
    return "83%";

  // Оформление займа
  if (
    [
      routes.application.loan_processing,
      routes.application.statuses.signing_process,
      routes.application.statuses.signed_issuing_process,
    ].includes(pathname)
  )
    return "99.3%";
};

export const ApplicationHeader: FC = () => {
  const applicationType = useSelector(
    applicationReducerSelectors.getApplicationType
  );

  const error: errorApplicationType = useSelector(
    applicationReducerSelectors.getError
  );

  const { pathname } = useLocation();

  const [isBurgerOpen, setIsBurgerOpen] = useState<boolean>(false);

  const epts = lsController.get("is_elpts");

  const { data, isLoading } = useGetInfoFromApplication();

  const isPhotos =
    pathname.includes("/application/documents") ||
    pathname.includes("/application/car_photo") ||
    pathname.includes("/application/replege_photo");

  const openBurger = () => {
    document.querySelector("html")!.style.overflow = "hidden";
    setIsBurgerOpen(true);
  };

  function getTitle() {
    if (pathname.includes("error_final")) {
      if (error) {
        if (
          error.error_type === "scoring" ||
          error.error_type === "car_documents" ||
          error.error_type === "pts" ||
          error.error_type === "car_appraisal"
        ) {
          return "Решение по займу";
        }
      }
    } else if (
      applicationType === "passport_filling" &&
      pathname.includes("/application/documents")
    ) {
      return "Распознание паспорта";
    } else if (
      applicationType === "passport_photo_filling" &&
      pathname.includes("/application/recognize_documents")
    ) {
      return "Распознание паспорта";
    } else if (
      applicationType === "car_photo_filling" &&
      pathname.includes("/application/recognize_documents") &&
      epts
    ) {
      return "Распознание СТС";
    } else if (isPhotos) {
      return isLoading
        ? ""
        : <LoanInfo />
    } else {
      return `${titleResolver[pathname]}`;
    }
  }

  const LoanInfo = createFC(() => {
    return (
      <>
        <div className={styles.name}>
          {`${data?.user_info?.first_name || ""}${data?.user_info?.middle_name ? " " + data?.user_info?.middle_name : ""}!`}
        </div>
        <div>
          {data?.loan_data?.amount && `Ваша заявка на ${stringHelpers.transformMoneyValue(data?.loan_data?.amount)} ₽ одобрена!`}
        </div>
      </>
    );
  });

  return (
    <div className={cn(styles.container, {
      [styles.container_photos]: isPhotos,
    })}>
      <div className={styles.wrapper}>
        <div className={styles.upper_part}>
          <div className={styles.creddy_logo}>
            <a href="https://creddy.ru/pts">
              <Icons.logos.Creddy />
            </a>
          </div>
          <div className={styles.burger} onClick={openBurger}>
            <Icons.ui.Burger />
          </div>
        </div>

        <div className={styles.stage_title}>{getTitle()}</div>

        {/*<div className={styles.progress_bar_container}>*/}
        {/*  /!* сюда прокинуть ширину в процентах на основе titleResolver (его тож дополнить процентами) *!/*/}
        {/*  <div*/}
        {/*    className={styles.progress_bar}*/}
        {/*    style={{*/}
        {/*      width: progressBarWidthResolver(pathname),*/}
        {/*      transition: "0.2s",*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</div>*/}

        <BurgerMenu
          isBurgerOpen={isBurgerOpen}
          setIsBurgerOpen={setIsBurgerOpen}
        />
      </div>
    </div>
  );
};
