import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { Icons } from "assets/icons";
import { Button } from "../../../../ui/Button";
import { ReactComponent as HandShake } from "../../../../../assets/icons/ui/HandShake.svg";
import { sendMetrics } from "../../../../../metrics";

type propsType = {};
export const PartnerApplication: FC<propsType> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const partnersHref =
    "https://bankipartners.ru/s/0ejVes4QdG?statid=1023_&erid=1";

  return (
    // <LoadingPage
    //   withoutIcon
    //   title={"Подождите, пожалуйста"}
    //   subtitle={
    //     <>
    //       <div>
    //         Ваши документы загружаются <br /> и распознаются. Между тем, <br />{" "}
    //         отличное время переместиться <br />к вашему автомобилю, чтобы <br />
    //         сфотографировать его для нас.{" "}
    //       </div>
    //       <div className={styles.bold}>Спасибо за терпение!</div>
    //     </>
    //   }
    //   additionalSubtitle={
    //     <>
    //       Мы пришлем СМС для <br />
    //       перехода к фото автомобиля
    //     </>
    //   }
    // />
    <>
      <MobilePageContainer className={styles.form}>
        {/*<BannerWhite />*/}
        <div className={styles.container}>
          <div className={styles.title}>Спасибо за обращение!</div>
          <div className={styles.text}>
            К сожалению, мы не можем выдать Вам займ. Вы можете получить деньги
            у наших партнёров
          </div>
          <div className={styles.loader}>
            <Icons.ui.HandShake />
          </div>
          {/*<div>Пришлём СМС для продолжения заполнения</div>*/}
        </div>
        <Button
          className={styles.button}
          onClick={() =>
            sendMetrics(
              "common-client_perekhod-na-partnerov_common-app",
              "perekhod-na-partnerov"
            )
          }
        >
          {" "}
          <a href={partnersHref}>Подать заявку у партнёров</a>
        </Button>
      </MobilePageContainer>
    </>
  );
};
