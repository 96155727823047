import TagManager from "react-gtm-module";
import ym from "react-yandex-metrika";
import { applicationType } from "../types/application/types";
import { lsController } from "../store/auxiliary/localStorage";

export const sendMetrics = (ymValue: string, gtmValue?: string) => {
  if (process.env.REACT_APP_ENABLE_METRICS === "true") {
    if (gtmValue) {
      TagManager.dataLayer({
        dataLayer: {
          event: "trackEvent",
          eventCategory: "common-client",
          eventAction: gtmValue,
          eventLabel: "common-app",
        }
      });
    }
    ym("reachGoal", ymValue);
    console.log(`ym-target "${ymValue}" reached prod`);
    console.log(`gtm-target "${gtmValue}" reached prod`);
  } else {
    console.log(`ym-target "${ymValue}" reached stage`);
    console.log(`gtm-target "${gtmValue}" reached stage`);
  }
};

export const checkPhotoRecognition = (
  documents: applicationType["document_photos"]
) => {
  const docsWithoutSelfieWithPassport = documents.filter(
    (document) => document.photo_type !== "selfie_with_passport"
  );

  for (const document of docsWithoutSelfieWithPassport) {
    if (
      document.photo_type === "passport_main" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasPassportMainRecognized")) {
        sendMetrics("foto_passport_titul");
        lsController.set("wasPassportMainRecognized", true);
      }
    }
    if (
      document.photo_type === "passport_registration" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasPassportRegistrationRecognized")) {
        sendMetrics("foto_registration_recognize");
        lsController.set("wasPassportRegistrationRecognized", true);
      }
    }
    if (
      document.photo_type === "pts_front" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasPtsFrontRecognized")) {
        sendMetrics("pts_external_recognize");
        lsController.set("wasPtsFrontRecognized", true);
      }
    }
    if (
      document.photo_type === "pts_back" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasPtsBackRecognized")) {
        sendMetrics("pts_internal_recognize");
        lsController.set("wasPtsBackRecognized", true);
      }
    }
    if (
      document.photo_type === "vehicle_registration_certificate_front" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasStsFrontRecognized")) {
        sendMetrics("sts_auto_recognize");
        lsController.set("wasStsFrontRecognized", true);
      }
    }
    if (
      document.photo_type === "vehicle_registration_certificate_back" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasStsBackRecognized")) {
        sendMetrics("sts_client_recognize");
        lsController.set("wasStsBackRecognized", true);
      }
    }
    if (
      document.photo_type === "driver_license_front" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasDriverLicenseFrontRecognized")) {
        sendMetrics("vu_foto_recognize");
        lsController.set("wasDriverLicenseFrontRecognized", true);
      }
    }
    if (
      document.photo_type === "driver_license_back" &&
      document.type === "recognized_document_photo"
    ) {
      if (!lsController.get("wasDriverLicenseBackRecognized")) {
        sendMetrics("vu_categories_recognize");
        lsController.set("wasDriverLicenseBackRecognized", true);
      }
    }
  }
};

//TODO: решил добавить описание целей, т.к. бывает нужно быстро найти что когда отрабатывает, или ваще кому-то нужен
// сразу весь список целей, надо дописать сюда все и поддерживать в актуальном состоянии

/**@JS-targets
 * ym: "common-client_start-avtorizacii-registracii_common-app",
 * gtm: "start-avtorizacii-registracii",
 * target: рендер экрана ввода номера телефона;
 *
 * ym: "common-client_click-net-avto-step1anketa_common-app",
 * gtm: "click-net-avto-step1anketa",
 * target: клик по кнопке "У меня нет автомобиля" на 1 шаге анкеты;
 *
 * ym: "common-client_click-popup-netavto-partner-app_common-app",
 * gtm: "click-popup-netavto-partner-app",
 * target: клик по кнопке "Перейти к предложениям" в попапе "У меня нет автомобиля";
 *
 * ym: "common-client_sozdanie-zayavki_common-app",
 * gtm: "sozdanie-zayavki",
 * target: после 201 ответа на POST/application в дефолтном и одношаговом флоу;
 *
 * ym: "common-client_otpravlen-pasport-v-ankete_common-app", "check anketa"
 * gtm: "otpravlen-pasport-v-ankete",
 * target: после 201 ответа на POST/passport в дефолтном и одношаговом флоу;
 *
 * ym: "bank account ok",
 * ym: "common-client_otpravleny-dop-dannye_common-app",
 * gtm: "otpravleny-dop-dannye",
 * target: после 200 ответа на PUT/survey в дефолтном и одношаговом флоу;
 *
 * ym: "common-client_api-banki-page-show_common-app",
 * gtm: "api-banki-page-show",
 * target: рендер экрана анкеты одношагового флоу;
 *
 * ym: "common-client_api-banki-page-otpravlena_common-app",
 * ym: "common-client_api-banki-postpage-success_common-app",
 * gtm: "api-banki-page-otpravlena",
 * gtm: "api-banki-postpage-success",
 * target: после 200 ответа на PUT/survey в одношаговом флоу;
 * */
