import styles from "../../pages/Application/pages/LoadingPage/index.module.css";
import React, { ReactFragment } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTokenToAxios } from "../../../api/methods/setTokenToAxios";
import { Icons } from "assets/icons";
import { createFC } from "helpers/createFC";
import { routes } from "../../../routes";
import { clearState } from "../../../store/auxiliary/clearState";
import { lsController } from "../../../store/auxiliary/localStorage";
import { Button } from "../Button";

type propsType = {
  title: string | ReactFragment;
  subtitle: string | ReactFragment;
  additionalTitle?: string | ReactFragment;
};

export const ErrorPage = createFC(
  ({ title, subtitle, additionalTitle }: propsType) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const routeChange = () => {
      dispatch(clearState());
      lsController.clearAll();
      setTokenToAxios(null);

      let path = routes.auth.vehicle_valuation;
      history.push(path);
    };

    return (
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>

        <div className={styles.loader}>
          <Icons.ui.CrossWarning className={styles.icon_cross} />
        </div>

        <div className={styles.additional_title}>{additionalTitle}</div>

        {subtitle === "Не удалось получить информацию по авто" && (
          <Button className={styles.button} onClick={() => routeChange()}>
            К оформлению займа
          </Button>
        )}

        <Button className={styles.button} onClick={() => routeChange()}>
          Выйти
        </Button>

        {/* TODO у нас тупо нет верстки этой страницы */}
        {/*<div className={styles.footer}>*/}
        {/*  <div className={styles.footer_title}>*/}
        {/*    Связаться с техподдержкой*/}
        {/*  </div>*/}
        {/*  <div className={styles.footer_links}>*/}
        {/*    <Icons.logos.Whatsapp />*/}
        {/*    <a href={constantStrings.telegramLinkForSupport} target={"_blank"}>*/}
        {/*      <Icons.logos.Telegram />*/}
        {/*    </a>*/}
        {/*    <a href={`tel:${constantStrings.phoneNumberForSupport}`}>*/}
        {/*      <Icons.logos.Phone />*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    );
  }
);
