import React, { FC, UIEvent, useEffect, useState } from "react";
import styles from "./index.module.css";
import { createFC } from "../../../../../helpers/createFC";
import { Title } from "../../components/Title";
import { ApplicationCard } from "./components/ApplicationCard";
import { usePaginator } from "../../../../../hooks/logical/usePaginator";
import { Paginator } from "../../../../ui/Paginator";
import cn from "classnames";

export const testData = [
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1,
];

type propsType = {};
export const History = createFC((props: propsType) => {
  const [haze, setHaze] = useState(true);

  const paginator = usePaginator({
    length: testData.length,
    perPage: 5,
  });

  const changeHazeWithScrolling = ({
    currentTarget: { scrollHeight, scrollTop, offsetHeight },
  }: UIEvent<HTMLElement>) => {
    if (scrollHeight - scrollTop == offsetHeight - 17) {
      setHaze(false);
    } else {
      setHaze(true);
    }
  };

  return (
    <div className={styles.container}>
      <Title>История займов</Title>

      <div className={styles.main_wrapper} onScroll={changeHazeWithScrolling}>
        {testData.map((data, index) => (
          <ApplicationCard isActive={index === 0} />
        ))}
      </div>
      <div className={cn(styles.haze, { [styles.haze_active]: haze })} />

      <div className={styles.paginator}>
        <Paginator {...paginator} pageCount={Math.ceil(testData.length / 5)} />
      </div>
    </div>
  );
});
