import * as axios from "axios";
import { applicationApi } from "./application";
import { userSaleApi } from "./user_sale";
import { servicesApi } from "./services";
import { documentsApi } from "./documents";

export const mainAxios = axios.default.create({
  baseURL: process.env.REACT_APP_MAIN_URL,
  timeout: 300000,
});

export const staticAxios = axios.default.create({
  baseURL: process.env.REACT_APP_STATIC_URL,
  timeout: 300000
})

export const mainApi = {
  application: applicationApi,
  user_sale: userSaleApi,
  services: servicesApi,
  documents: documentsApi,
};
