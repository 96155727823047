import { PayloadAction } from "@reduxjs/toolkit";
import { createLoanPaymentType } from "../../../api/main/application/types";
import { processStateController } from "../../util/helpers/processStateController";
import { call, put } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { loanReducer } from "../../../store/reducers/loan";

export function* createLoanPaymentSaga({
  payload,
  type: actionType,
}: PayloadAction<createLoanPaymentType>) {
  const process = processStateController(actionType);

  if (typeof payload.payment_type !== "undefined") {
    yield put(
      loanReducer.actions.setPaymentType({ paymentType: payload.payment_type })
    );
  }
  try {
    yield process.start();

    const response: sagaApiType = yield call(
      API.main.application.createLoanPayment,
      {
        loan_id: payload.loan_id,
        amount: payload.amount,
      }
    );

    yield redirect(
      routes.cabinet.loan_payment.root({
        loan_id: payload.loan_id,
        payment_id: response?.data?.data?.id,
      })
    );
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
    });
  } finally {
    yield process.stop();
  }
}
