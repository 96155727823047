import { QueryClient } from "react-query";
import { sagaActions } from "../sagas/util/sagaActions";
import { store } from "../store";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        store.dispatch(
          sagaActions.error({
            response: err,
          })
        );
      },
    },
    mutations: {
      onError: (err: any) => {
        store.dispatch(
          sagaActions.error({
            response: err,
          })
        );
      },
    },
  },
});
