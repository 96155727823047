import styles from "./index.module.css";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { API } from "../../../../../../api";
import { routes } from "../../../../../../routes";
import { Icons } from "../../../../../../assets/icons";
import {
  applicationStatusType,
  applicationType,
} from "../../../../../../types/application/types";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../../../../store/reducers/application";
import { lsController } from "../../../../../../store/auxiliary/localStorage";
import { MobilePageContainer } from "../../../../../ui/Containers/MobilePageContainer";
import { Button } from "../../../../../ui/Button";
import { Loader } from "../../../../../ui/Loader";
import { sendMetrics } from "../../../../../../metrics";
import { isMobile } from "react-device-detect";

export type VideoCallTimeFormType = {
  hours: { value: number };
  minutes: { value: number };
  day: "today" | "tomorrow" | "after-tomorrow";
};

export const SentAndScoringProcess: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const application_id = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const application_id_local = lsController.get("application_id");

  const [dataApplication, setDataApplication] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  // const [showClock, setShowClock] = useState(false);

  const form = useForm<VideoCallTimeFormType>();

  const query = useQuery(
    "CheckingCar",
    () =>
      API.main.application.getApplication({
        applicationId: application_id || application_id_local,
      }),
    {
      refetchInterval: 10000,
      onSuccess: (data: any) => {
        const application: applicationType = data?.data?.data;
        // setDataApplication(application);

        dispatch(
          applicationReducer.actions.setVideoCallStartDate(
            data.data.data.video_call_start_date
          )
        );

        dispatch(
          applicationReducer.actions.setApplicationType({
            application_type: application?.application_type,
          })
        );

        if (
          application?.application_type === "car_document_filling" ||
          application?.application_type === "passport_photo_filling"
        ) {
          if (!isMobile) {
            history.push(routes.auth.to_mobile_device);
          } else {
            history.push(routes.application.documents);
          }
        } else if (application?.application_type === "refusal_final") {
          sendMetrics(
            "common-client_otkaz-po-zayavke_common-app",
            "otkaz-po-zayavke"
          );
          dispatch(
            applicationReducer.actions.setError({
              error_message: application?.refusal_cause_comment,
              error_type:
                application?.refusal_cause as applicationType["error_type"],
            })
          );
          history.push(routes.application.partner_application);
        }
        if (application?.application_type === "error_final") {
          dispatch(
            applicationReducer.actions.setError({
              error_message: application?.error_message,
            })
          );
          history.push(routes.application.partner_application);
        }
      },
      onError: (e) => console.log(e),
    }
  );

  const videoCallDate = query?.data?.data?.data.video_call_start_date;
  const applicationType = query?.data?.data?.data.application_type;
  const actualPage = query?.data?.data?.data.actual_page;
  const underwriterId = query?.data?.data?.data?.underwriter_id;

  const underwriterIdAndPhoneNumber: Array<{
    underwriter_id: string;
    phone_number: string;
    name: "Pavel" | "Anna" | "Tatiana" | "Stas";
  }> = [
    {
      underwriter_id: "1edfadfd-bf20-62ce-955b-776c2f8d0f53",
      phone_number: "+79162600089",
      name: "Pavel",
    },
    {
      underwriter_id: "1edfadfc-3f7a-6c32-b067-e9cf9ab6993e",
      phone_number: "+79162600824",
      name: "Anna",
    },
    {
      underwriter_id: "918ad5c1-1154-4772-906e-401c4a3d445b",
      phone_number: "+79150901997",
      name: "Tatiana",
    },
    {
      underwriter_id: "b84dbb8a-fe15-4c37-b23a-fd54da5d4b1c",
      phone_number: "+79801460818",
      name: "Stas",
    },
  ];

  useMemo(() => {
    if (applicationType === "video_call") {
      return sendMetrics(
        "common-client_odobrenie-zayavki-po-ankete_common-app",
        "odobrenie-zayavki-po-ankete"
      );
    }
  }, [applicationType]);

  function getPhoneNumber(underwriterId: string) {
    const entry = underwriterIdAndPhoneNumber.find(
      (item) => item.underwriter_id === underwriterId
    );
    return entry ? entry.phone_number : "+79801460818";
  }

  const underwritePhoneNumber = getPhoneNumber(underwriterId);

  const iconStagesOrdersByStatuses: Array<{
    id: number;
    statuses: Array<applicationStatusType>;
    actual_page: number | null;
  }> = [
    {
      id: 1,
      statuses: ["scoring_process", "underwriter_scoring_process"],
      actual_page: null,
    },
    {
      id: 2,
      statuses: ["underwriter_scoring_process"],
      actual_page: actualPage,
    },
    {
      id: 3,
      statuses: ["video_call"],
      actual_page: actualPage,
    },
  ];

  const resolvedId = iconStagesOrdersByStatuses.find(
    (el) =>
      el.statuses.includes(applicationType) &&
      (applicationType === "underwriter_scoring_process"
        ? el.actual_page === actualPage
        : true)
  )?.id;

  // const resolvedId = iconStagesOrdersByStatuses.find((el) =>
  //   el.statuses.find((el) => el === applicationType)
  // )?.id;

  const renderStatusIcon = (
    resolvedId: number | undefined,
    targetId: number
  ) => {
    if (resolvedId === targetId) {
      return <Loader className={styles.icon_loader} />;
    }
    if ((resolvedId as number) > targetId) {
      return <Icons.ui.CheckmarkWhiteCircle className={styles.icon_check} />;
    }
    if (resolvedId && resolvedId < targetId) {
      return <div className={styles.cycle}></div>;
    }
    return null;
  };

  // const plannedVideoCallDate = videoCallDate?.date;
  // const videoCallTimePassed =
  //   Date.now() > new Date(plannedVideoCallDate).getTime() + 1800000;
  //
  // const { videoCallPlannedDate } = useClock(form);
  //
  // const submit = () => {
  //   setLoading(true);
  //
  //   API.main.application
  //     .sendVideoCallDate({
  //       applicationId: application_id,
  //       date: videoCallPlannedDate,
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         API.main.application
  //           .getApplication({
  //             applicationId: application_id || application_id_local,
  //           })
  //           .then((response: AxiosResponse<{ data: applicationType }>) => {
  //             const videoCallDateObj = response.data.data.video_call_start_date;
  //             dispatch(
  //               applicationReducer.actions.setVideoCallStartDate(
  //                 videoCallDateObj
  //               )
  //             );
  //           })
  //           .then(() => {
  //             setShowClock(false);
  //             setLoading(false);
  //           });
  //       }
  //     })
  //     .catch((e) => {
  //       dispatch(sagaActions.error({ response: e }));
  //       setLoading(false);
  //     });
  // };

  if (query.isLoading || videoCallDate === null) {
    return (
      <MobilePageContainer className={styles.loader_container}>
        <Loader variant={"large"} color={"purple"} className={styles.loader} />
      </MobilePageContainer>
    );
  }

  return (
    <>
      <MobilePageContainer className={styles.container}>
        <>
          <div className={styles.title}>Спасибо, заявка принята!</div>
          <div className={styles.subtitle}>
            Ожидание не более 30 минут <br /> с 09:00 до 20:00 по МСК
          </div>
          <div className={styles.container_statuses}>
            <div className={styles.container_status}>
              <div className={styles.status_title}>Заявка в очереди</div>
              {renderStatusIcon(resolvedId, 1)}
            </div>
            <div className={styles.container_status}>
              <div className={styles.status_title}>На рассмотрении</div>
              {renderStatusIcon(resolvedId, 2)}
            </div>
            <div className={styles.container_status}>
              <div className={styles.status_title}>
                {applicationType === "video_call"
                  ? "Идентификация и проверка документов"
                  : "Проверка пройдена успешно"}
              </div>
              {renderStatusIcon(resolvedId, 3)}
            </div>
          </div>
          {actualPage && applicationType === "video_call" ? (
            <>
              <div className={styles.additional_title}>Ожидайте звонок</div>
              <div className={styles.additional_subtitle}>
                С Вами свяжется наш специалист, чтобы идентифицировать вас как
                заемщика
              </div>
              <Button className={styles.button}>
                <a
                  href={`https://wa.me/+79153341178?text=Заявка ID ${application_id} Добрый день. Я хочу заказать звонок`}
                  target={"_blank"}
                >
                  Звонок в WhatsApp сейчас
                </a>
              </Button>
            </>
          ) : (
            <>
              <div className={styles.additional_title}>Статус заявки</div>
              <div className={styles.additional_subtitle}>
                Вы можете отслеживать статус заявки на этом экране. Можете
                закрыть экран, после рассмотрения мы пришлём СМС
              </div>
            </>
          )}

          {/*  <SmartForm className={styles.form} form={form} submit={submit}>*/}
          {/*    {videoCallTimePassed && (*/}
          {/*      <>*/}
          {/*        <div className={styles.title_big}>*/}
          {/*          Мы не смогли связаться с Вами для проведения видеозвонка*/}
          {/*        </div>*/}
          {/*        <div className={styles.icon}>*/}
          {/*          <Icons.ui.CrossWarning />*/}
          {/*        </div>*/}
          {/*      </>*/}
          {/*    )}*/}
          {/*    {(videoCallTimePassed || showClock) && (*/}
          {/*      <>*/}
          {/*        <div className={styles.title}>Выберите удобное время</div>*/}
          {/*        <div className={styles.subtitle}>*/}
          {/*          Часовой пояс: Московское время (MSK):*/}
          {/*          <br />*/}
          {/*          MSK+3: Московская область*/}
          {/*        </div>*/}
          {/*        <div style={{ width: "100%", marginTop: 19 }}>*/}
          {/*          <Clock form={form} />*/}
          {/*        </div>*/}
          {/*      </>*/}
          {/*    )}*/}
          {/*    {(videoCallTimePassed || showClock) && (*/}
          {/*      <Button className={styles.button_mb} loading={loading}>*/}
          {/*        Сохранить*/}
          {/*      </Button>*/}
          {/*    )}*/}
          {/*  </SmartForm>*/}
          {/*</>*/}
          {/*<>*/}
          {/*  {videoCallTimePassed ||*/}
          {/*    (!showClock && (*/}
          {/*      <>*/}
          {/*        <div className={styles.title}>Запланирован видеозвонок</div>*/}
          {/*        <div className={styles.subtitle}>*/}
          {/*          Дата и время:{" "}*/}
          {/*          <span className={styles.bold}>*/}
          {/*            {moment(plannedVideoCallDate).format("DD.MM.YYYY")}*/}
          {/*          </span>{" "}*/}
          {/*          в{" "}*/}
          {/*          <span className={styles.bold}>*/}
          {/*            {moment(plannedVideoCallDate).format("HH:mm")}*/}
          {/*          </span>{" "}*/}
          {/*          по <span className={styles.bold}>Московскому времени. </span>*/}
          {/*          Проверьте, что у Вас установлен{" "}*/}
          {/*          <span className={styles.underline}>WhatsApp</span> на номере{" "}*/}
          {/*          <span className={styles.bold}>*/}
          {/*            {stringHelpers.phoneNormalize(*/}
          {/*              query?.data?.data?.data?.phone*/}
          {/*            )}*/}
          {/*          </span>*/}
          {/*        </div>*/}
          {/*        <Button onClick={() => setShowClock(true)}>Изменить</Button>*/}
          {/*        <div className={styles.images_block}>*/}
          {/*          <div className={styles.title}>Вам будут нужны:</div>*/}
          {/*          <div className={styles.images}>*/}
          {/*            <div>*/}
          {/*              <div className={styles.img_title}>ПТС</div>*/}
          {/*              <div className={styles.image}>*/}
          {/*                <img*/}
          {/*                  src={*/}
          {/*                    Images.documentPhotoPlaceholders.ptsFrontDocument*/}
          {/*                  }*/}
          {/*                  alt="pts_front"*/}
          {/*                />*/}
          {/*              </div>*/}
          {/*              <div className={styles.img_title}>СТС</div>*/}
          {/*              <div className={styles.image}>*/}
          {/*                <img*/}
          {/*                  src={*/}
          {/*                    Images.documentPhotoPlaceholders.stsOwnerInformation*/}
          {/*                  }*/}
          {/*                  alt="sts_front"*/}
          {/*                />*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*            <div>*/}
          {/*              <div className={styles.img_title}>Паспорт</div>*/}
          {/*              <div className={styles.image}>*/}
          {/*                <img*/}
          {/*                  src={Images.documentPhotoPlaceholders.passportMain}*/}
          {/*                  alt="passport_main"*/}
          {/*                />*/}
          {/*              </div>*/}
          {/*              <div className={styles.img_title}>Автомобиль</div>*/}
          {/*              <div className={styles.image}>*/}
          {/*                <img*/}
          {/*                  src={Images.carPhotoPlaceholders.frontLeftNoCamera}*/}
          {/*                  alt="car"*/}
          {/*                />*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </>*/}
          {/*    ))}*/}
        </>
      </MobilePageContainer>
      {/*<HelpFooter className={styles.footer} variant={"videoCall"} />*/}
    </>
  );
};
