import { authAxios, authAxiosWithoutToken } from "../auth";
import { mainAxios } from "../main";
import { underwriterAxios } from "../underwriter";
import { lsController } from "../../store/auxiliary/localStorage";

export const setTokenToAxios = (token?: string | null) => {
  const transformedToken = token ? "Bearer " + token : "";
  authAxiosWithoutToken.defaults.headers.common["Authorization"] = "";
  authAxios.defaults.headers.common["Authorization"] = transformedToken;
  mainAxios.defaults.headers.common["Authorization"] = transformedToken;
  underwriterAxios.defaults.headers.common["Authorization"] = transformedToken;

  lsController.set("token", token || null);
};
