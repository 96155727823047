import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import styles from "./index.module.css";
import { SmartForm } from "../../../../../../hocs/SmartForm";
import { Title } from "../../../../../../ui/Title";
import { Icons } from "../../../../../../../assets/icons";
import { InputNumber } from "../../../../../../ui/Inputs/InputNumber";
import { Button } from "../../../../../../ui/Button";
import { amountSelectionAnimation } from "./animate";
import { cabinetSagaActions } from "../../../../../../../sagas/cabinet/actions";
import { paymentType } from "../../../../../../../api/main/application/types";
import { processLoading } from "../../../../../../../store/auxiliary/loadingReducer";
import { backgroundAnimation } from "../../../../../../ui/BurgerMenu/animate";
import { Subtitle } from "../../../../../../ui/Subtitle";
import { currentSituationLoanType } from "../../../../../../../types/cabinet/types";
import { showDMYDate } from "../../../../../../../helpers/date";
import { stringHelpers } from "../../../../../../../helpers/string";

type propsType = {
  amount?: number | null;
  id: string;
  isOpenPopup: boolean;
  isOverdue: boolean;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;

  // paymentType пропс решает, какой вариант оплаты будет производиться с этого попапа в сабмите
  paymentType: paymentType;
  currentSituation: currentSituationLoanType;
  setPaymentType: React.Dispatch<React.SetStateAction<paymentType>>;
  setToPay: React.Dispatch<React.SetStateAction<number>>;
  setIsAmountSelectionPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  partialRepaymentError: boolean;
  setPartialRepaymentError: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AmountSelectionPopup: FC<propsType> = ({
  amount,
  id,
  isOverdue,
  isOpenPopup,
  setIsOpenPopup,
  paymentType,
  setPaymentType,
  setToPay,
  setIsAmountSelectionPopupOpen,
  partialRepaymentError,
  setPartialRepaymentError,
  currentSituation,
}) => {
  const dispatch = useDispatch();
  const form = useForm<{ amount: string }>({
    reValidateMode: "onSubmit",
    // resolver: yupResolver(scheme),
  });

  const isLoadingPartialEarlyRepayment = useSelector(
    processLoading.get(cabinetSagaActions.createPartialEarlyRepayment.type)
  );

  const isLoadingLoanPayment = useSelector(
    processLoading.get(cabinetSagaActions.createLoanPayment.type)
  );

  useEffect(() => {
    if (paymentType === "partly_repayment" && !isOverdue) {
      form.setValue("amount", "");
    } else {
      form.setValue("amount", Boolean(amount) ? String(amount) : "");
    }
  }, [paymentType, isOpenPopup]);

  useEffect(() => {
    if (isOverdue) {
      form.setValue("amount", String(amount));
    }
  }, [amount]);

  useEffect(() => {
    if (isOpenPopup) {
      setTimeout(() => {
        form.setFocus("amount", { shouldSelect: true });
      }, 50);
    }
  }, [form.setFocus, isOpenPopup]);

  useEffect(() => {
    return () => {
      // setPaymentType("due_repayment")
    };
  });

  const submit = (data: any) => {
    if (paymentType === "partly_repayment") {
      dispatch(
        cabinetSagaActions.createPartialEarlyRepayment({
          loan_id: id,
          amount: Number(data.amount?.replaceAll(" ", "")),
          type: "partly_repayment",
        })
      );
    } else {
      dispatch(
        cabinetSagaActions.createLoanPayment({
          loan_id: id,
          amount: +data.amount?.replaceAll(" ", ""),
          payment_type: "due_repayment",
        })
      );
    }
    // setPaymentType("due_repayment");
  };

  return (
    <>
      <motion.div
        variants={backgroundAnimation}
        initial={"close"}
        animate={isOpenPopup ? "open" : "close"}
        className={styles.tinted_background}
        onClick={() => {
          setIsOpenPopup(false);
          setPaymentType("due_repayment");
        }}
      ></motion.div>

      <motion.div
        variants={amountSelectionAnimation}
        initial={"close"}
        animate={isOpenPopup ? "open" : "close"}
        className={styles.popup}
      >
        <div className={styles.background} />

        <div className={styles.content}>
          <SmartForm form={form} submit={submit} className={styles.form}>
            {partialRepaymentError && (
              <div className={styles.errorContainer}>
                <Title style={{ marginTop: "36px" }}>
                  Оплатите просроченные платежи
                </Title>
                <Subtitle className={styles.errorSubtitle}>
                  Перед досрочным погашением займа внесите просроченный платеж и
                  начисленные пени
                </Subtitle>
                <div className={styles.text}>
                  Долг на {showDMYDate(currentSituation?.date)}
                </div>
                <div className={styles.amount_overdue}>
                  {stringHelpers.transformMoneyValue(
                    (
                      currentSituation?.overdue_loan_body +
                      currentSituation?.overdue_percents +
                      currentSituation?.loan_body_penalties +
                      currentSituation?.percent_penalties
                    ).toFixed(2)
                  )}
                </div>
                <div className={styles.divider} />
                <ul className={styles.overdue_info}>
                  <li>
                    Просрочено:{" "}
                    <strong>
                      {stringHelpers.transformMoneyValue(
                        (
                          currentSituation?.overdue_percents +
                          currentSituation?.overdue_loan_body
                        ).toFixed(2)
                      )}
                    </strong>{" "}
                    ₽
                  </li>
                  {/*<li>*/}
                  {/*  Проценты на задолженность:{" "}*/}
                  {/*  <strong>*/}
                  {/*    {stringHelpers.transformMoneyValue(*/}
                  {/*      (*/}
                  {/*        currentSituation?.overdue_loan_body +*/}
                  {/*        currentSituation?.percent_penalties*/}
                  {/*      ).toFixed(2)*/}
                  {/*    )}*/}
                  {/*  </strong>{" "}*/}
                  {/*  ₽*/}
                  {/*</li>*/}
                  <li>
                    Пени:{" "}
                    <strong>
                      {stringHelpers.transformMoneyValue(
                        (
                          currentSituation?.loan_body_penalties +
                          currentSituation?.percent_penalties
                        ).toFixed(2)
                      )}
                    </strong>{" "}
                    ₽
                  </li>
                </ul>
                {/*<div className={styles.errorCircle}>*/}
                {/*  <Icons.ui.CrossWarning className={styles.iconCross} />*/}
                {/*</div>*/}
              </div>
            )}

            {!partialRepaymentError && (
              <Title className={styles.title}>Введите сумму</Title>
            )}

            <div className={styles.cross}>
              <Icons.ui.CrossBlackInPurpleCircle
                onClick={() => {
                  setIsOpenPopup(false);
                  setPaymentType("due_repayment");
                }}
              />
            </div>

            {!partialRepaymentError && (
              <InputNumber
                support={<Icons.ui.Ruble data-class={"dim"} />}
                thousandsGroupStyle={"thousand"}
                label={"Сумма"}
                inputMode={"numeric"}
                variant={"large"}
                name={"amount"}
              />
            )}

            {!partialRepaymentError && (
              <Button
                style={{ marginTop: "55px" }}
                loading={isLoadingPartialEarlyRepayment || isLoadingLoanPayment}
              >
                Оплатить
              </Button>
            )}
          </SmartForm>

          {partialRepaymentError && (
            <Button
              className={styles.button}
              onClick={() => {
                setIsOpenPopup(false);
                setPartialRepaymentError(false);
                setIsAmountSelectionPopupOpen(true);
                setToPay(
                  currentSituation.overdue_loan_body +
                    currentSituation.overdue_percents +
                    currentSituation?.percent_penalties +
                    currentSituation?.loan_body_penalties
                );
              }}
            >
              Оплатить
            </Button>
          )}

          <Button
            style={{ marginTop: "22px" }}
            variant={"underline"}
            className={styles.cancel}
            onClick={() => {
              setIsOpenPopup(false);
            }}
          >
            Отмена
          </Button>
        </div>
      </motion.div>
    </>
  );
};
