import { createFC } from "../../../../helpers/createFC";
import { useParams } from "react-router-dom";
import { DownloaderPage } from "../downloader/pd";
import { API } from "../../../../api";

export default createFC(() => {
  const { hash } = useParams<{ hash: string }>();

  return (
    <DownloaderPage
      apiMethod={() =>
        API.main.documents.downloadLoanContract({
          hash,
        })
      }
      name="Договор займа"
    />
  );
});
