import cn from "classnames";
import styles from "./index.module.css";
import React, { AllHTMLAttributes, useEffect } from "react";

type propsType = {
  hugeInputWrapper: boolean;
  length?: number | string;
  className?: string;
  placeholder?: string;
  error?: string;
  timeout?: number;
  onChange(value: string): void;
  timerCallback?(): void;
  sendCodeHandler?(): void;

  tempInput: any;
  fieldsArray: Array<string>;
  arrayOfInputs: any;
  setFieldsArray: (val: any) => void;
  onClickHandler: any;
  pasteHandler: any;
  keyPressHandler: any;
  onChangeHandler: any;
  type?: string[];
} & AllHTMLAttributes<HTMLDivElement>;

export const LicensePlateInput = (props: propsType) => {
  const {
    error,
    className,
    placeholder,
    fieldsArray,
    tempInput,
    arrayOfInputs,
    keyPressHandler,
    onClickHandler,
    pasteHandler,
    onChangeHandler,
    onFocus,
  } = props;

  const firstRegionInput = document.querySelector(
    "#regionPartInput div div input"
  ) as HTMLElement | null;

  useEffect(() => {
    if (fieldsArray[5]) {
      firstRegionInput?.focus();
    }
  }, [fieldsArray]);

  return (
    <div
      className={cn({
        [`${className}`]: className,
        [styles.wrapper]: true,
        [styles.wrapper_error]: error,
      })}
      id={props?.id}
    >
      <div className={styles.CodeField}>
        {fieldsArray.map((_: any, index: any) => {
          const disabled = tempInput != index && !fieldsArray[index];

          const isFilled = fieldsArray[index];

          function handlerRef(el: any) {
            arrayOfInputs.current[index] = el;
          }

          function handlerClick(event: React.MouseEvent<HTMLInputElement>) {
            onClickHandler(event, index);
          }

          return (
            <div
              key={index}
              className={cn(styles.input_wrapper, {
                [styles.input_wrapper_huge]: props.hugeInputWrapper,
                [styles.input_wrapper__error]: error,
                [styles.input_wrapper__filled]: isFilled,
              })}
            >
              <input
                className={cn({
                  [styles.wrapper_error_number]: error,
                })}
                // type="tel"
                type={props.type?.[index]}
                key={index}
                maxLength={1}
                max={1}
                id={`${index}-id`}
                data-cy={`field-${index}`}
                placeholder={placeholder}
                value={fieldsArray[index]}
                // disabled={disabled}
                ref={handlerRef}
                onChange={onChangeHandler}
                onKeyDown={keyPressHandler}
                onClick={handlerClick}
                onPaste={pasteHandler}
                onFocus={onFocus}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
