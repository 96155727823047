import { StateType } from "store/index";

export const userReducerSelectors = {
  getId(state: StateType) {
    return state.userReducer.user_id;
  },
  getPhone(state: StateType) {
    return state.userReducer.phone;
  },
  getToken(state: StateType) {
    return state.userReducer.token;
  },
  getCode(state: StateType) {
    return state.userReducer.code;
  },
  getPassword(state: StateType) {
    return state.userReducer.password;
  },
  getUserRole(state: StateType) {
    return state.userReducer.user_role;
  },
  getIsHasPassword(state: StateType) {
    return state.userReducer.is_has_password;
  },
  getIsHasSignedPdn(state: StateType) {
    return state.userReducer.signed_pdn;
  },
  getIsNewUser(state: StateType) {
    return state.userReducer.isNewUser;
  },
};
