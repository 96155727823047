import { AxiosResponse } from "axios";

export const getFirstErrorFromResponse = (res: AxiosResponse<any>) => {
  try {
    const error = res?.data?.data?.errors[0];
    const errorMessage = error?.message;
    if (!errorMessage) {
      return "";
    }
    const indexOfDots = errorMessage.indexOf(":");
    return errorMessage.substr(indexOfDots + 1);
  } catch (e) {
    console.log(e);
    return "";
  }
};
