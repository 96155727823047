import { takeEvery } from "redux-saga/effects";
import { authSagaActions } from "./actions";
import { getUserSaga } from "./methods/getUser";
import { createUserSaga } from "./methods/createUser";
import { getCarSaga } from "./methods/getCar";
import { getCodeSaga } from "./methods/getCode";
import { verifyCodeSaga } from "./methods/verifyCode";
import { createPasswordSaga } from "./methods/createPassword";
import { getTokenSaga } from "./methods/getToken";
import { refreshTokenSaga } from "./methods/RefreshToken";

export function* authWatcher() {
  yield takeEvery(authSagaActions.getUser, getUserSaga);
  yield takeEvery(authSagaActions.createUser, createUserSaga);
  yield takeEvery(authSagaActions.getCar, getCarSaga);
  yield takeEvery(authSagaActions.getCode, getCodeSaga);
  yield takeEvery(authSagaActions.verifyCode, verifyCodeSaga);
  yield takeEvery(authSagaActions.createPassword, createPasswordSaga);
  yield takeEvery(authSagaActions.getToken, getTokenSaga);
  yield takeEvery(authSagaActions.refreshToken, refreshTokenSaga);
}
