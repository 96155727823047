import { FC } from "react";
import styles from "./index.module.css";
import { ErrorPage } from "../../../../../ui/ErrorPage";
import { errorApplicationType } from "../../../../../../store/reducers/application/types";
import { useSelector } from "react-redux";
import { applicationReducerSelectors } from "../../../../../../store/reducers/application";
import { useGetInfoFromApplication } from "../../../../../../hooks/api/useGetInfoFromApplication";
import { sendMetrics } from "../../../../../../metrics";
import { FillingAssistance } from "../../../../../ui/FillingAssistance";
import { MobilePageContainer } from "../../../../../ui/Containers/MobilePageContainer";

export const ErrorFinal: FC = () => {
  const error: errorApplicationType = useSelector(
    applicationReducerSelectors.getError
  );

  const { data } = useGetInfoFromApplication();

  if (
    data?.real_refusal_cause_comment === "Регион отсутствует в белом списке"
  ) {
    sendMetrics("adress_reject_anketa");
  }

  if (data?.real_refusal_cause_comment === "Регион в черном списке") {
    sendMetrics("auto_reject_foto_documents");
  }

  return (
    <>
      <MobilePageContainer>
        <ErrorPage
          title={"Извините"}
          subtitle={"Мы не можем выдать вам займ"}
          additionalTitle={"Спасибо, что обратились к нам!"}
        />
      </MobilePageContainer>
      {/*<FillingAssistance className={styles.container_filling} />*/}
    </>
  );
};
