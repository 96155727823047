import React from "react";
import styles from "./index.module.css";
import cn from "classnames";

export type propsType = {
  length?: number | string;
  className?: string;
  placeholder?: string;
  error?: string;
  timeout?: number;
  onChange(value: string): void;
  onFocus(): void;
  timerCallback?(): void;
  sendCodeHandler?(): void;
  tempInput: any;
  fieldsArray: Array<string>;
  arrayOfInputs: any;
  setFieldsArray: (val: any) => void;
  onClickHandler: any;
  pasteHandler: any;
  keyPressHandler: any;
  onChangeHandler: any;
  required?: boolean;
};

export const VinField: React.FC<propsType> = (props) => {
  const {
    error,
    className,
    placeholder,
    timeout = 60,
    fieldsArray,
    tempInput,
    arrayOfInputs,
    keyPressHandler,
    onClickHandler,
    pasteHandler,
    onChangeHandler,
    onFocus,
    required,
  } = props;

  /* state */

  return (
    <div
      className={cn({
        [`${className}`]: className,
        [styles.wrapper]: true,
        [styles.wrapper_error]: error,
      })}
    >
      <div className={styles.CodeField}>
        {fieldsArray.map((_, index) => {
          const disabled =
            tempInput != index && !fieldsArray[index] && tempInput != 0;

          const isFilled = fieldsArray[index];

          function handlerRef(el: any) {
            arrayOfInputs.current[index] = el;
          }

          function handlerClick(event: React.MouseEvent<HTMLInputElement>) {
            onClickHandler(event, index);
          }

          return (
            <div
              key={index}
              className={cn(styles.input_wrapper, {
                // [styles.wrapper_input__error]: disabled,
                [styles.input_wrapper__filled]: isFilled,
              })}
            >
              <input
                className={cn({
                  [styles.wrapper_error_number]: error,
                })}
                // type="text"
                required={required}
                key={index}
                maxLength={1}
                max={1}
                id={`${index}-id`}
                data-cy={`field-${index}`}
                placeholder={placeholder}
                value={fieldsArray[index]}
                // disabled={disabled}
                ref={handlerRef}
                onChange={onChangeHandler}
                onKeyDown={keyPressHandler}
                onClick={handlerClick}
                onPaste={pasteHandler}
                onFocus={onFocus}
              />
            </div>
          );
        })}
        {/*{error && <div className={styles.error}>{error}</div>}*/}
      </div>
      {error && <div className={styles.red_line}></div>}
    </div>
  );
};
