import React, { useState } from "react";

export const useChangeFocus = (
  fields: (React.RefObject<HTMLInputElement> | { current: HTMLInputElement | null })[],
  isAllFieldsFilled = false
) => {
  const [currentField, setCurrentField] = useState("");

  const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter" && !isAllFieldsFilled) {
      event.preventDefault();

      fields[fields.findIndex((item) => (
          item.current && "name" in item.current && item.current?.name === currentField
        )
      ) + 1].current?.focus();
    }
  };

  return { keyPressHandler, setCurrentField };
};
