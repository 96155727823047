import styles from "./index.module.css";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { createFC } from "../../../../../helpers/createFC";
import { API } from "../../../../../api";
import { loanReducer, loanReducerSelectors } from "../../../../../store/reducers/loan";
import { processLoading, setProcessLoading } from "../../../../../store/auxiliary/loadingReducer";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { SmartForm } from "../../../../hocs/SmartForm";
import { Button } from "../../../../ui/Button";
import { Loader } from "../../../../ui/Loader";
import { Checkbox } from "../../../../ui/Checkbox";
import { NotificationItem } from "./NotificationItem";
import { Icons } from "../../../../../assets/icons";
import { routes } from "../../../../../routes";

type propsType = {};

export const Notifications = createFC((props: propsType) => {
  const [isCheckedAllNew, setIsCheckedAllNew] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const form = useForm();

  const notifications = useSelector(
    loanReducerSelectors.getNewNotifications
  );

  const loading = useSelector(
    processLoading.get("notifications")
  );

  const loadingAccept = useSelector(
    processLoading.get("notifications:accept")
  );

  const checkedNotifications = notifications?.items.filter(item => item.checked);

  const submit = () => {
    dispatch(setProcessLoading("notifications:accept", true));
    API.main.application.acceptNotification({
      notification_ids: notifications?.items.filter(item => item.checked).map(item => item.id)
    }).then(res =>{
      if (res.status === 201) {
        API.main.application.getNotifications().then(res => {
          dispatch(loanReducer.actions.setNotifications({
            notifications: {
              items_count: res.data.data.items_count,
              items: res.data.data.items.sort(
                (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).map(item => ({
                  ...item,
                  checked: false
                })
              )
            }
          }));
        });
        dispatch(setProcessLoading("notifications:accept", false));
      }
    });
  };

  if (loading) {
    return (
      <MobilePageContainer className={styles.bg_white}>
        <Loader variant={"large"} color={"purple"} />
      </MobilePageContainer>
    );
  }

  return (
    <SmartForm submit={submit} form={form}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Уведомления</div>
          <div className={styles.checkbox}>
            {!!(notifications?.items_count && notifications.items_count > 0) && (
              <>
                <Checkbox
                  name={"notif-all"}
                  className={styles.mr_check_box}
                  checked={isCheckedAllNew}
                  onChange={() => setIsCheckedAllNew((prev) => !prev)}
                  blockValue={true}
                />
                <span>Выбрать все</span>
              </>
            )}
          </div>
        </div>
        <div className={styles.notifications}>
          {notifications?.items.map(item => (
            <NotificationItem
              key={item.id}
              data={item}
              isCheckedAllNew={isCheckedAllNew}
              setIsCheckedAllNew={setIsCheckedAllNew}
              className={styles.notification_item}
            />
          ))}
        </div>
        {notifications?.items_count === 0 && (
          <>
            <div className={styles.icon}>
              <Icons.ui.SquareWithSlash />
            </div>
            <div className={styles.text}>
              У вас нет новых уведомлений
            </div>
          </>
        )}
        {!!(notifications?.items_count && notifications.items_count > 0) && (
          <div className={styles.button}>
            <Button
              loading={loadingAccept}
              disabled={!checkedNotifications?.length}
            >
              Ознакомлен
            </Button>
          </div>
        )}
        {notifications?.items_count === 0 && (
          <Button
            type={"button"}
            onClick={() => history.push(routes.cabinet.my_loan)}
          >
            На главную
          </Button>
        )}
      </div>
    </SmartForm>
  )
});
