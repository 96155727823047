import { createFC } from "helpers/createFC";
import React, { ReactFragment } from "react";
import { Icons } from "assets/icons";
import { Button } from "components/ui/Button";
import { useHistory } from "react-router-dom";
import styles from "./index.module.css";
import { constantStrings } from "../../../../../../helpers/init/constantStrings";
import { routes } from "../../../../../../routes";

type propsType = {
  title: string | ReactFragment;
  subtitle: string | ReactFragment;
};

export const CabinetErrorPage = createFC(() => {

  const history = useHistory();

  return (
    <div className={styles.container}>
      <div
        className={styles.cross}
        onClick={() => history.push(routes.cabinet.my_loan)}
      >
        <Icons.ui.Cross  />
      </div>
      <div className={styles.title}>Ошибка</div>
      <div className={styles.subtitle}>К сожалению, что-то пошло не так</div>

      <div className={styles.loader}>
        <Icons.ui.CrossWarning className={styles.icon_cross} />
      </div>


      <div className={styles.footer_subtitle}>
        Нажмите кнопку "Связаться с техподдержкой" <br />
        и наш консультант расскажет вам <br />
        о дальнейших действиях.
      </div>

      <Button className={styles.contact_button}>
        Связаться с техподдержкой
      </Button>

      <div className={styles.footer_links}>
        <a href={`https://wa.me/${constantStrings.whatsappNumberForSupport}`} target={"_blank"}>
          <Icons.logos.Whatsapp />
        </a>
        <a href={constantStrings.telegramLinkForSupport} target={"_blank"}>
          <Icons.logos.Telegram />
        </a>
        <a href={`tel:${constantStrings.phoneNumberForSupport}`}>
          <Icons.logos.Phone />
        </a>
      </div>
    </div>
  );
});
