import { mainAxios, staticAxios } from "../index";
import {
  attachingCarPhotoToTheApplicationType,
  attachingDocumentToTheApplicationType,
  downloadFileDocument,
  downloadPdf,
  downloadPdfWithIdType,
  getCarPhotoFull,
  getCarPhotoPreview,
  getDocumentFull,
  getDocumentPreview,
  getInfoFromDocumentType,
  uploadDocumentMyLoanType,
  uploadDocumentType,
} from "./types";
import { AxiosResponse } from "axios";

class DocumentsClass {
  user_id: number | null = null;

  constructor() {}

  getDocumentPreview = ({ applicationId, photo_type }: getDocumentPreview) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${photo_type}/photo:download-preview`
    );

  getDocumentPreviewById = ({
    applicationId,
    documentId,
  }: {
    applicationId: number | null | undefined;
    documentId: string;
  }) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${documentId}/photo:download-preview-by-id`
    );

  getDocumentFull = ({ applicationId, photo_type }: getDocumentFull) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${photo_type}/photo:download-full`
    );

  getCarPhotoPreview = ({ applicationId, photo_type }: getCarPhotoPreview) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/car-photo/${photo_type}/photo:download-preview`
    );

  getCarPhotoFull = ({ applicationId, photo_type }: getCarPhotoFull) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/car-photo/${photo_type}/photo:download-full`
    );

  getInfoFromDocument = ({
    applicationId,
    photo_type,
  }: getInfoFromDocumentType): Promise<AxiosResponse<any>> =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${photo_type}`
    );

  downloadPdfWithId = ({ applicationId, hash }: downloadPdfWithIdType) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/applying-agreement/${hash}/photo:download`
    );

  downloadFileDocument = ({
    applicationId,
    documentType,
  }: downloadFileDocument) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/${documentType}/file:download`
    );

  downloadPdf = ({ hash }: downloadPdf) => mainAxios.get(`/doc/${hash}`);

  downloadPdfRegistration = () =>
    staticAxios.get(`/Согласие_на_обработку_ПД,_КИ_обезличенное.pdf`);

  downloadPdfRegistrationAsp = () =>
    staticAxios.get(`/Соглашение_об_использовании_АСП.pdf`);

  downloadLoanContract = ({ hash }: downloadPdf) =>
    mainAxios.get(`/doc/${hash}`);

  // нужен для сохранения документа на серве, возвращает id, в заявки закидываем именно id
  uploadDocument = (body: uploadDocumentType): Promise<AxiosResponse<any>> =>
    mainAxios.post(`/user/${this.user_id}/document`, body);

  uploadDocumentMyLoan = ({
    document_type,
    loan_id,
    ...body
  }: uploadDocumentMyLoanType) =>
    mainAxios.post(
      `/user/${this.user_id}/loan/${loan_id}/document/${document_type}`,
      body
    );

  attachingRepledgeDocumentToTheApplication = ({
    applicationId,
    photo_type,
    ...body
  }: attachingDocumentToTheApplicationType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${photo_type}`,
      body
    );

  attachingDocumentToTheApplication = ({
    applicationId,
    photo_type,
    ...body
  }: attachingDocumentToTheApplicationType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${photo_type}`,
      body
    );

  attachingCarPhotoToTheApplication = ({
    applicationId,
    photo_type,
    ...body
  }: attachingCarPhotoToTheApplicationType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/car-photo/${photo_type}`,
      body
    );
}

export const documentsApi = new DocumentsClass();
