import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { useGetSchedule } from "../../../../../hooks/api/useGetSchedule";
import { Loader } from "../../../../ui/Loader";
import { Title } from "../../../../ui/Title";
import { Subtitle } from "../../../../ui/Subtitle";
import { useForm, useWatch } from "react-hook-form";
import { SmartForm } from "../../../../hocs/SmartForm";
import { RangeWithTitle } from "../../../../ui/RangeWithTitle";
import { monthRangeData } from "../NewLoan";
import { Button } from "../../../../ui/Button";
import {
  offerType,
  useGetInfoFromApplication,
} from "../../../../../hooks/api/useGetInfoFromApplication";
import { useDispatch, useSelector } from "react-redux";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { PaymentSchedule } from "../../../../ui/PaymentSchedule";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import {
  declinationFromTheNumber,
  stringHelpers,
} from "../../../../../helpers/string";
import { useDebounce } from "../../../../../hooks/logical/useDebounce";
import { FillingAssistance } from "../../../../ui/FillingAssistance";
import { BannerGreen } from "../../../../ui/BannerGreen";

export const getMaxSumFromOffers = (array: Array<offerType>) => {
  if (!array) return 0;
  return Math.max(...array.map((offer) => offer.amount));
};
export const getMaxTermFromOffers = (array: Array<offerType>) => {
  if (!array) return 0;
  return Math.max(...array.map((offer) => Number(offer.loan_term)));
};

export const LoanDecision: FC = () => {
  const dispatch = useDispatch();

  const loading = useSelector(
    processLoading.get(applicationSagaActions.sendingLoanTerms.type)
  );

  const [isMatch, setIsMatch] = useState(true);
  const form = useForm<any>({
    reValidateMode: "onSubmit",
    // resolver: yupResolver(scheme),
  });

  const submit = (data: any) => {
    // console.log("DATA", data);
    dispatch(
      applicationSagaActions.sendingLoanTerms({
        amount: data?.loan_data?.amount,
        loan_term: String(data?.loan_data?.loan_term),
      })
    );
  };

  const [amount, loan_term, schedule_amount, schedule_loan_term] = useWatch({
    control: form.control,
    name: [
      "loan_data.amount",
      "loan_data.loan_term",
      "schedule_amount",
      "schedule_loan_term",
    ],
  });

  // дебаунс аргументы, чтобы не вызывать useGetSchedule на каждое движение ползунков
  const debouncedValue = useDebounce(
    { schedule_amount, schedule_loan_term },
    1000
  );

  // Эффект, который вызывает useGetSchedule при двигании ползунков
  useEffect(() => {
    form.setValue("schedule_amount", amount);
    form.setValue("schedule_loan_term", loan_term);
  }, [amount, loan_term, schedule_amount, schedule_loan_term]);

  const { data, loan_data, isLoading, offers } = useGetInfoFromApplication();

  const { schedule, isLoading: scheduleLoading } =
    useGetSchedule(debouncedValue);

  const maxTermFromOffers = getMaxTermFromOffers(offers);
  const maxSumFromOffers = getMaxSumFromOffers(offers);

  const [maxSum, setMaxSum] = useState(offers && maxSumFromOffers);

  /*
      Эффект и стейт сверху сопоставляет макс.суммы и сроки кредитования
      Эффект и [maxSum, setMaxSum] нужны, чтобы обрезать сумму до максимально доступной при уменьшении срока
      те уменьшаем срок - ограничиваем ползунок суммы максимальным для этого срока
  */

  const ObjectWithMaxAmount = offers?.reduce((acc, curr) =>
    acc?.amount > curr?.amount ? acc : curr
  );
  const arrTerms = offers?.map((item) => {
    return item?.loan_term + " месяцев";
  });

  const arrTermsNumber = offers?.map((item) => {
    return item?.loan_term;
  });

  const maxTerms = offers
    ?.map((item) => {
      return item?.loan_term;
    })
    .reduce((acc, rec) => {
      return acc > rec ? acc : rec;
    });

  const minTerms = offers
    ?.map((item) => {
      return item?.loan_term;
    })
    .reduce((acc, rec) => {
      return acc < rec ? acc : rec;
    });

  const amountFrom48Term = offers
    ?.filter((item) => {
      return item.loan_term === "48";
    })
    .reduce((acc, rec) => {
      return rec?.amount;
    }, 0);
  const amountFrom36Term = offers
    ?.filter((item) => {
      return item.loan_term === "36";
    })
    .reduce((acc, rec) => {
      return rec?.amount;
    }, 0);
  const amountFrom24Term = offers
    ?.filter((item) => {
      return item.loan_term === "24";
    })
    .reduce((acc, rec) => {
      return rec?.amount;
    }, 0);

  useEffect(() => {
    if (!offers) return;

    if (loan_term === Number("48")) {
      setMaxSum(amountFrom48Term);

      if (amount > maxSum) {
        form.setValue("loan_data.amount", maxSum);
      }
    }

    if (loan_term === Number("36")) {
      setMaxSum(amountFrom36Term);

      if (amount > maxSum) {
        form.setValue("loan_data.amount", maxSum);
      }
    }

    if (loan_term === Number("24")) {
      setMaxSum(amountFrom24Term);
      if (amount > maxSum) {
        form.setValue("loan_data.amount", maxSum);
      }
    }
  }, [loan_term, offers, maxSum]); // maxSum нужен, чтобы сетать ползунок при первом монтировании

  useEffect(() => {
    // * тут сечу нужные значения из заявки в Range
    if (loan_data) {
      form.setValue(
        "loan_data.loan_term",
        Number(ObjectWithMaxAmount.loan_term)
      );
      form.setValue("schedule_loan_term", maxTermFromOffers);
      form.setValue("loan_data.amount", Number(ObjectWithMaxAmount.amount));
      form.setValue("schedule_amount", maxSumFromOffers);
    }
  }, [loan_data, offers]);

  /*
      GET  https://api-stage.creddy.ru/api/v1/schedule?amount=80000&loan_term=24
      Date - дата платежа, amount - сумма оплаты + %, percents - сумма процентов, loan_body - сумма займа
   */

  return (
    <MobilePageContainer>
      {isLoading &&
      !offers &&
      !maxTermFromOffers &&
      !maxSumFromOffers &&
      !maxSum ? (
        <Loader variant={"large"} color={"purple"} className={styles.loader} />
      ) : (
        <div className={styles.container}>
          <BannerGreen
            maxSumFromOffers={maxSumFromOffers}
            variant={"greeting"}
            endData={data?.scoring_end_date}
          />
          <h1 className={styles.title}>Выберите удобные Вам условия:</h1>

          {/*application.loan_data*/}
          {/*<Subtitle className={styles.subtitle}>*/}
          {/*  Вам одобрен займ на сумму{" "}*/}
          {/*  {stringHelpers.transformMoneyValue(maxSumFromOffers)} ₽, <br />*/}
          {/*  сроком до {maxTermFromOffers} мес.*/}
          {/*</Subtitle>*/}

          <SmartForm form={form} submit={submit} className={styles.form}>
            <div className={styles.ignore_padding}>
              {maxSum && (
                <RangeWithTitle
                  min={80000}
                  max={maxSum === 80000 ? maxSum + 1 : maxSum}
                  title={"Сумма займа, ₽"}
                  name={"loan_data.amount"}
                  containerClass={styles.loan_amount}
                  step={5000}
                />
              )}

              {offers?.length > 1 && (
                <RangeWithTitle
                  // variant={"square"}
                  marks
                  min={Number(minTerms)}
                  max={Number(maxTerms)}
                  step={
                    arrTerms.length <= 2 &&
                    Number(arrTermsNumber[0]) + Number(arrTermsNumber[1]) === 72
                      ? 24
                      : 12
                  }
                  hasInput={false}
                  title={"Срок займа"}
                  rangeData={arrTerms}
                  name={"loan_data.loan_term"}
                  containerClass={styles.loan_amount}
                />
              )}

              {!scheduleLoading && isMatch ? (
                <>
                  <Button loading={loading} className={styles.button}>
                    Подтвердить
                  </Button>
                  <PaymentSchedule schedule={schedule} />
                </>
              ) : (
                <Loader className={styles.loader} variant={"large"} />
              )}
            </div>
            {/*<FillingAssistance*/}
            {/*  className={styles.filling_assistance_container}*/}
            {/*/>*/}
          </SmartForm>
        </div>
      )}
    </MobilePageContainer>
  );
};
