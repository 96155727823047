import React, { FC } from "react";
import styles from "./index.module.css";
import { LoadingPage } from "../../LoadingPage";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../../../../store/reducers/application";
import { useQuery } from "react-query";
import { API } from "../../../../../../api";
import { routes } from "../../../../../../routes";
import { applicationType } from "../../../../../../types/application/types";
import { lsController } from "../../../../../../store/auxiliary/localStorage";
import { sendMetrics } from "../../../../../../metrics";
import { FillingAssistance } from "../../../../../ui/FillingAssistance";

type propsType = {};
export const SigningProcess: FC<propsType> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const application_id = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const query = useQuery(
    "SigningProcess",
    () =>
      API.main.application.getApplication({
        applicationId: application_id || lsController.get("application_id"),
      }),
    {
      refetchInterval: 10000,
      onSuccess: (data: any) => {
        const application: applicationType = data?.data?.data;
        const error_type: applicationType = data?.data?.data?.error_type;
        dispatch(
          applicationReducer.actions.setApplicationType({
            application_type: application?.application_type,
          })
        );

        if (application?.application_type === "issued_final") {
          history.push(routes.application.statuses.issued_final);
        }

        if (application?.application_type === "signed_issuing_sbp_error") {
          lsController.set("bank_name", application?.bank_card?.bank);
          history.push(
            `/application/sbp_check_error/${
              error_type ? error_type : "sbp_error"
            }`
          );
        }

        if (application?.application_type === "signed_issuing_process") {
          history.push(routes.application.statuses.signed_issuing_process);
        } else if (application?.application_type === "error_final") {
          sendMetrics("common-client_otkaz-po-zayavke_common-app", "otkaz-po-zayavke");
          dispatch(
            applicationReducer.actions.setError({
              error_message: application?.error_message,
            })
          );
          history.push(routes.application.statuses.error_final);
        }
      },
    }
  );

  return (
    <div className={styles.container}>
      <LoadingPage
        title={"Ожидайте подписания"}
        subtitle={
          <>
            Как только все будет готово вам придет <br />
            СМС - сообщение
          </>
        }
      />
      <FillingAssistance className={styles.footer} />
    </div>
  );
};
