import React from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { Icons } from "../../../assets/icons";
import { Checkbox } from "../Checkbox";

export interface CheckboxData {
  name: string;
  text: string;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
  isDownloading?: boolean;
}

export const CheckboxWithPdf = (checkboxData: CheckboxData) => (
  <div className={styles.container}>
    <Checkbox
      name={checkboxData.name}
      className={styles.mr_check_box}
      onChange={() => checkboxData.setIsChecked((prev) => !prev)}
      checked={checkboxData.isChecked}
      blockValue={true}
      disabled={checkboxData.disabled}
    />
    <a
      target={"_blank"}
      href={checkboxData.href}
      onClick={checkboxData.onClick}
      className={styles.icon_and_text}
    >
      <div
        className={cn({
          [styles.checked]: checkboxData.isChecked,
          [styles.disabled]: checkboxData.disabled,
        })}
      >
        {checkboxData.isDownloading
          ? <Icons.ui.PdfLoading className={styles.pdf_icon} />
          : <Icons.ui.PdfLogo />
        }
      </div>
      <div className={cn(styles.text, {
        [styles.text_black]: checkboxData.isChecked,
        [styles.disabled]: checkboxData.disabled,
        [styles.downloading]: checkboxData.isDownloading,
      })}>
        {checkboxData.text}
      </div>
    </a>
  </div>
);
