import React, { FC, useEffect } from "react";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { authSagaActions } from "../../../../../sagas/auth/actions";
import { Button } from "../../../../ui/Button";
import { Icons } from "assets/icons";
import { useHistory } from "react-router-dom";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { carBodyType } from "../../../../../store/reducers/application/types";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { routes } from "../../../../../routes";
import { FillingAssistance } from "../../../../ui/FillingAssistance";

export const WrongVehicle: FC = () => {
  const history = useHistory();

  const carBody: carBodyType = useSelector(
    applicationReducerSelectors.getCarBody
  );

  const loading = useSelector(
    processLoading.get(authSagaActions.getToken.type)
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>Внимание!</div>
      <div className={styles.subtitle}>
        Автомобиль не подходит в качестве залогового
      </div>
      <div className={styles.icon}>
        <Icons.ui.StatusError />
      </div>
      <div className={styles.car_block}>
        <div>
          <div className={styles.car_block_title}>
            {carBody?.name || "Авто не определено"}
          </div>
          <div className={styles.car_block_vin}>VIN: {carBody?.vin || "-"}</div>
        </div>
        {carBody?.logotype_url && (
          <img
            className={styles.img}
            src={carBody?.logotype_url}
            alt="car_logo"
          />
        )}
      </div>
      <Button
        onClick={() => history.push(routes.auth.vehicle_valuation)}
        loading={loading}
        className={styles.button}
      >
        Другой автомобиль
      </Button>
      <div className={styles.footer}>{/*<FillingAssistance />*/}</div>
    </div>
  );
};
