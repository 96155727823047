import { AllHTMLAttributes, FC } from "react";
import cn from "classnames";
import styles from "./index.module.css";

type propsType = {
  infoKey?: string;
  infoValue?: string | number;
  warning?: boolean;
} & AllHTMLAttributes<HTMLDivElement>;

export const InfoBlock: FC<propsType> = ({
  infoKey,
  infoValue,
  warning = false,
  className,
  ...jsxAttr
}) => {
  return (
    <div {...jsxAttr} className={cn(styles.container, className)}>
      {Boolean(infoKey) && <p className={styles.key}>{infoKey}:</p>}
      <p
        className={cn(styles.value, {
          [styles.warning]: warning,
        })}
      >
        {infoValue || "—"}
      </p>
    </div>
  );
};
