import React, { FC, HTMLAttributes } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { Icons } from "../../../../../../assets/icons";
import { useSelector } from "react-redux";
import { StateType } from "../../../../../../store";
import {
  bankCardType,
  passportType,
} from "../../../../../../types/application/types";

type propsType = {
  passport?: passportType;
  bank_card?: bankCardType;
} & HTMLAttributes<HTMLDivElement>;

export const SBPDataBlock: FC<propsType> = ({
  className,
  passport,
  bank_card,
}) => {
  const phone = useSelector((state: StateType) => state.userReducer.phone);

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.row}>
        <Icons.ui.PhoneBlack className={styles.icon} />
        <div>
          <div className={styles.label}>Номер телефона получателя</div>
          <div className={styles.value}>{phone}</div>
        </div>
      </div>

      {passport && (
        <div className={styles.row}>
          <Icons.ui.Person className={styles.icon_person} />
          <div>
            <div className={styles.label}>ФИО получателя</div>
            <div className={styles.value}>
              {passport.first_name} {passport.middle_name}{" "}
              {passport.last_name ? `${passport.last_name[0]}.` : ""}
            </div>
          </div>
        </div>
      )}

      {bank_card && (
        <div className={styles.row}>
          <Icons.ui.Bank className={styles.icon} />
          <div>
            <div className={styles.label}>Банк получателя</div>
            <div className={styles.value}>{bank_card.bank}</div>
          </div>
        </div>
      )}
    </div>
  );
};
