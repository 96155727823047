import NumberFormat, { NumberFormatProps } from "react-number-format";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import mergeRefs from "react-merge-refs";
import get from "lodash/get";
import cn from "classnames";
import styles from "../index.module.css";
import styles2 from "./index.module.css";
import { TextInputPropsType } from "../types";
import { ConnectedForm } from "../../ConnectedForm";
import { InputWrapper } from "../InputWrapper";
import { useToggle } from "../../../../hooks/logical/useToggle";
import { useEffect, useRef } from "react";
import { Icons } from "../../../../assets/icons";
import { useBlurred } from "../../../../hooks/logical/useBlurred";
import { stringHelpers } from "../../../../helpers/string";
import { RangeType } from "../../Range/types";
import { Loader } from "../../Loader";

export const InputEditableNumber = (
  props: {
    amountError?: boolean;
    conditionForLockRangeAmount?: boolean;
    conditionForLockRangeLoanTerms?: boolean;
    period?: boolean;
    suffix?: string;
    thousandsGroupStyle?: "thousand";
    maxLength?: number;
    noNumericString?: boolean;
    decimalScale?: number;
    onIconClick?: () => any;
    loading?: boolean;
    primaryColor?: "blue";
  } & NumberFormatProps<any> & {
      min: number;
      max: number;
    }
) => {
  const {
    amountError,
    inputElementRef,
    support,
    noNumericString,
    thousandsGroupStyle = "thousand",
    onIconClick,
    loading,
    max,
    min,
    conditionForLockRangeAmount,
    conditionForLockRangeLoanTerms,
    primaryColor,
    ...jsxAttrs
  } = props;
  const { setValue, control } = useFormContext();

  const [isEditing, toggleEditing, setIsEditing] = useToggle();
  const ref = useRef<any>();
  const ref1 = useRef<any>();

  const inputValue = useWatch({
    control,
    name: jsxAttrs.name,
  });

  // useBlurred(ref, setIsEditing, isEditing);

  const handlerBlur = (event: FocusEvent) => {
    if (
      ref.current &&
      ref1.current &&
      !ref.current.contains(event.target) &&
      !ref1.current.contains(event.target) &&
      isEditing
    ) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (isEditing) {
      ref.current.focus();
    } else {
      if (inputValue < min || inputValue > max) {
        setValue(jsxAttrs.name, min);
      }
    }

    document.addEventListener("mousedown", handlerBlur);

    return () => document.removeEventListener("mousedown", handlerBlur);
  }, [isEditing]);

  return (
    <ConnectedForm>
      {({ control, formState: { errors } }) => (
        <>
          <InputWrapper
            variantWrapper={"slim"}
            {...props}
            error={props.error || get(errors as any, `${props.name}.message`)}
            support={
              <>
                {props.period ? (
                  <div className={styles.month}>мес.</div>
                ) : (
                  <div className={styles.month}> ₽</div>
                )}
              </>
            }
          >
            <Controller
              render={({ field }) => (
                <NumberFormat
                  {...jsxAttrs}
                  className={cn(styles2.Input, {
                    [styles.Input_editing]: isEditing && !amountError,
                    [styles.Input_error]: props.error,
                    [styles2.input_error]: amountError,
                    [styles.props]: props.error,
                  })}
                  getInputRef={mergeRefs([field.ref, ref])}
                  {...field}
                  onChange={(e: any) => {
                    field.onChange(
                      Number(stringHelpers.clearString(e.target.value)) || null
                    );
                  }}
                  thousandsGroupStyle={thousandsGroupStyle}
                  allowNegative={false}
                  placeholder={"0"}
                  mask={""}
                  disabled={!isEditing}
                  suffix={props.suffix}
                  format={props.format}
                  decimalScale={
                    props.decimalScale == undefined ? 2 : props.decimalScale
                  }
                  onPaste={props.onPaste}
                  thousandSeparator={thousandsGroupStyle ? " " : undefined}
                  isNumericString={!props.noNumericString}
                  maxLength={props.maxLength || 11}
                />
              )}
              defaultValue={props.defaultValue}
              name={props.name}
              control={control}
            />
          </InputWrapper>
          {isEditing ? (
            loading ? (
              <Icons.ui.LoaderGreyBg className={styles.loader} />
            ) : (
              <Icons.ui.BlackCrossGreyBg
                className={styles.setting_loader}
                // onClick={onIconClick && (() => onIconClick())}
                onClick={toggleEditing}
                ref={ref1}
              />
            )
          ) : (
            <div className={cn({
              [styles.icon_settings_blue]: primaryColor === "blue",
            })}>
              <Icons.ui.Settings
                className={cn(styles.setting_icon, {
                  [styles.setting_icon_disabled]:
                    conditionForLockRangeAmount ||
                    conditionForLockRangeLoanTerms,
                })}
                onClick={
                  conditionForLockRangeAmount || conditionForLockRangeLoanTerms
                    ? () => {}
                    : toggleEditing
                }
              />
            </div>
          )}
        </>
      )}
    </ConnectedForm>
  );
};
