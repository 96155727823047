import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../../util/helpers/processStateController";
import { call, put, select } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { API } from "../../../api";
import { applicationReducerSelectors } from "../../../store/reducers/application";
import { sagaApiType } from "../../util/types";
import { applicationType } from "../../../types/application/types";
import { applicationSagaActions } from "../actions";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";

export function* createCardSaga({
  payload,
  type: actionType,
}: PayloadAction<{
  repeat?: boolean;
  redirectTo: string;
}>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const applicationId: number = yield select(
      applicationReducerSelectors.getApplicationId
    );

    // * не делаем запрос, если внутри рекурсии уже
    if (!payload.repeat) {
      yield call(API.main.user_sale.createCard, {
        applicationId,
      });
    }

    const response: sagaApiType = yield call(
      API.main.application.getApplication,
      {
        applicationId,
      }
    );

    const application: applicationType = response?.data?.data;
    console.log("application", application);

    // * статус должен поменяться на issue_method_filling сразу после запроса выше
    // * но на всякий сделал рефетч, если не успеет поменяться
    if (
      // Временно тут будет sbp_define_process. позже вернуть как было issue_method_filling.
      // application?.application_type === "issue_method_filling"
      application?.application_type === "survey_filling"
    ) {
      // Отсюда редирект либо на issue_method_filling, либо на payment_system_response
      yield redirect(payload.redirectTo);
    } else {
      yield put(
        applicationSagaActions.createCard({
          repeat: true,
          redirectTo: payload.redirectTo,
        })
      );
    }
  } catch (e) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
    });
  } finally {
    yield process.stop();
  }
}
