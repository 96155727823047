import styles from "./index.module.css";
import React from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { Icons } from "../../../../../../assets/icons";
import { applicationReducerSelectors } from "../../../../../../store/reducers/application";
import { createFC } from "../../../../../../helpers/createFC";
import { useCountdown } from "../../../../../../helpers/date/useCountdown";
import { Loader } from "../../../../../ui/Loader";
import { MobilePageContainer } from "../../../../../ui/Containers/MobilePageContainer";

export const WaitingPage = createFC(() => {
  const applicationType = useSelector(
    applicationReducerSelectors.getApplicationType
  );

  const { timeLeft } = useCountdown(30);

  return (
    <MobilePageContainer className={styles.waiting_page}>
      <div className={styles.waiting_page_title}>Не закрывайте эту страницу</div>
      <div className={styles.waiting_page_subtitle}>Мы проверяем Ваши данные</div>
      <div className={styles.waiting_page_timer}>
        {typeof timeLeft !== "undefined" && (
          <div>
            <div className={styles.waiting_page_note}>
              {timeLeft === 0 && "Подождите\nеще немного..."}
            </div>
            {timeLeft > 0 && timeLeft < 60 && (
              <span>00 : </span>
            )}
            <span className={styles.digits_blue}>
                {timeLeft > 0 && timeLeft < 60
                  ? `${new Date(timeLeft * 1000).toISOString().substring(17, 19)}`
                  : timeLeft > 0 && new Date(timeLeft * 1000).toISOString().substring(14, 19)
                }
              </span>
          </div>
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.stages}>
        <div className={cn(styles.stage, {
          [styles.bold]: !(!applicationType || applicationType === "new"),
        })}>
          <div>Проверка машины</div>
          {!applicationType || applicationType === "new"
            ? <Loader color={"blue"} className={styles.loader} />
            : <Icons.ui.CheckmarkPurpleBg />
          }
        </div>
        <div className={cn(styles.stage, {
          [styles.bold]: applicationType === "survey_filling",
        })}>
          <div>Проверка паспорта</div>
          {applicationType === "passport_filling" || applicationType === "inn_check_process"
            ? <Loader color={"blue"} className={styles.loader} />
            : applicationType === "survey_filling"
              ? <Icons.ui.CheckmarkPurpleBg />
              : <div className={styles.circle} />
          }
        </div>
        <div className={styles.stage}>
          <div>Проверка анкетных данных</div>
          {applicationType === "survey_filling"
            ? <Loader color={"blue"} className={styles.loader} />
            : applicationType === "signing_applying_agreement_filling"
              ? <Icons.ui.CheckmarkPurpleBg />
              : <div className={styles.circle} />
          }
        </div>
      </div>
      <div className={styles.waiting_page_note}>Ожидайте окончания проверок...</div>
    </MobilePageContainer>
  );
});
