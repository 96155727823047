import { takeEvery } from "redux-saga/effects";
import { applicationSagaActions } from "./actions";
import { createApplicationSaga } from "./methods/createApplicationSaga";
import { verifyApplicationSaga } from "./methods/verifyApplicationSaga";
import { sendingDocumentsForRecognizeSaga } from "./methods/sendingDocumentsForRecognizeSaga";
import { sendAllDocsSaga } from "./methods/sendAllDocsSaga";
import { addingPassportAndInnToApplicationSaga } from "./methods/addingPassportAndInnToApplicationSaga";
import { sendAllCarPhotosSaga } from "./methods/sendAllCarPhotosSaga";
import { sendApplicationSaga } from "./methods/sendApplicationSaga";
import { sendingLoanTermsSaga } from "./methods/sendingLoanTermsSaga";
import { signLoanContractSaga } from "./methods/signLoanContractSaga";
import { createCardSaga } from "./methods/createCardSaga";
import { downloadFileSaga } from "./methods/downoloadFileSaga";
import { createApplicationShortSaga } from "./methods/createApplicationShortSaga";
import { sendingCarPhotoForRecognizeSaga } from "./methods/sendingCarPhotoForRecognizeSaga";
import { postApplicationSaga } from "./methods/postApplicationSaga";
import { newLoanSaga } from "./methods/newLoanSaga";
import { sendingReplegePhotoForRecognizeSaga } from "./methods/sendingReplegePhotoForRecognizeSaga";
import { sendingReplacedPhotoForRecognizeSaga } from "./methods/sendingReplacedPhotoForRecognizeSaga";
import { sendPtsAndStsDocumentsSaga } from "./methods/sendPtsAndStsDocumentsSaga";

export function* applicationWatcher() {
  yield takeEvery(
    applicationSagaActions.createApplication,
    createApplicationSaga
  );

  yield takeEvery(
    applicationSagaActions.sendingDocumentsForRecognize,
    sendingDocumentsForRecognizeSaga
  );

  yield takeEvery(
    applicationSagaActions.sendingReplegePhotoForRecognize,
    sendingReplegePhotoForRecognizeSaga
  );

  yield takeEvery(
    applicationSagaActions.sendingReplacedPhotoForRecognize,
    sendingReplacedPhotoForRecognizeSaga
  );

  yield takeEvery(applicationSagaActions.newLoanSaga, newLoanSaga);

  yield takeEvery(
    applicationSagaActions.postApplicationSaga,
    postApplicationSaga
  );

  yield takeEvery(
    applicationSagaActions.sendingCarPhotoForRecognize,
    sendingCarPhotoForRecognizeSaga
  );

  yield takeEvery(
    applicationSagaActions.addingPassportAndInnToApplication,
    addingPassportAndInnToApplicationSaga
  );

  yield takeEvery(applicationSagaActions.sendApplication, sendApplicationSaga);

  yield takeEvery(
    applicationSagaActions.verifyApplication,
    verifyApplicationSaga
  );

  yield takeEvery(
    applicationSagaActions.signLoanContract,
    signLoanContractSaga
  );

  yield takeEvery(
    applicationSagaActions.sendingLoanTerms,
    sendingLoanTermsSaga
  );

  // * новое поведение заявки
  yield takeEvery(applicationSagaActions.createCard, createCardSaga);

  //  * временно

  yield takeEvery(applicationSagaActions.sendAllDocs, sendAllDocsSaga);
  yield takeEvery(
    applicationSagaActions.sendPtsAndStsDocumentsSaga,
    sendPtsAndStsDocumentsSaga
  );
  yield takeEvery(
    applicationSagaActions.sendAllCarPhotos,
    sendAllCarPhotosSaga
  );

  yield takeEvery(applicationSagaActions.downloadFile, downloadFileSaga);

  yield takeEvery(
    applicationSagaActions.createApplicationShort,
    createApplicationShortSaga
  );
}
