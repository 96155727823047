import styles from "./index.module.css";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Control, useForm, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import { AnimatePresence, motion } from "framer-motion";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import cn from "classnames";
import get from "lodash/get";
import moment from "moment/moment";
import { object } from "yup";
import { StateType } from "../../../../../store";
import { API } from "../../../../../api";
import { Icons } from "../../../../../assets/icons";
import { sendMetrics } from "../../../../../metrics";
import { routes } from "../../../../../routes";
import { refinancingPurposeForSelect } from "../../../../../staticData";
import { sagaActions } from "../../../../../sagas/util/sagaActions";
import { routingAction } from "../../../../../sagas/util/helpers/routingSaga";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../../../store/reducers/application";
import { userReducer } from "../../../../../store/reducers/user";
import { userReducerSelectors } from "../../../../../store/reducers/user/selectors";
import { createFC } from "../../../../../helpers/createFC";
import { dateMethods, showYMDDate } from "../../../../../helpers/date";
import { capitalize, stringHelpers } from "../../../../../helpers/string";
import { validateSchemaObj } from "../../../../../helpers/validation/fields";
import { useClock } from "../../../../../hooks/logical/useClock";
import { useCountdown } from "../../../../../helpers/date/useCountdown";
import { useDebounce } from "../../../../../hooks/logical/useDebounce";
import { useGetInfoFromApplication } from "../../../../../hooks/api/useGetInfoFromApplication";
import { useGetIssuedBy } from "../../../../../hooks/api/useGetIssuedBy";
import { useGetSchedule } from "../../../../../hooks/api/useGetSchedule";
import { SmartForm } from "../../../../hocs/SmartForm";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { Button } from "../../../../ui/Button";
import {
  GetAddressSelect,
  getRelevantFias,
} from "../../../../ui/Selects/GetAddressSelect";
import { InputNumber } from "../../../../ui/Inputs/InputNumber";
import { InputText } from "../../../../ui/Inputs/InputText";
import { Loader } from "../../../../ui/Loader";
import { RangeWithTitleVariant2 } from "../../../../ui/RangeWithTitleVariant2";
import { RoundContainer } from "../../../../ui/Containers/RoundContainer";
import { Select } from "../../../../ui/Selects/Select";
import { SmartCalendar } from "../../../../ui/Calendar";
import { Switcher } from "../../../../ui/Switcher";
import { VinAndLicensePlate } from "../../../../ui/VinAndLicensePlate";
import { ConditionFioComponent } from "../FioAndGrz/ConditionFioComponent";
import { BankSelection } from "../../components/BankSelection";
import { TimeBlock } from "../../components/TimeBlock";
import { Banner } from "../../../../ui/Banner";
import { WaitingPage } from "./WaitingPage";

/** Одношаговый флоу для banki-api, логика работы следующая:
 * часть данных в инпуты подставляется из GET/lead, остальные чел заполняет руками;
 * на сабмит сохраняем всю анкету в localStorage в JSON формате, шлем POST/application с частью анкетных данных;
 * если бэк отвечает 400, подсвечиваем поля с ошибками;
 * если OK, пингуем заявку, ждем смену статуса на "passport_filling", шлем POST/passport с частью анкетных данных;
 * если бэк отвечает 400, редиректим чела на стандартный экран анкеты шаг 2, подсвечиваем поля с ошибками;
 * если OK, пингуем заявку, ждем смену статуса на "survey_filling", шлем PUT/survey с частью анкетных данных;
 * если бэк отвечает 400, редиректим чела на стандартный экран анкеты шаг 3, подсвечиваем поля с ошибками;
 * если OK, для реф заявки редиректим на экран с выбором причины рефа, если обычная, на экран подписания смс-кодом.
 * */

//TODO: типизировать форму

const {
  email,
  loan_purpose,
  inn: innSchema,
  passport,
  monthly_income,
  second_phone,
  user_info,
} = validateSchemaObj;

export const OneStepFlow = createFC(() => {
  const [autoDefinedInn, setAutoDefinedInn] = useState<string | undefined>(
    undefined
  );
  const [errorMessageBank, setErrorMessageBank] = React.useState("");
  const [isCodeInputFocused, setIsCodeInputFocused] = useState(false);
  const [pingInterval, setPingInterval] = useState<number | false>(false);
  // const [showPopup, setShowPopup] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [issuedByRequestDisabled, setIssuedByRequestDisabled] = useState(true);

  const birthDateRef = useRef<HTMLInputElement>(null);
  const issueDateRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const applicationId =
    useSelector(applicationReducerSelectors.getApplicationId) ||
    lsController.get("application_id");

  const applicationType = useSelector(
    applicationReducerSelectors.getApplicationType
  );

  const leadData = useSelector(
    (state: StateType) => state.applicationReducer.leadData
  );

  const userPhone = useSelector(userReducerSelectors.getPhone);

  const routeLoading = useSelector(processLoading.get("routing"));
  // const routeLoading = true;

  const lsData = JSON.parse(lsController.get("oneStepFlowData"));
  const fetching = lsController.get("oneStepFlowDataFetching");

  const form = useForm<any>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(
      object().shape({
        loan_purpose,
        passport,
        email,
        inn: innSchema,
        second_phone,
        monthly_income,
        user_info,
      })
    ),
    context: {
      birthDate: birthDateRef?.current?.value,
      passportIssueDate: issueDateRef?.current?.value,
      userPhone,
    },
  });

  /** !!! Нейминг инпутов полностью совпадает с неймингом полей в бэке (если изменить name, при получении 400 ошибки,
   соответствующее поле не будет подсвечиваться ошибкой) !!! */
  const [
    amount,
    loanTerm,
    regNumber,
    monthlyIncome,
    loanPurpose,
    bankId,
    bankSelected,
    inn,
    lastName,
    firstName,
    middleName,
    birthDay,
    passportSeries,
    passportNumber,
    issuedDate,
    divisionCode,
    selectedIssuedBy,
    registrationAddress,
    addressMatch,
    actualAddress,
    birthPlace,
    secondPhone,
    snils,
  ] = useWatch({
    control: form.control,
    name: [
      "loan_data.amount",
      "loan_data.loan_term",
      "reg_number",
      "monthly_income",
      "loan_purpose",
      "bank_id",
      "select",
      "inn",
      "user_info.last_name",
      "user_info.first_name",
      "user_info.middle_name",
      "user_info.birth_day",
      "passport.series",
      "passport.number",
      "passport.issue_date",
      "passport.division_code",
      "passport.issued_by",
      "registration_address",
      "address_match",
      "actual_address",
      "passport.birth_place",
      "second_phone",
      "snils",
    ],
  });

  const { data } = useGetInfoFromApplication(pingInterval);
  // const { videoCallPlannedDate } = useClock(form);

  const debouncedValue = useDebounce(
    { schedule_amount: amount, schedule_loan_term: loanTerm, percent_rate: 60 },
    1000
  );

  const { schedule, isLoading: scheduleLoading } =
    useGetSchedule(debouncedValue);

  const {
    isLoading: isIssuedByLoading,
    transformedDataForSelect,
    issuedByValue,
  } = useGetIssuedBy({
    control: form.control,
    setValue: form.setValue,
    disabled: issuedByRequestDisabled,
  });

  const { isLoading: isInnAutoDefinition } = useQuery(
    "inn",
    () =>
      API.main.application.getInn({
        birth_day: birthDay,
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        passport_series: passportSeries,
        passport_number: passportNumber,
      }),
    {
      retry: 1,
      refetchInterval: 3000,
      enabled: Boolean(
        birthDay &&
          firstName &&
          lastName &&
          middleName &&
          passportSeries &&
          passportNumber &&
          autoDefinedInn === undefined
      ),
      onSuccess: (data) => {
        if (data.status === 200) {
          setAutoDefinedInn(data.data.data.inn);
          form.setValue("inn", data.data.data.inn);
        }
      },
      onError: (e) => {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 400 || e.response?.status === 404) {
            setAutoDefinedInn("");
          }
        }
      },
    }
  );

  // const amountError = amount < 50000 || amount > 500000;
  // const lowPeriod = amount >= 50000 && amount < 80000;
  //
  // const monthRangeDataHigh = [3, 48];
  // const monthRangeDataLow = [3, 12];

  const submit = (data: any) => {
    const firstName = capitalize(
      data?.user_info.first_name.toLowerCase().trim()
    );
    const lastName = capitalize(data?.user_info.last_name.toLowerCase().trim());
    const middleName = data?.user_info?.middle_name
      ? capitalize(data?.user_info.middle_name.toLowerCase().trim())
      : "";
    const birthDay = showYMDDate(data?.user_info?.birth_day);
    const gender = data?.user_info?.gender ? "female" : "male";
    const email = data?.email.trim();

    const registration_address = {
      full_address:
        registrationAddress?.text ||
        leadData?.survey.registration_address.address,
      fias:
        getRelevantFias(registrationAddress?.value?.data) ||
        leadData?.survey.registration_address.fias,
    };

    /** Если после сабмита во время ожидания чел обновил страницу, нам нужно сразу заново отправить POST/application,
     POST/passport и PUT/survey, т.е. те из них, которые не успели уйти, поэтом сохраняем всю анкету в localStorage */
    lsController.set("oneStepFlowData", {
      vin_or_reg_number: regNumber,
      user_info: {
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        birth_day: birthDay,
        gender,
      },
      loan_data: {
        amount,
        loan_term: String(loanTerm),
      },
      email,
      inn,
      passport: {
        ...data.passport,
        series: passportSeries,
        number: passportNumber,
        issue_date: moment(issuedDate).format("YYYY-MM-DD"),
        issued_by:
          selectedIssuedBy?.value || leadData?.survey.passport.issued_by,
        birth_place: birthPlace?.trim() || "",
        division_code: stringHelpers.clearString(divisionCode),
      },
      actual_address: addressMatch
        ? registration_address
        : {
            full_address:
              actualAddress?.text || leadData?.survey.actual_address.address,
            fias:
              getRelevantFias(actualAddress?.value?.data) ||
              leadData?.survey.actual_address.fias,
          },
      registration_address,
      bank_id: bankId,
      ...(snils && { snils: stringHelpers.clearString(snils) }),
      monthly_income: Number(stringHelpers.clearString(monthlyIncome)),
      trusted_person_phone: stringHelpers.clearString(secondPhone),
      // video_call_start_date: videoCallPlannedDate,
      loan_purpose: loanPurpose?.value,
    });

    // if (regNumber?.length >= 8) {
    lsController.set("oneStepFlowDataFetching", true);
    setSubmitLoading(true);
    setPingInterval(3000);

    lsController.set("grz", regNumber);

    dispatch(
      userReducer.actions.setFullName({
        full_name: `${lastName} ${firstName}${
          middleName ? " " + middleName : ""
        }`,
      })
    );

    if (!applicationId || applicationType === "refusal_final") {
      API.main.application
        .postApplication({
          vin_or_reg_number: regNumber || leadData?.preSurvey?.grz,
          user_info: {
            first_name: firstName,
            last_name: lastName,
            middle_name: middleName,
            birth_day: birthDay,
            gender: gender,
          },
          loan_data: {
            amount,
            loan_term: String(loanTerm),
          },
          email,
        })
        .then((res) => {
          if (res.status === 201) {
            sendMetrics(
              "common-client_sozdanie-zayavki_common-app",
              "sozdanie-zayavki"
            );

            dispatch(
              applicationReducer.actions.setApplicationId({
                application_id: res.data.data.id || null,
              })
            );
          }
        })
        .catch((e) => {
          dispatch(
            sagaActions.error({
              response: e,
              handledStatuses: [400],
            })
          );
          setSubmitLoading(false);
          lsController.set("oneStepFlowData", undefined);
          lsController.set("oneStepFlowDataFetching", undefined);
        });
    } else {
      dispatch(routingAction());
    }
    // } else {
    //   form.setError("reg_number", {
    //     type: "custom",
    //     message: "Заполните поле",
    //   });
    //
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth"
    //   });
    // }
  };

  useEffect(() => {
    setPingInterval(3000);

    if (data?.application_type) {
      setSubmitLoading(true);

      if (data?.application_type === "passport_filling") {
        const registration_address = {
          full_address:
            registrationAddress?.text ||
            lsData.registration_address.full_address,
          fias:
            getRelevantFias(registrationAddress?.value?.data) ||
            lsData.registration_address.fias ||
            "",
        };

        API.main.application
          .addingPassportAndInnToApplication({
            applicationId,
            inn,
            passport: {
              ...data.passport,
              series: passportSeries || lsData.passport.series,
              number: passportNumber || lsData.passport.number,
              issue_date: issuedDate
                ? moment(issuedDate).format("YYYY-MM-DD")
                : lsData.passport.issue_date,
              issued_by: selectedIssuedBy?.value || lsData.passport.issued_by,
              birth_place:
                birthPlace?.trim() || lsData.passport.birth_place || "",
              division_code:
                stringHelpers.clearString(divisionCode) ||
                lsData.passport.division_code,
            },
            actual_address: addressMatch
              ? registration_address || lsData.registration_address
              : {
                  full_address:
                    actualAddress?.text || lsData.actual_address.full_address,
                  fias:
                    getRelevantFias(actualAddress?.value?.data) ||
                    lsData.actual_address.fias ||
                    "",
                },
            registration_address:
              registration_address || lsData.registration_address,
          })
          .then((res) => {
            if (res.status === 201) {
              sendMetrics("check anketa");
              sendMetrics(
                "common-client_otpravlen-pasport-v-ankete_common-app",
                "otpravlen-pasport-v-ankete"
              );
            }
          })
          .catch((err) => {
            if (axios.isAxiosError(err) && err?.response?.status === 400) {
              dispatch(
                sagaActions.error({
                  response: err,
                  handledStatuses: [400],
                })
              );

              history.push(routes.application.passport_and_inn);
              setSubmitLoading(false);
            }
          });
      } else if (applicationType === "survey_filling") {
        let bankId: any;

        if (bankSelected?.text) {
          bankId = bankSelected?.id;
        }

        if (bankSelected?.bank_id) {
          bankId = Number(bankSelected?.bank_id);
        }

        const validateBank = () => {
          if (!bankSelected && !bankId) {
            setErrorMessageBank("Поле обязательно к заполнению!");
          } else {
            setErrorMessageBank("");
          }
        };

        validateBank();

        API.main.application
          .defineSBPBank({
            applicationId,
            bank_id: bankId || lsData.bank_id,
            inn,
            ...(snils && { snils: stringHelpers.clearString(snils) }),
            monthly_income:
              Number(stringHelpers.clearString(monthlyIncome)) ||
              lsData.monthly_income,
            trusted_person_phone:
              stringHelpers.clearString(secondPhone).length > 2
                ? stringHelpers.clearString(secondPhone)
                : lsData.trusted_person_phone,
            // video_call_start_date: videoCallPlannedDate || lsData.video_call_start_date || "2024-11-04 09:30:00",
            loan_purpose: loanPurpose?.value || lsData.loan_purpose,
          })
          .then((res) => {
            if (res.status === 200) {
              lsController.set("lead", undefined);

              sendMetrics("bank account ok");
              sendMetrics(
                "common-client_otpravleny-dop-dannye_common-app",
                "otpravleny-dop-dannye"
              );
              sendMetrics(
                "common-client_api-banki-page-otpravlena_common-app",
                "api-banki-page-otpravlena"
              );
              sendMetrics(
                "common-client_api-banki-postpage-success_common-app",
                "api-banki-postpage-success"
              );

              res?.data?.data?.application_type === "repledge_filling"
                ? history.push(routes.application.refinancing)
                : history.push(routes.application.statuses.data_verification);
            }
          })
          .catch((err) => {
            if (axios.isAxiosError(err) && err?.response?.status === 400) {
              dispatch(
                sagaActions.error({
                  response: err,
                  handledStatuses: [400],
                })
              );
              history.push(routes.application.sbp_bank_filling);
              setSubmitLoading(false);
            }
          });
      } else if (data?.application_type === "refusal_final") {
        // Пока продублировал сюда частично логику отказных экранов, нужно будет вынести отдельно
        if (
          data?.application_type === "refusal_final" &&
          data?.refuse_reason !== "application_check_other"
        ) {
          dispatch(
            applicationReducer.actions.setRefuseReason({
              refuse_reason: data?.refuse_reason,
            })
          );
          history.push(routes.application.loan_rejection);
        } else if (
          data?.application_type === "refusal_final" &&
          data?.refuse_reason !== "application_check_no_auto_inn"
        ) {
          dispatch(
            applicationReducer.actions.setRefuseReason({
              refuse_reason: data?.refuse_reason,
            })
          );
          history.push(routes.application.loan_rejection);
        } else if (
          data?.application_type === "refusal_final" &&
          data?.refuse_reason === "application_check_other"
        ) {
          history.push(routes.application.partner_application);
        }
      }
    }
  }, [data?.application_type]);

  useEffect(() => {
    /** Если в GET/lead есть division_code и issued_by, то нам не нужно слать запрос GET/passport/division_code/{code}
     и показывать выпадающий список пока чел не начал редактировать поле, а просто подставить значения в инпут и див,
     поэтому передаем disabled в useGetIssuedBy
     */
    if (divisionCode?.length < 7 && issuedByRequestDisabled) {
      setIssuedByRequestDisabled(false);
    }

    if (divisionCode && divisionCode?.length <= 7) {
      form.setValue("passport.issued_by", "");
    }
  }, [divisionCode]);

  useEffect(() => {
    form.setValue("address_match", true);
    form.setValue("email", leadData?.survey?.contact?.email);
    form.setValue("loan_data.amount", leadData?.application?.amount);
    form.setValue(
      "loan_data.loan_term",
      leadData?.application?.term && leadData?.application?.term > 48 ? 48 : leadData?.application?.term
    );
    form.setValue("user_info.last_name", leadData?.survey?.passport?.last_name);
    form.setValue(
      "user_info.first_name",
      leadData?.survey?.passport?.first_name
    );
    form.setValue(
      "user_info.middle_name",
      leadData?.survey?.passport?.middle_name
    );
    form.setValue("user_info.birth_day", leadData?.survey?.passport?.birth_day);
    form.setValue(
      "passport.birth_place",
      leadData?.survey?.passport?.birth_place
    );
    form.setValue("passport.number", leadData?.survey?.passport?.number);
    form.setValue("passport.series", leadData?.survey?.passport?.series);
    form.setValue(
      "passport.issue_date",
      leadData?.survey?.passport?.issue_date
    );
    form.setValue("second_phone", leadData?.survey?.contact_person?.phone);
    form.setValue(
      "registration_address",
      leadData?.survey?.registration_address?.address
    );
    form.setValue("actual_address", leadData?.survey?.actual_address?.address);
    form.setValue(
      "monthly_income",
      stringHelpers.transformMoneyValue(
        leadData?.survey?.employment?.monthly_income
      )
    );
    form.setValue(
      "passport.division_code",
      `${leadData?.survey?.passport?.division_code?.slice(
        0,
        3
      )}-${leadData?.survey?.passport?.division_code?.slice(-3)}`
    );
    form.setValue("snils", leadData?.survey?.survey?.snils);
  }, [leadData]);

  useEffect(() => {
    sendMetrics(
      "common-client_api-banki-page-show_common-app",
      "api-banki-page-show"
    );
  }, []);

  // const memoizedConditions = React.useMemo(
  //   () => (
  //     <ConditionFioComponent
  //       animated={true}
  //       className={styles.conditions_container}
  //       scheduleLoading={scheduleLoading}
  //       monthly_payment={schedule?.monthly_payment}
  //       amount={amount}
  //       loan_term={loanTerm}
  //       loading={false}
  //       primaryColor={"blue"}
  //       children={
  //         <>
  //           <RangeWithTitleVariant2
  //             amount={amount}
  //             amountError={amountError}
  //             maxLength={7}
  //             period={false}
  //             step={10000}
  //             min={50000}
  //             max={500000}
  //             title={""}
  //             name={"loan_data.amount"}
  //             primaryColor={"blue"}
  //             containerClass={styles.loan_amount}
  //           />
  //           <div className={styles.divider} />
  //           <RangeWithTitleVariant2
  //             amountForTextInformation={amount}
  //             textInformation
  //             maxLength={2}
  //             period
  //             marks
  //             rangeData={lowPeriod ? monthRangeDataLow : monthRangeDataHigh}
  //             max={lowPeriod ? 12 : 48}
  //             min={3}
  //             title={""}
  //             name={"loan_data.loan_term"}
  //             primaryColor={"blue"}
  //             containerClass={styles.loan_amount}
  //           />
  //         </>
  //       }
  //     />
  //   ),
  //   [schedule?.monthly_payment, amount, loanTerm]
  // );

  // if (routeLoading || submitLoading || fetching) {
  //   return (
  //     <MobilePageContainer>
  //       <Loader variant={"large"} color={"purple"} className={styles.preloader} />
  //     </MobilePageContainer>
  //   );
  // const memoizedConditions = React.useMemo(
  //   () => (
  //     <ConditionFioComponent
  //       animated={true}
  //       className={styles.conditions_container}
  //       scheduleLoading={scheduleLoading}
  //       monthly_payment={schedule?.monthly_payment}
  //       amount={amount}
  //       loan_term={loanTerm}
  //       loading={false}
  //       primaryColor={"blue"}
  //       children={
  //         <>
  //           <RangeWithTitleVariant2
  //             amount={amount}
  //             amountError={amountError}
  //             maxLength={7}
  //             period={false}
  //             step={10000}
  //             min={50000}
  //             max={500000}
  //             title={""}
  //             name={"loan_data.amount"}
  //             primaryColor={"blue"}
  //             containerClass={styles.loan_amount}
  //           />
  //           <div className={styles.divider} />
  //           <RangeWithTitleVariant2
  //             amountForTextInformation={amount}
  //             textInformation
  //             maxLength={2}
  //             period
  //             marks
  //             rangeData={lowPeriod ? monthRangeDataLow : monthRangeDataHigh}
  //             max={lowPeriod ? 12 : 48}
  //             min={3}
  //             title={""}
  //             name={"loan_data.loan_term"}
  //             primaryColor={"blue"}
  //             containerClass={styles.loan_amount}
  //           />
  //         </>
  //       }
  //     />
  //   ),
  //   [schedule?.monthly_payment, amount, loanTerm]
  // );

  // const { timeLeft } = useCountdown(30);

  if (submitLoading || fetching) {
    return <WaitingPage />;
    // return (
    //   <MobilePageContainer className={styles.waiting_page}>
    //     <div className={styles.waiting_page_title}>Не закрывайте эту страницу</div>
    //     <div className={styles.waiting_page_subtitle}>Мы проверяем Ваши данные</div>
    //     <div className={styles.waiting_page_timer}>
    //       {typeof timeLeft !== "undefined" && (
    //         <div>
    //           <div className={styles.waiting_page_note}>
    //             {timeLeft === 0 && "Подождите\nеще немного..."}
    //           </div>
    //           {timeLeft > 0 && timeLeft < 60 && (
    //             <span>00 : </span>
    //           )}
    //           <span className={styles.digits_blue}>
    //             {timeLeft > 0 && timeLeft < 60
    //               ? `${new Date(timeLeft * 1000).toISOString().substring(17, 19)}`
    //               : timeLeft > 0 && new Date(timeLeft * 1000).toISOString().substring(14, 19)
    //             }
    //           </span>
    //         </div>
    //       )}
    //     </div>
    //     <div className={styles.divider} />
    //     <div className={styles.stages}>
    //       <div className={styles.stage}>
    //         <div>Проверка машины</div>
    //         {!applicationType || applicationType === "new"
    //           ? <Loader color={"blue"} className={styles.loader} />
    //           : <Icons.ui.CheckmarkPurpleBg />
    //         }
    //       </div>
    //       <div className={styles.stage}>
    //         <div>Проверка паспорта</div>
    //         {applicationType === "passport_filling"
    //           ? <Loader color={"blue"} className={styles.loader} />
    //           : applicationType === "survey_filling" || applicationType === "inn_check_process"
    //             ? <Icons.ui.CheckmarkPurpleBg />
    //             : <div className={styles.circle} />
    //         }
    //       </div>
    //       <div className={styles.stage}>
    //         <div>Проверка анкетных данных</div>
    //         {applicationType === "survey_filling"
    //           ? <Loader color={"blue"} className={styles.loader} />
    //           : applicationType === "signing_applying_agreement_filling"
    //             ? <Icons.ui.CheckmarkPurpleBg />
    //             : <div className={styles.circle} />
    //         }
    //       </div>
    //     </div>
    //     <div className={styles.waiting_page_note}>Ожидайте окончания проверок...</div>
    //   </MobilePageContainer>
    // );
  } else if (routeLoading) {
    return (
      <Loader variant={"large"} color={"purple"} className={styles.preloader} />
    );
  }

  return (
    <MobilePageContainer className={styles.container}>
      <SmartForm submit={submit} form={form}>
        <div>
          {/*<div className={styles.conditions_block}>*/}
          {/*  {memoizedConditions}*/}
          {/*</div>*/}
          <div className={styles.greeting_block}>
            <div className={styles.greeting_block_content}>
              <div className={styles.greeting_block_name}>
                {/*<div>Здравствуйте,</div>*/}
                <div>
                  {`${leadData?.survey?.passport?.first_name}${
                    leadData?.survey?.passport?.middle_name
                      ? " " + leadData?.survey.passport.middle_name
                      : ""
                  }!`}
                </div>
                <div className={styles.greeting_block_offer}>
                  <div>
                    Вам одобрено {stringHelpers.transformMoneyValue(amount)}{" "}
                    &#8381;
                  </div>
                  <div>
                    на {loanTerm} {dateMethods.getMonthString(loanTerm)}
                  </div>
                </div>
                <div className={styles.greeting_block_payment}>
                  Ежемесячный платеж:{" "}
                  {schedule?.monthly_payment ? (
                    `${stringHelpers.transformMoneyValue(
                      schedule?.monthly_payment.toFixed(2)
                    )} ₽`
                  ) : (
                    <Loader color={"white"} className={styles.loader} />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.icon}>
              <Icons.logos.BankiRuBig width={110} height={110} />
            </div>
          </div>
          <div className={styles.bank_selection_block}>
            <BankSelection
              bankId={bankId}
              bankSelected={bankSelected}
              data={data}
              form={form}
              variant={"banki"}
            />
            <div className={styles.error_message}>{errorMessageBank}</div>
          </div>
          {/*<div className={styles.section}>*/}
          {/*  <div className={styles.subtitle}>Цель займа</div>*/}
          <div className={styles.field}>
            <Select
              name={"loan_purpose"}
              control={form.control}
              setValue={form.setValue}
              defaultValue={"Ремонт"}
              options={refinancingPurposeForSelect}
              selectTitle={"Цель займа"}
              support={<Icons.ui.DropDown />}
              error={
                get(form.formState.errors, "loan_purpose")?.text?.message as any
              }
              clearErrors={form.clearErrors}
              // setFocus={loanPurposeFocus}
              // inputElementRef={loanPurposeRef}
            />
          </div>
          {/*<div className={styles.bank_selection_block}>*/}
          {/*  <BankSelection*/}
          {/*    form={form}*/}
          {/*    data={data}*/}
          {/*    bankId={bankId}*/}
          {/*    bankSelected={bankSelected}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className={styles.error_message}>{errorMessageBank}</div>
          {/*</div>*/}
          <div className={styles.main_block}>
            <div className={styles.main_block_title}>
              Внимательно проверьте <br /> Ваши данные
            </div>
            <div className={cn(styles.section, styles.car_block)}>
              <div className={styles.subtitle}>Автомобиль</div>
              <div className={styles.car_block_item}>
                Марка: <span>{leadData?.survey.car_name_original}</span>
              </div>
              <div className={styles.car_block_item}>
                Год производства: <span>{leadData?.survey.year}</span>
              </div>
              <div className={styles.car_block_item}>
                ГРЗ: <span>{leadData?.preSurvey?.grz}</span>
              </div>
            </div>
            <div className={cn(styles.section, styles.personal_data)}>
              {/*<div className={styles.title}>Ваша анкета</div>*/}
              <div className={styles.subtitle}>Личные данные</div>
              <div className={styles.field}>
                <InputText
                  name={"user_info.last_name"}
                  label={"Фамилия"}
                  className={styles.fullName}
                  primaryColor={"blue"}
                  // inputElementRef={lastNameRef}
                  // onKeyDown={keyPressHandler}
                  // onFocus={() => setCurrentField("passport.last_name")}
                  support={<Icons.ui.Lock className={styles.icon_lock} />}
                  disabled={true}
                />
              </div>
              <div className={styles.field}>
                <InputText
                  name={"user_info.first_name"}
                  label={"Имя"}
                  className={styles.fullName}
                  primaryColor={"blue"}
                  // inputElementRef={firstNameRef}
                  // onKeyDown={keyPressHandler}
                  // onFocus={() => setCurrentField("passport.first_name")}
                  support={<Icons.ui.Lock className={styles.icon_lock} />}
                  disabled={true}
                />
              </div>
              <div className={styles.field}>
                <InputText
                  name={"user_info.middle_name"}
                  label={"Отчество"}
                  className={styles.fullName}
                  primaryColor={"blue"}
                  // inputElementRef={middleNameRef}
                  // onKeyDown={keyPressHandler}
                  // onFocus={() => setCurrentField("passport.middle_name")}
                  support={<Icons.ui.Lock className={styles.icon_lock} />}
                  disabled={true}
                />
              </div>
              <div className={styles.field}>
                <SmartCalendar
                  name={"user_info.birth_day"}
                  placeholder={"Дата рождения"}
                  primaryColor={"blue"}
                  inputRef={birthDateRef as MutableRefObject<HTMLInputElement>}
                  // onKeyDown={keyPressHandler}
                  // onFocus={() => setCurrentField("passport.birth_day")}
                  support={<Icons.ui.Lock className={styles.icon_lock} />}
                  disabled={true}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <Switcher
                  name={"user_info.gender"}
                  className={styles.gender}
                  outerLabel={"Пол"}
                  innerLabels={["Мужской", "Женский"]}
                  switchVariant={"huge_gender"}
                  variant={"large"}
                  primaryColor={"blue"}
                />
              </div>
              <div style={{ marginTop: 15 }}>
                <InputText
                  className={styles.mt_input}
                  variant={"large"}
                  label={"e-mail"}
                  name={"email"}
                  maxLength={255}
                  autoCapitalize={"off"}
                  autoComplete={"email"}
                  inputMode={"email"}
                  primaryColor={"blue"}
                  // inputElementRef={emailRef}
                  // onKeyDown={keyPressHandler}
                  // onFocus={() => setCurrentField("email")}
                />
              </div>
            </div>
            <div className={cn(styles.section, styles.passport_data)}>
              <div className={styles.subtitle}>Паспортные данные</div>
              <div>
                <div className={styles.row}>
                  <InputNumber
                    // inputElementRef={seriesRef}
                    type={"tel"}
                    // support={
                    //   wasOptionalPhotoRecognized ? <Icons.ui.PencilBlack /> : null
                    // }
                    name={"passport.series"}
                    label={"Серия"}
                    min={4}
                    max={4}
                    maxLength={4}
                    decimalScale={0}
                    // autoFocus
                    variantWrapper={"banki"}
                    // onFocus={() => setCurrentField("passport.series")}
                    // onKeyDown={keyPressHandler}
                  />
                  <InputNumber
                    // inputElementRef={numberRef}
                    type={"tel"}
                    // support={
                    //   wasOptionalPhotoRecognized ? <Icons.ui.PencilBlack /> : null
                    // }
                    name={"passport.number"}
                    label={"Номер"}
                    max={6}
                    min={6}
                    maxLength={6}
                    decimalScale={0}
                    variantWrapper={"banki"}
                    // onFocus={() => setCurrentField("passport.number")}
                    // onKeyDown={keyPressHandler}
                  />
                </div>
              </div>
              <div style={{ marginTop: 8 }}>
                <SmartCalendar
                  name={"passport.issue_date"}
                  placeholder={"Дата выдачи паспорта"}
                  primaryColor={"blue"}
                  inputRef={issueDateRef as MutableRefObject<HTMLInputElement>}
                  // onKeyDown={keyPressHandler}
                  // onFocus={() => setCurrentField("passport.issue_date")}
                />
              </div>
              <div>
                <InputText
                  name={"passport.birth_place"}
                  label={"Место рождения как в паспорте"}
                  className={styles.fullName}
                  support={<Icons.ui.PencilBlack />}
                  primaryColor={"blue"}
                  // inputElementRef={birthPlaceRef}
                />
              </div>
            </div>
            <div className={styles.section}>
              <div style={{ marginTop: 8 }}>
                <div
                  className={cn(styles.division_code_and_issued_by_container, {
                    [styles.division_code_and_issued_by_container_without_padding]:
                      selectedIssuedBy?.value,
                  })}
                >
                  <InputText
                    onFocus={() => {
                      setIsCodeInputFocused(true);
                      // setCurrentField("passport.division_code");
                    }}
                    onBlur={() => setIsCodeInputFocused(false)}
                    // onKeyDown={keyPressHandler}
                    // TODO: возможно, стоит перенести лоадер в сам инпут, т.к. уже есть в двух местах дублирование
                    support={
                      isIssuedByLoading ? (
                        <Loader
                          className={styles.loader}
                          color={"black"}
                          variant={"medium"}
                        />
                      ) : selectedIssuedBy ? (
                        <Icons.ui.PencilBlack />
                      ) : null
                    }
                    name={"passport.division_code"}
                    label={"Код подразделения"}
                    inputMode={"numeric"}
                    mask={"999-999"}
                    primaryColor={"blue"}
                    // inputElementRef={divisionCodeRef}
                  />
                  {selectedIssuedBy?.value || issuedByValue ? (
                    <div className={styles.selected_issued_by_value}>
                      {selectedIssuedBy?.value || issuedByValue}
                    </div>
                  ) : (
                    <div className={styles.text_division_code_and_issued_by}>
                      {leadData?.survey.passport.issued_by ||
                        "Начните вводить код и выберите нужное из списка"}
                    </div>
                  )}
                  <Select
                    isCodeInputFocused={isCodeInputFocused}
                    division_code={divisionCode}
                    className={cn(styles.select_issued_by, {
                      [styles.select_issued_by_for_selected_value_short]:
                        selectedIssuedBy?.value &&
                        selectedIssuedBy?.value?.length < 30,
                      // [styles.select_issued_by_for_selected_value_long]:
                      // selectedIssuedBy?.value &&
                      // selectedIssuedBy?.value?.length > 30,
                    })}
                    name={"passport.issued_by"}
                    control={form.control as Control<any>}
                    setValue={form.setValue}
                    options={transformedDataForSelect}
                    selectTitle={"Кем выдан"}
                    search
                    support={<Icons.ui.DropDown />}
                  />
                </div>
              </div>
            </div>
            <div className={cn(styles.section, styles.personal_data)}>
              <div className={styles.subtitle}>Регистрация</div>
              <div>
                <GetAddressSelect
                  control={form.control as any}
                  label={"Адрес регистрации"}
                  name={"registration_address"}
                  setFocus={!!selectedIssuedBy?.value}
                  setValue={form.setValue}
                  error={
                    get(form.formState.errors, "registration_address")?.text
                      ?.message ||
                    (get(form.formState.errors, "registration_address")?.value
                      ?.data?.house?.message as any)
                  }
                  clearErrors={form.clearErrors}
                  // defaultValue={leadData?.survey.registration_address.address}
                  initialValue={leadData?.survey?.registration_address?.address}
                  // inputElementRef={addressRegistrationRef}
                  // keypressHandler={keyPressHandler}
                  // onFocus={() => setCurrentField("street-address")}
                />
                <div className={styles.text}>
                  Начните вводить адрес и выберите нужное из списка
                </div>
              </div>

              <div className={styles.address_match}>
                <div className={styles.address_text}>
                  Адрес фактического проживания совпадает с адресом регистрации
                </div>
                <Switcher
                  defaultChecked={true}
                  name={"address_match"}
                  primaryColor={"blue"}
                />
              </div>
              {!addressMatch && addressMatch !== undefined && (
                <>
                  <GetAddressSelect
                    name={"actual_address"}
                    label={"Адрес фактического проживания"}
                    setFocus={
                      !!(registrationAddress?.text && addressMatch === false)
                    }
                    setValue={form.setValue}
                    control={form.control as any}
                    error={
                      get(form.formState.errors, "actual_address")?.text
                        ?.message ||
                      (get(form.formState.errors, "actual_address")?.value?.data
                        ?.house?.message as any)
                    }
                    clearErrors={form.clearErrors}
                    // defaultValue={leadData?.survey.actual_address}
                    initialValue={leadData?.survey?.actual_address?.address}
                    // inputElementRef={addressActualRef}
                    // keypressHandler={keyPressHandler}
                    // onFocus={() => setCurrentField("street-address")}
                  />
                  <div className={styles.text}>
                    Начните вводить адрес и выберите нужное из списка
                  </div>
                </>
              )}
              {/*<InputText*/}
              {/*  name={"passport.birth_place"}*/}
              {/*  label={"Место рождения как в паспорте"}*/}
              {/*  className={styles.fullName}*/}
              {/*  support={<Icons.ui.PencilBlack />}*/}
              {/*  primaryColor={"blue"}*/}
              {/*  // inputElementRef={birthPlaceRef}*/}
              {/*/>*/}
            </div>
            <div className={styles.section}>
              <div className={styles.subtitle}>Анкетные данные</div>
              <div className={styles.field}>
                <InputNumber
                  inputMode={"numeric"}
                  support={<Icons.ui.Ruble />}
                  thousandsGroupStyle={"thousand"}
                  label={"Ежемесячный доход"}
                  name={"monthly_income"}
                  min={0}
                  max={1000000}
                  maxLength={9}
                  variantWrapper={"banki"}
                  // inputElementRef={monthlyIncomeRef}
                  // onFocus={() => setCurrentField("monthly_income")}
                  // onKeyDown={keyPressHandler}
                />
              </div>
              {/*<RoundContainer className={styles.second_phone}>*/}
              <div className={styles.title_second_phone}>
                Укажите второй номер телефона
              </div>
              <div className={styles.subtitle_second_phone}>
                Номер телефона родственника или друга
              </div>
              <InputNumber
                // support={<Icons.ui.PencilBlack />}
                variant={"large"}
                inputMode={"tel"}
                defaultValue={"+79"}
                format={"+# ### ### ## ##"}
                name={"second_phone"}
                noNumericString
                label={"Номер телефона"}
                maxLength={16}
                // autoFocus
                className={styles.phone_input}
                variantWrapper={"banki"}
                // onBlur={onBlurSecondPhoneHandler}
                // onFocus={() => setCurrentField("second_phone")}
                // onKeyDown={keyPressHandler}
                // inputElementRef={secondPhoneRef}
              />
              <div className={styles.second_phone_note}>
                <div className={styles.second_phone_note_icon}>
                  <Icons.ui.Exclamation />
                </div>
                <div>
                  Мы не будем звонить сейчас. Используется в крайнем случае
                </div>
              </div>
              <div className={styles.inn_block}>
                {/*<div className={styles.inn_title}>*/}
                {/*  {isInnAutoDefinition ? "Подождите, ваш ИНН определяется" : ""}*/}
                {/*</div>*/}
                <InputNumber
                  support={
                    autoDefinedInn ? (
                      <div className={styles.icon_lock}>
                        <Icons.ui.Lock />
                      </div>
                    ) : undefined
                  }
                  name={"inn"}
                  label={
                    isInnAutoDefinition ? "Подождите, определяется..." : "ИНН"
                  }
                  maxLength={12}
                  disabled={!!autoDefinedInn}
                  variantWrapper={"banki"}
                />
              </div>
              {isInnAutoDefinition ? (
                <div className={styles.banner}>
                  <Banner
                    variant={"blue-second"}
                    text={
                      <div className={styles.inn_text}>
                        ИНН определяется, подождите, <br /> пожалуйста
                      </div>
                    }
                    iconLeft={
                      <Loader color={"white"} className={styles.loader} />
                    }
                  />
                </div>
              ) : autoDefinedInn ? null : (
                <div className={styles.banner}>
                  <Banner
                    variant={"red"}
                    text={
                      <div className={styles.inn_text}>
                        Определить ИНН не удалось, <br /> введите вручную
                      </div>
                    }
                    iconLeft={
                      <Icons.ui.Exclamation
                        className={styles.exclamation_icon}
                      />
                    }
                  />
                </div>
              )}
              {/*</RoundContainer>*/}
              <div style={{ marginTop: 8 }}>
                <InputText
                  mask={"999-999-999 99"}
                  label={"СНИЛС"}
                  name={"snils"}
                  inputMode={"numeric"}
                  primaryColor={"blue"}
                  // inputElementRef={snilsRef}
                  // onFocus={() => setCurrentField("snils")}
                  // onKeyDown={keyPressHandler}
                />
              </div>
              <div className={styles.snils_note}>
                Поле необязательно для заполнения
              </div>
              {/*<div className={styles.field}>*/}
              {/*  <Select*/}
              {/*    name={"loan_purpose"}*/}
              {/*    control={form.control}*/}
              {/*    setValue={form.setValue}*/}
              {/*    defaultValue={"Ремонт"}*/}
              {/*    options={refinancingPurposeForSelect}*/}
              {/*    selectTitle={"Цель займа"}*/}
              {/*    support={<Icons.ui.DropDown />}*/}
              {/*    // setFocus={loanPurposeFocus}*/}
              {/*    // inputElementRef={loanPurposeRef}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
            {/*<div className={styles.fio_and_grz_block}>*/}
            {/*  <div className={styles.subtitle}>*/}
            {/*    Введите номер вашего автомобиля{" "}*/}
            {/*    <span*/}
            {/*      className={styles.link}*/}
            {/*      onClick={() => setShowPopup(true)}*/}
            {/*    >*/}
            {/*    зачем?*/}
            {/*  </span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className={styles.license_plate_container}>*/}
            {/*  <VinAndLicensePlate*/}
            {/*    variant={"onlyLicensePlate"}*/}
            {/*    setError={form.setError}*/}
            {/*    className={styles.vin_and_license_plate}*/}
            {/*    defaultValue={leadData?.preSurvey?.grz}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className={styles.note}>Автомобиль и ПТС остаются у вас</div>*/}
            {/*<div>*/}
            {/*  <TimeBlock form={form} primaryColor={"blue"} />*/}
            {/*</div>*/}
            {/*<div className={styles.inn_block}>*/}
            {/*  <div className={styles.inn_title}>*/}
            {/*    {isLoading ? "Подождите, ваш ИНН определяется" : ""}*/}
            {/*  </div>*/}
            {/*  <InputNumber*/}
            {/*    support={isLoading*/}
            {/*      ? <Loader className={styles.loader} color={"black"} variant={"medium"} />*/}
            {/*      : autoDefinedInn*/}
            {/*        ? <div className={styles.icon_lock}><Icons.ui.Lock /></div>*/}
            {/*        : <Icons.ui.PencilBlack />*/}
            {/*    }*/}
            {/*    name={"inn"}*/}
            {/*    label={"Ваш ИНН"}*/}
            {/*    maxLength={12}*/}
            {/*    disabled={!!autoDefinedInn}*/}
            {/*    variantWrapper={"banki"}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className={styles.greeting_block} style={{ margin: "-36px 0px -55px" }}>*/}
            {/*  <div className={styles.greeting_block_content}>*/}
            {/*    <div className={styles.greeting_block_text}>*/}
            {/*      <div>Проверьте Ваши <br /> анкетные данные</div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className={styles.icon}>*/}
            {/*    <Icons.logos.BankiRuBig />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className={styles.personal_data}>*/}
            {/*  <div className={styles.subtitle}>Регистрация</div>*/}
            {/*  /!*<RoundContainer className={styles.second_phone}>*!/*/}
            {/*  /!*  <div className={styles.title_second_phone}>*!/*/}
            {/*  /!*    Укажите второй номер телефона*!/*/}
            {/*  /!*  </div>*!/*/}
            {/*  /!*  <div className={styles.subtitle_second_phone}>*!/*/}
            {/*  /!*    Номер телефона родственника или друга*!/*/}
            {/*  /!*  </div>*!/*/}
            {/*  /!*  <InputNumber*!/*/}
            {/*  /!*    support={<Icons.ui.PencilBlack />}*!/*/}
            {/*  /!*    variant={"large"}*!/*/}
            {/*  /!*    inputMode={"tel"}*!/*/}
            {/*  /!*    defaultValue={"+79"}*!/*/}
            {/*  /!*    format={"+# ### ### ## ##"}*!/*/}
            {/*  /!*    name={"second_phone"}*!/*/}
            {/*  /!*    noNumericString*!/*/}
            {/*  /!*    label={"Номер телефона"}*!/*/}
            {/*  /!*    maxLength={16}*!/*/}
            {/*  /!*    // autoFocus*!/*/}
            {/*  /!*    className={styles.phone_input}*!/*/}
            {/*  /!*    variantWrapper={"banki"}*!/*/}
            {/*  /!*    // onBlur={onBlurSecondPhoneHandler}*!/*/}
            {/*  /!*    // onFocus={() => setCurrentField("second_phone")}*!/*/}
            {/*  /!*    // onKeyDown={keyPressHandler}*!/*/}
            {/*  /!*    // inputElementRef={secondPhoneRef}*!/*/}
            {/*  /!*  />*!/*/}
            {/*  /!*  <div className={styles.second_phone_note}>*!/*/}
            {/*  /!*    <div className={styles.second_phone_note_icon}>*!/*/}
            {/*  /!*      <Icons.ui.Exclamation />*!/*/}
            {/*  /!*    </div>*!/*/}
            {/*  /!*    <div>Мы не будем звонить сейчас. Используется в крайнем случае</div>*!/*/}
            {/*  /!*  </div>*!/*/}
            {/*  /!*</RoundContainer>*!/*/}
            {/*</div>*/}
            <Button className={styles.button}>Продолжить</Button>
          </div>
        </div>
      </SmartForm>
      {/*<AnimatePresence>*/}
      {/*  {showPopup && (*/}
      {/*    <div className={styles.popup}>*/}
      {/*      <motion.div*/}
      {/*        initial={{ scale: 0 }}*/}
      {/*        animate={{ scale: 1 }}*/}
      {/*        className={styles.popup_content}*/}
      {/*      >*/}
      {/*        <div>*/}
      {/*          <div*/}
      {/*            className={styles.icon_close}*/}
      {/*            onClick={() => setShowPopup(false)}*/}
      {/*          >*/}
      {/*            <Icons.ui.CrossBlack />*/}
      {/*          </div>*/}
      {/*          <div className={styles.text_block}>Мы выдаем займы только под залог автомобиля.</div>*/}
      {/*          <div className={styles.text_block}>*/}
      {/*            Номер автомобиля нужен нам для постановки залога в случае получения вами займа.*/}
      {/*          </div>*/}
      {/*          <div>Автомобиль и ПТС остаются у вас.</div>*/}
      {/*        </div>*/}
      {/*        <div className={styles.lower_part}>*/}
      {/*          <div className={styles.lower_part_title}>Требования к автомобилю:</div>*/}
      {/*          <ul>*/}
      {/*            <li className={styles.text_block}>автомобиль должен принадлежать Вам (стоять на учете на Ваши ФИО в*/}
      {/*              ГИБДД)*/}
      {/*            </li>*/}
      {/*            <li className={styles.text_block}>автомобиль должен быть на ходу в исправном состоянии, без*/}
      {/*              серьезных*/}
      {/*              повреждений*/}
      {/*            </li>*/}
      {/*          </ul>*/}
      {/*          <div className={styles.link}>Прочитать подробнее</div>*/}
      {/*        </div>*/}
      {/*      </motion.div>*/}
      {/*      <div></div>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</AnimatePresence>*/}
    </MobilePageContainer>
  );
});
