import React, { FC } from "react";
import styles from "./index.module.css";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { RoundContainer } from "../../../../ui/Containers/RoundContainer";
import { SBPDataBlock } from "./SBPDataBlock";
import { Button } from "../../../../ui/Button";
import { SBPSupportBlock } from "./SBPSupportBlock";
import { Icons } from "../../../../../assets/icons";
import { useHistory } from "react-router-dom";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { useDispatch, useSelector } from "react-redux";
import { useGetInfoFromApplication } from "../../../../../hooks/api/useGetInfoFromApplication";
import { Loader } from "../../../../ui/Loader";
import { FillingAssistance } from "../../../../ui/FillingAssistance";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { authSagaActions } from "../../../../../sagas/auth/actions";

type propsType = {};

/*
    /application/account_confirmation
    Подтверждение счета СБП
 */
export const AccountConfirmation: FC<propsType> = () => {
  const dispatch = useDispatch();

  const loading = useSelector(
    processLoading.get(applicationSagaActions.sendApplication.type)
  );

  const handleConfirm = () => {
    dispatch(applicationSagaActions.sendApplication());
  };

  const { isLoading, passport, bank_card } = useGetInfoFromApplication();

  // if (isLoading) {
  //   return (
  //     <MobilePageContainer>
  //       <Loader variant={"large"} color={"purple"} className={styles.loader} />
  //     </MobilePageContainer>
  //   );
  // }

  return (
    <>
      <MobilePageContainer>
        <div className={styles.container}>
          <RoundContainer className={styles.ignorePadding}>
            <>
              <div className={styles.title}>Счет для выдачи</div>
              <div className={styles.subtitle}>
                После одобрения заявки мы зачислим деньги <br /> на счет
                посредством СБП
              </div>
            </>
            {isLoading ? (
              <Loader
                variant={"large"}
                color={"purple"}
                className={styles.loader}
              />
            ) : (
              <SBPDataBlock passport={passport} bank_card={bank_card} />
            )}
          </RoundContainer>

          <Button
            className={styles.confirmButton}
            onClick={handleConfirm}
            loading={loading}
          >
            Подтвердить
          </Button>
          {/*<SBPSupportBlock />*/}
        </div>
        {/*<div className={styles.footer_container}>*/}
        {/*</div>*/}
      </MobilePageContainer>
      {/*<FillingAssistance className={styles.footer} />*/}
    </>
  );
};
