import React, { FC } from "react";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { SmartForm } from "../../../../hocs/SmartForm";
import { InputPassword } from "../../../../ui/Inputs/InputPassword";
import { Button } from "../../../../ui/Button";
import { authSagaActions } from "../../../../../sagas/auth/actions";
import { userReducerSelectors } from "../../../../../store/reducers/user/selectors";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { PasswordRequirement } from "./PasswordRequirement";
import { PasswordSchema } from "../../../../../helpers/validation/schemas/passwordValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { BannerWhite } from "../../../../ui/BannerWhite";
import { carBodyType } from "../../../../../store/reducers/application/types";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { lsController } from "../../../../../store/auxiliary/localStorage";

type formType = {
  password: string;
  password_confirm: string;
};

export const RestorePassword: FC = () => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const dispatch = useDispatch();
  const code = useSelector(userReducerSelectors.getCode);
  const loading = useSelector(
    processLoading.get(authSagaActions.createPassword.type)
  );
  const form = useForm<formType>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(PasswordSchema),
  });

  const [password, password_confirm] = useWatch({
    control: form.control,
    name: ["password", "password_confirm"],
  });

  const carBody: carBodyType = useSelector(
    applicationReducerSelectors.getCarBody
  );

  const carCost = Number(lsController.get("cost"));

  const submit = (data: formType) => {
    dispatch(
      authSagaActions.createPassword({
        ...data,
        code: code as string,
      })
    );
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  return (
    <div className={styles.container}>
      {Boolean(carBody) && <BannerWhite />}
      <div className={styles.title}>
        {" "}
        {Boolean(carBody) ? "Создание пароля" : "Восстановление пароля"}
      </div>
      <div className={styles.subtitle}>Придумайте и введите пароль</div>

      <SmartForm form={form} submit={submit}>
        <InputPassword
          name={"password"}
          label="Пароль"
          autoFocus
          className={styles.password}
          isVisible={passwordVisible}
          toggleVisible={togglePasswordVisibility}
        />
        <InputPassword
          name={"password_confirm"}
          label="Повторите пароль"
          className={styles.password}
          isVisible={passwordVisible}
          toggleVisible={togglePasswordVisibility}
        />

        <PasswordRequirement
          password={password}
          password_confirm={password_confirm}
        />

        <Button loading={loading} className={styles.button}>
          Продолжить
        </Button>
      </SmartForm>
    </div>
  );
};
