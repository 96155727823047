import React, { FC } from "react";
import styles from "./index.module.css";
import { Route } from "react-router-dom";
import { HeaderCabinet } from "./components/Header";
import { routes } from "../../../routes";
import { MyLoan } from "./pages/MyLoan";
import { ApplicationInfo } from "./pages/ApplicationInfo";
import { History } from "./pages/History";
import { CabinetStatusRoutes } from "./pages/Statuses";
import { Feedback } from "./pages/Feedback";
import { DocumentsCabinet } from "./pages/Documents";
import { LoanPayment } from "./pages/LoanPayment";
import { EarlyRepaymentCode } from "./pages/EarlyRepaymentCode";
import { EarlyRepaymentIframe } from "./pages/EarlyRepaymentIframe";
import { PageContainer } from "../../ui/Containers/PageContainer";
import { PaidLoan } from "../Application/pages/PaidLoan";
import { Notifications } from "./pages/Notifications";
import { EarlyRepaymentAmount } from "./pages/EarlyRepaymentAmount";
import { EarlyRepaymentToPay } from "./pages/EarlyRepaymenToPay";
import { EarlyRepaymentAccept } from "./pages/EarlyRepaymenAccept";
import { FullEarlyRepaymentIframe } from "./pages/FullEarlyRepaymentIframe";

type propsType = {};
export const Cabinet: FC<propsType> = (props) => {
  return (
    <div className={styles.cabinet_container}>
      <HeaderCabinet />

      <PageContainer>
        <Route path={routes.cabinet.my_loan}>
          <MyLoan />
        </Route>

        <Route path={routes.cabinet.notifications}>
          <Notifications />
        </Route>

        <Route path={routes.cabinet.early_repayment_amount}>
          <EarlyRepaymentAmount />
        </Route>

        <Route path={routes.cabinet.early_repayment_to_pay.path}>
          <EarlyRepaymentToPay />
        </Route>

        <Route path={routes.cabinet.early_repayment_accept}>
          <EarlyRepaymentAccept />
        </Route>

        <Route path={routes.cabinet.loan_payment.path}>
          <LoanPayment />
        </Route>

        <Route path={routes.cabinet.application_info}>
          <ApplicationInfo />
        </Route>

        <Route path={routes.cabinet.history}>
          <History />
        </Route>

        <Route path={routes.cabinet.documents}>
          <DocumentsCabinet />
        </Route>

        <Route path={routes.cabinet.early_repayment_code.path}>
          <EarlyRepaymentCode />
        </Route>

        <Route path={routes.cabinet.early_repayment_iframe.path}>
          <EarlyRepaymentIframe />
        </Route>

        <Route path={routes.cabinet.full_early_repayment_iframe.path}>
          <FullEarlyRepaymentIframe />
        </Route>

        <Route path={routes.cabinet.paid_loan}>
          <PaidLoan />
        </Route>

        <CabinetStatusRoutes />
      </PageContainer>
    </div>
  );
};
