import { authSagaActions } from "../actions";
import { processStateController } from "../../util/helpers/processStateController";
import { StateType } from "../../../store";
import { call, delay, put, race, select, take } from "redux-saga/effects";
import { lsController } from "../../../store/auxiliary/localStorage";
import { logout, userReducer } from "../../../store/reducers/user";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { routingAction } from "../../util/helpers/routingSaga";
import { setProcessLoading } from "../../../store/auxiliary/loadingReducer";
import { matchPath } from "react-router-dom";
import { stringHelpers } from "../../../helpers/string";
import { API } from "../../../api";

/*
  Редирект с тильды
   http://localhost:3000/user/phone?amount=500000&period_month=36&phone=+7%20(222)%20222-22-00

   если юзер есть в бд и есть активные заявки - в смс такая ссылка:
   lk.creddy.ru/?phone=79201112233
   мы должны открыть ему сразу экран с вводом пароля

  Если чел не зареган - ссылка такая
   http://lk.creddy.ru/?phone=79201112233&pin=6369
   НЕ отправляем post /code, кидаем на страницу смс кода c предзаполненным кодом и чекбоксами
*/

export function* autoAuth() {
  const actionType = authSagaActions.autoAuth.type;
  const process = processStateController(actionType);

  yield delay(1000);

  try {
    console.log("autoAuth called");
    yield process.start();
    yield put(setProcessLoading(actionType, true));

    const token = lsController.get("token");
    const user_id = lsController.get("user_id");
    const phone = lsController.get("phone");
    const user_role = lsController.get("user_role");

    const location: string = yield select(
      (state: StateType) => state.router.location.pathname
    );
    const search: string = yield select(
      (state: StateType) => state.router.location.search
    );
    const query: {
      [key: string]: string;
    } = yield select((state: StateType) => state.router.location.query);

    if (location.includes("banki-api")) {
      lsController.set("lead", "banki-api");
    }

    // Для логаута при редиректе с underwriter
    if (location === "/info") {
      console.log(location);
      yield redirect(routes.info);
      yield process.stop();
      return;
    }

    if (location === "/user/device_info") {
      yield redirect(routes.auth.device_info);
      yield process.stop();
      return;
    }

    if (location === "/logout") {
      yield delay(1);
      yield call(logout.saga);
      yield redirect(routes.auth.phone);
      yield process.stop();
      return;
    }

    if (token && phone && user_role && user_id) {
      yield put(userReducer.actions.setToken({ token }));
      yield put(userReducer.actions.setPhone({ phone }));
      yield put(userReducer.actions.setId({ user_id }));
      yield put(userReducer.actions.setUserRole({ user_role }));
      yield put({ type: "routing" });
      return;
    }

    /*
      Тут еще на подумать с разными способами входа, особенно смс с телефона
      мб так заработает, проверить чтобы лишний пост не летел
      Сейчас вроде ок работает

      Если юзер пришел на лендос с телефоном и transaction_id - кидаем пост сразу
      Если только с transaction_id - просто сохраняем и будем кидать пост в getUserSaga
      (Если чел пришел с телефоном, то пост летит второй раз из getUserSaga.
        Нужно делать поле типа makeRepeatResend, если придет тикет на это)
     */

    if (
      search.includes("transaction_id") &&
      search.includes("transaction_provider")
    ) {
      yield delay(1);

      lsController.set("transaction_id", query.transaction_id);
      lsController.set("transaction_provider", query.transaction_provider);

      if (search.includes("webmaster_id")) {
        lsController.set("webmaster_id", query.webmaster_id);
      }

      if (query.phone) {
        yield call(
          API.main.services.postAdvertisementTracking,
          lsController.get("webmaster_id")
            ? {
                // phone: stringHelpers.clearString(query.phone),
                phone: stringHelpers.clearStringMask(query.phone),
                transaction_id: String(lsController.get("transaction_id")),
                advertisement_type: String(
                  lsController.get("transaction_provider")
                ),
                webmaster_id: lsController.get("webmaster_id"),
              }
            : {
                phone: stringHelpers.clearStringMask(query.phone),
                transaction_id: String(lsController.get("transaction_id")),
                advertisement_type: String(
                  lsController.get("transaction_provider")
                ),
              }
        );
      }
    }

    if (location === "/user/personal_cabinet") {
      yield redirect(routes.auth.personal_cabinet);
      yield process.stop();
      return;
    }

    if (
      search.includes("amount") &&
      search.includes("period_month")
    ) {
      lsController.set("amount", query.amount);
      lsController.set("period_month", query.period_month);
    }

    // && search.includes("period_month") && search.includes("phone")

    // TODO: возможно, этот иф можно выпилить т.к. есть отдельный иф для amount и period_month и отдельный для phone с той же логикой
    if (
      search.includes("amount") &&
      search.includes("period_month") &&
      search.includes("phone")
    ) {
      // yield delay(1);
      // yield call(logout.saga);
      yield put(
        authSagaActions.getUser({
          phone: stringHelpers.clearStringMask(query.phone),
        })
      );
      // const amount = lsController.get("amount");
      // const period_month = lsController.get("period_month");
      lsController.set("amount", query.amount);
      lsController.set("period_month", query.period_month);
      yield process.stop();
      return;
    }

    if (search.includes("grz") || search.includes("vin")) {
      const payload = search.includes("grz")
        ? decodeURIComponent(query.grz)
        : decodeURIComponent(query.vin);

      // yield put(authSagaActions.getCar(payload));

      yield redirect(routes.auth.phone);
      yield process.stop();
      return;
    }

    /*
      Если чел не зареган - ссылка такая
     http://lk.creddy.ru/?phone=70000000097&pin=6369
     НЕ отправляем post /code, кидаем на страницу смс кода c предзаполненным кодом и чекбоксами
     */
    if (search.includes("phone") && search.includes("pin")) {
      console.log("query.pin", query.pin);
      yield put(
        userReducer.actions.setIsFromSms({
          isFromSms: true,
        })
      );
      yield put(
        userReducer.actions.setCode({
          code: query.pin,
        })
      );
      yield put(
        authSagaActions.getUser({
          phone: stringHelpers.clearStringMask(query.phone),
        })
      );

      yield delay(1000);

      yield put(
        userReducer.actions.setPhone({
          phone: query.phone,
        })
      );
      yield put(
        authSagaActions.verifyCode({
          code: query.pin,
        })
      );
      // yield redirect(routes.auth.code);
      // yield process.stop();
      return;
    }

    /*
      если юзер есть в бд и есть активные заявки - в смс такая ссылка:
       lk.creddy.ru/?phone=72222222222
       мы должны открыть ему сразу экран с вводом пароля
     */
    if (search.includes("phone")) {
      yield put(
        authSagaActions.getUser({
          phone: stringHelpers.clearStringMask(query.phone),
        })
      );
      yield put(
        userReducer.actions.setPhone({
          phone: query.phone,
        })
      );
      yield process.stop();
      return;
    }

    // http://localhost:3000/application/&amount=500000&period_month=36&phone=+7
    //
    // * 1 END
    // const token = lsController.get("token");
    // const user_id = lsController.get("user_id");
    // const phone = lsController.get("phone");
    // const user_role = lsController.get("user_role");

    const matchPD = matchPath(location, {
      path: routes.docs.pd.root,
      exact: false,
      strict: false,
    });

    const matchAsp = matchPath(location, {
      path: routes.docs.asp.root,
      exact: false,
      strict: false,
    });

    const matchAgreement = matchPath(location, {
      path: routes.docs.agr.root,
      exact: false,
      strict: false,
    });

    const matchLoanContract = matchPath(location, {
      path: routes.docs.loan_contract.root,
      exact: false,
      strict: false,
    });
    const matchByHash = matchPath(location, {
      path: routes.docs.root.path,
      exact: false,
      strict: false,
    });

    if (
      matchPD ||
      matchAgreement ||
      matchLoanContract ||
      matchAsp ||
      matchByHash
    ) {
      yield process.stop();

      return;
    }

    // * поменял 1000 на 1, вроде работает
    yield delay(1);

    if (token && user_id && phone && user_role) {
      yield put(
        userReducer.actions.setToken({
          token,
        })
      );
      yield put(
        userReducer.actions.setId({
          user_id,
        })
      );
      yield put(
        userReducer.actions.setPhone({
          phone,
        })
      );
      yield put(
        userReducer.actions.setUserRole({
          user_role,
        })
      );

      yield put(routingAction());
    } else {
      yield redirect(routes.auth.phone);

      yield process.stop();
    }
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
    });
  } finally {
    /*
      Нельзя тут стопать процесс автоауса,
      потому что он продолжается в routingAction
     */
    // yield process.stop();
  }
}
