import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isMobile } from "react-device-detect";
import styles from "./index.module.css";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { Button } from "../../../../ui/Button";
import { carPhotoSchema } from "./schema";
import { Loader } from "../../../../ui/Loader";
import { SmartForm } from "../../../../hocs/SmartForm";
import { DocumentsArea } from "../../../../ui/DocumentsArea";
import { useGetInfoFromApplication } from "../../../../../hooks/api/useGetInfoFromApplication";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { Images } from "assets/img";
import { routes } from "../../../../../routes";
import { sendMetrics } from "../../../../../metrics";
import { FillingAssistance } from "../../../../ui/FillingAssistance";
import { BannerWhite } from "../../../../ui/BannerWhite";
import { API } from "../../../../../api";
import { useHistory } from "react-router-dom";

export const getCarPhotoInfo = (car_photos: any[], name: string) => {
  return car_photos?.find((car_photo) => car_photo.photo_type === name);
};

export const CarPhoto: FC = () => {
  const history = useHistory();
  const isMobileFromDeviceDetect: boolean =
    process.env.NODE_ENV === "development" ? true : isMobile;
  const dispatch = useDispatch();
  const form = useForm({
    reValidateMode: "onSubmit",
    resolver: yupResolver(carPhotoSchema),
  });

  const loading = useSelector(
    processLoading.get(applicationSagaActions.sendingCarPhotoForRecognize.type)
  );
  // const loading = useSelector(
  //   processLoading.get(applicationSagaActions.createCard.type)
  // );
  const loading2 = useSelector(
    processLoading.get(applicationSagaActions.sendAllCarPhotos.type)
  );

  const isFileUploading = useSelector(processLoading.get("file/isUploading"));

  const applicationId: number | string | null = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const send = () => {
    dispatch(applicationSagaActions.sendAllCarPhotos());
  };

  const submit = () => {
    dispatch(
      applicationSagaActions.sendingCarPhotoForRecognize({
        applicationId,
      })
    );
    // API.main.application
    //   .sendingCarPhotoForRecognize({
    //     applicationId,
    //   })
    //   .then(() =>
    //     history.push(routes.application.statuses.sent_and_scoring_process)
    //   );
  };

  // сомнительный refetchInterval в аргументе
  const { car_photos, isLoading } = useGetInfoFromApplication(5000);

  // зачем это?
  useEffect(() => {
    console.log("car_photos", car_photos);
  }, [isFileUploading]);

  useEffect(() => {
    sendMetrics("foto car");
    sendMetrics("common-client_stranica-foto-avto_common-app", "stranica-foto-avto");
  }, []);

  return (
    <MobilePageContainer>
      {isLoading || !applicationId ? (
        <Loader variant={"large"} color={"purple"} className={styles.loader} />
      ) : isMobileFromDeviceDetect ? (
        <SmartForm form={form} submit={submit}>
          {process.env.REACT_APP_ENVIRONMENT == "stage" && (
            <Button onClick={send} loading={loading2}>
              Отправить тестовые документы
            </Button>
          )}

          <div className={styles.title}>Автомобиль и ПТС <br /> остаются у Вас</div>
          {/*<div className={styles.subtitle}>Автомобиль и ПТС остаются у вас</div>*/}
          <div className={styles.main_subtitle}>
            {/*Сделайте фото автомобиля*/}
          </div>

          {/*<BannerWhite secondBanner />*/}

          {/*<h1 className={styles.main_title}>*/}
          {/*  Чистый автомобиль <br /> оценивается дороже*/}
          {/*</h1>*/}

          {/*<div className={styles.title}>Подготовьте автомобиль.</div>*/}
          {/*<div className={styles.text}>*/}
          {/*  Он должен быть чистым и хорошо освещен, чтобы мы могли его*/}
          {/*  рассмотреть.*/}
          {/*</div>*/}
          {/*<div className={styles.text}>*/}
          {/*  Мы попросим вас снять автомобиль с четырех ракурсов, как показано на*/}
          {/*  примерах далее, а так же панель управления заведеного авто и*/}
          {/*  VIN-номер, который находится под лобовым стеклом*/}
          {/*</div>*/}
          {/*<div className={styles.text}>*/}
          {/*  - госномер - не закрыт, читаем, чисто вымыт и спереди и сзади;*/}
          {/*</div>*/}
          {/*<div className={styles.text}>*/}
          {/*  - на наружных фотографиях у машины должен быть заглушен двигатель и*/}
          {/*  выключены все осветительные приборы*/}
          {/*</div>*/}

          <div className={styles.file_inputs_grid}>
            <div className={styles.document_container}>
              <div className={styles.documents_title}>Ракурс спереди-слева</div>
              <DocumentsArea
                className={styles.ground_files}
                name="files.front_left"
                fixedFiles={["Ракурс спереди-слева"]}
                document_type={"front_left"}
                applicationId={applicationId}
                uploadedDocument={getCarPhotoInfo(car_photos, "front_left")}
                placeholderImage={Images.carPhotoPlaceholders.frontLeft}
              />
            </div>
            <div className={styles.document_container}>
              <div className={styles.documents_title}>
                Ракурс спереди-справа
              </div>
              <DocumentsArea
                className={styles.ground_files}
                name="files.front_right"
                fixedFiles={["Ракурс спереди-справа"]}
                document_type={"front_right"}
                applicationId={applicationId}
                uploadedDocument={getCarPhotoInfo(car_photos, "front_right")}
                placeholderImage={Images.carPhotoPlaceholders.frontRight}
              />
            </div>

            <div className={styles.document_container}>
              <div className={styles.documents_title}>Ракурс сзади-слева</div>
              <DocumentsArea
                className={styles.ground_files}
                name="files.back_left"
                fixedFiles={["Ракурс сзади-слева"]}
                document_type={"back_left"}
                applicationId={applicationId}
                uploadedDocument={getCarPhotoInfo(car_photos, "back_left")}
                placeholderImage={Images.carPhotoPlaceholders.backLeft}
              />
            </div>

            <div className={styles.document_container}>
              <div className={styles.documents_title}>Ракурс сзади-справа</div>
              <DocumentsArea
                className={styles.ground_files}
                name="files.back_right"
                fixedFiles={["Ракурс сзади-справа"]}
                document_type={"back_right"}
                applicationId={applicationId}
                uploadedDocument={getCarPhotoInfo(car_photos, "back_right")}
                placeholderImage={Images.carPhotoPlaceholders.backRight}
              />
            </div>

            <div className={styles.document_container}>
              <div className={styles.documents_title}>Фото с автомобилем</div>
              <DocumentsArea
                className={styles.ground_files}
                name="files.selfie_with_car"
                fixedFiles={["Сэлфи с автомобилем"]}
                document_type={"selfie_with_car"}
                applicationId={applicationId}
                uploadedDocument={getCarPhotoInfo(
                  car_photos,
                  "selfie_with_car"
                )}
                placeholderImage={Images.carPhotoPlaceholders.selfie}
              />
            </div>

            <div className={styles.document_container}>
              <div className={styles.documents_title}>VIN или номер кузова</div>
              <DocumentsArea
                className={styles.ground_files}
                name="files.vin"
                fixedFiles={["VIN"]}
                document_type={"vin"}
                applicationId={applicationId}
                uploadedDocument={getCarPhotoInfo(car_photos, "vin")}
                placeholderImage={Images.carPhotoPlaceholders.vin}
              />
            </div>

            <div className={styles.document_container}>
              <div className={styles.documents_title}>
                Передняя часть салона
              </div>
              <DocumentsArea
                className={styles.ground_files}
                name="files.interior"
                fixedFiles={["Передняя часть салона"]}
                document_type={"interior"}
                applicationId={applicationId}
                uploadedDocument={getCarPhotoInfo(car_photos, "interior")}
                placeholderImage={Images.carPhotoPlaceholders.carSalon}
              />
            </div>

            <div className={styles.document_container}>
              <div className={styles.documents_title}>Приборная панель</div>
              <DocumentsArea
                className={styles.ground_files}
                name="files.dashboard"
                fixedFiles={["Приборная панель"]}
                document_type={"dashboard"}
                applicationId={applicationId}
                uploadedDocument={getCarPhotoInfo(car_photos, "dashboard")}
                placeholderImage={Images.carPhotoPlaceholders.dashboard}
              />
            </div>
          </div>

          <Button
            className={styles.btn}
            loading={loading}
            disabled={car_photos?.length < 8 || isFileUploading}
          >
            Продолжить
          </Button>
          <FillingAssistance />
        </SmartForm>
      ) : (
        <div>Чтобы продолжить, откройте заявку на телефоне</div>
      )}
    </MobilePageContainer>
  );
};
