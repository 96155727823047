import { createFC } from "helpers/createFC";
import styles from "./index.module.css";
import { HTMLAttributes } from "react";
import cn from "classnames";

type propsType = {
  // children: any;
  variant?: "default" | "cabinet" | "cabby";
} & HTMLAttributes<HTMLDivElement>;

export const RoundContainer = createFC(
  ({ children, variant, className }: propsType) => {
    return (
      <div
        className={cn(styles.container, className, {
          [styles.cabinet]: variant === "cabinet",
          [styles.cabinet_cabby]: variant === "cabby",
        })}
      >
        {children}
      </div>
    );
  }
);
