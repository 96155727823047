import React, { ReactElement, ReactFragment } from "react";
import styles from "./index.module.css";
import { TextInputPropsType } from "components/ui/Inputs/types";
import cn from "classnames";
import { ConnectedForm } from "../ConnectedForm";
import { Icons } from "../../../assets/icons";

type checkBoxPropsType<T> = {
  blockValue?: boolean;
  iconPosition?: "left" | "right";
  iconClassName?: string
  label: ReactElement;
  primaryColor?: "purple-default" | "blue";
} & Omit<TextInputPropsType, "control" | "label">;

export function Radio<T>({
  name,
  className,
  iconPosition = "left",
  blockValue,
  primaryColor = "purple-default",
  ...jsxAttr
}: checkBoxPropsType<T>) {
  return (
    <ConnectedForm>
      {({ control }) => {
        const inputProps = control.register(name);

        return (
          <div className={cn(styles.container, className)}>
            <div className={styles.Input__wrapper}>
              <input
                type="radio"
                className={styles.input}
                id={jsxAttr.value || name}
                {...jsxAttr}
                value={blockValue ? undefined : jsxAttr.value}
                {...control.register(name)}
                onChange={(event) => {
                  inputProps.onChange(event).finally();
                  jsxAttr.onChange && jsxAttr.onChange(event);
                }}
              />
              <label htmlFor={jsxAttr.value || name} className={styles.label}>
                {jsxAttr.label}
                <div
                  className={cn(styles.icon_checked, jsxAttr?.iconClassName, {
                    [styles.icon_checked__left]: iconPosition === "left",
                  })}
                >
                  <Icons.ui.Radio_checked className={cn({
                    [styles.blue]: primaryColor === "blue",
                    [styles.blue_checked]: primaryColor === "blue",
                  })} />
                </div>
                <div
                  className={cn(styles.icon_unchecked, jsxAttr?.iconClassName, {
                    [styles.icon_unchecked__left]: iconPosition === "left",
                  })}
                >
                  <Icons.ui.Radio_unchecked className={cn({
                    [styles.blue]: primaryColor === "blue",
                  })} />
                </div>
              </label>
            </div>
          </div>
        );
      }}
    </ConnectedForm>
  );
}
