import { validateInn } from "helpers/validation/validateInn";
import * as yup from "yup";
import { validationsMessages } from "../../../../../helpers/validation/validationsMessages";
import { dateMethods } from "../../../../../helpers/date";

let minData: any = dateMethods.dateMinus60Years();
let maxData: any = dateMethods.dateMinus18Years();

// maxData = Number(maxData);
// minData = Number(minData) - 129600000;

// console.log("Number(maxData);", Number(maxData));

export const passportAndInnSchema = yup.object().shape({
  address_match: yup.boolean(),
  // inn: yup
  //   .string()
  //   .typeError(validationsMessages.requiredField)
  //   .required(validationsMessages.requiredField)
  //   .test(
  //     "length",
  //     "ИНН должен состоять из 12 цифр",
  //     (val) => val?.length === 12
  //   )
  //   .test("is inn valid", "ИНН не является валидным", validateInn),

  registration_address: yup.object().shape({
    text: yup.string().required(validationsMessages.requiredField),
    value: yup.object().shape({
      data: yup.object().shape({
        house: yup.string().nullable().required(validationsMessages.address),
      }),
    }),
  }),
  actual_address: yup.object().when("address_match", {
    is: false,
    then: yup.object().shape({
      text: yup.string().required(validationsMessages.requiredField),
      value: yup.object().shape({
        data: yup.object().shape({
          house: yup.string().nullable().required(validationsMessages.address),
        }),
      }),
    }),
  }),

  // ["passport.last_name"]: yup
  //   .string()
  //   .required(validationsMessages.required),

  passport: yup.object().shape({
    // last_name: yup
    //   .string()
    //   .required(validationsMessages.requiredField)
    //   .matches(
    //     /^[а-яёА-ЯЁa]+(?:[ -][а-яёА-ЯЁa]+)*$/i,
    //     validationsMessages.onlyLetters
    //   ),
    // first_name: yup
    //   .string()
    //   .required(validationsMessages.requiredField)
    //   .matches(
    //     /^[а-яёА-ЯЁa]+(?:[ -][а-яА-Яa]+)*$/i,
    //     validationsMessages.onlyLetters
    //   ),
    // middle_name: yup
    //   .string()
    //   .matches(
    //     /^$|[а-яёА-ЯЁa]+(?:[ -][а-яёА-ЯЁa]+)*$/i,
    //     validationsMessages.onlyLetters
    //   ),
    // birth_day: yup
    //   // .date()
    //   // .min(minData, validationsMessages.minDate)
    //   // .max(maxData, validationsMessages.maxDate),
    //   .date()
    //   .required(validationsMessages.requiredField)
    //   .max(maxData, validationsMessages.maxDate)
    //   .min(minData, validationsMessages.minDate),

    // гендер не важен!
    series: yup
      .string()
      .required(validationsMessages.requiredField)
      .min(4, validationsMessages.fullData)
      .max(4, validationsMessages.fullData),
    number: yup
      .string()
      .required(validationsMessages.requiredField)
      .min(6, validationsMessages.fullData)
      .max(6, validationsMessages.fullData),

    issue_date: yup
      .date()
      .required(validationsMessages.requiredField)
      .test("is-date-correct", validationsMessages.correctDate, (value, context) => {
        return dateMethods.validate(context.options.context?.dateInputValue);
      })
      .min(new Date("1900-01-01"), "Дата не должна быть ранее 1900 г.")
      .max(new Date("2100-01-01"), "Дата не должна быть позднее 2100 г.")
      .test("is-date-form-past", validationsMessages.dateFromPast, (date) => !!(date && date < new Date())),

    division_code: yup
      .string()
      .required(validationsMessages.requiredField)
      .min(7, validationsMessages.fullData),
  }),
});
