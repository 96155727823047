import React, { AllHTMLAttributes, FC } from "react";
import styles from "./index.module.css";
import cn from "classnames";

type propsType = {
  addALine?: boolean;
} & AllHTMLAttributes<HTMLDivElement>;

export const Title: FC<propsType> = ({
  addALine = false,
  children,
  className,
  ...jsxAttr
}) => {
  return (
    <>
      <div {...jsxAttr} className={cn(styles.container, className)}>
        {children}
      </div>
      {addALine && <div className={styles.line} />}
    </>
  );
};
