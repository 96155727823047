export const refinancingPurposeForSelect = [
  {
    id: 0,
    value: "repair",
    text: "Ремонт",
  },
  {
    id: 1,
    value: "buying_a_property",
    text: "Покупка недвижимости",
  },
  {
    id: 2,
    value: "buying_a_car",
    text: "Покупка автомобиля",
  },
  {
    id: 3,
    value: "repayment_of_loans",
    text: "Погашение кредитов",
  },
  {
    id: 4,
    value: "business_development",
    text: "Развитие бизнеса",
  },
  {
    id: 5,
    value: "treatment",
    text: "Лечение",
  },
  {
    id: 6,
    value: "other",
    text: "Другое",
  },
];
