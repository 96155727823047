import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import cn from "classnames";
import { API } from "../../../../../api";
import { Icons } from "assets/icons";
import { useGetCar } from "../../../../../hooks/api/useGetCar";
import {
  errorsReducer,
  errorsSelectors,
} from "../../../../../store/auxiliary/errorsReducer";
import {
  carBodyType,
  carType,
} from "../../../../../store/reducers/application/types";
import { sendMetrics } from "../../../../../metrics";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { userReducerSelectors } from "../../../../../store/reducers/user/selectors";
import { Button } from "../../../../ui/Button";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { CodeField } from "../../../../ui/CodeField";
import { useCodeFieldLogic } from "../../../../ui/CodeField/useCodeFieldLogic";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { authSagaActions } from "../../../../../sagas/auth/actions";
import { StateType } from "../../../../../store";
import { routes } from "../../../../../routes";
import { linksToDocumentsOnStatic } from "../../../../../helpers/init/constantStrings";
import { stringHelpers } from "../../../../../helpers/string";
// import { Code } from "../../../Auth/pages/Code";
import { BannerGreen } from "../../../../ui/BannerGreen";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { sagaActions } from "../../../../../sagas/util/sagaActions";
import { FillingAssistance } from "../../../../ui/FillingAssistance";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { Checkbox, CheckboxUncontrolled } from "../../../../ui/Checkbox";
import { Radio } from "../../../../ui/Radio";
import { SmartForm } from "../../../../hocs/SmartForm";
import { InputNumber } from "../../../../ui/Inputs/InputNumber";
import { CodeFieldOneInput } from "../../../../ui/CodeFieldOneInput";
import { CodeTimer } from "../../../Application/components/CodeTimer";

type propsType = {
  width?: number;
};

export const CarCheckingCode: FC<propsType> = ({ ...props }) => {
  const dispatch = useDispatch();
  const form = useForm();
  const history = useHistory();

  const { pathname } = useLocation();

  const codeFromQuery = useSelector(userReducerSelectors.getCode);

  const errors = useSelector(errorsSelectors.fieldsErrors);

  const loadingFirstStep = useSelector(
    processLoading.get(authSagaActions.verifyCode.type)
  );

  const loadingSecondStep = useSelector(
    processLoading.get(authSagaActions.createPassword.type)
  );

  const loadingThirdStep = useSelector(
    processLoading.get(authSagaActions.getToken.type)
  );

  const loadingForthStep = useSelector(
    processLoading.get(authSagaActions.autoAuth.type)
  );

  const loadingFifthStep = useSelector(
    processLoading.get(applicationSagaActions.createApplicationShort.type)
  );

  const loading =
    loadingFirstStep ||
    loadingSecondStep ||
    loadingThirdStep ||
    loadingForthStep ||
    loadingFifthStep;

  const [isChecked1, setIsChecked1] = useState(true);
  const [codeRequested, setCodeRequested] = useState(false);

  // const code = useSelector((state: StateType) => state.userReducer.code);

  const code: `${number}` | undefined = useWatch({
    control: form.control,
    name: "code",
  });

  const { codeMode } = useParams<{
    codeMode:
      | "register_mobile"
      | "register_desktop"
      | "logon_desktop"
      | "logon_mobile";
  }>();

  const codeField = useCodeFieldLogic({
    defaultValue: code || undefined,
    codeLength: 4,
    onChange: () => {
      dispatch(errorsReducer.actions.removeFieldError("code"));
    },
  });

  const confirm = () => {
    dispatch(
      authSagaActions.verifyCode({
        code: code!,
      })
    );
  };

  const resend = () => {
    setCodeRequested(true);
    API.auth.code({ smsTemplate: codeMode }).then((res) => {
      if (res.status === 201) {
        sendMetrics(
          "common-client_otpravlen-nomer-dlya-registracii_common-app",
          "otpravlen-nomer-dlya-registracii"
        );
      }
    });
    // dispatch(authSagaActions.getCode({ smsTemplate: codeMode }));
  };

  const isButtonDisabled = codeRequested && (!code || code.length < 4);
  // pathname === `${routes.auth.code}/register`
  //   ? codeField.value.length !== 4 || !isChecked1
  //   : codeField.value.length !== 4;

  const carBody: carBodyType = useSelector(
    applicationReducerSelectors.getCarBody
  );

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && (isButtonDisabled || loading)) {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isButtonDisabled, loading]);

  const phoneNumber =
    useSelector((state: StateType) => state.userReducer.phone) ||
    lsController.get("phone");

  const carCost = Number(lsController.get("cost"));

  const [document] = useWatch({
    control: form.control,
    name: ["document"],
  });

  const grz = lsController.get("grz");

  // if (!carBody) {
  //   return <Code />;
  // }

  // const car: carType = useSelector(applicationReducerSelectors.getCar);
  // const { carData } = useGetCar({ vin: car?.vin, reg_number: car?.reg_number });

  useEffect(() => {
    form.setValue("code", codeFromQuery);
  }, [codeFromQuery]);

  const isBankiru = lsController.get("lead");

  return (
    <MobilePageContainer className={cn(styles.form, {
      [styles.form_banki]: isBankiru
    })}>
      {isBankiru && (
        <div className={styles.banner}>
          <BannerGreen
            content={"Для получения денег, подтвердите\n номер телефона и выберите банк"}
            support={<Icons.ui.CheckmarkWhiteCircle />}
          />
        </div>
      )}
      <>
        <SmartForm form={form} submit={() => console.log()} className={cn(styles.container, {
          [styles.container_banki]: isBankiru
        })}>
          {!isBankiru && <div className={styles.auth_block_title}>Запрос кода</div>}
          {!isBankiru ? (
            <div className={styles.auth_block_text}>
              Нажмите на кнопку и получите код
            </div>
          ) : (
            <div className={styles.auth_block_text}>
              Нажмите кнопку "Получить код" <br /> и мы отправим его на номер:
            </div>
          )}
          <div className={cn(styles.auth_block_code, {
            [styles.auth_block_code_blue]: isBankiru
          })}>
            <div>{stringHelpers.phoneNormalizeSecondVariant(phoneNumber)}</div>
            <div
              className={cn({
                [styles.icon_blue]: isBankiru,
              })}
            >
              <Icons.ui.PencilPurple
                onClick={() => history.push(routes.auth.phone)}
              />
            </div>
          </div>
          <div className={cn(styles.code, {
            [styles.code_banki]: isBankiru
          })}>
            <CodeFieldOneInput
              primaryColor={isBankiru ? "blue" : undefined}
              disabled={!codeRequested}
              // sendCodeHandler={resend}
              // retryCount={codeField.retryCount}
              // setRetryCount={codeField.setRetryCount}
            />
            {/*<CodeField*/}
            {/*  {...codeField}*/}
            {/*  sendCodeHandler={resend}*/}
            {/*  error={errors?.code}*/}
            {/*  retryCount={codeField.retryCount}*/}
            {/*  setRetryCount={codeField.setRetryCount}*/}
            {/*/>*/}
          </div>
          <div className={styles.button}>
            <Button
              className={cn({ [styles.button_blue]: isBankiru })}
              loading={loading}
              disabled={isButtonDisabled || !isChecked1}
              onClick={codeRequested ? confirm : resend}
            >
              {!codeRequested ? "Получить код" : "Продолжить"}
            </Button>
          </div>
          {codeRequested && (
            <div className={cn(styles.code_timer, {
              [styles.code_timer_banki]: isBankiru
            })}>
              <CodeTimer
                // disabled={disabled}
                primaryColor={"blue"}
                sendCodeHandler={resend}
                retryCount={codeField.retryCount}
                setRetryCount={codeField.setRetryCount}
              />
            </div>
          )}

          <div className={styles.container_footer}>
            <div className={styles.notes_block}>
              <Checkbox
                name={"pd"}
                className={styles.mr_check_box}
                onChange={() => setIsChecked1((prev) => !prev)}
                checked={isChecked1}
                blockValue={true}
              />
              <div>
                <a target={"_blank"} href={linksToDocumentsOnStatic.pd}>
                  <Icons.ui.PdfLogo />
                </a>
              </div>
              <div className={styles.notes_block_text}>
                <a target={"_blank"} href={linksToDocumentsOnStatic.pd}>
                  {" "}
                  Согласие на обработку персональных данных и получение
                  кредитных отчетов. Соглашение об использовании аналога
                  собственноручной подписи
                </a>
              </div>
            </div>
          </div>
        </SmartForm>
      </>
    </MobilePageContainer>
  );
};
