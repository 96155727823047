import React, { ReactComponentElement, SVGProps } from "react";
import styles from "./index.module.css";
import { Icons } from "../../../assets/icons";
import { stringHelpers } from "../../../helpers/string";
import { lsController } from "../../../store/auxiliary/localStorage";
import { ReactComponent as Checkmark_white_circle } from "../../../assets/icons/ui/Checkmark_white_circle.svg";
import { showDMYDate } from "../../../helpers/date";

type propsType = {
  variant?: "default" | "greeting";
  maxSumFromOffers?: number;
  endData?: string;
  support?: ReactComponentElement<"svg">;
  content?: string | JSX.Element;
};

export const BannerGreen = (props: propsType) => {
  const { variant = "default", maxSumFromOffers, endData, content, support } = props;

  const carCost = Number(lsController.get("cost"));

  const currentDate = new Date(endData || new Date());
  currentDate.setDate(currentDate.getDate() + 5);

  const padZero = (num: number) => (num < 10 ? "0" + num : num);

  const newDateString = [
    padZero(currentDate.getDate()),
    padZero(currentDate.getMonth() + 1),
    currentDate.getFullYear(),
  ].join(".");

  if (variant === "greeting") {
    return (
      <>
        <div className={styles.car_price_block_greeting}>
          <div className={styles.img_container}>
            <Icons.ui.CheckmarkWhiteCircle />
          </div>
          <div className={styles.text_greeting_container}>
            <div className={styles.greeting_title}>Поздравляем!</div>
            <div>
              {maxSumFromOffers ? (
                <>
                  Вам одобрена сумма
                  <span className={styles.price}>
                    {" "}
                    {stringHelpers.transformMoneyValue(maxSumFromOffers)} ₽
                  </span>
                </>
              ) : (
                <>
                  Максимальная сумма займа до
                  <span className={styles.price}> 500 000 ₽</span>
                </>
              )}
            </div>
            <div className={styles.additional_title}>
              {`Успейте получить до ${newDateString}`}
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.car_price_block}>
        <div className={styles.img_container}>
          {/*<Icons.ui.BigFingerUpGreen />*/}
          {support}
        </div>
        <div className={styles.content}>
          {content}
          {/*{carCost ? (*/}
          {/*  <>*/}
          {/*    Залоговая стоимость <br /> Вашего автомобиля*/}
          {/*    <span className={styles.price}>*/}
          {/*      {" "}*/}
          {/*      {stringHelpers.transformMoneyValue(carCost)} ₽*/}
          {/*    </span>*/}
          {/*  </>*/}
          {/*) : (*/}
          {/*  <>*/}
          {/*    Максимальная сумма займа до*/}
          {/*    <span className={styles.price}> 500 000 ₽</span>*/}
          {/*  </>*/}
          {/*)}*/}
        </div>
      </div>
    </>
  );
};
