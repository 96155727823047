import React, { FC } from "react";
import { motion } from "framer-motion";
import styles from "./index.module.css";
import {
  sidebarAnimation,
  sidebarLinkAnimation,
  sidebarUpperPartAnimation
} from "./animate";
import { Icons } from "../../../../../assets/icons";
import { sidebarData } from "./data";
import { Link, useHistory } from "react-router-dom";
import { clearState } from "../../../../../store/auxiliary/clearState";
import { routes } from "../../../../../routes";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { useSelector } from "react-redux";
import { StateType } from "../../../../../store";
import { normalizeFullNameFromUppercase, stringHelpers } from "../../../../../helpers/string";
import { constantStrings } from "../../../../../helpers/init/constantStrings";
import { setTokenToAxios } from "../../../../../api/methods/setTokenToAxios";
import { loanReducerSelectors } from "../../../../../store/reducers/loan";

type propsType = {
  isOpenSidebar: boolean;
  setIsOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SidebarCabinet: FC<propsType> = ({
                                                isOpenSidebar,
                                                setIsOpenSidebar
                                              }) => {
  const history = useHistory();
  const full_name = useSelector((state: StateType) => state.userReducer.full_name);
  const phone = useSelector((state: StateType) => state.userReducer.phone);
  const { last_name, first_name } = normalizeFullNameFromUppercase(full_name);

  const loanStatus = useSelector(loanReducerSelectors.getLoanStatus);

  const closeSidebar = () => {
    setIsOpenSidebar(false);
  };

  const handleLogout = () => {
    clearState();
    setTokenToAxios(null)
    lsController.clearAll();
    history.push(routes.auth.personal_cabinet);
  };

  return (
    <motion.div
      variants={sidebarAnimation}
      initial={"close"}
      animate={isOpenSidebar ? "open" : "close"}
      className={styles.container}
    >
      <motion.div
        variants={sidebarUpperPartAnimation}
        className={styles.upper_part}
      >
        <div className={styles.creddy_logo}>
          <div className={styles.creddy_logo}>
            <a href="https://creddy.ru/" target={'_blank'}>
              <Icons.logos.Creddy className={styles.icon_logo}/>
            </a>
          </div>
        </div>
        <div className={styles.cross}>
          <Icons.ui.Cross onClick={closeSidebar} />
        </div>
      </motion.div>

      <motion.div
        variants={sidebarUpperPartAnimation}
        className={styles.main_info}
      >
        <p className={styles.fio}>{first_name} {last_name}</p>
        <p className={styles.car}>{stringHelpers.phoneNormalize(String(phone))}</p>
      </motion.div>

      <div className={styles.bottom_part}>
        {sidebarData.map(({ name, route, icon }) => (
          <motion.div variants={sidebarLinkAnimation} key={route}>
            <Link to={loanStatus === "paid_loan" && name === "Мой займ"
              ? routes.cabinet.paid_loan
              : route
            } onClick={closeSidebar} className={styles.link}>
              {React.createElement(icon, {})}
              <p className={styles.link_text}>{name}</p>
            </Link>
          </motion.div>
        ))}

        <motion.div
          variants={sidebarLinkAnimation}
          onClick={handleLogout}
          className={styles.link}
        >
          <Icons.cabinet.Exit />
          <p className={styles.link_text}>Выход</p>
        </motion.div>

        <motion.div
          variants={sidebarUpperPartAnimation}
          className={styles.footer}
        >
            <div className={styles.footer_title}>
              Связаться с техподдержкой
            </div>
            <div className={styles.footer_links}>
              <a href={`https://wa.me/${constantStrings.whatsappNumberForSupport}`} target={"_blank"}>
                <Icons.logos.Whatsapp />
              </a>
              <a href={constantStrings.telegramLinkForSupport} target={"_blank"}>
                <Icons.logos.Telegram />
              </a>
              <a href={`tel:${constantStrings.phoneNumberForSupport}`}>
                <Icons.logos.Phone />
              </a>
            </div>
        </motion.div>
      </div>
    </motion.div>
  );
};
