import PD from "./pages/pd";
import CreditLineAgreement from "./pages/agreement";
import LoanContract from "./pages/loan_contract";
import { Route } from "react-router-dom";
import { routes } from "../../../routes";
import PdDownloadPdf from "./pages/pdDownloadPdf";
import AspDownloadPdf from "./pages/aspDownloadPdf";
import DownloadDocumentByHash from "./pages/DownloadDocumentByHash";

export const Docs = () => {
  return (
    <>
      <Route path={routes.docs.pd.path} exact>
        <PD />
      </Route>


      <Route path={routes.docs.root.path} exact>
        <DownloadDocumentByHash />
      </Route>

      <Route path={routes.docs.pd.root} exact>
        <PdDownloadPdf />
      </Route>

      <Route path={routes.docs.asp.root} exact>
        <AspDownloadPdf />
      </Route>

      <Route path={routes.docs.agr.path} exact>
        <CreditLineAgreement />
      </Route>

      <Route path={routes.docs.loan_contract.path} exact>
        <LoanContract />
      </Route>
    </>
  );
};
