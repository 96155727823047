import { createFC } from "../../../../helpers/createFC";
import styles from "./index.module.css";
import { Icons } from "../../../../assets/icons";
import React from "react";
import { LicensePlateInput } from "./components/MainPart";
import { RegionPartInput } from "./components/RegionPart";
import cn from "classnames";

type propsType = {
  huge?: boolean;
  mainFieldProps: any;
  regionFieldProps: any;
  error?: string;
};

export const LicensePlate = createFC((props: propsType) => {
  const { mainFieldProps, regionFieldProps, error } = props;

  return (
    <div
      className={cn(styles.container, {
        [styles.container__error]: error,
        [styles.huge_container]: props.huge,
      })}
    >
      <div className={styles.left}>
        <LicensePlateInput
          hugeInputWrapper
          {...mainFieldProps}
          id={"mainPartInput"}
        />
      </div>
      <div className={styles.right}>
        <RegionPartInput
          hugeInputWrapper
          {...regionFieldProps}
          id={"regionPartInput"}
          className={styles.region}
        />
        <div className={styles.rus}>
          <div className={styles.label}>RUS</div>
          <Icons.ui.RUSSIA className={styles.image} />
        </div>
      </div>
    </div>
  );
});
