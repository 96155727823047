import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { LoaderPage } from "../../components/ui/LoaderPage";

// lazy load component with custom fallback

export const lazyLoad = (importCb: () => PromiseLike<any>) =>
  loadable(() => pMinDelay(importCb(), 500), {
    fallback: <LoaderPage />,
  });
