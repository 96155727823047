import React, { FC } from "react";
import styles from "./index.module.css";
import moment from "moment";
import { loanDocumentsType } from "../../../../../../../types/documents/types";
import { stringHelpers } from "../../../../../../../helpers/string";
import { useGetInfoFromApplication } from "../../../../../../../hooks/api/useGetInfoFromApplication";
import { DocumentsBlock } from "../DocumentsBlock";
import { DocumentsBlockHeader } from "../DocumentsBlockHeader";
import { SingleDocument } from "../SingleDocument";

type propsType = {
  data: {
    id: number;
    amount: number;
    loan_number: number;
    documents: Array<loanDocumentsType>;
  };
};

export const DocumentsBlockItem: FC<propsType> = ({ data }) => {
  const { data: applicationData, userId } = useGetInfoFromApplication();

  const applyingAgreement = data?.documents?.find(
    (doc) => doc.document_type === "applying_agreement"
  );
  const applyingAgreementSecond = data?.documents?.find(
    (doc) => doc.document_type === "applying_agreement_second"
  );
  const depositContract = data?.documents?.find(
    (doc) => doc.document_type === "deposit_contract"
  );
  const loanContract = data?.documents?.find(
    (doc) => doc.document_type === "loan_contract"
  );
  const personalDataAgreement = data?.documents?.find(
    (doc) => doc.document_type === "personal_data_agreement"
  );
  const preAcceptance = data?.documents?.find(
    (doc) => doc.document_type === "pre_acceptance"
  );
  const riskNotification = data?.documents?.find(
    (doc) => doc.document_type === "risk_notification"
  );

  const agreementDocuments = [
    {
      title: "Согласие с обработкой ПД и использованием АСП",
      documentType: "personal_data_agreement",
      documentNumber: personalDataAgreement?.id,
      date: moment(personalDataAgreement?.created_at).format("DD.MM.YYYY"),
    },
    // {
    //   title: "Согласие с безакцептным списанием",
    //   documentType: "pre_acceptance",
    //   documentNumber: preAcceptance?.id,
    //   date: moment(preAcceptance?.created_at).format("DD.MM.YYYY"),
    // },
  ];

  const loanDocuments = [
    {
      title: "Заявление на предоставление займа",
      documentType: applicationData?.repledge
        ? "applying_agreement_second"
        : "applying_agreement",
      documentNumber: applyingAgreement?.id,
      date: moment(applyingAgreement?.created_at).format("DD.MM.YYYY"),
    },
    {
      title: "Индивидуальные условия",
      documentType: "loan_contract",
      documentNumber: loanContract?.id,
      date: moment(loanContract?.created_at).format("DD.MM.YYYY"),
    },
    {
      title: "Договор залога",
      documentType: "deposit_contract",
      documentNumber: depositContract?.id,
      date: moment(depositContract?.created_at).format("DD.MM.YYYY"),
    },
  ];

  if (riskNotification) {
    loanDocuments.push({
      title: "Уведомление о существующих рисках",
      documentType: "risk_notification",
      documentNumber: riskNotification?.id,
      date: moment(riskNotification?.created_at).format("DD.MM.YYYY"),
    });
  }

  return (
    <div className={styles.loan_documents}>
      <div className={styles.block}>
        <DocumentsBlock>
          <DocumentsBlockHeader
            title={<>Договор № {data?.loan_number}</>}
            amount={stringHelpers.transformMoneyValue(data?.amount)}
            date={moment(
              data?.documents?.find(
                (doc) => doc.document_type === "loan_contract"
              )?.created_at
            ).format("DD.MM.YYYY")}
          />
          {loanDocuments.map((item) => {
            return (
              <SingleDocument
                key={item.documentNumber}
                applicationId={data.id}
                documentType={item?.documentType}
                userId={userId}
                title={item.title}
                documentNumber={item.documentNumber || ""}
                date={item.date}
              />
            );
          })}
        </DocumentsBlock>
      </div>
      <div className={styles.block}>
        <DocumentsBlock>
          {agreementDocuments.map((item) => {
            return (
              <SingleDocument
                key={item.documentNumber}
                applicationId={data.id}
                documentType={item?.documentType}
                userId={userId}
                title={item.title}
                documentNumber={item.documentNumber || ""}
                date={item.date}
              />
            );
          })}
        </DocumentsBlock>
      </div>
    </div>
  );
};
