import { PayloadAction } from "@reduxjs/toolkit";
import { createApplicationType } from "../../../api/main/application/types";
import { processStateController } from "../../util/helpers/processStateController";
import { call, put } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { applicationReducer } from "../../../store/reducers/application";
import {lsController} from "../../../store/auxiliary/localStorage";

export function* createApplicationSaga({
  payload,
  type: actionType,
}: PayloadAction<createApplicationType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const response: sagaApiType = yield call(
      API.main.application.createApplication,
      payload
    );
    lsController.set('application_id', response.data?.data?.id)

    yield put(
      applicationReducer.actions.setApplicationId({
        application_id: response.data?.data?.id,
      })
    );

    yield redirect(routes.application.statuses.checking_car);
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
      handledStatuses: [400],
    });
  } finally {
    yield process.stop();
  }
}
