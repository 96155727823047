import { PayloadAction } from "@reduxjs/toolkit";
import {
  createLoanPaymentType,
  createPartialEarlyRepaymentType,
} from "../../../api/main/application/types";
import { processStateController } from "../../util/helpers/processStateController";
import { call, delay, put } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { loanReducer } from "../../../store/reducers/loan";

// сага подходит на чдп и на пдп тоже
export function* createPartialEarlyRepaymentSaga({
  payload,
  type: actionType,
}: PayloadAction<createPartialEarlyRepaymentType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    yield put(
      loanReducer.actions.setPaymentType({ paymentType: payload.type })
    );

    const response: sagaApiType = yield call(
      API.main.application.createPartialEarlyRepayment,
      payload
    );

    yield put(
      loanReducer.actions.setAmountForPayment({
        amountForPayment: response?.data?.data?.amount_for_payment,
      })
    );

    yield put(
      loanReducer.actions.setPaymentDate({
        paymentDate: response?.data?.data?.payment_date,
      })
    );

    yield put(
      loanReducer.actions.setCreatedAtPayment({
        createdAtPayment: response?.data?.data?.created_at,
      })
    );

    yield redirect(
      routes.cabinet.early_repayment_to_pay.root({
        loan_id: payload.loan_id,
        early_repayment_id: response?.data?.data?.id,
        amount: payload.amount,
      })
    );

    // yield redirect(
    //   routes.cabinet.early_repayment_code.root({
    //     loan_id: payload.loan_id,
    //     early_repayment_id: response?.data?.data?.id,
    //     amount: payload.amount,
    //   })
    // );
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
    });
  } finally {
    yield process.stop();
  }
}
