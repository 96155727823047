import { mainAxios } from "../index";
import {
  addingPassportAndInnToApplicationType,
  applicationBasisType,
  createApplicationType,
  createLoanPaymentType,
  createPartialEarlyRepaymentType,
  creatingAnAccrualType,
  deletePartialEarlyRepaymentType,
  downloadDocumentType, getInnQueryType,
  getLoanType,
  getPartialEarlyRepaymentCodeType,
  getPaymentType,
  getScheduleType,
  postApplicationType,
  sendingLoanTermsType,
  updateApplicationType,
  verifyApplicationType,
  verifyPartialEarlyRepaymentCodeType,
} from "./types";
import { AxiosResponse } from "axios";
import { dataToQueryString } from "../../methods/dataToQueryString";
import { authAxios } from "../../auth";
import { applicationType, leadType } from "../../../types/application/types";
import {
  earlyRepaymentInfoType,
  loanType,
  notificationDataType,
  paymentInfoType,
} from "../../../types/cabinet/types";

class ApplicationClass {
  user_id: number | null = null;

  constructor() {}

  getApplications = () =>
    mainAxios.get<{
      data: { items: Array<applicationType>; items_count: number };
    }>(`/user/${this.user_id}/application`);

  getApplication = (
    { applicationId }: applicationBasisType,
    signal?: AbortSignal
  ) =>
    mainAxios.get<{ data: applicationType }>(
      `/user/${this.user_id}/application/${applicationId}`,
      {
        signal,
      }
    );

  getInn = ({
    birth_day,
    first_name,
    last_name,
    middle_name,
    passport_series,
    passport_number
  }: getInnQueryType) =>
    mainAxios.get<{ data: { inn: `${number}` } }>(
      `/inn?birth_day=${birth_day}&first_name=${first_name}&last_name=${last_name}&middle_name=${middle_name}&passport_series=${passport_series}&passport_number=${passport_number}`
    );

  getLead = ({ phone }: { phone: string }) =>
    mainAxios.get<{ data: leadType }>(`/lead?phone=${phone}`);

  createApplication = (body: createApplicationType) =>
    mainAxios.post(`/user/${this.user_id}/application`, body);

  postApplication = ({ ...body }: postApplicationType) =>
    mainAxios.post<{ data: Partial<applicationType> }>(`/user/${this.user_id}/application`, body);

  createApplicationShort = (body: { vin_or_reg_number: string }) =>
    mainAxios.post(`/user/${this.user_id}/application`, body);

  updateApplication = ({ applicationId, ...body }: updateApplicationType) =>
    mainAxios.patch(`/user/${this.user_id}/application/${applicationId}`, body);

  sendingApplicationForEnrichment = ({ applicationId }: applicationBasisType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/enrichment`
    );

  addingPassportAndInnToApplication = ({
    applicationId,
    ...body
  }: addingPassportAndInnToApplicationType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/passport`,
      body
    );

  sendingDocumentsForRecognize = ({ applicationId }: applicationBasisType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/photo:check`
    );

  sendingReplacedPhotoForRecognize = ({
    applicationId,
  }: applicationBasisType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/replaced-photo:check`
    );

  sendingCarPhotoForRecognize = ({ applicationId }: applicationBasisType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/car-photo:check`
    );

  sendingReplegePhotoForRecognize = ({ applicationId }: applicationBasisType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/repledge-photo:check`
    );

  code = ({ applicationId }: applicationBasisType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/applying-agreement`
    );

  repeatCode = ({ applicationId }: applicationBasisType) =>
    mainAxios.put(
      `/user/${this.user_id}/application/${applicationId}/applying-agreement`
    );

  verifyApplication = ({ applicationId, code }: verifyApplicationType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/applying-agreement/${code}`
    );

  sendingLoanTerms = ({ applicationId, ...body }: sendingLoanTermsType) =>
    mainAxios.post<{ data: applicationType }>(
      `/user/${this.user_id}/application/${applicationId}/order`,
      body
    );

  // getSchedule = ({
  //   applicationId,
  //   ...query
  // }: getScheduleType): Promise<AxiosResponse<any>> =>
  //   mainAxios.get(
  //     `/user/${
  //       this.user_id
  //     }/application/${applicationId}/schedule${dataToQueryString(query)}`
  //   );

  getSchedule = ({
    applicationId,
    ...query
  }: getScheduleType): Promise<AxiosResponse<any>> =>
    mainAxios.get(`/schedule${dataToQueryString(query)}`);

  //  * неточный запрос, потом поправить, как сваггер будет
  creatingAnAccrual = ({ applicationId }: creatingAnAccrualType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/issue-method`
    );

  getLoan = (
    query: getLoanType
  ): Promise<
    AxiosResponse<{
      data: {
        items: Array<loanType>;
        items_count: number;
      };
    }>
  > => mainAxios.get(`user/${this.user_id}/loan${dataToQueryString(query)}`);

  getLoanById = (loan_id: string | null): Promise<AxiosResponse<any>> =>
    mainAxios.get(`user/${this.user_id}/loan/${loan_id}`);

  createLoanPayment = ({ loan_id, ...body }: createLoanPaymentType) =>
    mainAxios.post(`/user/${this.user_id}/loan/${loan_id}/payment`, body);

  /*
    Запросы на чдп в лк
   */

  // Создание досрочного погашения
  createPartialEarlyRepayment = ({
    loan_id,
    ...body
  }: createPartialEarlyRepaymentType) =>
    mainAxios.post(
      `/user/${this.user_id}/loan/${loan_id}/early-repayment`,
      body
    );

  deletePartialEarlyRepayment = ({
    loan_id,
    early_repayment_id,
  }: deletePartialEarlyRepaymentType) =>
    mainAxios.delete(
      `/user/${this.user_id}/loan/${loan_id}/early-repayment/${early_repayment_id}`
    );

  // Отправка кода для подписания досрочного погашения
  getPartialEarlyRepaymentCode = ({
    loan_id,
    early_repayment_id,
    ...body
  }: getPartialEarlyRepaymentCodeType) =>
    mainAxios.post(
      `/user/${this.user_id}/loan/${loan_id}/early-repayment/${early_repayment_id}/code`,
      body
    );

  // Подписание досрочного погашения
  verifyPartialEarlyRepaymentCode = ({
    loan_id,
    early_repayment_id,
    code,
  }: verifyPartialEarlyRepaymentCodeType) =>
    mainAxios.put(
      `/user/${this.user_id}/loan/${loan_id}/early-repayment/${early_repayment_id}/code/${code}`
    );

  // Проведение платежа
  completePartialEarlyRepayment = ({
    loan_id,
    early_repayment_id,
  }: getPartialEarlyRepaymentCodeType) =>
    mainAxios.post(
      `/user/${this.user_id}/loan/${loan_id}/early-repayment/${early_repayment_id}/payment`
    );

  getFullRepaymentAmount = ({ loan_id }: { loan_id: string }) =>
    mainAxios.get(`/user/${this.user_id}/loan/${loan_id}/full_repayment`);

  downloadDocument = ({
    loan_id,
    early_repayment_id,
    file_type,
  }: downloadDocumentType) =>
    mainAxios.get(
      `/user/${this.user_id}/loan/${loan_id}/early-repayment/${early_repayment_id}/${file_type}/file:download`
    );

  getAllPayments = ({ loan_id }: { loan_id: string }) =>
    mainAxios.get<{
      data: { items_count: number; items: Array<paymentInfoType> };
    }>(`/user/${this.user_id}/loan/${loan_id}/payment`);

  // Получить все досрочные погашения
  getAllEarlyRepayments = ({ loan_id }: { loan_id: string | null }) =>
    mainAxios.get<{
      data: { items_count: number; items: Array<earlyRepaymentInfoType> };
    }>(`/user/${this.user_id}/loan/${loan_id}/early-repayment`);

  getPayment = ({ loan_id, payment_id }: getPaymentType) =>
    mainAxios.get(
      `/user/${this.user_id}/loan/${loan_id}/payment/${payment_id}`
    );

  continueApplicationSms = () =>
    mainAxios.put(`/user/${this.user_id}/continue-application-sms`);
  getBanks = () => mainAxios.get(`/bank`);

  defineSBPBank = ({
    applicationId,
    ...body
  }: {
    applicationId: number | null;
    bank_id: number;
    // loan_data: {
    //   amount: number;
    //   loan_term: string;
    // };
    video_call_start_date: string;
    inn: string;
    snils?: string;
    monthly_income: number;
    trusted_person_phone: string;
    loan_purpose: string;
  }) =>
    mainAxios.put<{ data: applicationType }>(
      `/user/${this.user_id}/application/${applicationId}/survey`,
      body
    );

  sendRepledgeReason = ({
    applicationId,
    ...body
  }: {
    applicationId: number | null;
    repledge_reason: string;
  }) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/repledge`,
      body
    );

  defineSBPBankAfterError = ({
    applicationId,
    ...body
  }: {
    applicationId: number | null;
    bank_id: number;
  }) =>
    mainAxios.put(
      `/user/${this.user_id}/application/${applicationId}/define-sbp-bank-after-error`,
      body
    );

  checkSBPBank = ({ applicationId }: { applicationId: number | null }) =>
    mainAxios.put(
      `/user/${this.user_id}/application/${applicationId}/check-sbp`
    );

  sendVideoCallDate = ({
    applicationId,
    ...body
  }: {
    applicationId: number | null;
    date: string;
  }) =>
    mainAxios.put(
      `/user/${this.user_id}/application/${applicationId}/video-call-start-date`,
      body
    );

  getCertificate = ({ loan_id }: { loan_id: string }) =>
    mainAxios.get<{
      data: {
        items: Array<{
          id: string;
          certificate_type: string;
          created_at: Date;
        }>;
        items_count: number;
      };
    }>(`/user/${this.user_id}/loan/${loan_id}/certificate`);

  generateCertificate = ({
    loan_id,
    ...body
  }: {
    loan_id: string;
    certificate_type:
      | "debt_absence_certificate"
      | "debt_availability_certificate";
  }) =>
    mainAxios.post<{
      data: { id: string; certificate_type: string; created_at: Date };
    }>(`/user/${this.user_id}/loan/${loan_id}/certificate`, body);

  // getGeneratedCertificateById = ({ loan_id, certificate_id }: {
  //   loan_id: string;
  //   certificate_id: string;
  // }) =>
  //   mainAxios.get(
  //     `/user/${this.user_id}/loan/${loan_id}/certificate/${certificate_id}`,
  //     );

  downloadCertificate = ({
    loan_id,
    certificate_id,
  }: {
    loan_id: string;
    certificate_id: string;
  }) =>
    mainAxios.get(
      `/user/${this.user_id}/loan/${loan_id}/certificate/${certificate_id}/file:download`
    );

  getNotifications = () =>
    mainAxios.get<{
      data: {
        items_count: number;
        items: Array<notificationDataType>;
      };
    }>(`/user/${this.user_id}/notification`);

  downloadNotificationFile = ({ notification_id }: { notification_id: any }) =>
    mainAxios.get<{
      data: {
        content: string;
        file_name: string;
      };
    }>(`/user/${this.user_id}/notification/${notification_id}/file`);

  acceptNotification = ({ ...body }) =>
    mainAxios.post<{ data: unknown }>(
      `/user/${this.user_id}/notification:accept`,
      body
    );
}

export const applicationApi = new ApplicationClass();
