import React, { AllHTMLAttributes, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import cn from "classnames";
import get from "lodash/get";
import styles from "./index.module.css";
import { Icons } from "assets/icons";
import { downloadFile } from "helpers/file";
import { fileTypeBase } from "../../index";
import {
  DocumentApiTypes,
  DocumentTypes,
} from "../../../../../types/documents/types";
import { Loader } from "../../../Loader";
import { Manual } from "../Manual";
import {
  processLoading,
  setProcessLoading,
} from "../../../../../store/auxiliary/loadingReducer";
import { authSagaActions } from "../../../../../sagas/auth/actions";
import { Images } from "../../../../../assets/img";

export const mimeTypesAllowedForPreview = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "image/tiff",
];

const mimeTypeDataMap: {
  [key: string]: {
    component: FC;
    name: string;
  };
} = {
  "application/pdf": {
    component: Icons.ui.Eye,
    name: ".pdf",
  },
  "application/msword": {
    component: Icons.ui.Eye,
    name: ".doc",
  },
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
    component: Icons.ui.Eye,
    name: ".docx",
  },
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
    component: Icons.ui.Eye,
    name: ".xls",
  },
};

type FilePropsType = {
  file: fileTypeBase;
  index: number;
  accept?: string;
  handleRemove?: (e: any) => void;
  handleChange?: (e: any) => void;
  viewMode?: boolean;
  documentEntity?: DocumentTypes.entity;
  update: (index: number, value: any) => void;
  status?: string;
  //  * NEW
  //  * имя в форме
  name: string;
  isConfirmedByUnderwriter?: boolean;
  loadingDocuments?: boolean;

  // TODO any
  placeholderImage?: any;
} & DocumentApiTypes.documentTypeField &
  AllHTMLAttributes<HTMLDivElement>;
const DocumentFile = ({
  isConfirmedByUnderwriter,
  file,
  handleRemove,
  handleChange,
  index,
  update,
  viewMode,
  documentEntity,
  accept,
  name,
  document_type,
  ...props
}: FilePropsType) => {
  // console.log(props.className);
  const dataByMimeType = mimeTypeDataMap[file.type];
  const [isDownloading, setIsDownloading] = useState(false);
  const [isOpenManual, setIsOpenManual] = useState(false);
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);

  console.log(file, "file");

  useEffect(() => {
    dispatch(setProcessLoading("isUploading", Boolean(file?.loading)));
    if (file.loading) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 1;
          return newProgress > 100 ? 100 : newProgress;
        });
      }, 10);

      return () => clearInterval(interval);
    } else {
      setProgress(0);
    }
  }, [file.loading]);

  useEffect(() => {
    dispatch(setProcessLoading("isRecognized", Boolean(file.recognition)));
  }, [file.recognition]);

  const {
    formState: { errors },
    clearErrors,
  } = useFormContext();

  // * ошибка из формы

  const errorMessage = get(errors, name)?.message;

  const setManualToTrue = () => {
    document.querySelector("html")!.style.overflow = "hidden";
    setIsOpenManual(true);
  };

  console.log(props.status, "props.status");

  // const upLoading = useSelector(
  //   processLoading.get("file/isUploading")
  // );

  const handleDownload = async () => {
    try {
      setIsDownloading(true);

      const fileIdx = documentEntity!.files.findIndex(
        (doc) => doc.id === file.file_id
      );

      // const fileResponse = await DocumentsApiClass.getFile({
      //   document_id:
      //     documentEntity!.files[fileIdx].document_id ||
      //     (documentEntity?.id as string),
      //   file_id: file!.file_id as string,
      //   document_type:
      //     documentEntity!.files[fileIdx].document_type ||
      //     documentEntity!.document_type,
      //   application_id: documentEntity!.application_id,
      // } as DocumentApiTypes.getFile);

      // downloadFile(fileResponse.data.data.content, file?.file_name);
    } catch (e) {
      // dispatch(
      //   uiReducer.actions.setModal({
      //     status: "error",
      //     button: "close",
      //     message: "Ошибка загрузки документа",
      //   })
      // );
    } finally {
      setIsDownloading(false);
    }
  };

  const downloadLocal = () => {
    downloadFile(file.value, file.file_name);
  };

  const handleRemoveProxy = (e: any) => {
    if (file.fixed) {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      update(index, {
        fixed: true,
        title: file.title,
      });
    } else {
      handleRemove?.(e);
    }
  };

  // view
  const renderPreview = () => {
    // TODO тут mime типов пока других не будет, поэтому всегда показываем img
    if (file.value && mimeTypesAllowedForPreview.includes(file?.type)) {
      if (isConfirmedByUnderwriter === false) {
        return (
          <>
            <div>
              <img
                src={Images.documentPhotoPlaceholders.backGroundGray}
                alt="placeholderImage"
                className={cn(styles.placeholderImageIsConfirmedByUnderwriter)}
                onClick={setManualToTrue}
              />
              <div
                className={
                  styles.file__preview_container_is_confirmed_by_underwriter
                }
              >
                <img
                  src={file.value}
                  className={styles.file__preview_is_confirmed_by_underwriter}
                />
              </div>
            </div>

            {/*{file?.loading && (*/}
            {/*  <div className={styles.file_fixed_preview}>{file.title}</div>*/}
            {/*)}*/}
          </>
        );
      } else {
        return (
          <>
            <div className={styles.file__preview_container}>
              <img src={file.value} className={styles.file__preview} />
            </div>
            {/*{file?.loading && (*/}
            {/*  <div className={styles.file_fixed_preview}>{file.title}</div>*/}
            {/*)}*/}
          </>
        );
      }
    } else if (
      props?.placeholderImage &&
      !file.value &&
      (props.status === "recognized_document_photo" ||
        (props.status === "document_photo" &&
          document_type === "selfie_with_passport")) &&
      !file.loading &&
      !file.recognition
    ) {
      return (
        <>
          <div className={cn(styles.icon_wrapper2)}>
            <Icons.ui.CheckmarkTransparentGreenBg
              className={styles.img_green}
            />
          </div>
          <img
            src={props.placeholderImage}
            alt="placeholderImage"
            className={cn(styles.placeholderImageWithoutValue)}
          />
        </>
      );
    } else {
      return (
        <>
          <div className={cn(styles.icon_wrapper2)}>
            <Icons.ui.photoCameraWhite className={styles.camera_icon} />
          </div>
          <img
            src={props.placeholderImage}
            alt="placeholderImage"
            className={cn(styles.placeholderImage)}
            onClick={setManualToTrue}
          />
        </>
      );
    }

    // TODO убрать пока dataByMimeType
    // if (file.file_name) {
    //   return (
    //     <div title={file.file_name} className={styles.file__no_preview}>
    //       <h5 className={styles.file_name}>{file?.file_name}</h5>
    //       {dataByMimeType && (
    //         <div className={styles.icon_wrapper}>
    //           <div className={styles.icon}>
    //             {React.createElement(dataByMimeType.component)}
    //           </div>
    //           <div style={{ marginTop: 10 }}>{dataByMimeType.name}</div>
    //         </div>
    //       )}
    //     </div>
    //   );
    // }
    if (file.fixed && file.title) {
      return (
        <>
          {errorMessage && (
            <div
              className={cn(
                styles.file_fixed_preview_error,
                styles.file_fixed_preview
              )}
            >
              Заменить
            </div>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <>
      <div
        className={cn(styles.file, props.className, {
          [styles.file_uploaded]:
            file.value &&
            mimeTypesAllowedForPreview.includes(file?.type) &&
            !file?.loading,
          [styles.file_error]:
            (errorMessage ||
              props.status === "recognition_error_document_photo") &&
            !file?.loading &&
            !file.recognition,
          [styles.file_error_repeated_photos]:
            isConfirmedByUnderwriter === false &&
            !file?.loading &&
            !file.recognition,
          [styles.file_loading]: file?.loading || (!props.status && file.value),
          // [styles.file_loading]: file?.loading,
        })}
      >
        {/* here */}
        {renderPreview()}

        {/* REMOVE */}
        {!viewMode && !file?.file_id && file.value && <></>}

        {/* FIXED MODE */}
        {/*{file.fixed && !file.value && !file.loading && (*/}
        {/*  <>*/}
        {/*    <div className={cn(styles.label, {*/}
        {/*      [styles["file--error"]]: errorMessage*/}
        {/*    })} onClick={setManualToTrue}>*/}
        {/*      <input*/}
        {/*        type="file"*/}
        {/*        capture="user"*/}
        {/*        accept="image/*"*/}
        {/*        className={styles.input}*/}
        {/*        data-index={index}*/}
        {/*        onChange={(e) => {*/}
        {/*          handleChange && handleChange(e);*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      {errorMessage && (*/}
        {/*        <span className={styles.error_message}>{errorMessage}</span>*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  </>*/}
        {/*)}*/}

        {/*TODO Переделать на некостыльный вариант*/}
        {/*{file.loading && (*/}
        {/*  <>*/}
        {/*    <div className={cn(styles.label, styles["file--error"])}>*/}
        {/*      <div className={styles.error_message}>*/}
        {/*        Фото загружается, подождите пожалуйста*/}
        {/*      </div>*/}
        {/*      <div className={styles.progress_bar}>*/}
        {/*        <div className={styles.progress_bar_purple}></div>*/}
        {/*        <div className={styles.progress_bar_gray}></div>*/}
        {/*      </div>*/}
        {/*      <div className={styles.percents}>{progress}</div>*/}
        {/*    </div>*/}
        {/*  </>*/}
        {/*)}*/}

        {/*TODO Временное решение, нужно утонить от какого значения отталкиваться при рендере прогресс бара*/}
        {file.loading && (
          <div className={cn(styles.label, styles["file--error"])}>
            <div className={styles.error_message}>
              Фото загружается, подождите пожалуйста
            </div>

            <div className={styles.progress_bar_gray}>
              <div
                className={styles.progress_bar_purple}
                style={{ width: `${progress}%` }}
              ></div>
            </div>

            <div className={styles.percents}>{progress}%</div>
          </div>
        )}

        {(file.recognition ||
          ((!props.status ||
            (props.status === "document_photo" &&
              document_type !== "selfie_with_passport" &&
              document_type !== "repledge_certificate_of_debt_status" &&
              document_type !== "repledge_loan_contract")) &&
            file.value)) &&
          !file.loading && (
            <>
              <div className={cn(styles.label, styles["file--error"])}>
                <div className={styles.loader_container}>
                  <Loader className={styles.loader_big} />
                </div>
                <div>Идет распознавание</div>
              </div>
            </>
          )}

        {file.value && errorMessage && !file.loading && (
          <label className={styles.label}>
            <input
              type="file"
              capture={
                document_type === "selfie_with_passport" ||
                document_type === "selfie_with_car"
                  ? "user"
                  : "environment"
              }
              accept="image/*"
              className={styles.input}
              data-index={index}
              onChange={(e) => {
                handleChange && handleChange(e);
              }}
            />
            <span className={styles.error_message}>{errorMessage}</span>
          </label>
        )}

        {/* CHANGE */}
        {!viewMode && !file.uploaded && file.value && !file.loading && (
          <>
            {(props.status === "recognition_error_document_photo" ||
              errorMessage) &&
              !file.loading &&
              !file.recognition && (
                <label className={styles.change_button}>
                  <div className={styles.undo_container_img}>
                    <div className={styles.text_button}>Заменить</div>
                    <Icons.ui.UndoArrowBlue />
                  </div>

                  <input
                    type="file"
                    capture={
                      document_type === "selfie_with_passport" ||
                      document_type === "selfie_with_car"
                        ? "user"
                        : "environment"
                    }
                    accept="image/*"
                    className={styles.input}
                    onChange={handleChange}
                    data-index={index}
                  />
                </label>
              )}

            {/*{file?.document_type === "recognized_document_photo" && (*/}
            {/*  <Icons.ui.Lock className={styles.lock_icon} />*/}
            {/*)}*/}

            {(props.status === "recognized_document_photo" ||
              (props.status === "document_photo" &&
                document_type === "selfie_with_passport") ||
              document_type === "repledge_loan_contract" ||
              document_type === "repledge_certificate_of_debt_status") &&
              !file.loading &&
              !file.recognition &&
              isConfirmedByUnderwriter !== false && (
                <div className={cn(styles.icon_wrapper2)}>
                  <Icons.ui.CheckmarkTransparentGreenBg
                    className={styles.img_green}
                  />
                </div>
              )}
          </>
        )}

        {!file.loading &&
          !file.recognition &&
          !errorMessage &&
          isConfirmedByUnderwriter === false &&
          file.value && (
            // !(
            //   file?.document_type === "recognition_error_document_photo" ||
            //   file?.document_type === "recognized_document_photo" ||
            //   file?.document_type === "document_photo"
            // ) &&
            // !file.value && (
            <label className={styles.change_button}>
              <div
                className={styles.make_photo_container_img}
                onClick={setManualToTrue}
              >
                <div className={styles.text_button}>Заменить</div>
                <Icons.ui.CameraTransparentBg />
              </div>
            </label>
          )}

        {(props.status === "recognition_error_document_photo" ||
          errorMessage) &&
          !file.loading &&
          !file.recognition && (
            <label className={styles.change_button}>
              <div className={styles.undo_container_img}>
                <div className={styles.text_button}>Заменить</div>
                <Icons.ui.UndoArrowBlue />
              </div>
              <input
                type="file"
                capture={
                  document_type === "selfie_with_passport" ||
                  document_type === "selfie_with_car"
                    ? "user"
                    : "environment"
                }
                accept="image/*"
                className={styles.input}
                onChange={handleChange}
                data-index={index}
              />
            </label>
          )}

        {!file.loading &&
          !file.recognition &&
          !(
            props.status === "recognition_error_document_photo" ||
            props.status === "recognized_document_photo" ||
            props.status === "document_photo"
          ) &&
          !file.value && (
            <label className={styles.change_button}>
              <div
                className={styles.make_photo_container_img}
                onClick={setManualToTrue}
              >
                <div className={styles.text_button}>Прикрепить фото</div>
                <Icons.ui.CameraTransparentBg />
              </div>
            </label>
          )}

        {/* FETCHING */}
        {isDownloading && (
          <div className={styles.download_preloader}>
            <Loader className={styles.loader_icon} />
          </div>
        )}

        {(props.status === "recognition_error_document_photo" ||
          errorMessage) &&
          !file.loading &&
          !file.recognition && (
            <div className={styles["file--error"]}>
              {props.status === "recognition_error_document_photo" && (
                <div className={styles.text_error}>
                  Не удалось распознать, сделайте фотографию еще раз
                </div>
              )}
              <div className={styles.icon_wrapper2}>
                <Icons.ui.RedExclamation className={styles.img_red} />
              </div>
            </div>
          )}

        {/* DOWNLOAD */}
        {/*{!isDownloading && file.file_id && (*/}
        {/*  <Icons.ui.Eye*/}
        {/*    // download={file?.value ? file.file_name : undefined}*/}
        {/*    href={file?.value ? file.value : undefined}*/}
        {/*    onClick={file?.value ? downloadLocal : handleDownload}*/}
        {/*    className={styles.download}*/}
        {/*  />*/}
        {/*)}*/}
        {(file?.loading ||
          (props.status === "document_photo" &&
            document_type !== "selfie_with_passport")) && (
          <div className={styles["file--loading"]}>
            <div className={styles.icon_wrapper2}>
              {/*<Loader className={styles.loader_icon} />*/}
              {/*<>Фото загружается, подождите пожалуйста</>*/}
            </div>
          </div>
        )}
      </div>

      <Manual
        isOpenManual={isOpenManual}
        setIsOpenManual={setIsOpenManual}
        handleChange={handleChange}
        document_type={document_type}
      />
    </>
  );
};

type FileAddPropsType = {
  handleAdd: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isUploading: boolean;
  accept?: string;
};
const FileDocumentAdd = ({
  handleAdd,
  isUploading,
  accept,
}: FileAddPropsType) => {
  const {
    formState: { errors },
    clearErrors,
  } = useFormContext();

  return (
    <>
      <label className={cn(styles.file, styles.file_add)}>
        <input
          type="file"
          capture="environment"
          accept="image/*"
          className={styles.input}
          onChange={(e) => {
            handleAdd(e);
            clearErrors("ground_document.files");
          }}
        />
        {isUploading ? <Loader /> : <Icons.ui.Eye />}
        {errors?.ground_document?.files && (
          <div className={styles.error}>
            {errors?.ground_document?.files?.message}
          </div>
        )}
      </label>
    </>
  );
};

export default {
  DocumentFile,
  FileDocumentAdd,
};
