import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateType } from "../../index";
import { sagaActions } from "sagas/util/sagaActions";

const initialState: {
  [key: string]: boolean;
} = (function () {
  let temp: any = {};
  let groupKey: keyof typeof sagaActions;
  for (groupKey in sagaActions) {
    const group = sagaActions[groupKey];
    let actionKey: keyof typeof group;
    // @ts-ignore
    for (actionKey in group) {
      // @ts-ignore
      temp[`${group[actionKey].type}`] = false;
    }
  }
  return temp;
})();

export const loadingReducer = createSlice({
  name: "@loadingReducer",
  initialState,
  reducers: {
    set: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: string;
        value: boolean;
      }>
    ) => {
      state[payload.key] = payload.value;
    },
    clear: () => {},
  },
});

export const processLoading = {
  get: (key: string) => (state: any) => {
    return state.loadingReducer[key];
  },
  getMany: (keys: Array<string>) => {
    return createSelector(
      (state: StateType) => state.loadingReducer,
      (processes) => {
        let loading = false;
        keys.forEach((processKey) => {
          if (processes[processKey]) {
            loading = true;
            return;
          }
        });
        return loading;
      }
    );
  },
};

export const setProcessLoading = (key: string, value: boolean) => {
  return loadingReducer.actions.set({
    key,
    value,
  });
};
