import * as yup from "yup";
import { validationsMessages } from "../../../../../helpers/validation/validationsMessages";

export const newLoanSchema = yup.object().shape({
  trusted_person_phone: yup
    .string()
    .required(validationsMessages.required)
    .min(16, validationsMessages.required),
  monthly_income: yup
    .string()
    .required(validationsMessages.required)
    .min(0, validationsMessages.min)
    .max(1000000, validationsMessages.max),
  email: yup
    .string()
    .required(validationsMessages.required)
    .matches(/\S+@\S+\.\S+/, validationsMessages.email),
  comfortable_payment: yup
    .string()
    .required(validationsMessages.required)
    .min(0, validationsMessages.min)
    .max(1000000, validationsMessages.max),
  current_loans_payment: yup
    .string()
    .required(validationsMessages.required)
    .min(0, validationsMessages.min)
    .max(1000000, validationsMessages.max),
});
