import React, { HTMLAttributes } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { Icons } from "../../../assets/icons";
import { constantStrings } from "../../../helpers/init/constantStrings";

type propsType = {} & HTMLAttributes<HTMLDivElement>;

export const FillingAssistance = ({ className }: propsType) => {
  return (
    <div
      className={cn(styles.container, {
        [`${className}`]: className,
      })}
    >
      <div className={styles.text_container}>
        <div className={styles.text}>
          <div className={styles.title}>Помощь в оформлении</div>
          <div className={styles.subtitle}>
            Напишите нам, если Вам требуется помощь в заполнении
          </div>
        </div>
        <div className={styles.container_icon}>
          <a
            href={`https://wa.me/${constantStrings.whatsappNumberForSupport}`}
            target={"_blank"}
          >
            <Icons.ui.WhatsAppGreen />
          </a>
        </div>
      </div>
    </div>
  );
};
