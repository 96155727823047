import { applicationBasisType } from "../../../api/main/application/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../../util/helpers/processStateController";
import { call, select } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { sendMetrics } from "../../../metrics";
import { sagaApiType } from "../../util/types";

export function* sendingCarPhotoForRecognizeSaga({
  payload: { applicationId },
  type: actionType,
}: PayloadAction<applicationBasisType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const response: sagaApiType = yield call(
      API.main.application.sendingCarPhotoForRecognize,
      {
        applicationId,
      }
    );

    if (response.status === 204) {
      sendMetrics(
        "common-client_otpravleny-foto-avto-polnaya-zayavka_common-app",
        "otpravleny-foto-avto-polnaya-zayavka"
      );
    }

    yield redirect(routes.application.statuses.scoring_and_loan_disbursement);
    // yield redirect(routes.application.repeated_photos);
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
    });
  } finally {
    yield process.stop();
  }
}
