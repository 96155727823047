import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { useDebounce } from "hooks/logical/useDebounce";

type hookApiType<T> = {
  setOptions: (v: Array<T>) => void;
  setError: (v: string) => void;
  setShowCustomContent: (v: boolean) => void;
};

type useSearchSelectPropsType<T> = {
  fetch: (val: any) => Promise<AxiosResponse<any>>;
  onSuccess?: (res: any, hookApi: hookApiType<T>) => void | Array<T>;
  onError?: (hookApi: hookApiType<T>) => void;
  onFinally?: () => void;
  onSelect?: (val: any) => void;
  setValue?: UseFormSetValue<any>;
  defaultValue?: string | null;
  name?: string;
};
export function useSearchSelect<T>({
  fetch,
  onError,
  onFinally,
  onSuccess,
  onSelect,
  defaultValue,
  setValue,
  name,
}: useSearchSelectPropsType<T>) {
  const [options, setOptions] = useState<Array<T>>();
  const [inputValue, setInputValue] = useState("");
  const debouncedValue = useDebounce(inputValue, 500);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showCustomContent, setShowCustomContent] = useState(false);

  const hookApi: hookApiType<T> = {
    setError,
    setOptions,
    setShowCustomContent,
  };
  useEffect(() => {
    if (debouncedValue) {
      if (debouncedValue == defaultValue) {
        return;
      }

      setShowCustomContent(false);
      setError("");
      setIsLoading(true);
      fetch(debouncedValue)
        .then((res) => {
          onSuccess && onSuccess(res, hookApi);
        })
        .catch(() => {
          onError && onError(hookApi);
        })
        .finally(() => {
          setIsLoading(false);
          onFinally && onFinally();
        });
    }
  }, [debouncedValue]);
  useEffect(() => {
    if (defaultValue) {
      setIsLoading(true);
      fetch(defaultValue)
        .then((res) => {
          if (onSuccess) {
            const array = onSuccess(res, hookApi);
            if (array && name && setValue) {
              setValue(name, array[0]);
            }
            setInputValue(defaultValue);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [defaultValue]);

  const onSelectHandler = (val: any) => {
    setError("");
    setOptions([]);
    onSelect && onSelect(val);
  };

  return {
    onSelectHandler,
    options,
    setOptions,
    isLoading,
    error,
    setError,
    setInputValue,
    inputValue,
    showCustomContent,
    setShowCustomContent,
  };
}
