import { routes } from "../../../../../routes";
import { Icons } from "../../../../../assets/icons";

type dataType = {
  name: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  route: string;
};

export const sidebarData: dataType[] = [
  {
    name: "Мой займ",
    icon: Icons.cabinet.Ruble2,
    route: routes.cabinet.my_loan,
  },
  // В mvp этого пока не будет
  // {
  //   name: "Анкета",
  //   icon: Icons.cabinet.Info,
  //   route: routes.cabinet.application_info,
  // },
  // {
  //   name: "История займов",
  //   icon: Icons.cabinet.History,
  //   route: routes.cabinet.history,
  // },
  // {
  //   name: "Карты для выдачи",
  //   icon: Icons.cabinet.Card,
  //   route: routes.cabinet.cards_to_issue,
  // },
  // {
  //   name: "Способы погашения",
  //   icon: Icons.cabinet.Check,
  //   route: routes.cabinet.repayment_methods,
  // },
  {
    name: "Документы",
    icon: Icons.cabinet.Document,
    route: routes.cabinet.documents,
  },
  // {
  //   name: "Связь с техподдержкой",
  //   icon: Icons.cabinet.Support,
  //   route: routes.cabinet.feedback,
  // },
];
