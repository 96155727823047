import React, { useEffect, useRef, useState } from "react";

const isNumber = (value: string) => {
  return (Number(value) > 0 && Number(value) <= 9) || value == "0";
};

type useVinFieldType = {
  codeLength: number;
  onChange: (value: string) => void;
  onFocus: () => void;
  defaultValue?: string;
  inputTypes?: string[];
  transformToCyrillic?: boolean
};

// в бек шлем кириллицу
const LatinToCyrillic: { [key: string]: string } = {
  "A": "А",
  "B": "В",
  "E": "Е",
  "K": "К",
  "M": "М",
  "H": "Н",
  "O": "О",
  "P": "Р",
  "C": "С",
  "T": "Т",
  "Y": "У",
  "X": "Х",
  "F": "А",
  "R": "К",
  "D": "В",
  "J": "О",
  "V": "М",
  "N": "Т",
  "[": "Х",

  ["А"]: "А",
  ["В"]: "В",
  ["Е"]: "Е",
  ["К"]: "К",
  ["М"]: "М",
  ["Н"]: "Н",
  ["О"]: "О",
  ["Р"]: "Р",
  ["С"]: "С",
  ["Т"]: "Т",
  ["У"]: "У",
  ["Х"]: "Х",
  ["И"]: "В",
  ["Щ"]: "О",
  ["З"]: "Р",
  ["Ф"]: "А",
  ["Л"]: "К",
  ["Ч"]: "Х",
  ["Ь"]: "М"
};
export const useVinFieldLogic = (props: useVinFieldType) => {
  const { codeLength, onChange, defaultValue } = props;

  const [pastedRegionPart, setPastedRegionPart] = useState<Array<string>>(["", "", ""]);
  const [tempInput, setTempInput] = useState(0);
  const [counter, setCounter] = useState(0);
  const [fieldsArray, setFieldsArray] = useState<Array<string>>(
    defaultValue ? defaultValue.split("") : Array(codeLength).fill("")
  );
  const arrayOfInputs = useRef<Array<HTMLInputElement | null>>([]);
  const focus = (index: number) => {
    arrayOfInputs.current[index]?.focus();
  };

  /* methods */
  const pasteHandler = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.clipboardData.getData("Text");
    const valueLength = value.length;
    const tempArray: Array<string> = fieldsArray;
    let isValid = true;

    /** массив должен быть из трех элементов, даже если регион из двух цифр */
    setPastedRegionPart(value.slice(6, value.length).length > 2
      ? value.slice(6, value.length).split("")
      : [...value.slice(6, value.length).split(""), ""]
    );

    for (let i = 0; i < valueLength && i < codeLength; i++) {
      tempArray[i] = value[i];

      // закомментировал проверку ниже, теперь нормально копипастится вин, хз для чего она, в вине нам нужны буквы и в
      // номере тоже, вроде все норм

      // if (!isNumber(value[i])) isValid = false;
    }
    const newIndex =
      valueLength < codeLength - 1 ? valueLength : codeLength - 1;

    if (isValid) {
      setTempInput(newIndex);
      focus(newIndex);
      setFieldsArray(tempArray.slice(0, codeLength));
      setCounter(value.slice(0, 6).length);
    }
  };

  const setFieldsArraySetter = (newIndex: number, value: string) => {
    setFieldsArray(
      fieldsArray.map((item, index) => {
        return index == newIndex ? value : item;
      })
    );
  };

  const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    /*
      на телефоне баг с удалением последнего поля
      Положили пока.
     */
    // console.log(event.key);
    const eventType = {
      delete: event.key == "Backspace"
    };
    if (eventType.delete) {
      if (!!fieldsArray[tempInput]) {
        setFieldsArraySetter(tempInput, "");
      } else if (tempInput != 0) {
        setFieldsArraySetter(tempInput - 1, "");
        focus(tempInput - 1);
        setTempInput(tempInput - 1);
      }

      if (counter > 0) setCounter(counter - 1);
    }
  };

  // Тут поведение инпутов при введении символов.
  const onChangeHandler = (event: any) => {
    let value = event.nativeEvent.data;

    // запрещает печатать символы, не подходящие по типу инпута
    if (
      (!isNumber(value) && props.inputTypes?.[counter] === "tel") ||
      (isNumber(value) && props.inputTypes?.[counter] === "text")
    ) {
      return;
    }

    // Логика для госномера
    if (props.transformToCyrillic) {
      if (!isNumber(value) && props.inputTypes?.[counter] === "text") {
        if (!LatinToCyrillic[value?.toUpperCase()]) {
          return;
        }

        value = LatinToCyrillic[value?.toUpperCase()];

      }
    }

    // убрать наверх

    const eventType = {
      moveIfIsFilled: Boolean(
        tempInput != codeLength - 1 && fieldsArray[tempInput]
      ),
      moveIfIsEmpty: !fieldsArray[tempInput]
    };

    if (eventType.moveIfIsFilled) {
      setFieldsArraySetter(tempInput + 1, value);

      setCounter(counter + 1);
      setTempInput(tempInput + 1);
      focus(tempInput + 1);
    }
    if (eventType.moveIfIsEmpty) {
      setFieldsArraySetter(tempInput, value);

      if (tempInput != codeLength - 1) {
        setTempInput(tempInput + 1);
        focus(tempInput + 1);
      }
      if (counter != codeLength) setCounter(counter + 1);
    }
  };

  const onClickHandler = (
    event: React.MouseEvent<HTMLInputElement>,
    // индекс инпута в массиве
    value: number
  ) => {
    focus(fieldsArray.join("").length);
    event.currentTarget.setAttribute("placeholder", "");
    const updatedFieldsArray = fieldsArray.map((item, index) => {
      return index >= value ? "" : item;
    });
    setTempInput(updatedFieldsArray.join("").length);
    setFieldsArray(updatedFieldsArray);

    setCounter(updatedFieldsArray.join("").length);
  };

  const [renderCounter, setRenderCounter] = useState(0);

  /* effects */
  useEffect(() => {
    arrayOfInputs.current = arrayOfInputs.current.slice(0, codeLength);
  }, []);
  useEffect(() => {
    if (renderCounter) {
      onChange(fieldsArray.join(""));
    }
  }, [fieldsArray]);

  // Твердый и чистый код
  useEffect(() => {
    setRenderCounter(renderCounter + 1);

    if (defaultValue) {
      setTempInput(defaultValue.length);
    }
  }, []);

  return {
    tempInput,
    fieldsArray,
    arrayOfInputs,
    setFieldsArray,
    onClickHandler,
    pasteHandler,
    keyPressHandler,
    onChange: props.onChange,
    onFocus: props.onFocus,
    onChangeHandler,
    value: fieldsArray.join(""),
    type: props.inputTypes,
    pastedRegionPart
  };
};
