import { useQuery } from "react-query";
import moment from "moment";
import { API } from "../../api";
import {
  scheduleCreditType,
  scheduleType,
} from "../../types/application/types";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userReducer } from "../../store/reducers/user";
import { StateType } from "../../store";
import { dateMethods } from "../../helpers/date";
import { loanType } from "../../types/cabinet/types";

export const getCurrentSchedule = (credit: scheduleCreditType[]) => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const day = new Date().getDate();

  // eslint-disable-next-line array-callback-return
  return credit?.find((schedule) => {
    // 2022-11-07
    const dateArr = schedule.date.split("-");
    const scheduleYear = +dateArr[0];
    const scheduleMonth = +dateArr[1];
    const scheduleDay = +dateArr[2];

    if (
      (scheduleYear === year &&
        scheduleMonth === month &&
        scheduleDay >= day) ||
      (scheduleYear === year && scheduleMonth > month) ||
      scheduleYear > year
    ) {
      return true;
    }
  });
};

export const getNextSchedule = (credit: scheduleCreditType[] | undefined) => {
  if (typeof credit === "undefined") {
    return;
  }

  const currentScheduleIndex = credit?.findIndex(
    (schedule) => schedule?.date === getCurrentSchedule(credit)?.date
  );

  return credit[currentScheduleIndex + 1];
};

export const scheduleSort = (schedule: scheduleCreditType[]) => {
  return schedule?.sort(
    (a, b) =>
      // @ts-ignore
      moment(a.date) - moment(b.date)
  );
};

export const useGetLoan = () => {
  const dispatch = useDispatch();
  const loan_id = useSelector((state: StateType) => state.loanReducer.loan_id);
  const { data, isLoading } = useQuery(
    "getLoanById",
    () => API.main.application.getLoanById(loan_id),
    {
      enabled: loan_id !== null, // Включаем запрос, когда loan_id не равен null
    }
  );

  const currentLoan: loanType = data?.data?.data;

  const orderedDate = currentLoan?.ordered_date;

  const overdueFullDays = dateMethods.getFullDaysBetween(
    currentLoan?.overdue_date,
    new Date()
  );

  if (currentLoan?.full_name) {
    dispatch(
      userReducer.actions.setFullName({
        full_name: currentLoan?.full_name,
      })
    );
  }

  if (currentLoan?.last_name) {
    dispatch(
      userReducer.actions.setLastName({
        last_name: currentLoan?.last_name,
      })
    );
  }

  if (currentLoan?.first_name) {
    dispatch(
      userReducer.actions.setFirstName({
        first_name: currentLoan?.first_name,
      })
    );
  }

  const schedule: scheduleType = {
    ...currentLoan?.loan_data,
    schedule: scheduleSort(currentLoan?.schedule),
  };

  const currentSchedule = useMemo(() => {
    return getCurrentSchedule(scheduleSort(currentLoan?.schedule));
  }, [data]);

  const nextSchedule = useMemo(() => {
    return getNextSchedule(scheduleSort(currentLoan?.schedule));
  }, [data]);

  const nextPayment = useMemo(() => {
    return currentLoan?.next_payment;
  }, [data]);

  const paymentHasBeenMade = useMemo(() => {
    return (
      (currentSchedule?.amount ?? 1) -
        currentLoan?.current_situation?.balance <=
      0
    );
  }, [data]);

  const isPaymentSoon = useMemo(() => {
    // currentSchedule?.date    "2022-10-13"
    const nearestPaymentDate = moment(currentSchedule?.date).format(
      "YYYY-MM-DD"
    );
    const nearestPaymentDateMinusThreeDays = moment(currentSchedule?.date)
      .subtract(3, "d")
      .format("YYYY-MM-DD");

    return moment(new Date()).isBetween(
      nearestPaymentDateMinusThreeDays,
      nearestPaymentDate
    );
  }, [currentSchedule]);

  const fullDaysToPenalty = useMemo(() => {
    if (currentSchedule?.date) {
      return dateMethods.getFullDaysBetween(
        new Date(),
        new Date(currentSchedule.date)
      );
    }
  }, [currentSchedule]);

  const currentAmount = useMemo(() => {
    /*
    Старая нерабочая логика
    if (currentSchedule && currentLoan &&  currentLoan?.current_situation?.overdue_loan_body !== 0) {
      return (
        currentLoan?.current_situation?.balance -
        currentSchedule.amount +
        currentLoan?.current_situation?.overdue_loan_body +
        currentLoan?.current_situation?.overdue_percents
      );
    } else if (currentSchedule && currentLoan ) {
      return (
        currentSchedule.amount
      );
    }
     */

    if (currentSchedule && currentLoan) {
      if (
        currentLoan?.current_situation?.overdue_loan_body !== 0 ||
        currentLoan?.current_situation?.overdue_percents !== 0
      )
        // Очень важный лог, без которого мемо возвращает undefined. TODO разобраться с мемо
        console.log("");
      return (
        currentSchedule.amount +
        currentLoan?.current_situation?.overdue_loan_body +
        currentLoan?.current_situation?.overdue_percents -
        currentLoan?.current_situation?.balance
      );
    } else if (currentSchedule?.amount) {
      if (currentSchedule?.amount > currentLoan?.current_situation?.balance) {
        return (
          currentSchedule?.amount - currentLoan?.current_situation?.balance
        );
      } else {
        return 0;
      }
    }
  }, [currentSchedule, currentLoan]);

  return {
    data,
    isLoading,
    nextPayment, // * данные ближайшего платежа
    currentLoan, // * c запроса приходит много займов, это только последний, сделал пока так, потому что у юзера не может быть более одного активного займа
    nextSchedule, // * инфа по след выплате
    isPaymentSoon, // * если <=3 дней до конца выплаты в этом месяце, то ui меняется
    currentAmount, // * текущая сумма к выплате, считается из многих полей
    currentSchedule, // * инфа по текущей выплате
    paymentHasBeenMade, // * это когда юзер залил на баланс монетку и он в этом месяце не должен уже
    allSchedule: schedule,
    currentSituation: currentLoan?.current_situation, // * общая инфа по кредиту
    isOverdue:
      Boolean(currentLoan?.current_situation?.percent_penalties) ||
      Boolean(currentLoan?.current_situation?.loan_body_penalties), // * когда есть просрочка
    overdueFullDays, // * полных дней просрочки
    fullDaysToPenalty,
    orderedDate,
  };
};
