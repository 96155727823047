import { createFC } from "helpers/createFC";
import styles from "./index.module.css";
import { AllHTMLAttributes } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { StateType } from "../../../../store";

type propsType = {
  children: any;
} & AllHTMLAttributes<HTMLDivElement>


/*
  Это был MobilePageContainer с пропсом isCabinet.
  решил не мучаться с пропсом и создал новый контейнер для лк
  тк это теперь не мобайл, а для-всего-контейнер
 */
export const PageContainer = createFC((props: propsType) => {

  const location = useSelector(
    (state: StateType) => state.router.location.pathname
  );

  return (
    <div
      className={cn(styles.container, props.className, {
        [styles.bg_gray]: location === "/cabinet/notifications",
      })}
    >
      {props.children}
      <div className={styles.ellipse}></div>
    </div>
  );
});