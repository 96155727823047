import React, { HTMLAttributes } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { Icons } from "assets/icons";

type propsType = {
  margin?: "small" | "medium";
  form?: string;
  disabled?: boolean;
  size?: "small" | "big";
  variant?: "default" | "underline" | "black" | "outline" | "warning";
  loading?: boolean;
  loadingText?: string;
  type?: "submit" | "reset" | "button";
  isDisabled?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

export const Button = ({
  children,
  margin,
  size = "small",
  variant = "default",
  loading,
  loadingText,
  disabled,
  isDisabled,
  ...jsxAttr
}: propsType) => {
  return (
    <button
      disabled={disabled}
      {...jsxAttr}
      className={cn(styles.container, jsxAttr.className, {
        [styles[`margin--${margin}`]]: margin,
        // [styles.small]: size === "small",
        [styles[`${variant}`]]: variant,
        [styles.loading]: loading,
        [styles.disabled]: isDisabled
      })}
    >
      {loading && <Icons.ui.Loader className={styles.loaderIcon} />}
      {loading ? loadingText || "Подождите..." : children}
    </button>
  );
};
