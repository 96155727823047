import { applicationBasisType } from "../../../api/main/application/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../../util/helpers/processStateController";
import { call, select } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";

export function* sendingReplegePhotoForRecognizeSaga({
  payload: { applicationId },
  type: actionType,
}: PayloadAction<applicationBasisType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    yield call(API.main.application.sendingReplegePhotoForRecognize, {
      applicationId,
    });

    yield redirect(routes.application.car_photo);
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
    });
  } finally {
    yield process.stop();
  }
}
