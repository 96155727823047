import React, { FC } from "react";
import styles from "./index.module.css";
import { constantStrings } from "../../../../../helpers/init/constantStrings";
import { Icons } from "../../../../../assets/icons";

type propsType = {};

export const DesktopSupportLinks: FC<propsType> = () => {
  return (
    <div className={styles.support_links}>
      <div className={styles.support_title}>Техподдержка:</div>
      <a href={`tel:${constantStrings.phoneNumberForSupport}`}>
        {constantStrings.phoneNumberForSupportMask}
      </a>
      <a
        href={constantStrings.telegramLinkForSupport}
        target={"_blank"}
        className={styles.telegram_icon}
      >
        <Icons.logos.Telegram />
      </a>
      <a
        href={`https://wa.me/${constantStrings.whatsappNumberForSupport}`}
        target={"_blank"}
        className={styles.whatsapp_icon}
      >
        <Icons.logos.Whatsapp />
      </a>
    </div>
  );
};
