import React, { FC } from "react";
import { routes } from "../../../../../routes";
import { CheckingCar } from "../../../Application/pages/Statuses/CheckingCar";
import { Route } from "react-router-dom";
import { RepaymentSigningProcess } from "./RepaymentSigningProcess";

type propsType = {};

const {
  cabinet: { statuses },
} = routes;
export const CabinetStatusRoutes: FC<propsType> = (props) => {

  return <>
    <Route path={statuses.repayment_signing.path}>
      <RepaymentSigningProcess />
    </Route>
  </>;
};
