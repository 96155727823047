import React, { FC } from "react";
import styles from "./index.module.css";
import { Button } from "../../../../ui/Button";
import { useGetLoan } from "../../../../../hooks/api/useGetLoan";
import { stringHelpers } from "../../../../../helpers/string";
import { useHistory, useParams } from "react-router-dom";
import { Loader } from "../../../../ui/Loader";
import { routes } from "../../../../../routes";
import { useSelector } from "react-redux";
import { StateType } from "../../../../../store";
import { showDMYDate } from "../../../../../helpers/date";
import moment from "moment";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { authSagaActions } from "../../../../../sagas/auth/actions";

export const EarlyRepaymentToPay: FC = () => {
  const history = useHistory();

  const paymentType = useSelector(
    (state: StateType) => state.loanReducer.paymentType
  );

  const amountForPayment = useSelector(
    (state: StateType) => state.loanReducer.amountForPayment
  );

  const createdAtPayment = useSelector(
    (state: StateType) => state.loanReducer.createdAtPayment
  );

  const {
    data,
    nextPayment,
    paymentHasBeenMade,
    currentSituation,
    currentSchedule,
    isPaymentSoon,
    currentAmount,
    nextSchedule,
    allSchedule,
    currentLoan,
    isOverdue,
    isLoading,
    fullDaysToPenalty,
    overdueFullDays,
    orderedDate,
  } = useGetLoan();

  const { early_repayment_id, loan_id, amount } = useParams<{
    early_repayment_id: string;
    loan_id: string;
    amount: string;
  }>();

  const loadingAutoAuth = useSelector(
    processLoading.get(authSagaActions.autoAuth.type)
  );

  if (paymentType === "full_repayment") {
    return (
      <div className={styles.container}>
        {isLoading || loadingAutoAuth ? (
          <Loader variant={"large"} className={styles.loader} />
        ) : (
          <>
            <div className={styles.balance_container}>
              <div>Ваш баланс:</div>
              <div className={styles.amount}>
                {" "}
                {currentSituation?.balance || currentSituation?.balance === 0
                  ? stringHelpers.transformMoneyValue(currentSituation?.balance)
                  : " - "}{" "}
                ₽
              </div>
              <div className={styles.divider} />
              <h2 className={styles.nearest_payment}>
                Платеж: до {moment(nextPayment.date).format("DD MMMM")},{" "}
                <strong className={styles.bold}>
                  {stringHelpers.transformMoneyValue(nextPayment.amount)} ₽
                </strong>
              </h2>
            </div>
            <div className={styles.board_white}>
              <h2 className={styles.early_repayment}>
                Досрочное погашение:{" "}
                {amount || Number(amount) === 0
                  ? stringHelpers.transformMoneyValue(amount)
                  : " - "}
              </h2>
              <div className={styles.date_payment}>
                до {moment(createdAtPayment).format("DD.MM.YYYY")}
              </div>
            </div>
            <div className={styles.board_white}>
              <h2 className={styles.early_repayment}>
                Сумма к оплате:{" "}
                {amountForPayment
                  ? stringHelpers.transformMoneyValue(
                      amountForPayment.toFixed(2)
                    )
                  : 0}{" "}
                ₽
              </h2>
            </div>
            {amountForPayment === 0 && (
              <div className={styles.board_green}>
                <h2 className={styles.early_repayment}>
                  Суммы на балансе достаточно для досрочного погашения
                </h2>
              </div>
            )}
            <Button
              className={styles.button}
              onClick={() =>
                history.push(
                  routes.cabinet.full_early_repayment_iframe.root({
                    loan_id,
                    payment_id: early_repayment_id,
                    amount,
                  })
                )
              }
            >
              Далее
            </Button>
            <Button
              className={styles.cancel}
              variant={"underline"}
              onClick={() => history.push(routes.cabinet.my_loan)}
            >
              Отмена
            </Button>
          </>
        )}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {isLoading || loadingAutoAuth ? (
        <Loader variant={"large"} className={styles.loader} />
      ) : (
        <>
          <div className={styles.balance_container}>
            <div>Ваш баланс:</div>
            <div className={styles.amount}>
              {" "}
              {currentSituation?.balance || currentSituation?.balance === 0
                ? stringHelpers.transformMoneyValue(currentSituation?.balance)
                : " - "}{" "}
              ₽
            </div>
            <div className={styles.divider} />
            <h2 className={styles.nearest_payment}>
              Сумма досрочного погашения:{" "}
              <strong>
                {amount || Number(amount) === 0
                  ? stringHelpers.transformMoneyValue(amount)
                  : " - "}{" "}
                ₽
              </strong>
            </h2>
          </div>
          <div className={styles.board_white}>
            Сумма к оплате:{" "}
            {amountForPayment
              ? stringHelpers.transformMoneyValue(amountForPayment?.toFixed(2))
              : 0}{" "}
            ₽
          </div>
          {amountForPayment === 0 && (
            <div className={styles.board_green}>
              <h2 className={styles.early_repayment}>
                Суммы на балансе достаточно для досрочного погашения
              </h2>
            </div>
          )}
          <Button
            className={styles.button}
            onClick={() =>
              history.push(
                routes.cabinet.early_repayment_code.root({
                  loan_id,
                  early_repayment_id,
                  amount,
                })
              )
            }
          >
            Далее
          </Button>
          <Button
            className={styles.cancel}
            variant={"underline"}
            onClick={() => history.push(routes.cabinet.my_loan)}
          >
            Отмена
          </Button>
        </>
      )}
    </div>
  );
};
