import { useSelector } from "react-redux";
import { applicationReducerSelectors } from "../../store/reducers/application";
import { useQuery } from "react-query";
import { API } from "../../api";
import { useEffect } from "react";
import { UseFormSetError, UseFormSetValue } from "react-hook-form";
import { passportAndInnFormType } from "../../components/pages/Application/pages/PassportAndInn/types";
import {
  passportMainFieldsType,
  passportRegistrationFieldsType,
} from "../../types/documents/types";
import { dateMethods } from "../../helpers/date";
import { applicationType } from "../../types/application/types";
import { postApplicationType } from "../../api/main/application/types";

export const useGetApplicationAndSetValue = (
  setValue: UseFormSetValue<applicationType>,
  setError: UseFormSetError<any>
) => {
  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const getApplication = useQuery(
    "code",
    () => API.main.application.getApplication({ applicationId }),
    {
      enabled: applicationId !== null,
      onSuccess: (data: any) => {
        const application: postApplicationType = data?.data?.data;
      },
    }
  );

  useEffect(() => {
    if (getApplication?.data) {
      const applicationMainFields: any = getApplication?.data?.data?.data;

      let firstName = String(
        applicationMainFields?.user_info?.first_name
      ).toLowerCase();
      let last_name = String(
        applicationMainFields?.user_info?.last_name
      ).toLowerCase();
      let middle_name =
        applicationMainFields?.user_info?.middle_name &&
        String(applicationMainFields?.user_info?.middle_name).toLowerCase();
      const inputValueBirthDate =
        applicationMainFields?.user_info?.birth_day || null;

      // const regex = /^(\d{2})\.(\d{2})\.(\d{4})$/;

      // if (inputValueBirthDate && regex.test(inputValueBirthDate)) {
      setValue("passport.birth_day", dateMethods.format2(inputValueBirthDate));
      // }
      setValue(
        "passport.first_name",
        firstName?.charAt(0).toUpperCase() + firstName?.slice(1)
      );
      setValue(
        "passport.last_name",
        last_name?.charAt(0).toUpperCase() + last_name?.slice(1)
      );
      setValue(
        "passport.middle_name",
        middle_name
          ? middle_name?.charAt(0).toUpperCase() + middle_name?.slice(1)
          : null
      );

      setValue(
        "passport.gender",
        applicationMainFields?.user_info?.gender !== "male"
      );

      setValue("email", applicationMainFields?.email);

      // setError("reg_number", {
      //   type: "custom",
      //   message: "Введите VIN своего автомобиля",
      // });

      setError("vin_number", {
        type: "custom",
        message: "Введите VIN своего автомобиля",
      });
    }
  }, [getApplication?.data]);

  return {
    isLoading: getApplication?.isLoading,
  };
};
