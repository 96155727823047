import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../../util/helpers/processStateController";
import { call, put } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { lsController } from "../../../store/auxiliary/localStorage";
import { applicationReducer } from "../../../store/reducers/application";

export function* createApplicationShortSaga({
  type: actionType,
}: PayloadAction) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const grz = lsController.get("grz");
    const vin = lsController.get("vin");

    const vinOrRegNumber = grz || vin;

    if (vinOrRegNumber) {
      const response: sagaApiType = yield call(
        API.main.application.createApplicationShort,
        { vin_or_reg_number: vinOrRegNumber }
      );

      if (response.status === 201) {
        lsController.set("application_id", response.data?.data?.id);

        yield put(
          applicationReducer.actions.setApplicationId({
            application_id: response.data?.data?.id,
          })
        );

        yield put(
          applicationReducer.actions.setApplicationDocuments({
            document_photos: response.data?.data?.document_photos,
          })
        );

        yield redirect(routes.application.fio_and_grz);
      }
    } else {
      yield redirect(routes.application.fio_and_grz);
    }
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
      handledStatuses: [400],
    });
  } finally {
    yield process.stop();
  }
}
