import React, { AllHTMLAttributes, FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { motion, AnimatePresence } from "framer-motion";

type propsType = {
  defaultValue?: boolean;
  header?: any;
  setIsOpened?: React.Dispatch<React.SetStateAction<boolean>>;
  isOpened?: boolean;
} & AllHTMLAttributes<HTMLDivElement>;

export const ResizablePanel: FC<propsType> = ({
  children,
  className,
  defaultValue = false,
  header,
  setIsOpened,
  isOpened,
  ...jsxAttr
}) => {
  const [isOpenedInternal, setIsOpenedInternal] = useState<boolean>(
    defaultValue || false
  );

  const toggleState = () => {
    if (setIsOpened) {
      setIsOpened(!isOpened);
    } else {
      setIsOpenedInternal(!isOpenedInternal);
    }
  };

  return (
    <>
      {header && (
        <div onClick={toggleState} className={cn(styles.header, className)}>
          {header}
        </div>
      )}

      <AnimatePresence initial={defaultValue}>
        {(isOpenedInternal || isOpened) && (
          <motion.div
            className={styles.content_wrapper}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
