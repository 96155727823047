import React, { FC, useEffect } from "react";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { authSagaActions } from "../../../../../sagas/auth/actions";
import { InputPassword } from "../../../../ui/Inputs/InputPassword";
import { Button } from "../../../../ui/Button";
import { SmartForm } from "../../../../hocs/SmartForm";
import { InputText } from "../../../../ui/Inputs/InputText";
import { userReducerSelectors } from "../../../../../store/reducers/user/selectors";
import { Icons } from "assets/icons";
import { routes } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import {
  errorsReducer,
  errorsSelectors,
} from "../../../../../store/auxiliary/errorsReducer";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { setTokenToAxios } from "../../../../../api/methods/setTokenToAxios";
import { clearState } from "../../../../../store/auxiliary/clearState";
import { BannerWhite } from "../../../../ui/BannerWhite";
import { InputNumber } from "../../../../ui/Inputs/InputNumber";
import { onPastePhoneNumberHandler } from "../../../../../helpers/string";

type formType = {
  password: string;
  phone_number: string;
};

export const PersonalCabinetPassword: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const phoneNumber = useSelector(userReducerSelectors.getPhone);
  const error = useSelector(errorsSelectors.globalError);
  const errorPassword = useSelector(errorsSelectors.fieldsErrors);
  const loading = useSelector(
    processLoading.get(authSagaActions.getToken.type)
  );

  const form = useForm<formType>({
    defaultValues: {
      phone_number: phoneNumber,
    },
    reValidateMode: "onSubmit",
    // resolver: yupResolver(scheme),
  });

  const submit = (data: formType) => {
    dispatch(
      authSagaActions.getToken({
        password: data.password,
      })
    );
  };

  const forgotPassword = () => {
    dispatch(
      authSagaActions.getCode({
        smsTemplate: "restore",
      })
    );
  };

  const handleLogout = () => {
    dispatch(clearState());
    history.push(routes.auth.personal_cabinet);
    lsController.clearAll();
    setTokenToAxios(null);
    console.log("handleLogout");
  };

  return (
    <div className={styles.container}>
      <SmartForm form={form} submit={submit}>
        <div className={styles.subtitle_container}>
          <div className={styles.title_vehicle}>
            Займ <br />
            под залог авто{" "}
          </div>
          <div className={styles.subtitle_text}>Вход в личный кабинет</div>
          <div className={styles.comments}>
            Авторизуйтесь для входа в личный кабинет
          </div>
        </div>
        <InputNumber
          name={"phone_number"}
          label={"Телефон"}
          variant={"large"}
          className={styles.phone_input}
          mask={"+7 999 999 99 99"}
          support={
            <Icons.ui.PencilBlack
              className={styles.support}
              data-class={"dim"}
              onClick={handleLogout}
            />
          }
          disabled
        />
        <InputPassword
          name={"password"}
          label="Пароль"
          autoFocus
          className={styles.password_input}
          error={error}
        />
        <Button className={styles.button}>Продолжить</Button>
        <Button
          variant={"underline"}
          className={styles.forgot_password}
          onClick={() => forgotPassword()}
        >
          Не помню пароль
        </Button>
      </SmartForm>
    </div>
  );
};
