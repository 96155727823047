import { createFC } from "helpers/createFC";
import styles from "./index.module.css";
import React, { HTMLAttributes, useRef, useState } from "react";
import { useBlurred } from "hooks/logical/useBlurred";
import { informationDropdownMenuAnimation } from "./animate";
import { motion } from "framer-motion";
import cn from "classnames";
import { Icons } from "../../../../../../../assets/icons";

type propsType = {
  className?: string;
  variant?: "grey" | "white";
  title?: string | JSX.Element;
  titleFooter?: string | JSX.Element;
  shiftDownStyles?: React.CSSProperties;
  isOverdue?: boolean;
  notMoneyEnough?: boolean;
};

/*
  Скопировал дропдаун из экрана ввода грз/vin, сделал пока отдельным компонентом
 */

export const InfoDropdown = createFC(
  ({
    className,
    variant,
    title,
    titleFooter,
    shiftDownStyles,
    isOverdue,
    notMoneyEnough,
  }: propsType) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);
    useBlurred(menuRef, setIsMenuOpen, isMenuOpen);

    return (
      <div
        className={cn(styles.container, {
          [`${className}`]: className,
          [`${shiftDownStyles}`]: isMenuOpen && variant === "white",
          // [styles.shiftDown]: variant === "grey" && isMenuOpen,
          [styles.bg_red]: isOverdue && !className,
          // [styles.bg_red_light]: isOverdue,
        })}
      >
        {/*{variant === "grey" && (*/}
        {/*  <>*/}
        {/*    <div className={styles.title}>Общая информация</div>*/}
        {/*    <div*/}
        {/*      className={styles.image}*/}
        {/*      onClick={() => setIsMenuOpen(true)}*/}
        {/*      // ref={menuRef}*/}
        {/*    >*/}
        {/*      <Icons.ui.Drop_down />*/}
        {/*    </div>*/}
        {/*  </>*/}
        {/*)}*/}

        {variant === "white" && (
          <div className={styles.container_title_white}>
            <div className={styles.title_white}>{title}</div>
            <div className={styles.arrow}>
              <div
                className={cn(styles.image, {
                  [styles.rotateIcon]: isMenuOpen,
                })}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                // ref={menuRef}
              >
                {isOverdue && !className ? (
                  <Icons.cabinet.dropDownArrowWhite />
                ) : (
                  <Icons.ui.DropDown />
                )}
              </div>
              <div className={styles.arrow_text}>
                {isMenuOpen ? "Свернуть" : "Развернуть"}
              </div>
            </div>
          </div>
        )}

        <motion.div
          variants={informationDropdownMenuAnimation}
          initial={"close"}
          animate={isMenuOpen ? "open" : "close"}
          className={cn(styles.menu, {
            // [styles.menu_white]: variant === "white",
          })}
        >
          <div className={styles.container_menu}>
            {/*{variant === "grey" && (*/}
            {/*  <>*/}
            {/*    {" "}*/}
            {/*    <div className={styles.image_close}>*/}
            {/*      <Icons.ui.Drop_down onClick={() => setIsMenuOpen(false)} />*/}
            {/*    </div>*/}
            {/*    <div className={styles.title_footer}>*/}
            {/*      Полное фирменное наименование: Общество <br /> с ограниченной*/}
            {/*      ответственностью Микрокредитная компания Кредди.*/}
            {/*      Сокращенное фирменное наименование: <br /> ООО МКК «Кредди»*/}
            {/*    </div>*/}
            {/*    <div className={styles.strip}></div>*/}
            {/*    <div className={styles.title_footer}>*/}
            {/*      ИНН: 9710037450 <br />*/}
            {/*      ОГРН: 5177746022262*/}
            {/*    </div>*/}
            {/*    <div className={styles.strip}></div>*/}
            {/*    <div className={styles.title_footer}>*/}
            {/*      Ссылка на страницу Банка России, содержащую государственный*/}
            {/*      реестр микрофинансовых организаций:{" "}*/}
            {/*      <a*/}
            {/*        href="https://cbr.ru/microfinance/registry"*/}
            {/*        target={"_blank"}*/}
            {/*        className={styles.underline}*/}
            {/*      >*/}
            {/*        https://cbr.ru/microfinance/registry*/}
            {/*      </a>*/}
            {/*    </div>*/}
            {/*    <div className={styles.strip}></div>*/}
            {/*    <div className={styles.title_footer}>*/}
            {/*      Регистрационный номер записи <br /> в государственном реестре*/}
            {/*      микрофинансовых организаций: 1703045008644 от 01.12.2017*/}
            {/*    </div>*/}
            {/*  </>*/}
            {/*)}*/}
            {variant === "white" && (
              <>
                <div className={styles.title_footer_white}>{titleFooter}</div>
              </>
            )}
          </div>
        </motion.div>
      </div>
    );
  }
);
