import styles from "./index.module.css";
import { FC, useRef, useState } from "react";
import { Button } from "../../../../ui/Button";

const lsKeys: (string | null)[] = [];
const ssKeys: (string | null)[] = [];

for (let i = 0; i < localStorage.length; i++) {
  lsKeys.push(localStorage.key(i));
}

for (let i = 0; i < sessionStorage.length; i++) {
  ssKeys.push(sessionStorage.key(i));
}

export const UserDeviceInfo: FC = () => {
  const [copied, setCopied] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);

  const copyToClipboard = async (text: string) => {
    if ("clipboard" in window.navigator) {
      return await window.navigator.clipboard.writeText(text)
        .then(() => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false)
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const lsValuesNew = lsKeys.map(item => {
    return (
      <div className={styles.item}>
        <span className={styles.bold}>{item}</span>:{" "}{localStorage.getItem(item as string)}
      </div>
    );
  });

  const ssValuesNew = ssKeys.map(item => {
    return (
      <div className={styles.item}>
        <span className={styles.bold}>{item}</span>:{" "}{sessionStorage.getItem(item as string)}
      </div>
    );
  });

  return (
    <>
      <div className={styles.container} ref={ref}>
        <div className={styles.title}>User Agent:</div>
        <div>{window.navigator.userAgent}</div>
        <div className={styles.title}>local storage:</div>
        <div>{lsValuesNew}</div>
        <div className={styles.title}>session storage:</div>
        <div>{ssValuesNew}</div>
      </div>
      <Button className={styles.button} onClick={() => copyToClipboard(ref.current?.innerText || "")}>
        {copied ? "Скопировано!" : "Скопировать"}
      </Button>
    </>
  );
};
