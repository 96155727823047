import React, { FC, useEffect, useRef } from "react";
import styles from "./index.module.css";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { loanProcessingSchema } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { useGetInfoFromApplication } from "../../../../../hooks/api/useGetInfoFromApplication";
import { Loader } from "../../../../ui/Loader";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../routes";

/*
    /application/loan_processing
    Это страница с айфреймом для прикрепления карты через СБП
 */
export const LoanProcessing: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // * когда логика полная будет известна, то убрать из компоненты всё лишнее
  const form = useForm<any>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(loanProcessingSchema),
  });
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { payment_url, application_type, isLoading, error_type } =
    useGetInfoFromApplication(5000);

  useEffect(() => {
    if (application_type === "sbp_check_process") {
      history.push(routes.application.statuses.account_check);
    }
    if (application_type === "survey_filling") {
      history.push(routes.application.sbp_bank_filling);
    }
    // sbp_check_process можем проскочить - тогда кидаем сразу на account_confirmation
    if (application_type === "signing_applying_agreement_filling") {
      history.push(routes.application.account_confirmation);
    }
    if (application_type === "sbp_check_error") {
      history.push(`/application/sbp_check_error/${error_type}`);
    }
  }, [application_type]);

  if (isLoading) {
    return (
      <MobilePageContainer>
        <Loader variant={"large"} color={"purple"} className={styles.loader} />
      </MobilePageContainer>
    );
  }

  return (
    <MobilePageContainer>
      <div className={styles.container}>
        <div>
          <div className={styles.title}>
            Не закрывайте экран до завершения <br />
            процесса <Loader className={styles.loader} />
          </div>
          <div className={styles.text}>
            Укажите банковскую карту, на счет которой мы выдаем деньги. <br />
            Мы спишем и вернем 1 рубль для проверки вашего доступа к счету
          </div>
          <div className={styles.h_iframe}>
            <iframe
              ref={iframeRef}
              key={payment_url}
              src={payment_url}
              className={styles.responsive_iframe}
              // scrolling={"no"}
            />
          </div>
        </div>
      </div>
    </MobilePageContainer>
  );
};
