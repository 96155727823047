import { useFormContext } from "react-hook-form";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import styles from "./index.module.css";
import {
  carPhotoType,
  DocumentApiTypes,
  documentPhotoType,
  DocumentTypes,
  photoType,
  photoTypeData,
} from "../../../types/documents/types";
import { API } from "../../../api";
import { DocumentsAreaCore } from "./components/Core";
import { validationsMessages } from "../../../helpers/validation/validationsMessages";
import {
  applicationStatusType,
  applicationType,
} from "../../../types/application/types";

type baseType = {
  id?: string;
  name: string;
  borderError?: boolean;
  title?: string;
  className?: string;
  accept?: string;
  viewMode?: boolean;
  fixedFiles?: Array<string>;
  uploadedDocument?: DocumentTypes.entity;
  defaultFiles?: Array<DocumentApiTypes.FileServerType>;
  applicationId: number | null;
  onUploaded?: (photoType: string) => any;
  delayPing?: (photoType: string) => any;
  continuePing?: () => any;
  plusIcon?: boolean;
  docs?: any;
  isConfirmedByUnderwriter?: boolean;
  loadingDocuments?: boolean;
  isPhotoRepeat?: boolean;
  applicationType?: applicationStatusType;

  // TODO remove any
  placeholderImage?: any;
} & DocumentApiTypes.documentTypeField;

export type fileTypeBase = {
  file_name: string;
  file_id?: string | number;
  loading?: boolean;
  type: string;
  value: string;
  uploaded?: boolean;
  extension: string;
  recognition?: boolean;
  // fixed files
  fixed?: boolean;
  title?: string;
  //  * NEW
  loadingDocuments?: boolean;
  document_type?: documentPhotoType | "repledge_loan_contract_add";
};

export const mimeTypesForPreload = ["image/jpeg", "image/png"];

// Container specifies api methods
export const DocumentsArea = React.memo(
  ({ applicationId, ...props }: baseType) => {
    const [documentId, setDocumentId] = useState<null | number | string>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [documentEntity, setDocumentEntity] = useState<
      DocumentTypes.entity | undefined
    >(props.uploadedDocument);

    const [num, setNum] = useState(props?.docs?.length);
    const [numRef, setNumRef] = useState(props?.docs?.length);

    const [isForRepeat, setIsForRepeat] = useState(false);

    const dispatch = useDispatch();
    const { control, setValue, clearErrors, setError } = useFormContext();

    const status = props.uploadedDocument?.type;

    // api methods
    const upload = async (file: fileTypeBase) => {
      const response = await API.main.documents.uploadDocument({
        content: file.value,
        file_name: file.file_name,
      });

      return response?.data?.data;
    };

    const handleRemove = useCallback(
      (fileId: string) => {
        // return mainApi.documents.deleteFileById({
        //   file_id: fileId,
        //   document_id: String(documentId),
        // });
        return new Promise((res) => res(""));
      },
      [documentId, props.uploadedDocument]
    );

    useEffect(() => {
      setNum(props?.docs?.length);
    }, [props?.docs?.length]);

    const attachingDocumentToTheApplication = async ({
      applicationId,
      document_type,
      data,
    }: {
      applicationId: number | null;
      document_type: string;
      data?: { id: number };
    }) => {
      // TODO сделать нормальное сужение типов
      if (
        document_type === "repledge_loan_contract" ||
        document_type === "repledge_certificate_of_debt_status" ||
        document_type === "repledge_loan_contract_add"
      ) {
        (props.applicationType === "repledge_document_filling" ||
          props.applicationType === "need_replace_photo_filling") &&
          setNum((prev: any) => prev + 1);
        return await API.main.documents.attachingRepledgeDocumentToTheApplication(
          {
            applicationId,
            document_id: data!.id,
            photo_type:
              document_type === "repledge_loan_contract_add"
                ? "repledge_loan_contract"
                : (document_type as photoType),
          }
        );
      } else if (photoTypeData.includes(document_type)) {
        return await API.main.documents.attachingDocumentToTheApplication({
          applicationId,
          document_id: data!.id,
          photo_type: document_type as photoType,
        });
      } else {
        setIsForRepeat(true);
        return await API.main.documents.attachingCarPhotoToTheApplication({
          applicationId,
          document_id: data!.id,
          photo_type: document_type as carPhotoType,
        });
      }
    };

    const getFiles = async () => {
      if (props.uploadedDocument) {
        const fileMetaData = {
          file_name: props.uploadedDocument?.file_name,
          file_id: props.uploadedDocument?.id,
          type: "image/jpeg",
          document_type: props.uploadedDocument?.type,
        };

        try {
          setIsFetching(true);

          if (
            props.uploadedDocument?.type === "recognition_error_document_photo"
          ) {
            setIsFetching(false);

            await setTimeout(() => {
              setError(props.name, {
                message: validationsMessages.api.recognize,
              });
            }, 0);

            return;
          }

          let fileResponse: any;

          if (photoTypeData.includes(props.document_type)) {
            if (props.document_type === "repledge_loan_contract") {
              fileResponse = await API.main.documents.getDocumentPreviewById({
                applicationId,
                documentId: props.id!,
              });
            } else {
              fileResponse = await API.main.documents.getDocumentPreview({
                applicationId,
                photo_type: props.document_type as photoType,
              });
            }
          } else {
            fileResponse = await API.main.documents.getCarPhotoPreview({
              applicationId,
              photo_type: props.document_type as carPhotoType,
            });
          }

          setValue(props.name, [
            {
              value: fileResponse.data.data.content,
              ...fileMetaData,
            },
          ]);
        } catch (e) {
          setValue(props.name, [
            {
              value: "",
              ...fileMetaData,
            },
          ]);
        } finally {
          setIsFetching(false);
        }
      } else {
        return;
      }
    };

    useEffect(() => {
      getFiles();
    }, [applicationId]);

    // fixed files logic
    useEffect(() => {
      if (props.fixedFiles) {
        setValue(
          props.name,
          props.fixedFiles.map((fileTitle: any) => {
            return {
              fixed: true,
              title: fileTitle,
            };
          })
        );
      }
    }, []);

    return (
      <div className={cn(styles.container, props.className)} id="document_area">
        {(props.title || props.document_type) && (
          <h2 className={styles.title}>
            {props.title ||
              (props.document_type
                ? // ? titleByDocumentTypeMap[props.document_type]
                  ""
                : props.title)}
          </h2>
        )}
        <DocumentsAreaCore
          documentEntity={documentEntity}
          isPhotoRepeat={props.isPhotoRepeat}
          clearErrors={clearErrors}
          viewMode={props.viewMode}
          isFetching={isFetching}
          delete={handleRemove}
          accept={props.accept}
          setError={setError}
          control={control}
          upload={upload}
          {...props}
          isForRepeat={isForRepeat}
          num={num}
          document_type={props.document_type}
          status={status}
          onUploaded={async ({ applicationId, document_type, data }) => {
            console.log("onUploaded props to DocumentsAreaCore");
            if (props.onUploaded) {
              props.onUploaded(document_type);
            }
            const { data: dataFromAttachingDocument } =
              await attachingDocumentToTheApplication({
                applicationId,
                document_type,
                data,
              });
          }}
        />
      </div>
    );
  }
);
