import { createAction } from "@reduxjs/toolkit";
import { errorHandlerSagaType } from "./helpers/errorHandlerSaga";
import { authSagaActions } from "../auth/actions";
import { applicationSagaActions } from "../application/actions";
import { cabinetSagaActions } from "../cabinet/actions";

export const sagaActions = {
  auth: authSagaActions,
  application: applicationSagaActions,
  // user: authSagaActions,
  // application: applicationSagaActions,
  // factoring: applicationFactoringSagaActions,
  // user: userSagaActions,
  // creditLine: creditLineSagaActions,
  cabinet: cabinetSagaActions,
  error: createAction<errorHandlerSagaType>("error"),
};
