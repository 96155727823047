export const validationsMessages = {
  number: "Введите данные",
  min: "Минимально 0 р.",
  max: "Максимально 1 000 000 р.",
  maxMillion: "Максимально 1 000 000 р.",
  required: "Фото обязательно к загрузке",
  requiredField: "Поле обязательно к заполнению",
  inn: "Введите верный ИНН",
  onlyLetters: "Поле может содержать только буквы, дефис и пробел",
  minDate: "Вам должно быть не более 65 лет",
  maxDate: "Должно быть более 21 года!",
  dateFromPast: "Дата должна быть из прошлого",
  phone: {
    required: "Введите телефон",
    correctNumber: "Введите корректный номер",
  },
  correctDate: "Введите корректную дату",
  dateOfBirth: "Возраст должен быть больше 18 лет",
  fullData: "Введите данные полностью",
  email: "Введен некорректный E-mail",
  snils: "Введен некорректный Снилс",
  address: "Заполните адрес до дома",
  file: "Недопустимый формат файла",
  api: {
    document: "Не удалось загрузить, повторите попытку",
    recognize: "Фото не удалось распознать, замените",
  },
  integer: "Введите целое число",
  invalidEmail: "Введите корректный email",
  secondPhone: "Введите второй номер телефона",
  secondPhoneLength: "Номер должен состоять из 11 цифр"
};
