import { VehicleValuation } from "../components/pages/Auth/pages/VehicleValuation";
import { ScoringAndLoanDisbursement } from "../components/pages/Application/pages/Statuses/ScoringAndLoanDisbursement";
import { DataVerification } from "../components/pages/Application/pages/Statuses/DataVerification";
import { VideoCallPlanned } from "../components/pages/Application/pages/VideoCallPlanned";
import { EarlyRepaymentAmount } from "../components/pages/Cabinet/pages/EarlyRepaymentAmount";
import { Refinancing } from "../components/pages/Application/pages/Refinancing";

export const routes = {
  sandbox: "/sandbox",
  info: "/info",
  pd: "/pd",
  // device_info: "/device_info",
  // personal_cabinet: "/personal_cabinet",
  auth: {
    root: "/user",
    personal_cabinet: "/user/personal_cabinet",
    personal_cabinet_password: "/user/personal_cabinet_password",
    wrong_vehicle: "/user/wrong_vehicle",
    vehicle_valuation: "/user/vehicle_valuation",
    vehicle_not_found: "/user/vehicle_not_found",
    vehicle_error: "/user/vehicle_error",
    phone: "/user/phone",
    code: "/user/code",
    password: "/user/password",
    restore_password: "/user/restore_password",
    to_mobile_device: "/user/to_mobile_device",
    device_info: "/user/device_info",
  },
  application: {
    root: "/application",
    banki_api: "/application/banki_api",
    loan_rejection: "/application/loan_rejection",
    partner_application: "/application/partner_application",
    new_loan: "/application/new_loan",
    documents: "/application/documents",
    repeated_photos: "/application/repeated_photos",
    passport_and_inn: "/application/passport_and_inn",
    refinancing: "/application/refinancing",
    fio_and_grz: "/application/fio_and_grz",
    car_photo: "/application/car_photo",
    replege_photo: "/application/replege_photo",
    video_call_planned: "/application/video_call_planned",
    loan_decision: "/application/loan_decision",
    loan_processing: "/application/loan_processing",
    account_confirmation: "/application/account_confirmation",
    sbp_bank_filling: "/application/sbp_bank_filling",
    signing: (variant: "application" | "loan_contract") =>
      `/application/signing/${variant}`,
    // status: (variant: "error" | "waiting" | "") =>
    //   `/application/status/${variant}`,

    statuses: {
      checking_car: "/application/checking_car",
      refusal_final_bad_car: "/application/refusal_final_bad_car",
      recognize_documents: "/application/recognize_documents",
      data_verification: "/application/data_verification",
      sent_and_scoring_process: "/application/sent_and_scoring_process",
      scoring_and_loan_disbursement:
        "/application/scoring_and_loan_disbursement",
      signing_process: "/application/signing_process",
      signed_issuing_process: "/application/signed_issuing_process",
      issued_final: "/application/issued_final",
      issue_method_filling: "/application/issue_method_filling",
      error_final: "/application/error_final",
      account_check: "/application/account_check",
      sbp_check_error: "/application/sbp_check_error/:error_type",
      sbp_check_error_connected: "/application/sbp_check_error/not_connected",
      sbp_check_error_not_sbp_bank: "/application/sbp_check_error/not_sbp_bank",
      sbp_check_error_name_differs: "/application/sbp_check_error/name_differs",
      sbp_bank_filling: "/application/sbp_bank_filling",
      account_confirmation: "/application/account_confirmation",
      payment_system_response: "/application/payment_system_response",
      underwriter_refusal: "/application/underwriter_refusal",
    },

    code: {
      path: "/application/code/:variant",
      root: "/application/code",
      loan: "/application/code/loan",
      contract: "/application/code/contract",
    },
  },

  cabinet: {
    root: "/cabinet",
    my_loan: "/cabinet/my_loan",
    notifications: "/cabinet/notifications",
    history: "/cabinet/history",
    paid_loan: "/cabinet/paid_loan",
    application_info: "/cabinet/application_info",
    early_repayment_amount: "/cabinet/early_repayment_amount",
    early_repayment_to_pay: {
      path: "/cabinet/early_repayment_to_pay/:loan_id/:early_repayment_id/:amount",
      root: ({
        loan_id,
        early_repayment_id,
        amount,
      }: {
        loan_id?: number | string | null;
        early_repayment_id?: number | string | null;
        amount?: number | string | null;
      }) =>
        `/cabinet/early_repayment_to_pay/${loan_id}/${early_repayment_id}/${amount}`,
    },
    early_repayment_accept: "/cabinet/early_repayment_accept",
    loan_payment: {
      path: "/cabinet/loan_payment/:loan_id/:payment_id",
      root: ({
        loan_id,
        payment_id,
      }: {
        loan_id?: number | string | null;
        payment_id?: number | string | null;
      }) => `/cabinet/loan_payment/${loan_id}/${payment_id}`,
    },
    early_repayment_code: {
      path: "/cabinet/early_repayment/:loan_id/:early_repayment_id/:amount",
      root: ({
        loan_id,
        early_repayment_id,
        amount,
      }: {
        loan_id?: number | string | null;
        early_repayment_id?: number | string | null;
        amount?: number | string | null;
      }) =>
        `/cabinet/early_repayment/${loan_id}/${early_repayment_id}/${amount}`,
    },
    early_repayment_iframe: {
      path: "/cabinet/early_repayment_iframe/:loan_id/:payment_id",
      root: ({
        loan_id,
        payment_id,
      }: {
        loan_id?: number | string | null;
        payment_id?: number | string | null;
      }) => `/cabinet/early_repayment_iframe/${loan_id}/${payment_id}`,
    },
    full_early_repayment_iframe: {
      path: "/cabinet/full_early_repayment_iframe/:loan_id/:payment_id/:amount",
      root: ({
        loan_id,
        payment_id,
        amount,
      }: {
        loan_id?: number | string | null;
        payment_id?: number | string | null;
        amount?: number | string | null;
      }) =>
        `/cabinet/full_early_repayment_iframe/${loan_id}/${payment_id}/${amount}`,
    },

    cards_to_issue: "/cabinet/cards_to_issue",
    repayment_methods: "/cabinet/repayment_methods",
    documents: "/cabinet/documents",
    feedback: "/cabinet/feedback",

    statuses: {
      repayment_signing: {
        path: "/cabinet/repayment_signing/:loan_id/:early_repayment_id/:amount",
        root: ({
          loan_id,
          early_repayment_id,
          amount,
        }: {
          loan_id?: number | string | null;
          early_repayment_id?: number | string | null;
          amount?: number | string | null;
        }) =>
          `/cabinet/repayment_signing/${loan_id}/${early_repayment_id}/${amount}`,
      },
    },
  },

  underwriter: {
    root: "/underwriter",
  },
  docs: {
    root: {
      path: "/doc/:hash",
      root: (hash: string) => `/doc/${hash}`,
    },
    pd: {
      path: "/pd/:hash",
      root: "/pd",
      hash: (hash: string) => `/pd/${hash}`,
    },
    asp: {
      root: "/asp",
    },
    agr: {
      path: "/agr/:hash",
      root: "/agr",
      hash: (hash: string) => `/agr/${hash}`,
    },
    loan_contract: {
      path: "/loan_contract/:hash",
      root: "/loan_contract",
      hash: (hash: string) => `/loan_contract/${hash}`,
    },
  },
};
