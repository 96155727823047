import React, {
  FC,
  MutableRefObject,
  ReactComponentElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Control,
  Controller,
  get,
  useFormContext,
  UseFormSetValue,
} from "react-hook-form";
import cn from "classnames";
import DatePicker from "react-datepicker";
import { ru } from "date-fns/locale";
import mergeRefs from "react-merge-refs";
import styles from "./style.module.css";
import { Icons } from "../../../assets/icons";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.css";
import { useToggle } from "../../../hooks/logical/useToggle";
import { useBlurred } from "../../../hooks/logical/useBlurred";
import moment from "moment";
import { CalendarHeader } from "./Bricks/CalendarHeader";
import { CalendarInput } from "../Inputs/InputCalendar";
import { formatWeekDay, renderDayContents } from "./helpers";
import { dateMethods } from "../../../helpers/date";

type propsType = {
  control: Control<any>;
  name: string;
  setValue: UseFormSetValue<any>;
  big?: boolean;
  highlightDates?: any[];
  placeholder?: string;
  rightCalendar?: boolean;
  className?: string;
  error?: string;
  style?: any;
  defaultValue?: string;
  minDate?: Date;
  maxDate?: Date;
  inputRef?: MutableRefObject<HTMLInputElement>;
  onFocus?: any;
  onKeyDown?: any;
  primaryColor?: "blue";
  support?: ReactComponentElement<"svg">;
  disabled?: boolean;
};
export const Calendar: FC<propsType> = ({
  control,
  name,
  big,
  highlightDates,
  placeholder = "Дата",
  rightCalendar = false,
  className,
  error,
  style,
  defaultValue,
  minDate,
  maxDate,
  setValue,
  inputRef,
  onFocus,
  onKeyDown,
  primaryColor,
  support,
  disabled,
}) => {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
  }, []);
  const [isOpen, toggleOpen, setIsOpen] = useToggle(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [localError, setLocalError] = useState("");
  useBlurred(wrapperRef, setIsOpen, isOpen);
  const close = (event?: any) => {
    setIsOpen(false);
  };

  return (
    <div
      ref={wrapperRef}
      className={cn({
        [styles.container]: true,
        [`${className}`]: className,
        big: big,
      })}
      style={style}
    >
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => {
          // console.log(field.ref, "field");
          const value = (function () {
            try {
              return field.value ? new Date(field.value) : undefined;
            } catch (e) {
              return undefined;
            }
          })();

          return (
            //  @ts-ignore
            <DatePicker
              locale={ru}
              formatWeekDay={formatWeekDay}
              // inline={big}
              popperClassName={cn({
                [styles.right]: rightCalendar,
              })}
              open={isOpen}
              highlightDates={highlightDates}
              renderDayContents={renderDayContents}
              renderCustomHeader={(props) => (
                <CalendarHeader big={big} {...props} />
              )}
              onChange={(date: Date) => {
                // INFO если в инпут что-то ввести, а потом убрать и попробовать открыть календарь, то прила падает
                // INFO из за того, что date null, поэтому если date нету, то мы тупо чистим поле в нашей форме
                if (date) {
                  field.onChange(dateMethods.format(date, "yyyy-MM-DD"));
                } else {
                  setValue(name, undefined);
                }
                close();
              }}
              onBlur={close}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              dateFormat="dd.MM.yyyy"
              placeholderText={placeholder}
              minDate={minDate}
              maxDate={maxDate}
              onChangeRaw={(e: any) => {
                setLocalError("");
              }}
              customInputRef={field.ref}
              name={field.name}
              value={value}
              selected={value || null}
              disabled={disabled}
              customInput={
                <CalendarInput
                  isOpen={isOpen}
                  variant={big ? "large" : "default"}
                  primaryColor={primaryColor}
                  support={support ||
                    <Icons.ui.Calendar
                      className={styles.icon}
                      onClick={toggleOpen}
                    />
                  }
                  customRef={
                    inputRef ? mergeRefs([field.ref, inputRef]) : field.ref
                  }
                  className={styles.input}
                  placeholder={placeholder}
                  label={placeholder}
                  inputMode="numeric"
                  error={localError || error}
                  customOnBlur={(e) => {
                    close();
                    const validDateFormat = e.target.value
                      ?.split(".")
                      ?.reverse()
                      ?.join("-");
                    let isDateValid =
                      validDateFormat?.length &&
                      moment(validDateFormat).isValid();

                    if (!isDateValid) {
                      // setLocalError("Введите корректную дату");
                    } else {
                      setLocalError("");
                    }

                    try {
                      if (maxDate != undefined && isDateValid) {
                        const validMaxDate =
                          validDateFormat &&
                          moment(validDateFormat).isBefore(maxDate);

                        if (!validMaxDate) {
                          setLocalError("Введите дату из прошлого!");
                          return;
                        }
                      }
                    } catch (e) {}
                  }}
                />
              }
            />
          );
        }}
      />
    </div>
  );
};

export const SmartCalendar = (
  props: Omit<propsType, "control" | "setValue">
) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  return React.createElement(Calendar, {
    control,
    setValue,
    error: get(errors, `${props.name}.message`),
    ...props,
  });
};
