import styles from "./index.module.css";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { API } from "../../../../../api";
import { loanType } from "../../../../../types/cabinet/types";
import { stringHelpers } from "../../../../../helpers/string";
import {
  loanReducer,
  loanReducerSelectors,
} from "../../../../../store/reducers/loan";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { Button } from "../../../../ui/Button";
import { Loader } from "../../../../ui/Loader";
import { PaidLoanItemCard } from "./PaidLoanItemCard";
import { logout, userReducer } from "../../../../../store/reducers/user";
import { applicationReducer } from "../../../../../store/reducers/application";
import { clearState } from "../../../../../store/auxiliary/clearState";

export const PaidLoan: FC = () => {
  const [loanItems, setLoanItems] = useState<loanType[]>([]);
  const [monthlyPayment, setMonthlyPayment] = useState<number | null>(null);
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const loanAmount = useSelector(loanReducerSelectors.getLoanAmount);

  const getLoanInfo = () => {
    setLoading(true);

    API.main.application
      .getLoan({})
      .then((res) => {
        if (res.status === 200) {
          setLoanItems(res.data.data.items);

          const paidLoans = res?.data?.data?.items.filter(
            (item: loanType) => item.status === "paid_loan"
          );

          if (!paidLoans.length) {
            newLoan();
          }

          dispatch(
            loanReducer.actions.setPaidLoans({
              paidLoans: paidLoans.map((item) => ({
                applicationId: item.application_id,
                amount: item.loan_data.amount,
                end_date: item.end_date,
                loan_number: item.loan_number,
              })),
            })
          );

          const lastPaidLoan = paidLoans.sort(
            (a: loanType, b: loanType) =>
              new Date(b.end_date).getTime() - new Date(a.end_date).getTime()
          )[0];

          API.main.services
            .getSchedule({
              amount: lastPaidLoan.loan_data.amount,
              loan_term: "48",
              loan_date: moment(Date.now()).format("YYYY-MM-DD"),
            })
            .then((res) => {
              setMonthlyPayment(res.data.data.monthly_payment);
              setDate(
                moment(res.data.data.schedule[47].date).format("DD.MM.YYYY")
              );
            });

          dispatch(
            loanReducer.actions.setLoanId({
              loan_id: lastPaidLoan?.id,
            })
          );
          dispatch(
            loanReducer.actions.setLoanStatus({
              loanStatus: lastPaidLoan?.status,
            })
          );
          dispatch(
            loanReducer.actions.setLoanAmount({
              loanAmount: lastPaidLoan.loan_data.amount,
            })
          );
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("getLoan error", e);
        setLoading(false);
      });
  };

  const newLoan = () => {
    // dispatch(applicationSagaActions.newLoanSaga());
    lsController.set("grz", "");
    lsController.set("vin", "");
    dispatch(applicationSagaActions.createApplicationShort());
  };

  useEffect(() => {
    getLoanInfo();
  }, []);

  if (loading) {
    return (
      <MobilePageContainer className={styles.bg_white}>
        <Loader variant={"large"} color={"purple"} className={styles.loader} />
      </MobilePageContainer>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.card_white}>
          <div style={{ marginBottom: "9px" }}>Вам уже одобрено:</div>
          <div className={styles.sum_big}>
            {loanAmount && stringHelpers.transformMoneyValue(loanAmount)} ₽
          </div>
          <div className={styles.divider} />
          <div style={{ fontSize: "14px" }}>
            <span style={{ fontWeight: 600 }}>
              {monthlyPayment
                ? stringHelpers.transformMoneyValue(monthlyPayment)
                : "-"}
            </span>{" "}
            в месяц до <span style={{ fontWeight: 600 }}>{date}</span>
          </div>
        </div>
        <Button className={styles.button} onClick={newLoan}>
          Получить займ
        </Button>
      </div>
      {loanItems
        .filter((item) => item.status === "paid_loan")
        .map((item) => (
          <PaidLoanItemCard key={item.id} loan={item} />
        ))}
    </>
  );
};
