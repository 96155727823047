import { Control, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import React, { AllHTMLAttributes, useState } from "react";
import cn from "classnames";
import styles from "./index.module.css";
import { getFileFromEvent } from "helpers/file";
import FileComponents from "../File";
import FileDocumentComponents from "../DocumentFile";
import { fileTypeBase } from "../../index";
import { Loader } from "../../../Loader";
import {
  DocumentApiTypes,
  DocumentTypes,
  photoTypeData,
} from "../../../../../types/documents/types";
import { sagaActions } from "../../../../../sagas/util/sagaActions";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { validationsMessages } from "../../../../../helpers/validation/validationsMessages";

const { File, FileAdd } = FileComponents;
const { DocumentFile, FileDocumentAdd } = FileDocumentComponents;

type propsType<T> = {
  control: Control<any>;
  name: string;
  clearErrors: any;
  setError: any;
  isFetching?: boolean;
  upload: (value: any) => Promise<any>;
  onUploaded?: (value: {
    applicationId: number | null;
    document_type: string;
    data?: any;
  }) => any;
  delete: (id: string) => Promise<any>;
  viewMode?: boolean;
  accept?: string;
  fixedFiles?: Array<string>;
  documentEntity?: DocumentTypes.entity;
  status?: string;
  num?: any;
  isForRepeat?: boolean;
  isConfirmedByUnderwriter?: boolean;
  loadingDocuments?: boolean;
  isPhotoRepeat?: boolean;
} & DocumentApiTypes.documentTypeField &
  AllHTMLAttributes<HTMLDivElement>;

export function DocumentsAreaCore<T>({ setError, ...props }: propsType<T>) {
  // console.log(props.className);
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const { append, fields, remove, update } = useFieldArray({
    control: props.control,
    name: props.name,
  });

  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const validateAcceptable = (file: fileTypeBase) => {
    // const acceptable = props.accept?.split(", ");
    const acceptable = [
      ".png",
      ".jpg",
      ".jpeg",
      ".heic",
      ".HEIC",
      ".HEIF",
      ".heif",
      ".tiff",
      ".TIFF",
    ];

    // * на всякий props.accept &&
    if (acceptable && !acceptable.includes(file.extension)) {
      setError(props.name, {
        message: validationsMessages.file,
      });

      setIsUploading(false);
      setTimeout(() => {
        return false;
      }, 0);
    } else {
      return true;
    }
  };

  // Handlers
  async function handleAdd(event: React.ChangeEvent<HTMLInputElement>) {
    setIsUploading(true);
    getFileFromEvent<fileTypeBase>(event).then(async (file: any) => {
      if (!validateAcceptable(file)) {
        return;
      }

      props
        .upload(file)
        .then((res) => {
          append({
            ...file,
            file_id: res?.id,
          });
        })
        .catch((e) => {
          if (e?.response?.status === 401) {
            dispatch(sagaActions.error({ response: e }));
          } else {
            // dispatch(
            //   uiReducer.actions.setModal({
            //     status: "error",
            //     message: "Ошибка загрузки документа",
            //   })
            // );
          }
        })
        .finally(() => {
          setIsUploading(false);
        });
    });
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = Number(event.currentTarget.getAttribute("data-index"));
    //@ts-ignore
    const oldFile: fileTypeBase = fields[index];

    getFileFromEvent<fileTypeBase>(event).then(async (file: any) => {
      if (!validateAcceptable(file)) {
        return;
      }

      // * чистим ошибки в форме по имени для обнуления стилей
      props.clearErrors(props.name);

      update(index, {
        ...oldFile,
        ...file,
        loading: true,
      });

      // Delete -> upload new -> update form
      props
        .upload(file)
        .catch((e) => {
          setError(props.name, {
            message: validationsMessages.api.document,
          });

          update(index, {
            ...oldFile,
            loading: false,
          });
          return Promise.reject();
        })
        .then(async (res) => {
          update(index, {
            ...oldFile,
            ...file,
            file_id: res?.id,
            loading: false,
            recognition: true,
          });
          if (props.onUploaded) {
            console.log("(props.onUploaded inside crore", res);
            await props.onUploaded({
              applicationId,
              document_type: props.document_type,
              data: res,
            });
          }

          // update(index, {
          //   ...oldFile,
          //   ...file,
          //   file_id: res?.id,
          //   loading: false,
          // });
          update(index, {
            ...oldFile,
            ...file,
            file_id: res?.id,
            // loading: false,
            recognition: Boolean(props.isPhotoRepeat),
          });
        })
        .catch((e) => {
          if (e !== undefined) {
            if (e?.response?.status === 401) {
              dispatch(sagaActions.error({ response: e }));
            } else {
              // if (props.document_type !== "repledge_loan_contract_add") {
              setError(props.name, {
                message: validationsMessages.api.document,
              });
              // }
            }
            update(index, {
              ...oldFile,
              loading: false,
              recognition: false,
            });
          }
        });
    });
  };
  const handleRemove = (e: any) => {
    const index = e.currentTarget.getAttribute("data-index");
    //@ts-ignore
    const file: fileTypeBase = fields[index];
    e.stopPropagation();

    update(index, {
      ...file,
      loading: true,
    });

    props
      .delete(file.file_id as string)
      .then((res) => {
        remove(index);
      })
      .catch(() => {
        update(index, {
          ...file,
          loading: false,
        });
      });
  };

  // view
  const isFixed = props.fixedFiles != undefined;

  const files = props.viewMode
    ? fields.filter((item: any) => Boolean(item.file_id))
    : fields;

  return (
    <div
      className={cn(styles.container, {
        [styles["container--blocked"]]: isUploading,
      })}
    >
      {props.isFetching ? (
        <div className={styles.loader_wrapper}>
          <div className={styles.loader_skeleton}>
            <Loader className={styles.loader_skeleton__loader_icon} />
          </div>
        </div>
      ) : (
        files.map((item: any, index) => {
          // photoTypeData.includes(props.document_type) ?
          if (photoTypeData.includes(props.document_type)) {
            return (
              <DocumentFile
                {...props}
                handleChange={props.viewMode ? undefined : handleChange}
                handleRemove={props.viewMode ? undefined : handleRemove}
                documentEntity={props.documentEntity}
                viewMode={props.viewMode}
                accept={props.accept}
                name={props.name}
                update={update}
                index={index}
                key={item.id}
                file={item}
                document_type={props.document_type}
                status={props.status}
              />
            );
          } else {
            return (
              <File
                {...props}
                isForRepeat={props.isForRepeat}
                num={props.num}
                handleChange={props.viewMode ? undefined : handleChange}
                handleRemove={props.viewMode ? undefined : handleRemove}
                documentEntity={props.documentEntity}
                viewMode={props.viewMode}
                accept={props.accept}
                name={props.name}
                update={update}
                index={index}
                key={item.id}
                file={item}
                document_type={props.document_type}
              />
            );
          }
        })
      )}
      {!props.viewMode &&
        !isFixed &&
        (photoTypeData.includes(props.document_type) ? (
          <FileDocumentAdd
            isUploading={isUploading || !!props.isFetching}
            accept={props.accept}
            handleAdd={handleAdd}
          />
        ) : (
          <FileAdd
            isUploading={isUploading || !!props.isFetching}
            accept={props.accept}
            handleAdd={handleAdd}
            document_type={props.document_type}
          />
        ))}
      {props.viewMode && !!props.isFetching && <Loader />}
    </div>
  );
}
