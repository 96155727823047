import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import styles from "./index.module.css";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { useHistory } from "react-router-dom";
import { SmartForm } from "../../../../hocs/SmartForm";
import { useGetInfoFromApplication } from "../../../../../hooks/api/useGetInfoFromApplication";
import { Icons } from "../../../../../assets/icons";
import { Select } from "../../../../ui/Selects/Select";
import { Button } from "../../../../ui/Button";
import { Loader } from "../../../../ui/Loader";
import { logout } from "../../../../../store/reducers/user";
import { routes } from "../../../../../routes";
import { API } from "../../../../../api";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { sendMetrics } from "../../../../../metrics";
import { sagaActions } from "../../../../../sagas/util/sagaActions";

export const Refinancing: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const history = useHistory();

  const dispatch = useDispatch();

  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const form = useForm<any>({
    reValidateMode: "onSubmit",
    // resolver: yupResolver(documentsSchema),
  });

  const [selectWatch] = useWatch({
    control: form.control,
    name: ["select"],
  });

  const { data, isLoading } = useGetInfoFromApplication();

  const pledgeeName = data?.reports.find(
    (item: any) => item.type === "report_pledge"
  ).pledgee_name;

  const submit = () => {
    if (!selectWatch) {
      setError("Обязательно к заполнению");
      return;
    }

    setLoading(true);

    API.main.application
      .sendRepledgeReason({
        applicationId,
        repledge_reason: selectWatch.value,
      })
      .then((res) => {
        if (res.status === 200) {
          sendMetrics(
            "common-client_otpravlena-prichina-refinansa_common-app",
            "otpravlena-prichina-refinansa"
          );
          setLoading(false);
          history.push(routes.application.statuses.data_verification);
        }
      })
      .catch((e) => {
        setLoading(false);
        dispatch(
          sagaActions.error({
            response: e,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refinancingReasonForSelect = [
    {
      id: 0,
      value: "increase_loan_term_and_monthly_payment_reduction",
      text: "Увеличение срока и снижение суммы ежемесячного платежа",
    },
    {
      id: 1,
      value: "interest_rate_reduction",
      text: "Снижение ставки",
    },
    {
      id: 2,
      value: "increase_loan_amount",
      text: "Увеличение суммы займа",
    },
    {
      id: 3,
      value: "dissatisfaction_with_the_company",
      text: "Недовольство компанией",
    },
    {
      id: 4,
      value: "other",
      text: "Другое",
    },
  ];

  useEffect(() => {
    sendMetrics("common-client_ekran-prichiny-refinansa_common-app", "ekran-prichiny-refinansa");
  }, []);

  useEffect(() => {
    if (selectWatch && error) {
      setError("");
    }
  }, [selectWatch]);

  return (
    <>
      <MobilePageContainer className={styles.mobile_page_container}>
        <SmartForm submit={submit} form={form}>
          {isLoading ? (
            <Loader className={styles.loader} variant={"large"} />
          ) : (
            <>
              <div className={styles.title}>Автомобиль в залоге</div>
              <div className={styles.subtitle}>
                {data?.car &&
                  data?.car?.brand +
                    " " +
                    data?.car?.model +
                    " " +
                    data?.car?.year +
                    "г." +
                    " " +
                    data?.car?.reg_number}
                <br />
                Залогодержатель {pledgeeName}
              </div>
              <div className={styles.icon}>
                <Icons.ui.Refinancing />
              </div>
              <div className={styles.additional_text}>
                Для рефинансирования выберите причину из списка и нажмите
                “Продолжить”
              </div>
              <Select
                error={error}
                name={"select"}
                control={form.control}
                setValue={form.setValue}
                defaultValue={"Выберите причину"}
                options={refinancingReasonForSelect}
                selectTitle={"Причина рефинансирования"}
                // search
                support={<Icons.ui.DropDown />}
              />
              <Button className={styles.button} loading={loading}>
                Продолжить
              </Button>
              <div
                className={styles.link_lc}
                onClick={() => logout.default(dispatch)}
              >
                <nav className={styles.link_text}>Закрыть заявку</nav>
              </div>
            </>
          )}
        </SmartForm>
      </MobilePageContainer>
    </>
  );
};
