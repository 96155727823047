import React, { ReactComponentElement, useState } from "react";
import styles from "./index.module.css";
import { ReactComponent as AddFileIcon } from "../../../assets/icons/ui/Upload.svg";
import { stringHelpers } from "../../../helpers/string";
import { lsController } from "../../../store/auxiliary/localStorage";
import { ReactComponent as Checkmark_white_circle } from "../../../assets/icons/ui/Checkmark_white_circle.svg";
import { showDMYDate } from "../../../helpers/date";
import cn from "classnames";
import { Control, useWatch } from "react-hook-form";
import { ReactComponent as Upload } from "*.svg";
import { Loader } from "../Loader";
import { Icons } from "../../../assets/icons";

export type onAddFileType = { content: string; file_name: string };

type propsType = {
  control?: Control<any>;
  setValue?: any;
  name?: string;
  iconLeft: ReactComponentElement<"svg">;
  iconRight?: ReactComponentElement<"svg">;
  text: string | JSX.Element;
  variant: "green" | "blue" | "red" | "orange" | "blue-second";
  uploadDocument?: (file: onAddFileType) => Promise<any>;
};

export const Banner = (props: propsType) => {
  const {
    name,
    iconLeft,
    iconRight,
    text,
    variant = "green",
    uploadDocument,
  } = props;

  const [loading, setLoading] = useState(false);

  const [currentIndex, setCurrentIndex] = useState("0");

  const [isError, setIsError] = useState(false);

  // const files = useWatch({
  //   control: props.control,
  //   name: props.name,
  // });

  const imageHandler = (e: any) => {
    e.preventDefault();
    // props.onChange && props.onChange(e);
    const reader = new FileReader();
    const file = e.target.files[0];
    const target = e.target;
    //check accept types if exist
    // if (fileErrorTypeAlert(file, props.accept)) {
    // } else {
    //   return;
    // }
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      if (props.uploadDocument) {
        setIsError(false);
        setLoading(true);
        props
          .uploadDocument({
            content: reader.result as string,
            file_name: file.name,
          })
          .then((res) => {
            setLoading(false);
            if (res.status != 201) {
              alert("Error");
              return;
            }
            props.setValue(props.name + `.${currentIndex}`, {
              file_name: file.name,
              content: reader.result,
              index: currentIndex,
            });
          })
          .catch((error) => {
            target.value = null;
            setLoading(false);
            setIsError(true);
            return;
          });
      } else {
        props.setValue(props.name + `.${currentIndex}`, {
          file_name: file.name,
          content: reader.result,
          index: currentIndex,
        });
      }

      setCurrentIndex((prev) => String(Number(prev) + 1));
    };
  };

  const renderText = () => {
    if (loading) {
      return "Справка загружается, подождите пожалуйста";
    } else if (isError) {
      return "Ошибка загрузки файла, загрузите файл повторно";
    } else {
      return text;
    }
  };

  return (
    <>
      <div
        className={cn(styles.container_banner, {
          [styles.banner_blue]: variant === "blue",
          [styles.banner_blue_second]: variant === "blue-second",
          [styles.banner_red]: variant === "red",
          [styles.banner_orange]: variant === "orange",
          [styles.banner_error]: isError,
        })}
      >
        <div className={styles.img_container_left}>
          {isError ? (
            <Icons.ui.Exclamation className={styles.exclamation_icon} />
          ) : (
            iconLeft || null
          )}
        </div>
        <div
          className={cn(styles.text_container, {
            [styles.text_container_right_padding]:
              variant === "green" || variant === "blue",
          })}
        >
          <div>{renderText()}</div>
        </div>
        <div className={styles.img_container_right}>
          {iconRight || null}
          {variant === "blue" && (
            <>
              <input
                {...props}
                disabled={loading}
                type="file"
                name={props.name}
                className={styles.input}
                id={props.name}
                onChange={imageHandler}
              />
              {loading ? (
                <Loader color={"white"} className={styles.loader} />
              ) : (
                <label className="FilesInput__file-label" htmlFor={props.name}>
                  {React.createElement(AddFileIcon)}
                </label>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
