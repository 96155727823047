import { call, put, select } from "redux-saga/effects";
import { authSagaActions } from "../actions";
import { processStateController } from "../../util/helpers/processStateController";
import { StateType } from "../../../store";
import { lsController } from "../../../store/auxiliary/localStorage";
import { logout, userReducer } from "../../../store/reducers/user";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { stringHelpers } from "../../../helpers/string";
import { API } from "../../../api";
import { carType } from "../../../store/reducers/application/types";
import { sagaApiType } from "../../util/types";
import { applicationReducerSelectors } from "../../../store/reducers/application";

export function* refreshTokenSaga() {
  const actionType = authSagaActions.refreshToken.type;
  const process = processStateController(actionType);

  console.log("refreshToken called");
  try {
    yield process.start();

    const location: string = yield select(
      (state: StateType) => state.router.location.pathname
    );
    const search: string = yield select(
      (state: StateType) => state.router.location.search
    );
    const query: {
      [key: string]: string;
    } = yield select((state: StateType) => state.router.location.query);

    lsController.clearToken();
    yield put(userReducer.actions.setToken({ token: null }));

    const grz = lsController.get("grz");
    const vin = lsController.get("vin");
    const phone = lsController.get("phone");

    if (grz || vin) {
      const payload = grz ? grz : vin;

      if (payload !== null) {
        yield put(authSagaActions.getCar(payload));
      }
    }

    if (location === "/") {
      yield call(logout.saga);
    }

    if (search.includes("phone")) {
      lsController.set("phone", stringHelpers.clearStringMask(query.phone));

      if (search.includes("amount") && search.includes("period_month")) {
        lsController.set("amount", query.amount);
        lsController.set("period_month", query.period_month);
      }

      yield put(
        userReducer.actions.setPhone({
          phone: stringHelpers.clearStringMask(query.phone),
        })
      );

      const response: sagaApiType = yield call(API.auth.get, {
        phone: stringHelpers.clearStringMask(query.phone),
      });

      const users = response.data.data;

      if (!users.length) {
        lsController.clearGRZ();
        lsController.clearVIN();

        const car: carType = yield select(applicationReducerSelectors.getCar);

        yield put(
          authSagaActions.createUser({
            phone: stringHelpers.clearStringMask(query.phone),
            car: car,
          })
        );

        yield redirect(routes.auth.phone);
        yield process.stop();
        return;
      }

      if (users.length) {
        yield redirect(routes.auth.code + "/:codeMode");
        yield process.stop();
        return;
      }
      return;
    }

    if (search.includes("grz") || search.includes("vin")) {
      const payload = search.includes("grz")
        ? decodeURIComponent(query.grz)
        : decodeURIComponent(query.vin);

      yield put(authSagaActions.getCar(payload));

      yield redirect(routes.auth.phone);
      yield process.stop();
      return;
    }
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
    });
  } finally {
    /*
      Нельзя тут стопать процесс автоауса,
      потому что он продолжается в routingAction
     */
    // yield process.stop();
  }
}
