import { createFC } from "../../../../helpers/createFC";
import { authApi } from "../../../../api/auth";
import { useParams } from "react-router-dom";
import { DownloaderPage } from "../downloader/pd";

export default createFC(() => {
  const { hash } = useParams<{ hash: string }>();

  return (
    <DownloaderPage
      name="Политика обработки данных"
      apiMethod={() => authApi.getPdDocument({ hash })}
    />
  );
});
