import styles from "./index.module.css";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import {
  errorsReducer,
} from "../../../../../store/auxiliary/errorsReducer";
import { useCodeFieldLogic } from "../../../../ui/CodeField/useCodeFieldLogic";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { applicationReducer, applicationReducerSelectors } from "../../../../../store/reducers/application";
import { API } from "../../../../../api";
import { Button } from "../../../../ui/Button";
import { Title } from "../../../../ui/Title";
import { Subtitle } from "../../../../ui/Subtitle";
import { userSaleApi } from "../../../../../api/main/user_sale";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { useQuery } from "react-query";
import { applicationType } from "types/application/types";
import { Loader } from "components/ui/Loader";
import { StateType } from "../../../../../store";
import { linksToDocumentsOnStatic } from "../../../../../helpers/init/constantStrings";
import { SmartForm } from "../../../../hocs/SmartForm";
import { stringHelpers } from "../../../../../helpers/string";
import { Conditions } from "../../../../ui/Conditions";
import cn from "classnames";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { CodeFieldOneInput } from "../../../../ui/CodeFieldOneInput";
import useTrackTabClose from "../../../../../metrics/customHooks/useTrackTabClose";
import { sendMetrics } from "../../../../../metrics";
import { AxiosResponse } from "axios";
import { downloadFile, setDownloadedFileName } from "../../../../../helpers/file";
import { loanDocsType } from "../../../../../types/documents/types";
import { CheckboxData, CheckboxWithPdf } from "../../../../ui/CheckboxWithPdf";
import { usePdfDownloading } from "./usePdfDownloading";
import { CodeTimer } from "../../components/CodeTimer";
import { FillingAssistance } from "../../../../ui/FillingAssistance";

const intervals: NodeJS.Timeout[] = [];

export const Code: FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCheckedPersonalDataAgreement, setIsCheckedPersonalDataAgreement] = useState(true);
  const [isCheckedApplyingAgreement, setIsCheckedApplyingAgreement] = useState(true);
  const [isCheckedGeneralInfo, setIsCheckedGeneralInfo] = useState(true);
  const [isCheckedRules, setIsCheckedRules] = useState(true);
  const [isCheckedDepositContract, setIsCheckedDepositContract] = useState(true);
  const [isCheckedLoanContract, setIsCheckedLoanContract] = useState(true);
  const [isCheckedRiskNotification, setIsCheckedRiskNotification] = useState(true);
  const [isApplyingAgreementSecond, setIsApplyingAgreementSecond] = useState(true);
  const [isCheckedOrder, setIsCheckedOrder] = useState(true);
  const [testLoading, setTestLoading] = useState(false);
  const [coderRequested, setCodeRequested] = useState(false);
  // const [timer, setTimer] = useState(timeout);

  const disabled = isDropdownOpen;

  const dispatch = useDispatch();
  const form = useForm();

  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );
  const makeRepeatResend = useSelector(
    applicationReducerSelectors.getMakeRepeatResend
  );
  const loading = useSelector(
    processLoading.get(applicationSagaActions.verifyApplication.type)
  );
  const loading2 = useSelector(
    processLoading.get(applicationSagaActions.signLoanContract.type)
  );
  const selectedLoanData = useSelector(
    applicationReducerSelectors.getSelectedLoanData
  );

  const { variant } = useParams<{
    variant: "loan" | "contract";
  }>();
  const { data, isLoading } = useQuery(
    "code",
    () => API.main.application.getApplication({ applicationId }),
    {
      enabled: applicationId !== null,
      onSuccess: (data: any) => {
        const application: applicationType = data?.data?.data;

        dispatch(
          applicationReducer.actions.setSelectedLoanData({
            amount: application.selected_loan_data?.amount,
          })
        );
      },
    }
  );

  const application: applicationType = data?.data?.data;

  const isRepledge: any = application?.repledge;

  const code: `${number}` | undefined = useWatch({
    control: form.control,
    name: "code",
  });

  const codeField = useCodeFieldLogic({
    codeLength: 4,
    onChange: () => {
      dispatch(errorsReducer.actions.removeFieldError("code"));
    },
  });

  const { getDownloading, setDownloading } = usePdfDownloading();

  const confirm = () => {
    switch (variant) {
      case "loan":
        dispatch(
          applicationSagaActions.verifyApplication({
            code: code!,
            applicationId,
          })
        );
        break;
      case "contract":
        // post loan-contract
        dispatch(
          applicationSagaActions.signLoanContract({
            code: code!,
            applicationId,
          })
        );
        break;
    }
  };

  const resend = () => {
    setCodeRequested(true);

    switch (variant) {
      case "loan":
        API.main.application.repeatCode({
          applicationId,
        });
        break;
      case "contract":
        // На повторный код - тот же запрос, что на первое получение
        userSaleApi.code({
          applicationId,
        });
        break;
    }
  };

  // * если юзер релоадид страницу, то я должен отправить запрос на resend кода
  // * для этого у нас есть поле в стор
  // useEffect(() => {
  //   if (makeRepeatResend && applicationId) {
  //     resend();
  //   }
  // }, [applicationId]);

  const downloadDocument = (documentType: loanDocsType | "order" | "not_signed_order") => {
    if (getDownloading(documentType)) return;

    setDownloading(documentType, true);

    let interval = setInterval(() => {
      API.main.documents
        .downloadFileDocument({
          applicationId: applicationId,
          documentType: documentType,
        })
        .then((res: AxiosResponse<any>) => {
          if (res.status === 200) {
            downloadFile(
              res?.data?.data?.content,
              setDownloadedFileName(documentType),
              "_blank"
            );
            setDownloading(documentType, false);
            clearInterval(interval);
          }
        });
    }, 3000);

    intervals.push(interval);
  };

  const phoneNumber = useSelector(
    (state: StateType) => state.userReducer.phone
  );

  const hasRiskNotificationDocument = application?.documents?.some(
    (document: any) => document.document_type === "risk_notification"
  );

  const checkboxesLoan: CheckboxData[] = [
    {
      name: "personal_data_agreement",
      isChecked: isCheckedPersonalDataAgreement,
      setIsChecked: setIsCheckedPersonalDataAgreement,
      onClick: () => downloadDocument("not_signed_personal_data_agreement"),
      text: "Согласие на обработку персональных данных и запрос кредитной истории",
      isDownloading: getDownloading("not_signed_personal_data_agreement"),
    },
    {
      name: "applying_agreement",
      isChecked: isCheckedApplyingAgreement,
      setIsChecked: setIsCheckedApplyingAgreement,
      onClick: () => downloadDocument("not_signed_applying_agreement"),
      text: "Анкета-заявление на выдачу потребительского микрозайма физическому лицу",
      isDownloading: getDownloading("not_signed_applying_agreement"),
    },
    {
      name: "inf",
      isChecked: isCheckedGeneralInfo,
      setIsChecked: setIsCheckedGeneralInfo,
      text: "Ознакомлен с Общими условиями договора потребительского микрозайма",
      href: linksToDocumentsOnStatic.gt,
    },
    {
      name: "rules",
      isChecked: isCheckedRules,
      setIsChecked: setIsCheckedRules,
      text: "Ознакомлен с Правилами предоставления потребительских микрозаймов",
      href: linksToDocumentsOnStatic.rules,
    },
  ];

  const checkboxesContract: CheckboxData[] = [
    {
      name: "pd",
      isChecked: isCheckedLoanContract,
      setIsChecked: setIsCheckedLoanContract,
      onClick: () => downloadDocument("not_signed_loan_contract"),
      text: "Индивидуальные условия договора микрозайма",
      isDownloading: getDownloading("not_signed_loan_contract"),
      disabled,
    },
    {
      name: "aa",
      isChecked: isCheckedDepositContract,
      setIsChecked: setIsCheckedDepositContract,
      onClick: () => downloadDocument("not_signed_deposit_contract"),
      text: "Условия договора обеспечения исполнения обязательств заемщика (залогодателя) по договору потребительского микрозайма",
      isDownloading: getDownloading("not_signed_deposit_contract"),
      disabled,
    },
    {
      name: "applying_agreement_second",
      isChecked: isApplyingAgreementSecond,
      setIsChecked: setIsApplyingAgreementSecond,
      onClick: () => downloadDocument("applying_agreement_second"),
      text: "Анкета-заявление на выдачу потребительского микрозайма физическому лицу",
      isDownloading: getDownloading("applying_agreement_second"),
      disabled,
    },
  ];

  if (hasRiskNotificationDocument) {
    checkboxesContract.push({
      name: "rn",
      isChecked: isCheckedRiskNotification,
      setIsChecked: setIsCheckedRiskNotification,
      onClick: () => downloadDocument("not_signed_risk_notification"),
      text: "Уведомление о существующих рисках",
      isDownloading: getDownloading("not_signed_risk_notification"),
      disabled,
    });
  }

  if (application?.repledge) {
    checkboxesContract.push({
      name: "or",
      isChecked: isCheckedOrder,
      setIsChecked: setIsCheckedOrder,
      onClick: () => downloadDocument("not_signed_order"),
      text: "Распоряжение о перечислении в счет погашения задолженности",
      isDownloading: getDownloading("not_signed_order"),
      disabled,
    });
  }

  const areAllCheckboxesChecked = [
    isCheckedPersonalDataAgreement,
    isCheckedGeneralInfo,
    isCheckedRules,
    isCheckedApplyingAgreement,
    isCheckedDepositContract,
    isCheckedLoanContract,
    isCheckedRiskNotification,
    isApplyingAgreementSecond,
    isCheckedOrder,
  ].every(Boolean);

  const amount = lsController.get("amount");

  useEffect(() => {
    if (variant === "loan") {
      sendMetrics(
        "common-client_ehkran-podpisaniya-zayavki_common-app",
        "ehkran-podpisaniya-zayavki"
      );
    } else {
      sendMetrics("common-client_finalnoe-odobrenie_common-app", "finalnoe-odobrenie");
    }

    return () => intervals.forEach((item) => clearInterval(item));
  }, []);

  useTrackTabClose("common-client_leave-LK_common-app", "leave-LK", variant);

  // * тут нужно поменять текста в зависимости от variant
  return (
    <>
      <MobilePageContainer className={styles.form}>
        {isLoading || applicationId === null ? (
          <Loader
            variant={"large"}
            color={"purple"}
            className={styles.loader}
          />
        ) : (
          <>
            {variant === "contract" && (
              <Conditions
                repledgeExternalLoanData={
                  application?.repledge_external_loan_data
                }
                isRepledge={isRepledge}
                loanData={application?.loan_data}
                offers={application.offers}
                selectedLoanData={application?.selected_loan_data}
                loading={false}
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
              />
            )}
            <div
              className={cn(styles.container, {
                [styles.disabled]: disabled,
              })}
            >
              <Title disabled={disabled} className={styles.title}>
                {variant === "loan" ? "Заявление на займ" : "Подпишите договор"}
              </Title>
              {variant === "loan" && (
                <Subtitle className={styles.subtitle}>
                  Нажмите кнопку "Получить код" <br /> и мы отправим его на номер:
                </Subtitle>
              )}

              {variant === "contract" && (
                <Subtitle className={styles.sum} disabled={disabled}>
                  На сумму{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {stringHelpers.transformMoneyValue(selectedLoanData?.amount) || (
                      amount && stringHelpers.transformMoneyValue(amount)
                    )}{" "}₽
                  </span>
                </Subtitle>
              )}

              <div
                className={cn(styles.auth_block_code, {
                  [styles.disabled]: disabled,
                })}
              >
                <div>
                  {stringHelpers.phoneNormalizeSecondVariant(phoneNumber)}
                </div>
              </div>
              <SmartForm submit={() => console.log()} form={form}>
                <div className={styles.code}>
                  <CodeFieldOneInput
                    disabled={!coderRequested}
                    // control={form.control}
                    // disabled={disabled}
                    // sendCodeHandler={resend}
                    // retryCount={codeField.retryCount}
                    // setRetryCount={codeField.setRetryCount}
                  />
                </div>
              </SmartForm>
              {/*<CodeField*/}
              {/*  {...codeField}*/}
              {/*  sendCodeHandler={resend}*/}
              {/*  error={errors?.code}*/}
              {/*  disabled={disabled}*/}
              {/*  className={styles.codeField}*/}
              {/*  retryCount={codeField.retryCount}*/}
              {/*  setRetryCount={codeField.setRetryCount}*/}
              {/*/>*/}
              {/*<div*/}
              {/*  className={cn(styles.retry_count, {*/}
              {/*    [styles.disabled]: disabled,*/}
              {/*  })}*/}
              {/*>*/}
              {/*  Попытка {codeField.retryCount} из 5*/}
              {/*</div>*/}
              {/*<Button style={{ marginTop: 24 }} onClick={resend}>Получить код</Button>*/}
              <Button
                disabled={(coderRequested && (!code || code.length < 4 || !areAllCheckboxesChecked)) || isDropdownOpen}
                onClick={coderRequested ? confirm : resend}
                className={styles.button}
                loading={loading || loading2}
              >
                {!coderRequested ? "Получить код" : "Продолжить"}
              </Button>
              <div className={styles.timer}>
                {coderRequested && (
                  <>
                    <CodeTimer
                      disabled={disabled}
                      sendCodeHandler={resend}
                      retryCount={codeField.retryCount}
                      setRetryCount={codeField.setRetryCount}
                    />
                    <div
                      className={cn(styles.retry_count, {
                        [styles.disabled]: disabled,
                      })}
                    >
                      Попытка {codeField.retryCount} из 5
                    </div>
                  </>
                )}
              </div>
            </div>
            {variant === "loan" && (
              <>
                <SmartForm
                  form={form}
                  submit={() => console.log()}
                  className={styles.popularBanks}
                >
                  {checkboxesLoan.map((checkboxData) => (
                    <CheckboxWithPdf key={checkboxData.name} {...checkboxData} />
                  ))}
                </SmartForm>
              </>
            )}
            {variant === "contract" && (
              <SmartForm form={form} submit={() => console.log()}>
                {checkboxesContract.map((checkboxData) => (
                  <CheckboxWithPdf key={checkboxData.name} {...checkboxData} />
                ))}
                <FillingAssistance />
              </SmartForm>
            )}
          </>
        )}
      </MobilePageContainer>
    </>
  );
};
