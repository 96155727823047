import { Variants } from "framer-motion";

export const burgerAnimation: Variants = {
  open: {
    x: "0px",
    opacity: 1,
    transition: {
      x: {
        duration: 0.2
      },
      opacity: {
        duration: 0
      }
    },
    display: "block"
  },
  close: {
    x: "308px",
    opacity: 0,
    transition: {
      duration: 0.2
    },
    transitionEnd: {
      display: "none"
    }
  }
};

export const backgroundAnimation: Variants = {
  open: {
    opacity: 0.5,
    transition: {
      x: {
        duration: 1
      },
      opacity: {
        duration: 0.2
      }
    },
    display: "block"
  },

  close: {
    opacity: 0,
    transition: {
      duration: 0.2
    },
    transitionEnd: {
      display: "none"
    }
  }
};