import { createAction } from "@reduxjs/toolkit";
import {
  createPasswordType,
  getCodeType,
  getTokenType,
  verifyCodeType,
} from "../../api/auth/types";
import { carType } from "../../store/reducers/application/types";

export const authSagaActions = {
  getUser: createAction<{ phone: string; car?: carType }>("auth/getUser"),
  createUser: createAction<{ phone: string; car?: carType }>("auth/createUser"),
  getCode: createAction<getCodeType>("auth/getCode"),
  verifyCode: createAction<verifyCodeType>("auth/verifyCode"),
  createPassword: createAction<createPasswordType>("auth/createPassword"),
  getToken: createAction<getTokenType>("auth/getToken"),
  getCar: createAction<string>("auth/getCar"),
  refreshToken: createAction("auth/refreshToken"),
  autoAuth: createAction("auth/autoAuth"),
};
