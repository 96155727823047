import { createFC } from "../../../../helpers/createFC";
import { DownloaderPage } from "../downloader/pd";
import { mainApi } from "../../../../api/main";

export default createFC(() => {
  return (
    <DownloaderPage
      name="Согласие с обработкой ПД"
      apiMethod={() => mainApi.documents.downloadPdfRegistration()}
    />
  );
});
