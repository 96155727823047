import { getDivisionCodeType } from "../application/types";
import { mainAxios } from "../index";
import { dataToQueryString } from "../../methods/dataToQueryString";
import {
  getAddressType,
  getCarType,
  getCompanyType,
  getInTransactionType,
  getLoanType,
  getOutTransactionType,
  getScheduleType,
  postAdvertisementTrackingType,
} from "./types";
import { AxiosResponse } from "axios";

export const servicesApi = {
  getDivisionCode: ({ code }: getDivisionCodeType) =>
    mainAxios.get(`/passport/division_code/${code}`),

  getLoan: (query: getLoanType) =>
    mainAxios.get(`/loan${dataToQueryString(query)}`),

  getOutTransaction: (query: getOutTransactionType) =>
    mainAxios.get(`/out-transaction${dataToQueryString(query)}`),

  getInTransaction: (query: getInTransactionType) =>
    mainAxios.get(`/in-transaction${dataToQueryString(query)}`),

  getSchedule: (query: getScheduleType): Promise<AxiosResponse<any>> =>
    mainAxios.get(`/schedule${dataToQueryString(query)}`),

  getCompany: ({ name }: getCompanyType) =>
    mainAxios.get(`/suggestions/party?search${name}`),

  getCar: (query: getCarType) =>
    mainAxios.get(`/car${dataToQueryString(query)}`),

  getAddress: ({ address }: getAddressType) =>
    mainAxios.get(`/address?search=${address}`),

  postAdvertisementTracking: ({
    transaction_id,
    phone,
    advertisement_type,
    webmaster_id,
  }: postAdvertisementTrackingType) =>
    mainAxios.post(`/partner-link`, webmaster_id ? {
      partner_transaction_id: transaction_id,
      phone: phone,
      advertisement_type,
      webmaster_id,
    } : {
      partner_transaction_id: transaction_id,
      phone: phone,
      advertisement_type,
    }),
};
