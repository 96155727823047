import { loanInitialStateType } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { lsController } from "../../auxiliary/localStorage";
import { paymentType } from "../../../api/main/application/types";
import { StateType } from "../../index";
import { notificationDataType } from "../../../types/cabinet/types";

const initialState: loanInitialStateType = {
  paymentDate: null,
  loan_id: null,
  paymentType: null,
  loanAmount: null,
  loanStatus: null,
  paidLoans: null,
  onlyErrorLoans: false,
  notifications: null,
  amountForPayment: null,
  createdAtPayment: null,
};

export const loanReducer = createSlice({
  name: "@loanReducer",
  initialState,
  reducers: {
    setLoanId: (
      state,
      { payload }: PayloadAction<{ loan_id: string | null }>
    ) => {
      state.loan_id = payload.loan_id || lsController.get("loan_id");
      lsController.set("loan_id", payload.loan_id);
    },
    // редюсер для ЧДП / ПДП.
    // Позже можно туда кинуть payment_id и урл оплаты, чтобы не прокидывать их в урлах
    setPaymentType: (
      state,
      { payload }: PayloadAction<{ paymentType: paymentType }>
    ) => {
      state.paymentType =
        payload.paymentType || lsController.get("payment_type");
      lsController.set("payment_type", payload.paymentType);
    },
    setLoanAmount: (
      state,
      { payload }: PayloadAction<{ loanAmount: number }>
    ) => {
      state.loanAmount = payload.loanAmount;
    },
    setAmountForPayment: (
      state,
      { payload }: PayloadAction<{ amountForPayment: number }>
    ) => {
      state.amountForPayment = payload.amountForPayment;
    },
    setPaymentDate: (
      state,
      { payload }: PayloadAction<{ paymentDate: Date }>
    ) => {
      state.paymentDate = payload.paymentDate;
    },
    setCreatedAtPayment: (
      state,
      { payload }: PayloadAction<{ createdAtPayment: Date }>
    ) => {
      state.createdAtPayment = payload.createdAtPayment;
    },
    setLoanStatus: (
      state,
      { payload }: PayloadAction<{ loanStatus: string }>
    ) => {
      state.loanStatus = payload.loanStatus;
    },
    setPaidLoans: (
      state,
      {
        payload,
      }: PayloadAction<{
        paidLoans: Array<{
          applicationId: number;
          amount: number;
          end_date: Date;
          loan_number: number;
        }>;
      }>
    ) => {
      state.paidLoans = payload.paidLoans;
    },
    setOnlyErrorLoans: (
      state,
      { payload }: PayloadAction<{ onlyErrorLoans: boolean }>
    ) => {
      state.onlyErrorLoans = payload.onlyErrorLoans;
    },
    setNotifications: (
      state,
      {
        payload,
      }: PayloadAction<{
        notifications: {
          items_count: number;
          items: Array<notificationDataType & { checked: boolean }>;
        };
      }>
    ) => {
      state.notifications = payload.notifications;
    },
    updateNotification: (
      state,
      { payload }: PayloadAction<{ id: string; checked: boolean }>
    ) => {
      if (state.notifications?.items.find((item) => item.id === payload.id)) {
        state.notifications.items.find(
          (item) => item.id === payload.id
        )!.checked = payload.checked;
      }
    },
    clear: () => initialState,
  },
});

export const loanReducerSelectors = {
  getLoanId: (state: StateType) => state.loanReducer.loan_id,
  getLoanAmount: (state: StateType) => state.loanReducer.loanAmount,
  getLoanStatus: (state: StateType) => state.loanReducer.loanStatus,
  getPaidLoans: (state: StateType) => state.loanReducer.paidLoans,
  getOnlyErrorLoans: (state: StateType) => state.loanReducer.onlyErrorLoans,
  getNewNotifications: (state: StateType) => state.loanReducer.notifications,
};
