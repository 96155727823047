import { mainAxios } from "../index";
import { applicationBasisType } from "../application/types";
import { createCardType, sendCodeType } from "./types";

class UserSaleClass {
  user_id: number | null = null;

  constructor() {}

  createCard = ({ applicationId, ...data }: createCardType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/check-bank`,
      data
    );

  code = ({ applicationId }: applicationBasisType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/loan-contract`
    );

  sendCode = ({ applicationId, code }: sendCodeType) =>
    mainAxios.put(
      `/user/${this.user_id}/application/${applicationId}/loan-contract/${code}`
    );

  // sendApplication = ({ applicationId }: applicationBasisType) =>
  //   mainAxios.post(`/user/${this.user_id}/application/${applicationId}/send`);

  CancellationOfTheSale = ({ applicationId }: applicationBasisType) =>
    mainAxios.delete(`/user/${this.user_id}/application/${applicationId}`);
}

export const userSaleApi = new UserSaleClass();
