import React, { FC, useEffect, useState } from "react";
import { LoadingPage } from "components/pages/Application/pages/LoadingPage";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../../../../../api";
import { routes } from "../../../../../../routes";
import { AxiosResponse } from "axios";
import { useGetPaymentInfo } from "../../../../../../hooks/api/useGetPaymentInfo";
import { CabinetErrorPage } from "../CabinetErrorPage";
import { useSelector } from "react-redux";
import { StateType } from "../../../../../../store";
import moment from "moment";
import styles from "../../EarlyRepaymentIframe/index.module.css";
import { Icons } from "../../../../../../assets/icons";
import { Button } from "../../../../../ui/Button";
import { processLoading } from "../../../../../../store/auxiliary/loadingReducer";
import { authSagaActions } from "../../../../../../sagas/auth/actions";
import { Loader } from "../../../../../ui/Loader";
import { MobilePageContainer } from "../../../../../ui/Containers/MobilePageContainer";

type propsType = {};

// Скорее всего, эта страница нужна чтобы просто подождать ответ от POST /early-repayment/payment
export const RepaymentSigningProcess: FC<propsType> = (props) => {
  const history = useHistory();
  const { early_repayment_id, loan_id, amount } = useParams<{
    early_repayment_id: string;
    loan_id: string;
    amount: string;
  }>();

  const loadingAutoAuth = useSelector(
    processLoading.get(authSagaActions.autoAuth.type)
  );

  const amountForPayment = useSelector(
    (state: StateType) => state.loanReducer.amountForPayment
  );

  const paymentDate = useSelector(
    (state: StateType) => state.loanReducer.paymentDate
  );

  const [paymentId, setPaymentId] = useState("");
  const [showErrorPage, setShowErrorPage] = useState(false);

  useEffect(() => {
    if (amountForPayment !== 0) {
      API.main.application
        .createLoanPayment({
          loan_id,
          amount: amountForPayment,
        })
        .then((res: AxiosResponse<any>) => {
          if (res.status === 201 && res.data?.data?.id) {
            setPaymentId(res.data?.data?.id);
          } else {
            setShowErrorPage(true);
          }
        });
    }
  }, []);

  const { paymentInfo, isLoading, error } = useGetPaymentInfo({
    loan_id,
    payment_id: paymentId,
    amount_for_payment: amountForPayment,
  });

  useEffect(() => {
    if (paymentInfo?.payment_url) {
      history.push(
        routes.cabinet.early_repayment_iframe.root({
          loan_id,
          payment_id: paymentId,
        })
      );
    } else if (error) {
      setShowErrorPage(true);
    }
  }, [paymentInfo?.payment_url]);

  if (showErrorPage) return <CabinetErrorPage />;

  if (amountForPayment !== 0 && !showErrorPage)
    return (
      <>
        {loadingAutoAuth ? (
          <MobilePageContainer>
            <Loader variant={"large"} className={styles.loader_page} />
          </MobilePageContainer>
        ) : (
          <LoadingPage
            title={"Заявление принято"}
            subtitle={
              <>
                Оплата по заявлению должна поступить <br /> до{" "}
                {moment(paymentDate).format("DD.MM.YYYY")} г
              </>
            }
            additionalSubtitle={<>Подготовка оплаты...</>}
          />
        )}
      </>
    );

  return (
    <MobilePageContainer>
      <>
        {loadingAutoAuth ? (
          <Loader variant={"large"} className={styles.loader_page} />
        ) : (
          <>
            <div className={styles.icon_cross}>
              {/*TODO svg*/}
              {/*<Icons.ui.CrossBlack />*/}
            </div>
            <div className={styles.title}>Заявление принято</div>
            <div className={styles.icon}>
              <Icons.ui.CheckmarkNoPadding />
            </div>
            <Button
              onClick={() => history.push(routes.cabinet.my_loan)}
              className={styles.button}
            >
              На главную страницу
            </Button>{" "}
          </>
        )}
      </>
    </MobilePageContainer>
  );
};
