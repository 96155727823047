import React from "react";
import { Route } from "react-router-dom";
import "./assets/styles/index.css";
// import { Sandbox } from "./components/pages/Sandbox";
import { routes } from "./routes";
import { Router } from "./routes/RouterDevTools";
import { createArrayRouter } from "./routes/createRouter";
import { lazyLoad } from "./helpers/lazyLoad";
import { Auth } from "./components/pages/Auth";
import { Application } from "./components/pages/Application";
import { Cabinet } from "./components/pages/Cabinet";
import { useSelector } from "react-redux";
import { authSagaActions } from "./sagas/auth/actions";
import { processLoading } from "./store/auxiliary/loadingReducer";
import { LoaderPage } from "./components/ui/LoaderPage";
import { Underwriter } from "./components/pages/Underwriter";
import { ModalWindow } from "./components/ui/ModalWindow";
import { Docs } from "./components/pages/Docs";
import { Info } from "./components/pages/Info";
import { YMInitializer } from "react-yandex-metrika";
import { select } from "redux-saga/effects";
import { StateType } from "./store";
import { UserDeviceInfo } from "./components/pages/Application/pages/UserDeviceInfo";

const Sandbox = lazyLoad(() => import("./components/pages/Sandbox"));

function App() {
  const authProcess = useSelector(
    processLoading.get(authSagaActions.autoAuth.type)
  );
  const authProcessGetUser = useSelector(
    processLoading.get(authSagaActions.getUser.type)
  );
  const authProcessCreateUser = useSelector(
    processLoading.get(authSagaActions.createUser.type)
  );
  const authProcessGetCode = useSelector(
    processLoading.get(authSagaActions.getCode.type)
  );
  const authProcessRefreshToken = useSelector(
    processLoading.get(authSagaActions.refreshToken.type)
  );
  const authProcessGetCar = useSelector(
    processLoading.get(authSagaActions.getCar.type)
  );

  const location: string = useSelector(
    (state: StateType) => state.router.location.pathname
  );

  const search: string = useSelector(
    (state: StateType) => state.router.location.search
  );

  const loaderPage =
    (search.includes("grz") ||
      search.includes("vin") ||
      search.includes("amount") ||
      search.includes("period_month") ||
      search.includes("phone") ||
      // (location === "/user/personal_cabinet") ||
      location === "/") &&
    (authProcess ||
      authProcessGetUser ||
      authProcessCreateUser ||
      authProcessGetCode ||
      authProcessRefreshToken ||
      authProcessGetCar);

  if (loaderPage) {
    return <LoaderPage />;
  }

  return (
    <>
      <Route path={routes.auth.root}>
        <Auth />
      </Route>

      <Route path={routes.application.root}>
        <Application />
      </Route>

      <Route path={routes.cabinet.root}>
        <Cabinet />
      </Route>

      {/* * ВРЕМЕННО */}
      <Route path={routes.underwriter.root}>
        <Underwriter />
      </Route>

      {/* * ВРЕМЕННО */}
      <Route path={routes.info}>
        <Info />
      </Route>

      <ModalWindow />
      <Docs />
      {/*{process.env.NODE_ENV == "development" && (*/}
      {process.env.REACT_APP_ENVIRONMENT == "stage" && (
        <>
          <Router router={createArrayRouter(routes)} />
          <Route path={routes.sandbox}>
            <Sandbox />
          </Route>
        </>
      )}
      {/*)}*/}
      <YMInitializer accounts={[91568230]} options={{ webvisor: true }} />
    </>
  );
}

export default App;
