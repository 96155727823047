import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../../util/helpers/processStateController";
import { call, put, select } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../store/reducers/application";

export function* sendApplicationSaga({ type: actionType }: PayloadAction) {
  const process = processStateController(actionType);
  try {
    yield process.start();

    // const applicationId: number = yield select(
    //   applicationReducerSelectors.getApplicationId
    // );

    // yield call(API.main.application.code, {
    //   applicationId,
    // });

    yield put(
      applicationReducer.actions.setMakeRepeatResend({
        value: false,
      })
    );

    yield redirect(routes.application.code.loan);
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
    });
  } finally {
    yield process.stop();
  }
}
