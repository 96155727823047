import { createFC } from "helpers/createFC";
import styles from "./index.module.css";
import React, {
  AllHTMLAttributes,
  ReactElement,
  ReactFragment,
  SVGProps,
} from "react";
import { Icons } from "assets/icons";
import { useCountdown } from "../../../../../helpers/date/useCountdown";
import cn from "classnames";
import { Button } from "../../../../ui/Button";
import { BannerWhite } from "../../../../ui/BannerWhite";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";

type propsType = {
  banner?: boolean;
  withoutIcon?: boolean;
  title: string | ReactFragment;
  subtitle?: string | ReactFragment;
  countdownTime?: number;
  additionalSubtitle?: string | ReactFragment;
  svgIcon?: SVGProps<SVGSVGElement>;
  button?: ReactElement;
};

/*
  При наличии лишнего времени - допилить CircularProgressBar до рабочего состояния и впилить сюда

 */

export const LoadingPage = createFC(
  ({
    title,
    subtitle,
    countdownTime,
    additionalSubtitle,
    svgIcon,
    button,
    ...props
  }: propsType) => {
    const { timeLeft } = useCountdown(countdownTime);

    return (
      <MobilePageContainer className={styles.form}>
        <>
          {props.banner && <BannerWhite secondBanner />}
          <div className={styles.container}>
            <div className={styles.title}>
              <>{title}</>
            </div>
            <div className={styles.subtitle}>{subtitle}</div>

            {!props.withoutIcon && (
              <div
                className={cn(styles.loader, {
                  [styles.no_animation]: svgIcon,
                })}
              >
                {svgIcon ? svgIcon : <Icons.ui.Hourglass />}
              </div>
            )}

            {countdownTime && typeof timeLeft !== "undefined" && (
              <div className={styles.timer}>
                {timeLeft === 0 && "Подождите еще..."}
                {timeLeft > 0 && timeLeft < 60
                  ? `${new Date(timeLeft * 1000)
                      .toISOString()
                      .substring(17, 19)} сек.`
                  : timeLeft > 0 &&
                    new Date(timeLeft * 1000).toISOString().substring(14, 19)}
              </div>
            )}

            {additionalSubtitle && (
              <div className={styles.additionalSubtitle}>
                {additionalSubtitle}
              </div>
            )}

            {button ? button : null}
          </div>
        </>
      </MobilePageContainer>
    );
  }
);
