import React, { FC, useRef } from "react";
import styles from "./index.module.css";
import { useFormContext, useWatch } from "react-hook-form";
import cn from "classnames";
import { InputNumber } from "../Inputs/InputNumber";

type propsType = {
  primaryColor?: "blue";
  disabled?: boolean;
};

const spaceResolver = (code: string | undefined) => {
  if (!code) return;

  let defaultSpacing = 32;

  for (let i = 0; i < code.length - 1; i++) {
    if (code[i] === "1") {
      if (i === 2) {
        defaultSpacing += 2;
      } else {
        defaultSpacing += 1;
      }
    } else if (code[i] === "7") {
      defaultSpacing += 0.5;
    }
  }

  return Math.floor(defaultSpacing);
};

export const CodeFieldOneInput: FC<propsType> = ({
  primaryColor,
  disabled,
}) => {
  const form = useFormContext();
  const inputCodeRef = useRef<HTMLInputElement>(null);

  const code: `${number}` | undefined = useWatch({
    control: form.control,
    name: "code",
  });

  const { error } = form.getFieldState("code");

  return (
    <div className={styles.wrapper} onClick={() => inputCodeRef.current?.focus()}>
      <InputNumber
        style={{ letterSpacing: spaceResolver(code) }}
        className={styles.input}
        name={"code"}
        maxLength={4}
        minLength={4}
        disabled={disabled}
        autoFocus
        inputElementRef={inputCodeRef}
      />
      <div className={styles.backlight}>
        <div className={cn(styles.backlight_item, {
          [styles.highlighted]: code?.length && code.length >= 1,
          [styles.highlighted_blue]: code?.length && code.length >= 1 && primaryColor === "blue",
          [styles.highlighted_error]: code?.length && code.length >= 1 && error
        })} />
        <div className={cn(styles.backlight_item, {
          [styles.highlighted]: code?.length && code.length >= 2,
          [styles.highlighted_blue]: code?.length && code.length >= 2 && primaryColor === "blue",
          [styles.highlighted_error]: code?.length && code.length >= 2 && error
        })} />
        <div className={cn(styles.backlight_item, {
          [styles.highlighted]: code?.length && code.length >= 3,
          [styles.highlighted_blue]: code?.length && code.length >= 3 && primaryColor === "blue",
          [styles.highlighted_error]: code?.length && code.length >= 3 && error
        })} />
        <div className={cn(styles.backlight_item, {
          [styles.highlighted]: code?.length && code.length >= 4,
          [styles.highlighted_blue]: code?.length && code.length >= 4 && primaryColor === "blue",
          [styles.highlighted_error]: code?.length && code.length >= 4 && error
        })} />
      </div>
    </div>
  );
};
