import * as yup from "yup";
import { dateMethods } from "../date";
import { validationsMessages } from "./validationsMessages";

// Вынес сюда схемы валидации для каждого поля в отдельности, пока только те, которые дублируются в одношаговом флоу,
// наверное, есть смысл перенести сюда вообще все, и собирать из них схемы уже для каждого конкретного экрана.

// На самом первом экране c грз логика валидации ниже дублируется, но там немного отличается нейминг полей, не стал
// пока трогать, может что-то наебнуться неочевидное, но в целом можно брать теперь отсюда (на шаге 3 анкеты сделано).

/**
 * @passport.issue_date,
 * @user_info.bith_day
 При вводе в инпут с датой всякой дичи типа 55.68.999, компонент datepicker сразу же пытается преобразовать это в
 какую-то валидную, но неочевидную дату, и соответственно, дальнейшая валидация через yup проходит, из hook-form тоже
 достать можем только уже преобразованную дату, поэтому отсекаем такие штуки сразу через рефы.

 * @second_phone
 Если вводить номер вручную, будет стринг в формате +7 XXX XXX XX XX, если он подставляется из бэка и поле не трогать,
 будет стринг в формате 7XXXXXXXXXX, поэтому сначала приводим к формату бэка и потом валидируем.
 */

const emailRegex =
  /^[\s\S]*\S[\s\S]*[-a-z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@([a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|ru|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])\s*$/i;

let minData: any = dateMethods.dateMinus60Years();

export const validateSchemaObj = {
  email: yup
    .string()
    .required(validationsMessages.requiredField)
    .matches(emailRegex, validationsMessages.invalidEmail),
  loan_purpose: yup.object().shape({
    text: yup
      .string()
      .required(validationsMessages.requiredField),
  }),
  monthly_income: yup
    .string()
    .required(validationsMessages.requiredField)
    .min(0, validationsMessages.min)
    .max(1000000, validationsMessages.max),
  inn: yup
    .string()
    .required(validationsMessages.requiredField)
    .min(12, validationsMessages.inn),
  passport: yup.object().shape({
    series: yup
      .string()
      .required(validationsMessages.requiredField)
      .min(4, validationsMessages.fullData)
      .max(4, validationsMessages.fullData),
    number: yup
      .string()
      .required(validationsMessages.requiredField)
      .min(6, validationsMessages.fullData)
      .max(6, validationsMessages.fullData),
    issue_date: yup
      .date()
      .required(validationsMessages.requiredField)
      .test("is-date-correct", validationsMessages.correctDate, (value, context) => {
        return dateMethods.validate(context.options.context?.passportIssueDate);
      })
      .min(new Date("1900-01-01"), "Дата не должна быть ранее 1900 г.")
      .max(new Date("2100-01-01"), "Дата не должна быть позднее 2100 г.")
      .test("is-date-form-past", validationsMessages.dateFromPast, (date) => Boolean(date && date < new Date())),
    division_code: yup
      .string()
      .required(validationsMessages.requiredField)
      .min(7, validationsMessages.fullData),
  }),
  second_phone: yup
    .string()
    .required(validationsMessages.requiredField)
    .test("is-valid-second-phone-length",
      validationsMessages.secondPhoneLength,
      (value) => value?.replaceAll("+", "").replaceAll(" ", "").length === 11
    )
    .test(
      "is-valid-second-phone",
      validationsMessages.secondPhone,
      (value, context) => value?.replace(/\D/g, "") !== context.options.context?.userPhone
    ),
  user_info: yup.object().shape({
    last_name: yup
      .string()
      .required(validationsMessages.requiredField)
      .matches(
        /^(?:\s*[а-яёА-ЯЁa-zA-Z\d]+(?:[ -][а-яёА-ЯЁa-zA-Z\d]*)*\s*)$/i,
        validationsMessages.onlyLetters
      ),
    first_name: yup
      .string()
      .required(validationsMessages.requiredField)
      .matches(
        /^(?:\s*[а-яёА-ЯЁa-zA-Z\d]+(?:[ -][а-яёА-ЯЁa-zA-Z\d]*)*\s*)$/i,
        validationsMessages.onlyLetters
      ),
    birth_day: yup
      .date()
      .required(validationsMessages.requiredField)
      .test("is-date-correct", validationsMessages.correctDate, (value, context) => {
        return dateMethods.validate(context.options.context?.birthDate);
      })
      .min(minData, validationsMessages.minDate)
  })
};
