import { Variants } from "framer-motion";

export const informationDropdownMenuAnimation: Variants = {
  open: {
    opacity: 1,
    transition: {
      opacity: {
        duration: 0.1,
      },
    },
    display: "block",
  },
  close: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
    transitionEnd: {
      display: "none",
    },
  },
};
