import styles from "./index.module.css";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../../../store";
import { useForm, useWatch } from "react-hook-form";
import {
  errorsReducer,
  errorsSelectors,
} from "../../../../../store/auxiliary/errorsReducer";
import { useCodeFieldLogic } from "../../../../ui/CodeField/useCodeFieldLogic";
import { API } from "../../../../../api";
import { Button } from "../../../../ui/Button";
import { Title } from "../../../../ui/Title";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { Loader } from "components/ui/Loader";
import { routes } from "../../../../../routes";
import { downloadFile } from "../../../../../helpers/file";
import { AxiosResponse } from "axios";
import { CabinetErrorPage } from "../Statuses/CabinetErrorPage";
import { CodeFieldOneInput } from "../../../../ui/CodeFieldOneInput";
import { SmartForm } from "../../../../hocs/SmartForm";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { authSagaActions } from "../../../../../sagas/auth/actions";
import { CodeTimer } from "../../../Application/components/CodeTimer";
import cn from "classnames";
import { CheckboxData, CheckboxWithPdf } from "../../../../ui/CheckboxWithPdf";
import { Subtitle } from "../../../../ui/Subtitle";
import { stringHelpers } from "../../../../../helpers/string";

/*
  Страница во многом повторяет <Code /> из заявки
*/

const intervals: NodeJS.Timeout[] = [];

export const EarlyRepaymentCode: FC = () => {
  const dispatch = useDispatch();
  const form = useForm();
  const history = useHistory();

  const loadingAutoAuth = useSelector(
    processLoading.get(authSagaActions.autoAuth.type)
  );

  const [isSendCodeLoading, setIsSendCodeLoading] = useState(true);
  const [isVerifyCodeLoading, setIsVerifyCodeLoading] = useState(false);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [isLoadingDownloadDocument, setIsLoadingDownloadDocument] =
    useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [coderRequested, setCodeRequested] = useState(false);

  const { early_repayment_id, loan_id, amount } = useParams<{
    early_repayment_id: string;
    loan_id: string;
    amount: string;
  }>();

  const code: `${number}` | undefined = useWatch({
    control: form.control,
    name: "code",
  });

  const phoneNumber = useSelector(
    (state: StateType) => state.userReducer.phone
  );

  const errors = useSelector(errorsSelectors.fieldsErrors);

  const codeField = useCodeFieldLogic({
    codeLength: 4,
    onChange: () => {
      dispatch(errorsReducer.actions.removeFieldError("code"));
    },
  });

  const sendCode = () => {
    if (early_repayment_id && loan_id) {
      setCodeRequested(true);
      setIsSendCodeLoading(true);

      API.main.application
        .getPartialEarlyRepaymentCode({
          loan_id,
          early_repayment_id,
        })
        .then((res) => {
          if (res.status === 201) {
            setIsSendCodeLoading(false);
          } else {
            setShowErrorPage(true);
          }
        });
    }
  };

  // Доки формируются секунд 7, для этого пингуем, пока не улетит запрос с 200
  const downloadDocument = () => {
    if (isLoadingDownloadDocument) return;

    setIsLoadingDownloadDocument(true);

    let interval = setInterval(() => {
      API.main.application
        .downloadDocument({
          early_repayment_id,
          loan_id,
          file_type: "not_signed_early_repayment_application",
        })
        .then((res: AxiosResponse<any>) => {
          if (res.status === 200) {
            downloadFile(
              res?.data?.data?.content,
              "Заявление на досрочное погашение",
              "_blank"
            );
            setIsLoadingDownloadDocument(false);
            clearInterval(interval);
          }
        });
    }, 3000);

    intervals.push(interval);
  };

  // значение кода лежит в codeField.value
  const confirm = () => {
    setIsVerifyCodeLoading(true);
    if (early_repayment_id && loan_id) {
      API.main.application
        .verifyPartialEarlyRepaymentCode({
          loan_id,
          early_repayment_id,
          code: code!,
        })
        .then((res) => {
          if (res.status === 200) {
            setIsVerifyCodeLoading(false);
            history.push(
              routes.cabinet.statuses.repayment_signing.root({
                loan_id,
                early_repayment_id,
                amount,
              })
            );
          }
        })
        .catch((res) => {
          dispatch(
            errorsReducer.actions.setFieldsErrors({
              code: "Неверный код!",
            })
          );
          setIsVerifyCodeLoading(false);
        });
    }
  };

  const checkboxesData: CheckboxData[] = [
    {
      name: "pd",
      isChecked: isChecked,
      setIsChecked: setIsChecked,
      onClick: downloadDocument,
      text: "Заявление на досрочное погашение потребительского микрозайма",
      isDownloading: isLoadingDownloadDocument,
    },
  ];

  useEffect(() => {
    // sendCode();

    return () => intervals.forEach((item) => clearInterval(item));
  }, []);

  if (showErrorPage) return <CabinetErrorPage />;

  // * тут нужно поменять текста в зависимости от variant
  // * зачем-то продублирован код для состояний isSendCodeLoading true/false, пока трогать на всякий случай не стал
  return (
    <MobilePageContainer className={styles.wrapper}>
      {loadingAutoAuth ? (
        <Loader variant={"large"} className={styles.loader} />
      ) : (
        <>
          {isSendCodeLoading ? (
            <>
              <div className={styles.container}>
                <Title className={styles.title}>Подпишите заявление</Title>
                <Subtitle className={styles.subtitle}>
                  {coderRequested
                    ? "Введите код, отправленный на номер:"
                    : "Нажмите кнопку \"Получить код\"\nи мы отправим его на номер:"
                  }
                </Subtitle>
                <div className={styles.phone}>
                  {stringHelpers.phoneNormalizeSecondVariant(phoneNumber)}
                </div>
                {/*<Subtitle className={styles.subtitle}>*/}
                {/*  Сумма {stringHelpers.transformMoneyValue(amount)}{" "}*/}
                {/*  <Icons.ui.Ruble />*/}
                {/*</Subtitle>*/}
                {/*<div style={{ marginTop: 24 }} onClick={sendCode}>Получить код</div>*/}

                <SmartForm submit={() => console.log()} form={form}>
                  <div className={styles.code}>
                    <CodeFieldOneInput
                      disabled={!coderRequested}
                      // sendCodeHandler={sendCode}
                      // retryCount={codeField.retryCount}
                      // setRetryCount={codeField.setRetryCount}
                    />
                  </div>
                </SmartForm>

                {/*<CodeField*/}
                {/*  {...codeField}*/}
                {/*  sendCodeHandler={sendCode}*/}
                {/*  error={errors?.code}*/}
                {/*  className={styles.codeField}*/}
                {/*  retryCount={codeField.retryCount}*/}
                {/*  setRetryCount={codeField.setRetryCount}*/}
                {/*test*/}
                {/*/>*/}

                {/*<div className={styles.retry_count}>*/}
                {/*  Попытка {codeField.retryCount} из 5*/}
                {/*</div>*/}

                {/*<div className={styles.pers_data}>*/}
                {/*  Подписывая{" "}*/}
                {/*  {isLoadingDownloadDocument ? (*/}
                {/*    <Loader children={"заявление"} />*/}
                {/*  ) : (*/}
                {/*    <span*/}
                {/*      onClick={downloadDocument}*/}
                {/*      className={styles.pers_data_link}*/}
                {/*    >*/}
                {/*      заявление*/}
                {/*    </span>*/}
                {/*  )}{" "}*/}
                {/*  Вы соглашаетесь <br />с правилами досрочного погашения*/}
                {/*</div>*/}
                <Button
                  disabled={coderRequested && (!code || code.length < 4 || !isChecked)}
                  onClick={coderRequested ? confirm : sendCode}
                  className={styles.button}
                  loading={isVerifyCodeLoading}
                >
                  {coderRequested ? "Подписать" : "Получить код"}
                </Button>
                <div className={styles.timer}>
                  {coderRequested && (
                    <>
                      <CodeTimer
                        sendCodeHandler={sendCode}
                        retryCount={codeField.retryCount}
                        setRetryCount={codeField.setRetryCount}
                      />
                      <div
                        className={cn(styles.retry_count, {
                          // [styles.disabled]: disabled,
                        })}
                      >
                        Попытка {codeField.retryCount} из 5
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={styles.confirm_text}>
                Введя код из СМС, вы подписываете <br /> следующие документы:
              </div>
              <SmartForm submit={() => console.log()} form={form}>
                {checkboxesData.map((checkboxData) => (
                  <CheckboxWithPdf key={checkboxData.name} {...checkboxData} />
                ))}
              </SmartForm>
            </>
          ) : (
            // <LoadingPage
            //   title={""}
            //   subtitle={
            //     <>
            //       Мы загружаем экран оплаты.
            //       <br /> Пожалуйста, подождите!
            //     </>
            //   }
            //   additionalSubtitle={
            //     <>
            //       Оплата будет доступна
            //       <br /> через несколько секунд
            //     </>
            //   }
            //   countdownTime={30}
            // />
            <>
              <div className={styles.container}>
                <Title className={styles.title}>Подпишите заявление</Title>
                <Subtitle className={styles.subtitle}>
                  {coderRequested
                    ? "Введите код, отправленный на номер:"
                    : "Нажмите кнопку \"Получить код\"\nи мы отправим его на номер:"
                  }
                </Subtitle>
                <div className={styles.phone}>
                  {stringHelpers.phoneNormalizeSecondVariant(phoneNumber)}
                </div>
                {/*<div style={{ marginTop: 24 }} onClick={sendCode}>Получить код</div>*/}

                {/*<Subtitle className={styles.subtitle}>*/}
                {/*  Сумма {stringHelpers.transformMoneyValue(amount)}{" "}*/}
                {/*  <Icons.ui.Ruble />*/}
                {/*</Subtitle>*/}

                {/*<CodeField*/}
                {/*  {...codeField}*/}
                {/*  sendCodeHandler={sendCode}*/}
                {/*  error={errors?.code}*/}
                {/*  className={styles.codeField}*/}
                {/*  retryCount={codeField.retryCount}*/}
                {/*  setRetryCount={codeField.setRetryCount}*/}
                {/*/>*/}

                <SmartForm submit={() => console.log()} form={form}>
                  <div className={styles.code}>
                    <CodeFieldOneInput
                      disabled={!coderRequested}
                      // sendCodeHandler={sendCode}
                      // retryCount={codeField.retryCount}
                      // setRetryCount={codeField.setRetryCount}
                    />
                  </div>
                </SmartForm>

                {/*<div className={styles.retry_count}>*/}
                {/*  Попытка {codeField.retryCount} из 5*/}
                {/*</div>*/}

                {/*<div className={styles.pers_data}>*/}
                {/*  Подписывая{" "}*/}
                {/*  {isLoadingDownloadDocument ? (*/}
                {/*    <Loader children={"заявление"} />*/}
                {/*  ) : (*/}
                {/*    <span*/}
                {/*      onClick={downloadDocument}*/}
                {/*      className={styles.pers_data_link}*/}
                {/*    >*/}
                {/*      заявление*/}
                {/*    </span>*/}
                {/*  )}{" "}*/}
                {/*  Вы соглашаетесь <br />с правилами досрочного погашения*/}
                {/*</div>*/}
                <Button
                  disabled={coderRequested && (!code || code.length < 4 || !isChecked)}
                  onClick={coderRequested ? confirm : sendCode}
                  className={styles.button}
                  loading={isVerifyCodeLoading}
                >
                  {coderRequested ? "Подписать" : "Получить код"}
                </Button>
                <div className={styles.timer}>
                  {coderRequested && (
                    <>
                      <CodeTimer
                        sendCodeHandler={sendCode}
                        retryCount={codeField.retryCount}
                        setRetryCount={codeField.setRetryCount}
                      />
                      <div
                        className={cn(styles.retry_count, {
                          // [styles.disabled]: disabled,
                        })}
                      >
                        Попытка {codeField.retryCount} из 5
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={styles.confirm_text}>
                Введя код из СМС, вы подписываете <br /> следующие документы:
              </div>
              <SmartForm submit={() => console.log()} form={form}>
                {checkboxesData.map((checkboxData) => (
                  <CheckboxWithPdf key={checkboxData.name} {...checkboxData} />
                ))}
              </SmartForm>
            </>
          )}
        </>
      )}
    </MobilePageContainer>
  );
};
