import React, { FC } from "react";
import styles from "./index.module.css";
import { Icons } from "assets/icons";
import cn from "classnames";

type propsType = { width: number };
export const Header: FC<propsType> = (props) => {
  return (
    <div className={styles.container}>
      <div
        className={cn(styles.creddy_logo, {
          [styles.creddy_logo_1920]: props.width >= 1023,
        })}
      >
        <a href="https://creddy.ru/pts">
          <Icons.logos.CreddyLogo
            className={cn(styles.image, {
              [styles.image_1920]: props.width >= 1023,
            })}
          />
        </a>
      </div>
    </div>
  );
};
