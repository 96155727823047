import React, { FC, useState } from "react";
import styles from "./index.module.css";
import { Icons } from "../../../../../assets/icons/";
import { useLocation } from "react-router-dom";
import { BurgerMenu } from "../../../../ui/BurgerMenu";
import { routes } from "../../../../../routes";
import { errorApplicationType } from "../../../../../store/reducers/application/types";
import { useSelector } from "react-redux";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";

const titleResolver: { [key: string]: string } = {
  // Дописать еще статусы по мере поступления дезигна
  ["/user/phone"]: "Ввод номера",
  ["/user/code/register"]: "Подтверждение номера телефона",
  ["/user/to_mobile_device"]: "Переход в мобильную версию",
  ["/user/code/register_mobile"]: "Подтверждение номера телефона",
  ["/user/code/register_desktop"]: "Проверка автомобиля",
  ["/user/code/logon_desktop"]: "Ввод кода",
  ["/user/code/logon_mobile"]: "Ввод кода",
  ["/user/wrong_vehicle"]: "Проверка автомобиля",
  ["/user/vehicle_not_found"]: "Проверка автомобиля",
  ["/user/vehicle_error"]: "Проверка автомобиля",
  ["/user/restore_password"]: "Регистрация",
  ["/user/code/restore"]: "Ввод кода",
  ["/user/password"]: "Авторизация",
};

export const AuthHeader: FC = () => {
  const error: errorApplicationType = useSelector(
    applicationReducerSelectors.getError
  );
  const { pathname } = useLocation();

  return (
    <div className={styles.container}>
      <div className={styles.creddy_logo}>
        <a href="https://creddy.ru/pts">
          <Icons.logos.Creddy />
        </a>
      </div>
      <div className={styles.stage_title}>{titleResolver[pathname]}</div>
    </div>
  );
};
