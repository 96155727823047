import { createFC } from "../../../../helpers/createFC";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import styles from "./index.module.css";
import { useState } from "react";
import { downloadFile } from "../../../../helpers/file";
import { Loader } from "../../../ui/Loader";

export const DownloaderPage = createFC(
  (props: { apiMethod: () => Promise<any>; name: string }) => {
    const { id } = useParams<{ id: string }>();
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState("");

    const { isLoading, isFetching } = useQuery({
      queryFn: props.apiMethod,
      cacheTime: 0,
      staleTime: 0,
      retry: 1,
      refetchInterval: 8000,
      enabled: !isSuccess && !error,
      onSuccess: (res: any) => {
        if (res.status == 200) {
          const name = res?.data?.data?.file_name.includes(
            "agreement_rules_analogue"
          )
            ? "Правила аналога подписи"
            : props.name;

          setIsSuccess(true);
          downloadFile(res?.data?.data?.content, name);
        }
      },
      onError: (res: any) => {
        setError(res?.response?.data?.data?.errors?.[0]?.message || "");
      },
    });

    return (
      <div className={styles.container}>
        {isSuccess && (
          <>
            <h3
              style={{
                marginBottom: 30,
              }}
            >
              Скачивание завершено
            </h3>
          </>
        )}

        {error && (
          <h3>
            <div className={styles.error_title}>Ошибка:</div>
            <div style={{ textAlign: "center" }}>{error}</div>
          </h3>
        )}

        {(isLoading || isFetching) && (
          <>
            <Loader
              style={{
                marginBottom: 20,
              }}
              variant={"large"}
            />
            <h3>Идёт скачивание</h3>
          </>
        )}
      </div>
    );
  }
);
