import React, { FC, useRef } from "react";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { useBlurred } from "../../../hooks/logical/useBlurred";
import { Icons } from "../../../assets/icons";
import { Button } from "../Button";
import { modalWindowTemplateType } from "../../../store/reducers/ui/types";
import { uiReducer, uiReducerSelectors } from "../../../store/reducers/ui";

const modalBodyVocabulary: {
  [key in modalWindowTemplateType["status"]]: {
    statusText: string;
    statusIcon: FC;
  };
} = {
  success: {
    statusIcon: Icons.ui.Checkmark,
    statusText: "Успешно",
  },
  error: {
    statusIcon: Icons.ui.CrossWarning,
    statusText: "Ошибка",
  },
};

type propsType = {};
export const ModalWindow: FC<propsType> = (props) => {
  const template = useSelector(uiReducerSelectors.getModal);
  const history = useHistory();
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement | null>(null);
  const closeModal = () => {
    dispatch(uiReducer.actions.setModal(null));
    template?.reload && window.location.reload();
  };

  const closeModalAndBack = () => {
    dispatch(uiReducer.actions.setModal(null));
    history.goBack();
  };

  const handleResetForm = () => {
    template?.handleResetForm && template?.handleResetForm();
    dispatch(uiReducer.actions.setModal(null));
  };

  const redirectTo = () => {
    template?.redirectTo && template?.redirectTo();
    dispatch(uiReducer.actions.setModal(null));
  };

  useBlurred(
    ref,
    template?.button === "group" ? handleResetForm : closeModal,
    Boolean(template)
  );
  if (!template) return null;

  const statusBody = modalBodyVocabulary[template.status];

  return (
    <div className={styles.container}>
      <div
        ref={ref}
        className={cn({
          [styles.container__inner]: true,
          [styles.container__inner_big]: template.button === "group",
        })}
      >
        <div className={styles.body}>
          <h3 className={styles.title}>{statusBody.statusText}</h3>
          <div className={styles.icon}>
            {React.createElement(statusBody.statusIcon)}
          </div>
        </div>
        <p className={styles.message}>{template.message}</p>
        {template.button !== "group" ? (
          <Button
            onClick={
              template.button === "back" ? closeModalAndBack : closeModal
            }
            className={styles.button}
          >
            Закрыть
          </Button>
        ) : (
          <div className={styles.group_buttons}>
            <Button onClick={handleResetForm} className={styles.button}>
              Создать новую
            </Button>
            <Button onClick={redirectTo} className={styles.button}>
              В историю заявок
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
