import styles from "./index.module.css";
import { Icons } from "../../../assets/icons";
import cn from "classnames";
import { Loader } from "../Loader";

export const LoaderPage = () => {
  return (
    <div className={styles.LoaderPage_container}>
      <Icons.loader.LoaderTriangle
        className={cn(styles.triangle, styles.triangle_one)}
      />
      <Icons.loader.LoaderTriangle
        className={cn(styles.triangle, styles.triangle_two)}
      />
      <Icons.loader.LoaderTriangleNext
        className={cn(styles.triangle, styles.triangle_three)}
      />
      <Icons.loader.LoaderTriangleNext
        className={cn(styles.triangle, styles.triangle_four)}
      />
      <div className={styles.center}>
        <Loader variant={"large"} className={styles.LoaderImg}/>
        <Icons.loader.LoaderName className={styles.center_name}/>
      </div>
    </div>
  );
};
