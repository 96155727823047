import { useState } from "react";
import { loanDocsType } from "../../../../../types/documents/types";

export const usePdfDownloading = () => {
  const [isPersonalDataAgreementDownloading, setIsPersonalDataAgreementDownloading] = useState(false);
  const [isApplyingAgreementDownloading, setIsApplyingAgreementDownloading] = useState(false);
  const [isLoanContractDownloading, setIsLoanContractDownloading] = useState(false);
  const [isDepositContractDownloading, setIsDepositContractDownloading] = useState(false);
  const [isApplyingAgreementSecondDownloading, setIsApplyingAgreementSecondDownloading] = useState(false);
  const [isRiskNotificationDownloading, setIsRiskNotificationDownloading] = useState(false);
  const [isOrderDownloading, setIsOrderDownloading] = useState(false);

  const getDownloading = (documentType: loanDocsType | "order" | "not_signed_order") => {
    switch (documentType) {
      case "not_signed_personal_data_agreement":
        return isPersonalDataAgreementDownloading;
      case "applying_agreement":
      case "not_signed_applying_agreement":
        return isApplyingAgreementDownloading
      case "not_signed_loan_contract":
        return isLoanContractDownloading
      case "not_signed_deposit_contract":
        return isDepositContractDownloading
      case "applying_agreement_second":
      case "not_signed_applying_agreement_second":
        return isApplyingAgreementSecondDownloading
      case "not_signed_risk_notification":
        return isRiskNotificationDownloading
      case "not_signed_order":
        return isOrderDownloading
    }
  };

  const setDownloading = (documentType: loanDocsType | "order" | "not_signed_order", value: boolean) => {
    switch (documentType) {
      case "not_signed_personal_data_agreement":
        setIsPersonalDataAgreementDownloading(value);
        break;
      case "applying_agreement":
      case "not_signed_applying_agreement":
        setIsApplyingAgreementDownloading(value);
        break;
      case "not_signed_loan_contract":
        setIsLoanContractDownloading(value);
        break;
      case "not_signed_deposit_contract":
        setIsDepositContractDownloading(value);
        break;
      case "applying_agreement_second":
      case "not_signed_applying_agreement_second":
        setIsApplyingAgreementSecondDownloading(value);
        break;
      case "not_signed_risk_notification":
        setIsRiskNotificationDownloading(value);
        break;
      case "not_signed_order":
        setIsOrderDownloading(value);
        break;
    }
  };

  return { getDownloading, setDownloading };
};
