import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { userReducerType } from "./types";
import { lsController } from "../../auxiliary/localStorage";
import { setTokenToAxios } from "../../../api/methods/setTokenToAxios";
import { setUserIdToApiClass } from "../../../api/methods/setUserIdToApiClass";
import { clearState } from "../../auxiliary/clearState";
import { UserRoleType } from "../../../types/user/types";
import { redirect } from "../../../sagas/util/helpers/redirect";
import { routes } from "../../../routes";
import { push } from "connected-react-router";
import { put } from "redux-saga/effects";

const initialState: userReducerType = {
  user_id: null,
  password: null,
  code: null,
  token: null,
  user_role: null,
  full_name: null,
  last_name: null,
  first_name: null,
  is_has_password: null,
  isFromSms: null,
  isNewUser: false,
  signed_pdn: null,
};

export const userReducer = createSlice({
  name: "@userReducer",
  initialState,
  reducers: {
    setPhone(state, { payload }: PayloadAction<{ phone?: string }>) {
      state.phone = payload.phone;
      lsController.set("phone", payload.phone || null);
    },

    setPassword(
      state,
      { payload }: PayloadAction<{ password: string | null }>
    ) {
      state.password = payload.password;
    },

    setId(state, { payload }: PayloadAction<{ user_id: number | null }>) {
      state.user_id = payload.user_id;
      setUserIdToApiClass(payload.user_id);
    },

    setCode(state, { payload }: PayloadAction<{ code: string | null }>) {
      state.code = payload.code;
    },

    setToken(state, { payload }: PayloadAction<{ token: string | null }>) {
      state.token = payload.token;
      setTokenToAxios(payload.token);
    },

    setUserRole(
      state,
      { payload }: PayloadAction<{ user_role: UserRoleType }>
    ) {
      state.user_role = payload.user_role;

      lsController.set("user_role", payload.user_role || null);
    },

    setFullName(
      state,
      { payload }: PayloadAction<{ full_name: string | null }>
    ) {
      state.full_name = payload.full_name;
      lsController.set("full_name", payload.full_name || null);
    },

    setFirstName(
      state,
      { payload }: PayloadAction<{ first_name: string | null }>
    ) {
      state.first_name = payload.first_name;
      lsController.set("first_name", payload.first_name || null);
    },

    setLastName(
      state,
      { payload }: PayloadAction<{ last_name: string | null }>
    ) {
      state.last_name = payload.last_name;
      lsController.set("last_name", payload.last_name || null);
    },

    setIsHasPassword(
      state,
      { payload }: PayloadAction<{ is_has_password: boolean | null }>
    ) {
      state.is_has_password = payload.is_has_password;
      // скорее всего не нужно
      // lsController.set("is_has_password", payload.is_has_password || "");
    },

    setIsHasSigned_pdn(
      state,
      { payload }: PayloadAction<{ signed_pdn: boolean | null }>
    ) {
      state.signed_pdn = payload.signed_pdn;
      // скорее всего не нужно
      // lsController.set("is_has_password", payload.is_has_password || "");
    },

    /*
      Флаг, который нужен при входе по ссылке из смс
      для автозаполнения кодфилда
      и пропускания post /code в getCodeSaga
     */
    setIsFromSms(
      state,
      { payload }: PayloadAction<{ isFromSms: boolean | null }>
    ) {
      state.isFromSms = payload.isFromSms;
    },

    setIsNewUser(state, { payload }: PayloadAction<{ isNewUser: boolean }>) {
      state.isNewUser = payload.isNewUser;
    },
    /* clear */
    clear: () => {
      setTokenToAxios(null);
      setUserIdToApiClass(null);
      return initialState;
    },
  },
});

export const logout = {
  saga: function* logoutSaga() {
    // @ts-ignore
    yield put(clearState());
    yield redirect(routes.auth.phone);
    lsController.clearAll();
  },
  default: (dispatch: Dispatch) => {
    // @ts-ignore
    dispatch(clearState());
    dispatch(push(routes.auth.phone));
    lsController.clearAll();
  },
};
