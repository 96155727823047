import { useEffect } from "react";
import { sendMetrics } from "../index";

const useTrackTabClose = (ymName: string, gtmName: string, variant?: string) => {
  useEffect(() => {
    if (variant === "contract") {
      return;
    }
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      sendMetrics(ymName, gtmName);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [ymName, gtmName]);
};

export default useTrackTabClose;
