import React, { FC, useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./index.module.css";
import { Loader } from "../Loader";
import { dateMethods, showDMYDate, showYMDDate } from "../../../helpers/date";

export function formatLicensePlate(licensePlate?: string | null) {
  const firstPart = licensePlate?.substring(0, 1).toUpperCase();
  const secondPart = licensePlate?.substring(1, 4);
  const thirdPart = licensePlate?.substring(4, 6).toUpperCase();
  const fourthPart = licensePlate?.substring(6);

  return `${firstPart} ${secondPart} ${thirdPart} ${fourthPart}`;
}

export type propsType = {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  birthDay?: string;
  isCar?: boolean;
  isLoading: boolean;
  className?: string;
  carModel?: string;
  carYear?: number;
  carLicensePlate?: string | null;
};

interface TitleAndValue {
  title: string;
  value?: string | number | null;
}

export const CarInformation: FC<propsType> = (props) => {
  const {
    carModel,
    carYear,
    carLicensePlate,
    isLoading,
    isCar = true,
    firstName,
    middleName,
    lastName,
    birthDay,
  } = props;

  const grz = formatLicensePlate(carLicensePlate);

  const birthDayRender = showDMYDate(birthDay);

  const titleAndValue: TitleAndValue[] = [
    { title: "Марка:", value: carModel ? carModel : " - " },
    { title: "Год производства:", value: carYear ? carYear : " - " },
    { title: "ГРЗ:", value: grz ? grz : " - " },
  ];

  const titleAndValuePersonalData: TitleAndValue[] = [
    { title: "Фамилия:", value: lastName ? lastName : " - " },
    { title: "Имя:", value: firstName ? firstName : " - " },
    { title: "Отчество:", value: middleName ? middleName : " - " },
    { title: "Дата рождения:", value: birthDayRender ? birthDayRender : " - " },
  ];

  return (
    <div className={cn(styles.main_container, props.className)}>
      {isCar && (
        <>
          {titleAndValue.map((item: TitleAndValue, index: number) => (
            <div>
              <div key={index} className={styles.flex_container}>
                <span className={styles.title}>{item.title}</span>
                {isLoading ? (
                  <Loader />
                ) : (
                  <span className={styles.value}>
                    {item.value ? item.value : "-"}
                  </span>
                )}
              </div>
              {index !== titleAndValue.length - 1 && (
                <div className={styles.lane}></div>
              )}
            </div>
          ))}
        </>
      )}
      {!isCar && (
        <>
          {titleAndValuePersonalData.map(
            (item: TitleAndValue, index: number) => (
              <div>
                <div key={index} className={styles.flex_container}>
                  <span className={styles.title}>{item.title}</span>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <span className={styles.value}>
                      {item.value ? item.value : "-"}
                    </span>
                  )}
                </div>
                {index !== titleAndValuePersonalData.length - 1 && (
                  <div className={styles.lane}></div>
                )}
              </div>
            )
          )}
        </>
      )}
    </div>
  );
};
