import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { useHistory, useParams } from "react-router-dom";
import { Loader } from "../../../../ui/Loader";
import { useGetPaymentInfo } from "../../../../../hooks/api/useGetPaymentInfo";
import { Button } from "../../../../ui/Button";
import { routes } from "../../../../../routes";
import { LoadingPage } from "../../../Application/pages/LoadingPage";
import { CabinetErrorPage } from "../Statuses/CabinetErrorPage";
import { Icons } from "../../../../../assets/icons";

type propsType = {};

/*
 не понятно, к чему цеплять крутязееся состояние лоадера.
 В течение всей работы с iframe статус оплаты status: "process_payment",
 после прохождения оплаты сразу status: "success_payment"
 */

//TODO мб не нужно. Это страница LoanPayment по сути, но не совсем
export const EarlyRepaymentIframe: FC<propsType> = (props) => {
  const { loan_id, payment_id } = useParams<{
    loan_id: string;
    payment_id: string;
  }>();
  const history = useHistory();
  // const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);

  const { paymentInfo, isLoading, showFullRepaymentLoader, paymentError } =
    useGetPaymentInfo({
      loan_id,
      payment_id,
    });
  // secure тестануть

  useEffect(() => {
    console.log(iframeRef?.contentWindow);
    console.log(iframeRef?.contentWindow?.location?.pathname);
  }, [iframeRef]);

  /*
   единственное место в проекте, где нужно сделать падинг 0 на контейнере.
    не стал ради этого переписывать mobilePageContainer, который юзается везде - оставил падинг внизу
   */

  if (paymentError) {
    return <CabinetErrorPage />;
  }

  // if (showFullRepaymentLoader) {
  //   return (
  //     <LoadingPage
  //       title={"Ваш платеж успешно принят"}
  //       countdownTime={200}
  //       additionalSubtitle={
  //         <>
  //           Пожалуйста, не закрывайте этот экран. <br />
  //           Обновление информации по займу произойдет автоматически
  //         </>
  //       }
  //     />
  //   );
  // }

  return (
    <div className={styles.container}>
      {/*<div>*/}
      {/*  <div className={styles.title}>*/}
      {/*    Не закрывайте экран до завершения <br/>*/}
      {/*    процесса оплаты <Loader className={styles.loader} />*/}
      {/*  </div>*/}
      {/*  <div className={styles.h_iframe}>*/}
      {/*    <iframe*/}
      {/*      ref={setIframeRef}*/}
      {/*      key={paymentInfo?.payment_url}*/}
      {/*      src={paymentInfo?.payment_url}*/}
      {/*      className={styles.responsive_iframe}*/}
      {/*      scrolling={"no"}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <>
        <div className={styles.icon_cross}>
          {/*TODO svg*/}
          {/*<Icons.ui.CrossBlack />*/}
        </div>
        <div className={styles.title}>Будьте внимательны</div>
        <div className={styles.subtitle}>
          Зачисление при оплате картой в тот же день <br /> только до 22:00.
          Поступление средств по оплате <br />
          банковским переводом - до 3 дней
        </div>
        <div className={styles.icon}>
          <Icons.ui.Stacks />
        </div>
        <a href={paymentInfo?.payment_url} target={"_blank"}>
          <Button className={styles.button}>Перейти к оплате</Button>
        </a>
      </>
    </div>
  );
};
